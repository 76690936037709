const googleMapStyles = [
    {
        featureType: 'administrative.country',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'administrative.land_parcel',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'administrative.locality',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'administrative.land_parcel',
        stylers: [{ visibility: 'off' }],
    },

    {
        featureType: 'administrative.province',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'landscape.man_made',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'landscape.natural',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'landscape.natural.landcover',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'landscape.natural.terrain',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'poi.attraction',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.business',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.government',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.medical',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.park',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'poi.place_of_worship',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.school',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi.sports_complex',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'road.arterial',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'road.highway',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'road.highway.controlled_access',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'road.local',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'transit.line',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'transit.station',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'transit.station.airport',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'transit.station.bus',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'transit.station.rail',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'water',
        stylers: [{ visibility: 'on' }],
    },
];

export default googleMapStyles;
