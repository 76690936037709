import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

import { CommunityComparisonFiltersState } from 'src/app/insights/state/community-comparison/communityComparisonSlice';
import { getCurrentCommunityComparisonUIState } from 'src/app/insights/state/community-comparison/communityComparisonSelector';
import { useAppSelector } from 'src/store';
import { SingleYearCommunityComparisonData } from 'src/app/insights/state/api/queries/communityComparisonQuery';
import useProject from 'src/shared/hooks/useProject';
import { Sample } from 'src/shared/types';
import moment from 'moment';
import { STANDARD_DATE_FORMAT } from 'src/shared/constants/dateConstants';
import styles from './CommunityComparisonChart.module.scss';

const POINT_RADIUS = 5;

type OnChangeParams = Partial<Pick<CommunityComparisonFiltersState, 'selectedSampleGroup' | 'selectedSampleId'>>;

type CommunityComparisonChartProps = {
    data: SingleYearCommunityComparisonData[];
    samples: Sample[];
    onChange: (fragment: OnChangeParams) => void;
};

const CommunityComparisonChart = (props: CommunityComparisonChartProps) => {
    const uiState = useAppSelector(getCurrentCommunityComparisonUIState);

    const { samples } = props;

    const { getColourForArea } = useProject();

    const {
        filters: { selectedSampleGroup, selectedSampleId },
    } = uiState;

    const elementRef = useRef<SVGSVGElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!elementRef.current || !containerRef.current || !tooltipRef.current) {
            return;
        }

        const svgElement = d3.select(elementRef.current);
        svgElement.selectAll('*').remove();

        // Include the tooltip
        const tooltip = tooltipRef.current;

        const svgBoundingClientRect = svgElement.node()?.getBoundingClientRect();

        if (!svgBoundingClientRect) {
            return;
        }
        // Clear selections when a blank space on the svg is clicked.
        svgElement.on('click', event => {
            if (event.target.tagName === 'svg') {
                props.onChange({
                    selectedSampleGroup: null,
                    selectedSampleId: null,
                });
            }
        });
        const samplePoints = props.data.flatMap(entry => entry.points.map(point => point.coordinates)) as [number, number][];

        const domain = getDomain({
            polygonPoints: props.data.flatMap(entry => entry.polys.map(polygon => polygon.coordinates)) as [number, number][],
            samplePoints,
        });

        const xScale = d3.scaleLinear().domain(domain).range([0, svgBoundingClientRect?.width]);

        const yScale = d3.scaleLinear().domain(domain).range([0, svgBoundingClientRect?.height]);

        const circles: { fill: string; fillOpacity: number; cx: number; cy: number; sampleId: string }[] = [];

        props.data.forEach(entry => {
            const innerPath = d3.path();
            innerPath.moveTo(xScale(entry.polys[0].coordinates[0]), yScale(entry.polys[0].coordinates[1]));

            entry.polys.forEach(point => {
                const { coordinates } = point;

                // Now, let's plot the inner points
                innerPath.lineTo(xScale(coordinates[0]), yScale(coordinates[1]));
            });
            innerPath.closePath();

            let ellipseFillOpacity = 0.3;

            if ((selectedSampleGroup && selectedSampleGroup !== entry.variable.value) || selectedSampleId) {
                ellipseFillOpacity = 0.1;
            }

            const sampleGroupPolygon = svgElement
                .append('path')
                .attr('d', innerPath.toString())
                .style('fill', getColourForArea(entry.variable.value))
                .style('cursor', 'pointer')
                .style('fill-opacity', ellipseFillOpacity);

            sampleGroupPolygon.on('click', () => {
                setTimeout(
                    () =>
                        props.onChange({
                            selectedSampleGroup: entry.variable.value,
                            selectedSampleId: null,
                        }),
                    1
                );
            });

            entry.points.forEach(samplePoint => {
                let pointerFillOpacity = 1;
                if (
                    (selectedSampleGroup && selectedSampleGroup !== entry.variable.value) ||
                    (selectedSampleId && selectedSampleId !== samplePoint.sampleId)
                ) {
                    pointerFillOpacity = 0.1;
                }
                circles.push({
                    fill: getColourForArea(entry.variable.value),
                    fillOpacity: pointerFillOpacity,
                    cx: xScale(samplePoint.coordinates[0]),
                    cy: yScale(samplePoint.coordinates[1]),
                    sampleId: samplePoint.sampleId,
                });
            });
        });

        circles.forEach(circle => {
            const circleElement = svgElement
                .append('circle')
                .style('fill', circle.fill)
                .style('fill-opacity', circle.fillOpacity)
                .style('cursor', 'pointer')
                .attr('r', POINT_RADIUS)
                .attr('cx', circle.cx)
                .attr('cy', circle.cy);

            const currentSample = samples.find(sample => sample.sampleId === circle.sampleId);
            const metricValue = currentSample?.metrics.find(metric => metric.metricKey === 'speciesrichness')?.metricValue;
            const dataReceived = moment(currentSample?.sampleReceivedDate).format(STANDARD_DATE_FORMAT);

            circleElement
                .on('mouseover', (event: React.MouseEvent<SVGCircleElement>) => {
                    const tooltipContent = `<div>
                    <div class="${styles.topContent}">
                        <span class="${styles.sampleId}">${circle.sampleId}</span>
                        <span class="${styles.dateLabel}">Date received:</span>
                        <span class="${styles.dateValue}">${dataReceived}</span>
                    </div>
                    <div class="${styles.pointTooltipMetric}">
                        <div class="${styles.pointTooltipMetricLabel}">Species Richness</div>
                        <div class="${styles.pointTooltipMetricValue}">
                        ${metricValue}
                        <span>Species detected</span>
                        </div>
                    </div>
                </div>`;
                    tooltip.innerHTML = tooltipContent;
                    adjustAndDisplayPointerTooltip(event.target as SVGGraphicsElement, tooltip);
                })
                .on('mouseout', () => {
                    tooltip.style.visibility = 'hidden';
                });

            circleElement.on('click', () => {
                props.onChange({
                    selectedSampleId: circle.sampleId,
                    selectedSampleGroup: null,
                });
            });
        });

        return () => {
            svgElement.on('click', null);
        };
    }, [elementRef, containerRef, tooltipRef, selectedSampleGroup, selectedSampleId, props.data]);

    return (
        <div className={styles.communityComparisonChart} ref={containerRef}>
            <svg ref={elementRef}></svg>
            <div className={styles.pointTooltip} ref={tooltipRef}></div>
        </div>
    );
};

const adjustAndDisplayPointerTooltip = (target: SVGGraphicsElement, tooltip: HTMLDivElement) => {
    const circleElement = (target as SVGGraphicsElement).getBBox();
    const { height: tooltipHeight = 0, width: tooltipWidth = 0 } = tooltip.getBoundingClientRect() || {};
    if (circleElement.x < tooltipWidth / 2) {
        tooltip.classList.add(styles.pointTooltipLeft);
        tooltip.style.left = circleElement.x + 17 + 'px';
        tooltip.style.top = circleElement.y - tooltipHeight / 2 + 'px';
    } else {
        tooltip.classList.remove(styles.pointTooltipLeft);
        tooltip.style.left = circleElement.x - tooltipWidth / 2 + 'px';
        tooltip.style.top = circleElement.y - tooltipHeight - (POINT_RADIUS + 2) + 'px';
    }
    tooltip.style.visibility = 'visible';
};

type GetDomainProps = {
    polygonPoints: [number, number][];
    samplePoints: [number, number][];
};

const getDomain = (props: GetDomainProps) => {
    const { polygonPoints, samplePoints } = props;

    const xValues = [...polygonPoints.map(point => point[0]), ...samplePoints.map(point => point[0])];

    const yValues = [...polygonPoints.map(point => point[1]), ...samplePoints.map(point => point[1])];

    return [Number(Math.floor(Math.min(...xValues, ...yValues))), Number(Math.ceil(Math.max(...xValues, ...yValues)))];
};

export default CommunityComparisonChart;
