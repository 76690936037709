import once from 'lodash/once';
import React, { useEffect, useState } from 'react';
import cmsService from 'src/services/cms-service';

import styles from './CMSArticle.module.scss';

export type CMSArticleProps = {
    slug: string;
    substitutions?: { [key: string]: string };
    shouldShowTitle?: boolean;
};

const CMSArticle = (props: CMSArticleProps) => {
    const { slug, substitutions, shouldShowTitle = false } = props;

    const [article, setArticle] = useState<{ content: string; title: string } | null>(null);

    useEffect(() => {
        const fetchArticle = once(async () => {
            let { content, title } = (await cmsService.getArticleBySlug(slug)) || {};
            content = content || '';
            title = title || '';
            content = substitutions ? cmsService.substituteArticlePlaceholders(content, substitutions) : content;

            setArticle({ content, title });
        });

        fetchArticle();
    }, [substitutions]);

    if (!article) {
        return null;
    }
    const content = <span dangerouslySetInnerHTML={{ __html: article.content }} />;

    return (
        <div>
            {shouldShowTitle && article?.title && <div className={styles.title}>{article.title}</div>}
            {content}
        </div>
    );
};

export default CMSArticle;
