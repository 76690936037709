import React, { type ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './ChevronButton.module.scss';
import Chevron, { type Direction } from '../chevron/Chevron';

export type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    direction?: Direction;
    animate?: boolean;
};

const ChevronButton = (props: Props) => {
    const { className, direction = 'down', animate = true, disabled, ...buttonProps } = props;

    const buttonClasses = classNames(styles.chevronButton, styles.button, { [styles.chevronButtonDisabled]: disabled }, className);

    return (
        <button className={buttonClasses} disabled={disabled} {...buttonProps} data-testid='chevron-button'>
            <Chevron direction={direction} animate={animate} />
        </button>
    );
};

export default ChevronButton;
