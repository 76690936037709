export type SampleMetadata = {
    marine: {
        healthIndex: number;
        priceCategoryNumber: number;
        propEstFrom_nmSeqId: number;
        propEstFrom_taxspecies: number;
        trophicLevel: number;
        vulnerability: number;
    };
};

export type Assay = {
    assayId: string;
    assayName: string;
    numberNamedSpecies: number;
    assayQc: {
        percentNonTarget: number;
        percentTarget: number;
    };
};

export type Covariate = {
    canFilter: boolean;
    covariateKey: string;
    covariateName: string;
    covariateValue: string;
    valueType: string;
};

export type Metric = {
    assayId: string;
    metricKey: string;
    metricName: string;
    metricValue: number;
};

export type Sample = {
    kitBarcode: string;
    sampleId: string;
    sampleCollectedDatetime: number;
    sampleDeployDatetime: number;
    sampleReceivedDate: string | null;
    group: string;
    samplingDate: number;
    location: {
        coordinates: number[];
        type: string;
    };
    assays: Assay[];
    covariates: Covariate[];
    metrics: Metric[];
};

export type SampleFilter = {
    fromDate?: string;
    toDate?: string;
    habitatAssay?: string[];
    area?: string[];
    speciesOfInterest?: string;
    sampleIds?: string[];
    species?: string | null;
};

export type IucnSampleFilter = {
    fromDate?: string;
    toDate?: string;
    habitatAssay?: string;
};

export enum SurveySamplingCadenceType {
    SINGLE_YEAR = 'SINGLE_YEAR',
    MULTI_YEAR = 'MULTI_YEAR',
}

export type Samples = Sample[];
