import * as React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { TimeView } from '@mui/x-date-pickers';
import styles from './BasicTimePicker.module.scss';

type BasicTimePickerProps = {
    label?: string;
    value: Date | null;
    disablePast?: boolean;
    disableFuture?: boolean;
    ampm?: boolean;
    views?: TimeView[];
    onChange: (value: Date | undefined) => void;
    disabled?: boolean;
};

const BasicTimePicker = (props: BasicTimePickerProps) => {
    const { label, value, onChange, disablePast, disableFuture, ampm = false, views = ['hours', 'minutes'], disabled = false } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
                label={label}
                value={value ? moment(value || null) : null}
                onChange={value => onChange(value?.toDate())}
                className={styles.basicTimePicker}
                views={views}
                disablePast={disablePast}
                disableFuture={disableFuture}
                ampm={ampm}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
};

export default BasicTimePicker;
