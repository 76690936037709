import React, { ReactNode, useState } from 'react';
import styles from './HelpPopup.module.scss';
import Modal from '../modal/Modal';

type Props = {
    label: ReactNode;
    children: ReactNode;
    onClose?: () => void;
    onOpen?: () => void;
    popupClassNames?: {
        background?: string;
        body?: string;
    };
};

const HelpPopup = (props: Props) => {
    const [isOpen, setOpen] = useState(false);

    const { label, children, onClose, onOpen } = props;

    const modal = (
        <Modal
            onClose={() => {
                setOpen(false);
                onClose?.();
            }}
            classNames={props.popupClassNames}
        >
            {children}
        </Modal>
    );

    return (
        <>
            <span
                onClick={() => {
                    setOpen(true);
                    onOpen?.();
                }}
                className={styles.label}
            >
                {label}
            </span>
            {isOpen && modal}
        </>
    );
};

export default HelpPopup;
