import React from 'react';
import { ReactComponent as TickIcon } from 'src/assets/svg/general/green-tick.svg';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { CMSHabitatAssayTypeWithoutImage } from 'src/shared/types';

const RemainingTestTypes = () => {
    const { remainingTests } = useSamplingEvent();

    return (
        <div>
            <div className='text-sm text-primary flex items-center'>
                Remaining tests
                <QuestionButtonHelp slug='sample-manifest-remaining-tests' type='api' />
            </div>
            <div className='flex gap-4 flex-wrap max-w-[700px]'>
                {remainingTests.map((item, index) => {
                    return (
                        <React.Fragment key={item.testType.habitatAssayKey}>
                            <TestItem testType={item.testType} total={item.total} remaining={item.remaining} />
                            {remainingTests[index + 1] && (
                                <div className='inline-block h-[40px] min-h-[1em] w-0.5 self-stretch bg-primary'></div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

type TestItemProps = {
    testType: CMSHabitatAssayTypeWithoutImage;
    total: number;
    remaining: number;
};

const TestItem = (props: TestItemProps) => {
    const { testType, total, remaining } = props;

    return (
        <div className='flex flex-wrap gap-2 text-primary'>
            <div className='flex gap-2 flex-2 text-primary'>
                <div className='text-primary text-4xl'>
                    {remaining} / {total}
                </div>
                <div>
                    <div className='font-light'>{testType.habitatName}</div>
                    <div className='font-bold'>{testType.assayName}</div>
                </div>
            </div>
            {!remaining && (
                <div className='flex gap-2 items-center'>
                    <TickIcon data-testid='tick-icon' />
                    <div className='text-sm'>Limit reached</div>
                </div>
            )}
        </div>
    );
};

export default RemainingTestTypes;
