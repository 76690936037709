export enum LegendStyleType {
    CIRCLE = 'circle',
    DASHED = 'dashed',
    AREA = 'area',
}

export enum LineStyleType {
    SOLID = 'solid',
    DASHED = 'dashed',
    AREA = 'area',
}

export enum TickFormat {
    YEAR_ONLY = 'Y',
    MONTH_AND_YEAR = 'MMM YYYY',
}

export enum InteractivityPreference {
    TYPE = 'type',
    GROUP_TYPE = 'groupType',
}

export type LegendData = {
    label: string;
    type: string;
    color: string;
    styleType?: LegendStyleType;
    marginTop?: string;
    groupType?: string;
    interactivityPreference?: InteractivityPreference;
};

export type TimeSeriesChartData = {
    type: string;
    data: { date: number; value: number | number[] }[];
    color: string;
    label: string;
    styleType?: LineStyleType;
    groupType?: string;
    interactivityPreference?: InteractivityPreference;
};
