import React from 'react';

import MUIAutocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import styles from './Autocomplete.module.scss';

export type AutocompleteProps = {
    options: string[];
    onChange: (value: string | null) => void;
    onInputChange?: (value: string) => void;
    value: string;
    placeholder?: string;
    width?: string | number;
    required?: boolean;
    variant?: 'filled' | 'outlined';
    prefixIcon?: JSX.Element;
    disableClearable?: boolean;
    inputValue?: string | null;
    customStyle?: React.CSSProperties;
    disabled?: boolean;
};

const Autocomplete = (props: AutocompleteProps) => {
    const {
        placeholder,
        value,
        width = '100%',
        options,
        required = false,
        prefixIcon,
        disableClearable = true,
        onChange,
        onInputChange,
        inputValue,
        customStyle,
        disabled = false,
    } = props;

    return (
        <div className={styles.autocomplete}>
            <MUIAutocomplete
                data-testid='mui-autocomplete'
                sx={{ width, '& .MuiOutlinedInput-root': { padding: '0 9px' } }}
                value={value}
                disablePortal
                disableClearable={disableClearable}
                options={options}
                autoComplete
                placeholder=''
                onChange={(event, value) => onChange(value)}
                onInputChange={(event, value) => onInputChange?.(value)}
                inputValue={inputValue || ''}
                disabled={disabled}
                renderOption={(props, option: string) => (
                    <li {...props} style={customStyle}>
                        {option}
                    </li>
                )}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            required={required}
                            InputProps={{
                                ...params['InputProps'],
                                startAdornment: prefixIcon,
                            }}
                            className={styles.textfield}
                            data-testid='autocomplete-textfield'
                        />
                    );
                }}
            />
        </div>
    );
};

export default Autocomplete;
