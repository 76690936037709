import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area, ReferenceLine } from 'recharts';
import moment from 'moment';
import CustomLabel from './components/CustomLabel';
import styles from './RichnessChart.module.scss';
import { getAxisTicks } from '../chartHelpers';

type RichnessChartProps = {
    chartData: {
        value: number;
        date: number;
    }[];
    baseLineValue: number;
};
const renderTickFormatter = (dateTime: number) => {
    return moment(dateTime).format('MMM YYYY');
};

const DARK_TEAL = '#146568';
const GRADIENT_ID = uniqueId();
const axisTickStyle = {
    fill: '#1E1E1E',
};
const RichnessReChart = (props: RichnessChartProps) => {
    const { chartData, baseLineValue } = props;
    const dates = {
        startDate: chartData[0].date,
        endDate: chartData.at(-1)?.date || moment().valueOf(),
    };

    const xAxisTicks = getAxisTicks(dates) || [];

    return (
        <>
            <ResponsiveContainer width='100%' height={200}>
                <AreaChart
                    width={500}
                    height={200}
                    data={chartData}
                    margin={{
                        top: 10,
                        right: 10,
                        left: 20,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id={GRADIENT_ID} x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='50%' stopColor={DARK_TEAL} stopOpacity={0.2}></stop>
                            <stop offset='75%' stopColor={DARK_TEAL} stopOpacity={0.1}></stop>
                        </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} horizontal={false} />
                    <XAxis
                        dataKey='date'
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={renderTickFormatter}
                        tick={axisTickStyle}
                        type='number'
                        domain={['dataMin', 'dataMax']}
                        dy={5}
                        ticks={xAxisTicks}
                    />

                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        scale='pow'
                        domain={[(dataMin: number) => (dataMin === baseLineValue ? dataMin - (dataMin * 10) / 100 : dataMin), 'dataMax']}
                        tick={axisTickStyle}
                        dx={-5}
                    />
                    <Tooltip labelFormatter={renderTickFormatter} />
                    <ReferenceLine
                        y={baseLineValue}
                        stroke='#A9AFA9'
                        strokeWidth={1.75}
                        strokeDasharray='9 1'
                        className={styles.lozenge}
                        label={<CustomLabel label={baseLineValue} />}
                    />
                    <Area
                        type='monotone'
                        dataKey='value'
                        strokeWidth={2}
                        stroke={DARK_TEAL}
                        fillOpacity={1}
                        fill={`url(#${GRADIENT_ID})`}
                        dot={{ stroke: DARK_TEAL, strokeWidth: 3, r: 2 }}
                        isAnimationActive={false}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default RichnessReChart;
