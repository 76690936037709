import React from 'react';
import useProject from 'src/shared/hooks/useProject';
import { useCommunityComparisonChartDataQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import MetricCard from 'src/shared/components/metric-card/MetricCard';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import MetricViewDifferenceIndicator from 'src/app/dashboard/components/metrics/community-comparison-metric/components/metric-view-difference-indicator/MetricViewDifferenceIndicator';
import communityComparisonImageUrl from 'src/assets/images/dashboard/community_comparison.svg?url';

const CommunityComparisonMetric = () => {
    const chartTitle = 'Community Comparison';
    const { currentProjectSelectedHabitatAssayType: habitatAssayType, currentProjectId } = useProject();

    const { currentData, isFetching } = useCommunityComparisonChartDataQuery(
        {
            projectId: currentProjectId || '',
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    const helpContent = <QuestionButtonHelp type='api' placement='right' slug='community-comparison-helptext' />;

    if (!currentData || !currentData.communityComparison) {
        return <MetricCard header={chartTitle} helpText={helpContent} noData={true} />;
    } else {
        const comparisonData = currentData.communityComparison.data[0].years[0].data;
        const isSignificantlyDifferent = comparisonData.every(entry => entry.comparisons.every(comparison => comparison.sig_diff));
        return (
            <MetricCard
                backgroundImage={communityComparisonImageUrl}
                header={chartTitle}
                content={<MetricViewDifferenceIndicator isDifferent={isSignificantlyDifferent} />}
                helpText={helpContent}
            />
        );
    }
};

export default CommunityComparisonMetric;
