import React, { useState } from 'react';
import classNames from 'classnames';
import Autocomplete from 'src/shared/components/autocomplete/Autocomplete';
import styles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';
import Search from '@mui/icons-material/Search';
import Select from 'src/shared/components/select/Select';
import Textarea from 'src/shared/components/textarea/Textarea';
import { countries } from 'src/shared/data/countries';
import noop from 'lodash/noop';
import Input from 'src/shared/components/input/Input';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import useProjectDefinition from 'src/app/projects/views/new-project-definition/hooks/useProjectDefinition';
import { useCustomerProjectsInBCQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import BasicDatePicker from 'src/shared/components/basic-datepicker/BasicDatePicker';
import ConditionalField from 'src/shared/components/conditional-field/ConditionalField';
import { SubscriptionType } from 'src/shared/types';
import useSubscriptionTypes from '../../../../hooks/useSubscriptionTypes';

export const BasicInformation = () => {
    const {
        formState,
        canCurrentUserContinueForm,
        isInProgressByDifferentUser,
        isCheckingProjectDefinitionPresence,
        isEditMode,
        onFormChange,
    } = useProjectDefinition();
    const { currentCustomerId } = useCustomer();
    const [projectNameSearchText, setProjectNameSearchText] = useState('');
    const [countrySearchText, setCountrySearchText] = useState('');

    const { data: projectsData } = useCustomerProjectsInBCQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !currentCustomerId,
        }
    );

    const { projectSubscriptionTypeOptions } = useSubscriptionTypes(null);

    const onProjectChange = (projectName: string | null) => {
        if (!projectName) {
            return;
        }

        const selectedProjectData = projectsData?.customerProjectsInBC.projects.find(entry => entry.projectName === projectName);

        if (!selectedProjectData) {
            return;
        }

        onFormChange({
            projectId: selectedProjectData.projectId,
            projectName: projectName,
            projectCode: selectedProjectData.projectCode || 'Not set',
            projectLeadEmail: selectedProjectData.leadEmail || 'Not set',
            projectLeadName: selectedProjectData.leadName || 'Not set',
        });
    };

    const onSubscriptionTypeChange = (subscriptionType: string) => {
        onFormChange({
            subscriptionType,
            area: subscriptionType === SubscriptionType.BASIC ? '' : formState.area,
            projectEndDate: subscriptionType === SubscriptionType.BASIC ? '' : formState.projectEndDate,
        });
    };

    const projectNameOptions = (projectsData?.customerProjectsInBC.projects || []).map(entry => entry.projectName) || [];
    const customerName = projectsData?.customerProjectsInBC.customerName;

    if (!customerName) {
        return null;
    }

    const errorMessage = isInProgressByDifferentUser ? (
        <>
            <p>
                An incomplete project definition has already been created for this project by{' '}
                {formState.projectDefinitionCompletionStatus?.metadata?.creatorName}.
            </p>
            <br />
            <p>A new project definition cannot be created until the existing one is deleted or expires. </p>
        </>
    ) : (
        <>
            <p>A project definition has already been created for this project.</p>
            <br />
            <p>Go back to the project admin page to find this project definition or select a new project in the search field above.</p>
        </>
    );

    return (
        <div className={styles.formSection}>
            <div className={styles.formSectionTitle}>Basic information</div>
            <div className={styles.formInputSection}>
                <div className={styles.formInputSectionTitle}>Customer name</div>
                <Input value={customerName} onChange={noop} disabled={true} />
            </div>
            <div
                className={classNames(styles.formInputSection, {
                    [styles.formInputSectionError]:
                        formState.projectName && !isCheckingProjectDefinitionPresence && !canCurrentUserContinueForm,
                })}
            >
                <div className={styles.formInputSectionTitle}>Project name</div>
                <Autocomplete
                    options={['', ...projectNameOptions] || []}
                    placeholder='Search project name'
                    prefixIcon={<Search />}
                    onChange={onProjectChange}
                    onInputChange={setProjectNameSearchText}
                    inputValue={projectNameSearchText}
                    value={formState.projectName}
                    disabled={isEditMode}
                />
                <div style={{ marginTop: '15px' }}>
                    {formState.projectName && !isCheckingProjectDefinitionPresence && !canCurrentUserContinueForm && (
                        <ErrorMessage message={errorMessage} />
                    )}
                </div>
            </div>

            {!isCheckingProjectDefinitionPresence && canCurrentUserContinueForm && (
                <>
                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>Project code</div>
                        <Input value={formState.projectCode} onChange={noop} disabled={true} />
                    </div>

                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>Subscription type</div>
                        <Select
                            className={{ container: styles.selectContainer }}
                            options={projectSubscriptionTypeOptions}
                            selectedValue={formState.subscriptionType}
                            onChange={onSubscriptionTypeChange}
                            width='100%'
                        />
                    </div>

                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>Sampling country</div>
                        <Autocomplete
                            options={['', ...Object.keys(countries).sort()]}
                            placeholder='Search country name'
                            prefixIcon={<Search />}
                            onChange={value => onFormChange({ country: value || '' })}
                            value={formState.country}
                            onInputChange={setCountrySearchText}
                            inputValue={countrySearchText}
                        />
                    </div>

                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>
                            Project end date <QuestionButtonHelp type='api' slug='project-definition-end-date' />
                        </div>
                        <ConditionalField isSatisfied={formState.subscriptionType !== SubscriptionType.BASIC}>
                            <BasicDatePicker
                                value={formState.projectEndDate ? new Date(formState.projectEndDate) : null}
                                onChange={value => onFormChange({ projectEndDate: value?.toDateString() })}
                                disablePast={!isEditMode}
                            />
                        </ConditionalField>
                    </div>

                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>
                            Area
                            <QuestionButtonHelp type='api' slug='project-definition-area' />
                        </div>
                        <ConditionalField isSatisfied={formState.subscriptionType !== SubscriptionType.BASIC}>
                            <Textarea
                                placeholder='Short description of area'
                                value={formState.area}
                                resizable={false}
                                maxLength={40}
                                onChange={event => onFormChange({ area: event.target.value })}
                            />
                        </ConditionalField>
                    </div>
                </>
            )}
        </div>
    );
};

export default BasicInformation;
