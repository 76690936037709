import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import { IndividualRouteSetting, SampleManifestViews } from 'src/routes/routesConfig';
import SampleManifestStartPage from './sample-manifest-start-page/SampleManifestStartPage';
import SampleManifestForm from './sample-manifest-form/SampleManifestForm';

export const samplesRouteSettings: { [key in SampleManifestViews]: IndividualRouteSetting } = {
    startPage: {
        name: 'Ready to start?',
        path: '/',
        handler: <SampleManifestStartPage />,
    },
    form: {
        name: 'Sample Manifest Form',
        path: '/form/*',
        handler: <SampleManifestForm />,
    },
};

const SampleManifest = () => {
    return (
        <Routes>
            {Object.keys(samplesRouteSettings).map(routeKey => {
                const routeSetting = samplesRouteSettings[routeKey as SampleManifestViews];

                return <Route path={routeSetting.path} element={routeSetting.handler} key={routeSetting.name} />;
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default SampleManifest;
