import { IndividualTypeValue, TimeSeriesData } from 'src/shared/types';
import moment from 'moment';
import {
    habitatInsightsLandCoverTypes,
    habitatInsightsConnectivityTypes,
    habitatInsightsPrimaryProductivityTypes,
} from 'src/shared/constants/habitatInsights';
import { TableData, TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import { HabitatInsightsChartType } from 'src/app/maps/views/habitat-insights/state/api/habitatInsightsGraphSlice';

type SiteRecord = { [key: string]: string | (string | number)[] };
type AggregatedTableData = { [key: string]: { [key: string]: SiteRecord[] } };
type ListIndexLookup = { [key: string]: number };

const createIndexLookup = (typeList: string[]): ListIndexLookup => {
    const indexLookup: ListIndexLookup = {};
    typeList.forEach((type, typeIndex) => (indexLookup[type] = typeIndex));
    return indexLookup;
};

const createTableRowDataList = (groupedByDateData: { [key: string]: SiteRecord[] }): TableRowData[] => {
    const dateTimeRecords: TableRowData[] = [];
    const defaultConnectivityList = [0, '0 to 0', 0, '0 to 0', 0, '0 to 0'];
    const defaultPrimaryProductivityList = [0, '0 to 0'];
    Object.values(groupedByDateData).forEach(siteValues => {
        let siteLandCoverList: string | (string | number)[] = Array(habitatInsightsLandCoverTypes.length).fill(0);
        let siteConnectivityList: string | (string | number)[] = defaultConnectivityList;
        let sitePrimaryProductivityList: string | (string | number)[] = defaultPrimaryProductivityList;
        siteValues.forEach(siteVal => {
            if (siteVal[HabitatInsightsChartType.LAND_COVER].length > 0) siteLandCoverList = siteVal[HabitatInsightsChartType.LAND_COVER];
            if (siteVal[HabitatInsightsChartType.HABITAT_CONNECTIVITY].length > 0)
                siteConnectivityList = siteVal[HabitatInsightsChartType.HABITAT_CONNECTIVITY];
            if (siteVal[HabitatInsightsChartType.PRIMARY_PRODUCTIVITY].length > 0)
                sitePrimaryProductivityList = siteVal[HabitatInsightsChartType.PRIMARY_PRODUCTIVITY];
        });

        dateTimeRecords.push([
            siteValues[0].site,
            siteValues[0].date,
            ...siteLandCoverList,
            ...siteConnectivityList,
            ...sitePrimaryProductivityList,
        ]);
    });
    return dateTimeRecords;
};

type ConvertedGroupValues = (number | string)[] | (number | string)[][];

const convertGroupValuesToList = (
    group: IndividualTypeValue[],
    indexLookup: ListIndexLookup,
    chartType: string,
    isBuffer?: boolean
): ConvertedGroupValues => {
    const valueList: ConvertedGroupValues = [];
    Object.values(group).forEach(groupData => {
        const listIndex = indexLookup[groupData.type];
        const bufferValue = groupData.boundary || 0;
        valueList[listIndex] = isBuffer ? bufferValue : groupData.value;
        if (chartType === HabitatInsightsChartType.HABITAT_CONNECTIVITY || chartType === HabitatInsightsChartType.PRIMARY_PRODUCTIVITY) {
            const rangeMinVal = groupData.annualRange?.min || 0;
            const rangeMaxVal = groupData.annualRange?.max || 0;
            valueList[listIndex + 1] = `${rangeMinVal} to ${rangeMaxVal}`;
        }
    });
    return valueList;
};

const aggregateDataBySite = (tableData: TimeSeriesData[]): AggregatedTableData => {
    const aggregatedDataset: AggregatedTableData = {};

    const indexLookup: { [key: string]: ListIndexLookup } = {
        Land_Cover: createIndexLookup(habitatInsightsLandCoverTypes),
        Habitat_Connectivity: createIndexLookup(habitatInsightsConnectivityTypes),
        Primary_Productivity: createIndexLookup(habitatInsightsPrimaryProductivityTypes),
    };

    tableData.forEach(tableDataRecord => {
        const { datetime, chartType, site } = tableDataRecord;
        if (chartType && site) {
            const siteRecord: SiteRecord = createSiteRecord(site, datetime, chartType, tableDataRecord, indexLookup, false);
            const siteNameForBuffer = `${site} - Buffer`;
            const siteRecordForBuffer: SiteRecord = createSiteRecord(
                siteNameForBuffer,
                datetime,
                chartType,
                tableDataRecord,
                indexLookup,
                true
            );

            aggregatedDataset[datetime] ??= {};
            aggregatedDataset[datetime][site] ??= [];
            aggregatedDataset[datetime][siteNameForBuffer] ??= [];
            aggregatedDataset[datetime][site].push(siteRecord);
            aggregatedDataset[datetime][siteNameForBuffer].push(siteRecordForBuffer);
        }
    });

    return aggregatedDataset;
};

export const transformHabitatInsightsTableData = (input: TimeSeriesData[]): TableData => {
    const output: TableData = [];

    const aggregatedDataset = aggregateDataBySite(input);
    const sortedDateList = Object.keys(aggregatedDataset).sort();

    sortedDateList.forEach((dateValue: string) => {
        const tableRowDataList = createTableRowDataList(aggregatedDataset[dateValue]);
        output.push(...tableRowDataList);
    });

    return output;
};

function createSiteRecord(
    site: string,
    datetime: string,
    chartType: string,
    tableDataRecord: TimeSeriesData,
    indexLookup: { [key: string]: ListIndexLookup },
    isBufferRecord: boolean
) {
    const siteRecord: SiteRecord = {
        site: site,
        date: moment(parseInt(datetime)).format('Y'),
        Land_Cover: [],
        Habitat_Connectivity: [],
        Primary_Productivity: [],
    };

    siteRecord[chartType] = convertGroupValuesToList(tableDataRecord.group, indexLookup[chartType], chartType, isBufferRecord) as number[];
    return siteRecord;
}
