import { capitalize, map } from 'lodash';
export const getRoleLabel = (role: string) => {
    if (role.startsWith('NM_')) {
        role = role.slice(3);
    }
    return map(role.split('_'), capitalize).join(' ');
};

export const formatPhoneNumberWithCountryCode = (phoneCode: string | undefined, phone: string | undefined): string => {
    const countryCode = phoneCode ?? '';
    const phoneNumber = phone?.replace(countryCode, '') ?? '';
    return `${countryCode}${phoneNumber}`;
};
