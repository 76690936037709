import { RootState } from 'src/store';
import { SiteMenuUIState, initialSiteMenuState } from './siteMenuSlice';

export const getSiteMenuState = (state: RootState) => {
    return state.global.siteMenu || initialSiteMenuState;
};

export const getSiteMenuUIState = (state: RootState): SiteMenuUIState => {
    return getSiteMenuState(state).uiState;
};

export const getIsSiteMenuOpen = (state: RootState): boolean => {
    return getSiteMenuUIState(state).isOpen;
};
