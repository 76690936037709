import React, { useEffect, useState, useMemo } from 'react';
import { noop } from 'lodash';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as CompleteIcon } from 'src/assets/svg/general/complete.svg';
import DataTable from 'src/shared/components/data-table/DataTable';
import { ReactComponent as CloseIcon } from 'src/assets/svg/general/close.svg';
import { DataTableColumns, DataTableState, TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import ExpandableSection from 'src/shared/components/expandable-section/ExpandableSection';
import Loader from 'src/shared/components/loader/Loader';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { getSamplingKitFieldDataStatus } from 'src/shared/helpers/kitHelpers';
import { FieldDataStatus, SamplingEventBatchStatus, SamplingKit, SamplingKitStatus } from 'src/shared/types';
import SamplingEventBatchStatusIndicator from 'src/shared/components/sampling-event-batch-status-indicator/SamplingEventBatchStatusIndicator';
import moment from 'moment';
import TestTypeChip from 'src/shared/components/test-type-chip/TestTypeChip';
import classNames from 'classnames';
import { getBatchNumberFromBatchId } from 'src/app/samples/samplesHelpers';
import DataControl from 'src/app/samples/sample-reception/views/view-sample-manifest/components/data-control/DataControl';
import Tooltip from 'src/shared/components/tooltip/Tooltip';
import FieldDataStatusIndicator from 'src/app/samples/sample-manifest/sample-manifest-form/views/field-data/components/field-data-main-content/components/field-data-status-indicator/FieldDataStatusIndicator';
import PopOver from 'src/shared/components/pop-over/PopOver';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import ProcessingControl from './components/processing-control/ProcessingControl';
import styles from './SamplingEventBatch.module.scss';

const defaultColumns: DataTableColumns = [
    {
        columnId: 'sampleId',
        title: '',
        isUnique: true,
    },
    {
        columnId: 'sampleName',
        title: 'Sample Name',
    },
    {
        columnId: 'kitBarcode',
        title: 'Kit barcode',
    },
    {
        columnId: 'sampler',
        title: 'Sampler',
    },
    {
        columnId: 'samplingDate',
        title: 'Sampling date',
    },
    {
        columnId: 'samplingTime',
        title: 'Sampling time',
    },
    {
        columnId: 'latitude',
        title: 'Latitude',
    },
    {
        columnId: 'longitude',
        title: 'Longitude',
    },
    {
        columnId: 'habitat',
        title: 'Habitat',
    },
    {
        columnId: 'volumeFiltered',
        title: 'Volume filtered (mL)',
    },
    {
        columnId: 'tests',
        title: 'Test(s)',
    },
    {
        columnId: 'notes',
        title: 'Field notes',
    },
];

type SamplingEventBatchProps = {
    batchId: string;
    columns?: DataTableColumns;
    showDataControl?: boolean;
    isExpanded?: boolean;
    showBatchInfo?: boolean;
    showPendingStatus?: boolean;
    showAmendControl?: boolean;
    isViewOnly?: boolean;
    handleRowClick?: (row: TableRowData) => void;
};

const SamplingEventBatch = (props: SamplingEventBatchProps) => {
    const [selectedKit, setSelectedKit] = useState<string | null>(null);
    const {
        batchId,
        columns = defaultColumns,
        showDataControl = true,
        isExpanded = true,
        showBatchInfo = true,
        showPendingStatus = false,
        showAmendControl = false,
        isViewOnly = false,
        handleRowClick = noop,
    } = props;
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        rowsPerPage: Number.POSITIVE_INFINITY,
        hiddenColumnIds: new Set(['sampleId']),
    });

    const rejectExplainerInfo = <CMSArticle slug='sample-manifest-batch-reject-sample-tests-info' />;

    const tableColumns = showAmendControl
        ? [
              {
                  columnId: 'amend',

                  title: 'Proceed for \n processing ',
                  renderer(params: { rowData: TableRowData }) {
                      return <ProcessingControl samplingKitId={params.rowData[0] as string} isDisabled={requiredCustomerAmendment} />;
                  },
              },
              ...columns,
          ]
        : columns;
    const { getKitsByBatchId, getBatchById, samplingEvent, isBatchOnlySubmit, saveSamplingKit } = useSamplingEvent();
    const [totalCompleteSamples, setTotalCompleteSamples] = useState(0);
    const currentBatchKits = getKitsByBatchId(batchId);
    const currentBatch = getBatchById(batchId);
    const approvedBatchByLab = currentBatch?.status === SamplingEventBatchStatus.APPROVED_BY_LAB;
    const requiredCustomerAmendment = currentBatch?.status === SamplingEventBatchStatus.REQUIRED_CUSTOMER_AMENDMENT;
    const isReadOnly = !isBatchOnlySubmit && !showAmendControl && !isViewOnly;

    const wrapWithDiv = (content: React.ReactNode, className: string) => {
        return <div className={className}>{content}</div>;
    };

    const getColumnClassNames = useMemo(
        () => (kit: SamplingKit, columnId: string) => {
            if (!showAmendControl) {
                return '';
            }
            const isFlagged = kit.status === SamplingKitStatus.FLAGGED || kit.status === SamplingKitStatus.REJECTED_BY_LAB;
            return classNames({
                [styles['kit-approved']]: kit.status === SamplingKitStatus.APPROVED_BY_LAB,
                [styles['kit-flagged']]: isFlagged,
                [styles['kit-approved-name']]: columnId === 'sampleName' && kit.status === SamplingKitStatus.APPROVED_BY_LAB,
                [styles['kit-flagged-name']]: columnId === 'sampleName' && isFlagged,
            });
        },
        [showAmendControl]
    );

    const getRejectedColumnClassNames = useMemo(
        () => (kit: SamplingKit, columnId: string) => {
            if (kit.status === SamplingKitStatus.REJECTED_BY_LAB && (requiredCustomerAmendment || approvedBatchByLab)) {
                return classNames(styles['kit-flagged'], {
                    [styles['kit-flagged-name']]: columnId === 'sampleName',
                    [styles['kit-flagged-selected']]: kit.id === selectedKit,
                });
            }
            return '';
        },
        [currentBatch?.status, selectedKit]
    );

    const processBatchKits = (columns: DataTableColumns) => {
        let totalCompleteSamples = 0;
        const removeTestType = async ({ kit, habitatAssayKey }: { kit: SamplingKit; habitatAssayKey: string }) => {
            const newTestTypes = kit.testTypes.filter(test => test.habitatAssayKey !== habitatAssayKey);
            await saveSamplingKit({
                ...kit,
                testTypes: newTestTypes,
            });
        };

        const data = currentBatchKits.map(kit => {
            // Field data status can be None, Incomplete, or Complete
            const fieldDataStatus = getSamplingKitFieldDataStatus(kit);

            if (fieldDataStatus === FieldDataStatus.COMPLETE) {
                totalCompleteSamples++;
            }

            const tests = (
                <div className='flex gap-2'>
                    {kit.testTypes.map(test =>
                        isBatchOnlySubmit ? (
                            <div className='flex items-center bg-[#F6F6F6] rounded-md pr-2' key={test.habitatAssayKey}>
                                <TestTypeChip testTypeKey={test.habitatAssayKey} />
                                <CloseIcon
                                    className='cursor-pointer h-4 w-4'
                                    onClick={() =>
                                        removeTestType({
                                            kit: kit,
                                            habitatAssayKey: test.habitatAssayKey,
                                        })
                                    }
                                />
                            </div>
                        ) : (
                            <TestTypeChip key={test.habitatAssayKey} testTypeKey={test.habitatAssayKey} />
                        )
                    )}
                </div>
            );

            // Trim notes to 40 character and add ellipsis
            const truncatedNotes = kit.notes.length > 40 ? `${kit.notes.substring(0, 40)}...` : kit.notes;
            const notesTooltip = kit.notes ? <Tooltip content={kit.notes}>{truncatedNotes}</Tooltip> : <span>None provided</span>;

            return columns.map(column => {
                const columnClassNames = showAmendControl
                    ? getColumnClassNames(kit, column.columnId)
                    : getRejectedColumnClassNames(kit, column.columnId);

                const showRejectedInfo =
                    kit.status === SamplingKitStatus.REJECTED_BY_LAB &&
                    (requiredCustomerAmendment || approvedBatchByLab) &&
                    !showAmendControl;

                const sampleName =
                    column.columnId === 'sampleName' && showRejectedInfo ? (
                        <div className='flex flex-row gap-1'>
                            <PopOver content={rejectExplainerInfo} icon={<ErrorIcon className='text-red-100' />} />
                            {kit.name}
                        </div>
                    ) : (
                        kit.name
                    );

                const testInfo = showRejectedInfo ? <span>test(s) removed</span> : tests;

                const readyToSubmitIcon = kit.testTypes.length > 0 && (
                    <div className='flex justify-center'>
                        <CompleteIcon data-testid='complete-icon' />
                    </div>
                );

                switch (column.columnId) {
                    case 'amend':
                        return kit.id;
                    case 'sampleId':
                        return wrapWithDiv(kit.id, columnClassNames);
                    case 'sampleName':
                        return wrapWithDiv(sampleName, columnClassNames);
                    case 'kitBarcode':
                        return wrapWithDiv(kit.barcode, columnClassNames);
                    case 'sampler':
                        return wrapWithDiv(kit.sampler, columnClassNames);
                    case 'samplingDate':
                        return wrapWithDiv(moment(kit.sampledAt).format('DD MMM YYYY'), columnClassNames);
                    case 'samplingTime':
                        return wrapWithDiv(kit.sampledTime ? moment(kit.sampledTime).format('hh:mm A') : 'Not provided', columnClassNames);
                    case 'latitude':
                        return wrapWithDiv(kit.latitude, columnClassNames);
                    case 'longitude':
                        return wrapWithDiv(kit.longitude, columnClassNames);
                    case 'habitat':
                        return wrapWithDiv(kit.habitat, columnClassNames);
                    case 'volumeFiltered':
                        return wrapWithDiv(kit.volumeFiltered || 'N/A', columnClassNames);
                    case 'tests':
                        return wrapWithDiv(testInfo, columnClassNames);
                    case 'notes':
                        return wrapWithDiv(notesTooltip, columnClassNames);
                    case 'fieldData':
                        return wrapWithDiv(<FieldDataStatusIndicator status={fieldDataStatus as FieldDataStatus} />, columnClassNames);
                    case 'readyToSubmit':
                        return wrapWithDiv(readyToSubmitIcon, columnClassNames);

                    default:
                        return null;
                }
            });
        });
        return { data, totalCompleteSamples };
    };

    useEffect(() => {
        if (!currentBatchKits) {
            return;
        }
        const { data, totalCompleteSamples } = processBatchKits(tableColumns);
        setTotalCompleteSamples(totalCompleteSamples);
        setTableState({ ...tableState, data });
    }, [currentBatchKits.length, samplingEvent, selectedKit]);

    if (!tableState.data || !currentBatch || !samplingEvent) {
        return <Loader />;
    }

    const tableClassNames = classNames('flex-1 bg-white rounded-md mt-2', styles.table, {
        [styles['submitted']]: !showDataControl,
        'text-grey-60': isReadOnly,
    });

    const rowClick = (row: TableRowData) => {
        const rowId = React.isValidElement(row[0]) ? (row[0].props.children as string) : (row[0] as string);
        setSelectedKit(rowId);
        handleRowClick(row);
    };

    const table = (
        <DataTable
            state={tableState}
            columns={tableColumns}
            emptyStateMessage={'No samples to display.'}
            className={tableClassNames}
            onRowSelect={rowClick}
        />
    );

    const dataControl = <DataControl samplingEventBatch={currentBatch} />;

    const separator = <div className='inline-block h-[20px] min-h-[1em] w-0.5 bg-grey-60'></div>;
    // Batch number is eventId + B + number
    const batchNumber = getBatchNumberFromBatchId(batchId, samplingEvent.identifier);
    const isBatchCompleted = approvedBatchByLab;
    const batchStatus =
        !showAmendControl && requiredCustomerAmendment ? SamplingEventBatchStatus.CUSTOMER_AMENDMENT_REQUESTED : currentBatch.status;
    const toggle = (
        <div className='pl-8 flex gap-4 items-center overflow-auto'>
            <div className='flex flex-col'>
                <span>Batch {batchNumber}</span>
                {showBatchInfo && <span className='text-grey-80'>Batch ID: {`${samplingEvent?.identifier}-B${batchNumber}`}</span>}
            </div>
            <div className='text-sm text-grey-80'>{totalCompleteSamples} complete sample (s)</div>

            {!showPendingStatus && currentBatch?.createdAt && (
                <>
                    {separator}
                    <div className='text-sm text-grey-80'>Submitted {moment(currentBatch.createdAt).format('DD MMM YYYY')}</div>
                </>
            )}

            {!showPendingStatus && currentBatch?.logisticsApprovalAt && (
                <>
                    {separator}
                    <div className='text-sm text-grey-80'>Accepted {moment(currentBatch.logisticsApprovalAt).format('DD MMM YYYY')}</div>
                </>
            )}

            <div>
                <SamplingEventBatchStatusIndicator status={batchStatus || SamplingEventBatchStatus.CREATED} />
            </div>
            {isReadOnly && (
                <div className='ml-auto text-teal-100'>
                    Read Only &nbsp;
                    <LockOutlinedIcon />
                </div>
            )}
        </div>
    );

    const content = (
        <ExpandableSection
            isExpanded={!isBatchCompleted && isExpanded}
            classNames={{
                wrapper: 'w-full',
                toggle: 'left-[25px] top-[6px]  right-auto',
            }}
            expandedContent={
                <div className='flex flex-col flex-1'>
                    {toggle}
                    {table}
                    {showDataControl && dataControl}
                </div>
            }
            collapsedContent={toggle}
        />
    );

    return <div className='flex overflow-y-auto border-b border-grey-20 pb-2'>{content}</div>;
};

export default SamplingEventBatch;
