import React from 'react';
import classNames from 'classnames';
import styles from './NoData.module.scss';

type NoDataProps = {
    className?: string;
    text?: string;
    subText?: string;
    image?: string;
    height?: string;
};
const NoData = (props: NoDataProps) => {
    const { text = 'No Data', subText, image, height = '75vh' } = props;
    return (
        <div className={classNames(styles.noDataContainer, props.className)} style={{ height: height }} data-testid='no-data-container'>
            {image && <img src={image} alt='No data' className={styles.noDataImage} />}
            <h3 className={styles.noDataTextContainer}>{text}</h3>
            <p className={styles.noDataTextContainer}>{subText}</p>
        </div>
    );
};

export default NoData;
