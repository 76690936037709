import { Customer } from 'src/shared/types';
import { RootState } from 'src/store';

export const getCurrentCustomerId = (state: RootState): string | null => {
    return state.customers?.currentCustomerId || null;
};

export const getCurrentCustomerDetails = (state: RootState): Pick<Customer, 'customerId' | 'customerName'> | null => {
    const currentCustomerId = state.customers?.currentCustomerId;

    if (!currentCustomerId || !state.customers.allCustomers) {
        return null;
    }

    return state.customers.allCustomers.find(customer => customer.customerId === currentCustomerId) || null;
};

export const getAllCustomers = (state: RootState): Pick<Customer, 'customerId' | 'customerName'>[] => {
    return state.customers.allCustomers || [];
};
