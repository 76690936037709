import { AppName } from 'src/global/globalConfig';
import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';

const useDemoProjectAnalytics = () => {
    const sendTrackEvent = (ga: AnalyticsOptions) => {
        analyticsTracking.trackEvent({
            ...ga,
        });
    };

    const trackInterestRegistration = (appName: string) => {
        sendTrackEvent({
            action: 'demo_project_register_interest',
            category: 'demo-project',
            app: appName,
        });
    };

    const trackDemoProjectSelection = (projectName: string) => {
        sendTrackEvent({
            action: 'demo_project_selection',
            category: 'demo-project',
            app: AppName.DASHBOARD,
            label: projectName,
            project: projectName,
        });
    };

    return {
        trackInterestRegistration,
        trackDemoProjectSelection,
    };
};

export default useDemoProjectAnalytics;
