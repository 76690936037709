import { gql } from 'graphql-request';
import { HabitatInsightsChartType } from '../habitatInsightsGraphSlice';

export type HabitatInsightsMapSidebarData = {
    data: {
        chartType: HabitatInsightsChartType;
        datetime: string;
        group: {
            type: string;
            value: number;
            boundary: number;
            annualRange: {
                min: number;
                max: number;
                boundaryMin: number;
                boundaryMax: number;
            };
        }[];
        site: string;
    }[];
};

export const habitatInsightsMapSidebarDataQuery = gql`
    query getHabitatInsightsChartData(
        $projectId: String!
        $fromDate: String
        $toDate: String
        $siteName: [String!]
        $hlcaCharts: [HlcaChartType!]
    ) {
        habitatInsightsMapSidebarData: chart(
            projectId: $projectId
            chartId: "habitatinsightschart"
            hlcaCharts: $hlcaCharts
            siteName: $siteName
            fromDate: $fromDate
            toDate: $toDate
        ) {
            data
        }
    }
`;

export type HabitatInsightsMapSidebarDataQueryResult = {
    habitatInsightsMapSidebarData: HabitatInsightsMapSidebarData;
};
