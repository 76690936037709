import { useState, useRef } from 'react';

type UseBooleanHelpers = {
    toggle: () => void;
    on: () => void;
    off: () => void;
};

const useBoolean = (initialValue: boolean): [boolean, UseBooleanHelpers] => {
    const [value, setValue] = useState(initialValue);

    const updateValue = useRef<UseBooleanHelpers>({
        toggle: () => setValue(oldValue => !oldValue),
        on: () => setValue(true),
        off: () => setValue(false),
    });

    return [value, updateValue.current];
};

export default useBoolean;
