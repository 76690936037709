import React, { Suspense, lazy } from 'react';

import commonStyles from 'src/app/surveys/views/new-survey-design/NewSurveyDesign.module.scss';
import classNames from 'classnames';
import SurveySamplesUpload from './components/SurveySamplesUpload';
import useSurveyDesign from '../../hooks/useSurveyDesign';

const LazySurveySamplesReview = lazy(() => import('./components/SurveySamplesReview'));

export const SurveySamples = () => {
    const { formState } = useSurveyDesign();

    const { uploadedFileContent, surveySamples } = formState;
    const formViewClassNames = classNames(commonStyles.formView, commonStyles.formViewFullWidth);

    return (
        <div className={formViewClassNames}>
            <div className={commonStyles.formViewTitle}>Samples</div>
            {!uploadedFileContent && !surveySamples.length && <SurveySamplesUpload />}
            {(uploadedFileContent || surveySamples.length > 0) && (
                <Suspense fallback='Loading...'>
                    <LazySurveySamplesReview />
                </Suspense>
            )}
        </div>
    );
};

export default SurveySamples;
