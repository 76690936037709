import React from 'react';
import Modal from 'src/shared/components/modal/Modal';
import SubmittedImage from 'src/assets/images/samples/submitted.png';
import { ReactComponent as ReturnIcon } from 'src/assets/svg/general/return-arrow.svg';
import { SecondaryButton } from 'src/shared/components/button/Button';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import PrintBatchButton from 'src/app/samples/sample-manifest/sample-manifest-start-page/components/PrintBatchButton';

type SuccessfullySubmittedModalProps = {
    batchId: string;
};

const SuccessfullySubmittedModal = ({ batchId }: SuccessfullySubmittedModalProps) => {
    const navigation = useAppNavigation();
    const { refetchSamplingEvent, isBatchOnlySubmit } = useSamplingEvent();

    const onReturnClick = () => {
        refetchSamplingEvent();
        navigation.toSampleManifest();
    };
    return (
        <Modal
            classNames={{
                body: 'w-[750px]',
            }}
        >
            <div className='flex flex-col gap-6'>
                <div className='flex justify-center'>
                    <img height='160px' width='160px' src={SubmittedImage} alt='Successfully submitted' />
                </div>
                <div className='text-lg text-primary'>
                    Your data has been successfully {isBatchOnlySubmit ? 'resubmitted' : 'submitted'}!
                </div>
                <div className='flex flex-col gap-6 text-sm text-grey-80'>
                    <span>
                        NatureMetrics will review your data submission over the coming days to ensure all the data meets our requirements to
                        create your report.
                    </span>
                    <span>
                        If you have remaining sample data to submit for this event, you can begin the submission process again from the
                        sample manifest home page
                    </span>
                    {!isBatchOnlySubmit && (
                        <span> Please print these submitted data by clicking the option below or on the sample manifest home page</span>
                    )}
                </div>

                <div className={`flex gap-2 ${!isBatchOnlySubmit ? 'justify-center' : 'justify-start'} mx-auto`}>
                    {!isBatchOnlySubmit && <PrintBatchButton isInSubmitPopup={true} batchId={batchId} />}
                    <SecondaryButton className='flex gap-2' onClick={onReturnClick}>
                        <ReturnIcon /> Return to sample manifest home page
                    </SecondaryButton>
                </div>
            </div>
        </Modal>
    );
};

export default SuccessfullySubmittedModal;
