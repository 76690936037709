import React from 'react';

import useDataTable from 'src/shared/components/data-table/hooks/useDataTable';
import TableHeaderCell from './components/table-header-cell/TableHeaderCell';

import styles from './TableHeader.module.scss';

const TableHeader = () => {
    const { columns, data, hiddenColumnIds } = useDataTable();

    if (!columns || !data) {
        return null;
    }

    return (
        <thead className={styles.header} data-testid='table-header'>
            <tr>
                {columns.map((column, index) => {
                    const currentColumn = columns[index];
                    if (hiddenColumnIds && hiddenColumnIds.has(currentColumn.columnId)) {
                        return null;
                    }

                    const Contents = () => <TableHeaderCell {...column} key={column.columnId} />;

                    return <Contents key={index} />;
                })}
            </tr>
        </thead>
    );
};

export default TableHeader;
