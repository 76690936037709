import React from 'react';
import styles from './CMSTechnicalExplainer.module.scss';

export type CMSTechincalExplainerProps = {
    title: React.ReactNode;
    content: React.ReactNode;
};

const CMSTechincalExplainer = (props: CMSTechincalExplainerProps) => {
    return (
        <div>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: props.content as string }}></div>
        </div>
    );
};

export default CMSTechincalExplainer;
