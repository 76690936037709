import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import { customersQuery, CustomersQueryResult, customerQuery, CustomerQueryResult } from './queries/customersQuery';

const customersGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        customers: builder.query<CustomersQueryResult, unknown>({
            query: () => ({
                body: customersQuery,
                variables: undefined,
            }),
            transformResponse: (response: CustomersQueryResult) => {
                return {
                    customers: response?.customers.map(customer => {
                        return {
                            ...customer,
                            customerCode: !customer.customerCode || customer.customerCode === 'Not set' ? 'Missing' : customer.customerCode,
                        };
                    }),
                };
            },
        }),

        customer: builder.query<CustomerQueryResult['customer'], { customerId: string }>({
            query: params => ({
                body: customerQuery,
                variables: params,
            }),
            transformResponse: (response: CustomerQueryResult) => {
                return response.customer;
            },
        }),
    }),
});

export const { useCustomersQuery, useCustomerQuery } = customersGraphSlice;

export const { customers: fetchCustomers } = customersGraphSlice.endpoints;
