import { RootState } from 'src/store';
import { StatisticalComparisonUIState, StatisticalComparisonFiltersState, defaultUiState } from './statisticalComparisonSlice';

export const getCurrentStatisticalComparisonUIState = (state: RootState): StatisticalComparisonUIState => {
    const currentProjectId = state.projects.projectList?.currentProjectId;

    if (!currentProjectId) {
        return defaultUiState;
    }

    return state.insights.statisticalComparison.uiState[currentProjectId] || defaultUiState;
};

export const getStatisticalComparisonFilters = (state: RootState): StatisticalComparisonFiltersState => {
    const currentUiState = getCurrentStatisticalComparisonUIState(state);

    return currentUiState.filters;
};
