import moment from 'moment';
import {
    CMSHabitatAssayTypeWithoutImage,
    SamplingEventBatchStatus,
    SamplingEventStatus,
    SamplingKit,
    SamplingKitStatus,
    SamplingEventBatch,
    FieldDataStatus,
    SamplingEventBatchAmendmentOwner,
} from 'src/shared/types';
import { useSearchParams } from 'react-router-dom';
import { isKitFieldDataCompleted } from 'src/shared/helpers/kitHelpers';
import { getSamplingKitFieldDataStatus } from 'src/shared/helpers/kitHelpers';
import {
    useInitiateSampleManifestEventMutation,
    useSamplingEventQuery,
    useSubmitSampleKitsForProcessingMutation,
    useLazySamplingEventQuery,
    useUpdateSampleKitMutation,
    useBatchApprovedByLogisticsMutation,
    useBatchApprovedByLabMutation,
    useBatchAmendmentRequiredMutation,
} from '../sample-manifest/state/api/sampleManifestGraphSlice';

export type RemainingTest = {
    testType: CMSHabitatAssayTypeWithoutImage;
    total: number;
    remaining: number;
    used: number;
};

const useSamplingEvent = (params?: { samplingEventId?: string }) => {
    const { samplingEventId: samplingEventIdFromProps } = params || {};
    const [searchParams] = useSearchParams();
    const [fetchSamplingEvent] = useLazySamplingEventQuery();
    const samplingEventIdFromUrl = searchParams.get('samplingEventId') as string;
    const currentProjectId = searchParams.get('projectId');
    const currentBatchId = searchParams.get('batchId');
    const samplingEventId = samplingEventIdFromProps || samplingEventIdFromUrl;
    const [saveUpdatedSamplingKit] = useUpdateSampleKitMutation();
    const [submitSamplingKitsMutation] = useSubmitSampleKitsForProcessingMutation();
    const [initiateSampleManifestEvent] = useInitiateSampleManifestEventMutation();
    const [approveBatchByLogisticsMutation] = useBatchApprovedByLogisticsMutation();
    const [approveBatchByLabMutation] = useBatchApprovedByLabMutation();

    const [amendmentRequiredForBatchMutation] = useBatchAmendmentRequiredMutation();

    const { currentData, isFetching } = useSamplingEventQuery(
        {
            samplingEventId: samplingEventId,
            projectId: currentProjectId || '',
        },
        {
            skip: !currentProjectId || !samplingEventId,
        }
    );

    const saveSamplingKit = async (samplingKit: SamplingKit) => {
        const existingKit = currentData?.sampleManifestInfo.kits.find(kit => kit.id === samplingKit.id);
        // Merge the existing kit and the new sampling kit, then filter out 'status' and null values

        const samplingKitToSave: SamplingKit = {
            ...existingKit,
            ...samplingKit,
            status: samplingKit.status === SamplingKitStatus.READY_TO_SAMPLE ? SamplingKitStatus.SAMPLING_IN_PROGRESS : samplingKit.status,
        };

        // Remove null values explicitly
        Object.keys(samplingKitToSave).forEach(key => {
            if (samplingKitToSave[key as keyof SamplingKit] === null) {
                delete samplingKitToSave[key as keyof SamplingKit];
            }
        });

        return await saveUpdatedSamplingKit({
            projectId: currentProjectId || '',
            samplingEventId: samplingEventId || '',
            input: samplingKitToSave,
        });
    };

    const refetchSamplingEvent = async () => {
        if (currentProjectId && samplingEventId) {
            await fetchSamplingEvent({ projectId: currentProjectId, samplingEventId });
        }
    };
    const submitSamplingKits = async (params: { isSamplingEventCompleted: boolean }) => {
        if (!currentProjectId || !samplingEventId) {
            return '';
        }

        const readyToSubmitKits = currentBatchId ? getKitsByBatchId(currentBatchId) : getReadyToSubmitKits();

        const kitIds = readyToSubmitKits.map(kit => kit.id);

        const queryParams = {
            projectId: currentProjectId,
            samplingEventId: samplingEventId,
            kitIds,
            eventCompleted: params.isSamplingEventCompleted,
            ...(currentBatchId && { batchId: currentBatchId }),
        };
        try {
            const submitSamplingKitsResult = await submitSamplingKitsMutation(queryParams).unwrap();
            return submitSamplingKitsResult;
        } catch (e) {
            console.error('Kit submit error', e);
            return '';
        }
    };

    const onInitiateSampleManifest = async (samplingEventId: string) => {
        if (!currentProjectId || !samplingEventId) {
            return;
        }

        await initiateSampleManifestEvent({
            projectId: currentProjectId,
            samplingEventId: samplingEventId,
        });
    };

    const getAllUnsubmittedKits = () => {
        const kitsInBatches = new Set(currentData?.sampleManifestInfo.batches.flatMap(batch => batch.kits) || []);

        return (
            currentData?.sampleManifestInfo.kits.filter(kit => kit.status !== SamplingKitStatus.SUBMITTED && !kitsInBatches.has(kit.id)) ||
            []
        );
    };

    const getAllSubmittedKits = () => {
        const submittedStatusList = [
            SamplingKitStatus.SUBMITTED,
            SamplingKitStatus.APPROVED_BY_LAB,
            SamplingKitStatus.APPROVED_BY_LOGISTICS,
        ];
        return currentData?.sampleManifestInfo.kits.filter(kit => submittedStatusList.includes(kit.status)) || [];
    };

    const getFieldDataCompletedKits = () => {
        return currentData?.sampleManifestInfo.kits.filter(isKitFieldDataCompleted) || [];
    };

    const getReadyToAddTestKits = () => {
        const kitsInBatches = new Set(currentData?.sampleManifestInfo.batches.flatMap(batch => batch.kits) || []);
        return getFieldDataCompletedKits().filter(kit => kit.status !== SamplingKitStatus.SUBMITTED && !kitsInBatches.has(kit.id));
    };

    const getReadyToSubmitKits = () => {
        return getReadyToAddTestKits().filter(kit => kit.testTypes.length);
    };

    const getBatchById = (batchId: string) => {
        return currentData?.sampleManifestInfo.batches.find(batch => batch.id === batchId);
    };

    const getBatchWithRequiredCustomerAmendment = (): SamplingEventBatch[] => {
        const batches = currentData?.sampleManifestInfo.batches.filter(
            batch => batch.status === SamplingEventBatchStatus.REQUIRED_CUSTOMER_AMENDMENT
        );
        return batches || [];
    };

    const getKitsByBatchId = (batchId: string) => {
        const batch = getBatchById(batchId);
        return batch?.kits
            .map(kitId => currentData?.sampleManifestInfo.kits.find(kit => kit.id === kitId))
            .filter(Boolean) as SamplingKit[];
    };

    const getRemainingTests = (): RemainingTest[] => {
        const sampleCountByTestTypes = currentData?.sampleManifestInfo.countInfo?.countByTestTypes;

        if (!sampleCountByTestTypes) {
            return [];
        }

        return sampleCountByTestTypes.map(test => {
            const completedSampleCount = currentData?.sampleManifestInfo.kits.reduce((acc, kit) => {
                return acc + kit.testTypes.filter(t => t.habitatAssayKey === test.type.habitatAssayKey).length;
            }, 0);

            return {
                testType: test.type,
                total: test.count,
                remaining: test.count - (completedSampleCount || 0),
                used: completedSampleCount || 0,
            };
        });
    };

    const approveBatchByLab = async (batchId: string) => {
        if (!currentProjectId || !samplingEventId) {
            return;
        }

        await approveBatchByLabMutation({
            projectId: currentProjectId,
            samplingEventId: samplingEventId,
            batchId,
        });
    };

    const approveBatchByLogistics = async (batchId: string) => {
        if (!currentProjectId || !samplingEventId) {
            return;
        }

        await approveBatchByLogisticsMutation({
            projectId: currentProjectId,
            samplingEventId: samplingEventId,
            batchId,
        });
    };

    const amendmentRequiredForBatch = async (batchId: string, message: string, owner: SamplingEventBatchAmendmentOwner) => {
        if (!currentProjectId || !samplingEventId) {
            return;
        }

        const queryParams = {
            projectId: currentProjectId,
            samplingEventId: samplingEventId,
            batchId,
            message,
            owner,
        };

        await amendmentRequiredForBatchMutation(queryParams);
    };

    const isBatchSubmittedMoreThan5Days = (): boolean => {
        return (
            currentData?.sampleManifestInfo.batches?.some(
                batch => [SamplingEventBatchStatus.CREATED].includes(batch.status) && moment().diff(batch.createdAt, 'days') > 5
            ) || false
        );
    };

    const getBatchesToDisplay = () => {
        return currentBatchId
            ? currentData?.sampleManifestInfo.batches.filter(batch => batch.id === currentBatchId)
            : currentData?.sampleManifestInfo.batches;
    };

    const hasIncompleteSamplesInBatch = () => {
        if (!currentBatchId) {
            return false;
        }
        return (
            getKitsByBatchId(currentBatchId)?.some(kit => {
                return getSamplingKitFieldDataStatus(kit) === FieldDataStatus.INCOMPLETE;
            }) || false
        );
    };

    const hasIncompleteTestTypesInBatch = () => {
        if (!currentBatchId) {
            return false;
        }
        return (
            getKitsByBatchId(currentBatchId)?.some(kit => {
                return kit.testTypes.length === 0 && kit.status !== SamplingKitStatus.REJECTED_BY_LAB;
            }) || false
        );
    };

    const isKitInAnyBatch = (kitId: string): boolean => {
        return kitId ? currentData?.sampleManifestInfo.batches.some(batch => batch.kits.includes(kitId)) || false : false;
    };

    const getAllSubmittedBatches = (): SamplingEventBatch[] => {
        const batches = currentData?.sampleManifestInfo.batches.filter(
            batch => batch.status && batch.status !== SamplingEventBatchStatus.DEFAULT
        );
        return batches || [];
    };

    const isKitsInBatchProcessed = (batchId: string): boolean => {
        const batchKits = getKitsByBatchId(batchId);
        const validStatuses = new Set([SamplingKitStatus.FLAGGED, SamplingKitStatus.APPROVED_BY_LAB, SamplingKitStatus.REJECTED_BY_LAB]);
        const isProcessed = batchKits.every(kit => validStatuses.has(kit.status));
        return isProcessed;
    };

    const hasKitsWithLatLongs = (): boolean => {
        const submittedKits = getAllSubmittedKits();
        if (submittedKits.length > 0) {
            return true;
        }

        const unSubmittedKits = getAllUnsubmittedKits();
        const isUnsubmittedKitsWithLatLongs = unSubmittedKits?.find(kit => kit.latitude && kit.longitude);
        if (isUnsubmittedKitsWithLatLongs) {
            return true;
        }

        return false;
    };

    return {
        samplingEvent: currentData,
        currentBatchId,
        isBatchOnlySubmit: !!currentBatchId,
        samplingKits: currentData?.sampleManifestInfo.kits || [],
        isFetching,
        kitsCount: currentData?.sampleManifestInfo.countInfo.kitsCount || 0,
        isEventInProgress:
            currentData?.status &&
            currentData?.status !== SamplingEventStatus.READY_TO_SAMPLE &&
            currentData?.status !== SamplingEventStatus.COMPLETED,
        eventStatus: currentData?.status,
        fieldDataCompletedKits: getFieldDataCompletedKits(),
        kitsReadyToAddTests: getReadyToAddTestKits(),
        unsubmittedKits: getAllUnsubmittedKits(),
        submittedKits: getAllSubmittedKits(),
        remainingTests: getRemainingTests(),
        readyToSubmitKits: getReadyToSubmitKits(),
        isBatchSubmittedMoreThan5Days: isBatchSubmittedMoreThan5Days(),
        amendmentBatches: getBatchWithRequiredCustomerAmendment(),
        batchesToDisplay: getBatchesToDisplay(),
        hasIncompleteSamplesInBatch: hasIncompleteSamplesInBatch(),
        hasIncompleteTestTypesInBatch: hasIncompleteTestTypesInBatch(),
        submittedBatches: getAllSubmittedBatches(),
        isKitsInBatchProcessed: (batchId: string) => isKitsInBatchProcessed(batchId),
        saveSamplingKit,
        onInitiateSampleManifest,
        submitSamplingKits,
        getKitsByBatchId,
        getBatchById,
        refetchSamplingEvent,
        approveBatchByLab,
        approveBatchByLogistics,
        amendmentRequiredForBatch,
        isKitInAnyBatch,
        hasKitsWithLatLongs: hasKitsWithLatLongs(),
    };
};

export default useSamplingEvent;
