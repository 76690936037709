import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import ErrorIcon from '@mui/icons-material/Error';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import { PrimaryButton } from 'src/shared/components/button/Button';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { useAppSelector, useAppDispatch } from 'src/store';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import Select from 'src/shared/components/select/Select';
import { getBatchNumberFromBatchId } from 'src/app/samples/samplesHelpers';
import { selectSamplingKit } from 'src/app/samples/sample-manifest/state/sampleManifestSlice';
import { getCurrentSampleManifestState } from '../../state/sampleManifestSelector';
const AmendmentContent = () => {
    const navigation = useAppNavigation();
    const dispatch = useAppDispatch();
    const { selectedSamplingEventId } = useAppSelector(getCurrentSampleManifestState);
    const { amendmentBatches, isBatchSubmittedMoreThan5Days } = useSamplingEvent({
        samplingEventId: selectedSamplingEventId,
    });

    const [selectedBatch, setSelectedBatch] = useState('');

    const amendmentBatchOptions = sortBy(
        amendmentBatches?.map(batch => ({
            value: batch.id,
            label: `Batch ${getBatchNumberFromBatchId(batch.id, selectedSamplingEventId)}`,
        })),
        'value'
    );

    useEffect(() => {
        if (amendmentBatchOptions.length > 0 && !selectedBatch) {
            setSelectedBatch(amendmentBatchOptions[0].value);
        }
    }, [amendmentBatchOptions]);

    const handleSelectChange = (value: string) => {
        setSelectedBatch(value);
    };

    if (amendmentBatches.length === 0) {
        return null;
    }

    const onAmendSampleBatch = () => {
        dispatch(selectSamplingKit(''));
        navigation.toSampleManifestForm({ samplingEventId: selectedSamplingEventId, batchId: selectedBatch });
    };

    return (
        <div className='border-orange-100 border-2 shadow-lg p-4 rounded-lg bg-white flex gap-6 justify-between'>
            <div className='flex flex-col gap-2'>
                <div className='text-orange-100 flex text-lg font-bold'>Data amendment requested by NatureMetrics</div>
                <div>
                    <p>Details of the data amendment have been emailed to `email address`.</p>
                    <p>Please amend the data as requested and resubmit the sample batch.</p>
                </div>
                {isBatchSubmittedMoreThan5Days && (
                    <div className='bg-orange-0 border-orange-500 text-orange-100 p-4 rounded-lg' role='alert'>
                        <div className='flex flex-row gap-2'>
                            <ErrorIcon />
                            <CMSArticle slug='sample-manifest-batch-submission-more-than-5-days' />
                        </div>
                    </div>
                )}
            </div>
            <div className='content-end'>
                <div className='flex flex-row gap-4'>
                    <div>
                        <Select options={amendmentBatchOptions} selectedValue={selectedBatch} onChange={handleSelectChange} width='200px' />
                    </div>
                    <div>
                        <PrimaryButton onClick={onAmendSampleBatch} disabled={!selectedBatch}>
                            Amend sample batch &gt;
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AmendmentContent;
