import { RootState } from 'src/store';
import { NewProjectDefinitionFormState, NewProjectDefinitionUIState, defaultProjectDefinitionFormState } from './newProjectDefinitionSlice';

export const getProjectDefinitionUIState = (state: RootState): NewProjectDefinitionUIState | null => {
    return state.projects.newProjectDefinition.uiState || null;
};

export const getCurrentProjectDefinitionFormStep = (state: RootState): number => {
    const currentUIState = getProjectDefinitionUIState(state);

    if (!currentUIState) {
        return 0;
    }

    return currentUIState.formStep;
};

export const getCurrentProjectDefinitionFormState = (state: RootState): NewProjectDefinitionFormState => {
    const currentUIState = getProjectDefinitionUIState(state);

    if (!currentUIState) {
        return defaultProjectDefinitionFormState;
    }

    return currentUIState.formState;
};
