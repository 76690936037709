import { AppName } from 'src/global/globalConfig';
import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import useProject from 'src/shared/hooks/useProject';

const useDashboardAnalytics = () => {
    const { currentProjectDetails, currentProjectSelectedHabitatAssayType } = useProject();

    const sendTrackEvent = (ga: AnalyticsOptions) => {
        analyticsTracking.trackEvent({
            ...ga,
            project: currentProjectDetails?.projectName,
            habitatAssayType: currentProjectSelectedHabitatAssayType?.key || '',
            app: AppName.DASHBOARD,
        });
    };

    const trackDateFilterChange = (selectedDates: string) => {
        sendTrackEvent({
            action: 'date_filter_changed',
            category: 'date-filter',
            label: selectedDates,
        });
    };

    const trackSpeciesHighlightViewed = () => {
        sendTrackEvent({
            action: 'species_highlight_viewed',
            category: 'species-highlight',
            non_interaction: true,
        });
    };

    const trackIUCNTableViewed = () => {
        sendTrackEvent({
            action: 'iucn_table_viewed',
            category: 'iucn_table',
            non_interaction: true,
        });
    };

    return {
        trackDateFilterChange,
        trackSpeciesHighlightViewed,
        trackIUCNTableViewed,
    };
};

export default useDashboardAnalytics;
