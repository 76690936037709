import classNames from 'classnames';
import React, { ReactNode } from 'react';

import styles from './Lozenges.module.scss';

export type LozengeOption = {
    label: ReactNode;
    value: string;
};

type LozengesProps = {
    selectedValue: string;
    options: LozengeOption[];
    onChange: (value: string) => void;
};

const Lozenges = (props: LozengesProps) => {
    const { selectedValue, options, onChange } = props;

    return (
        <div className={styles.lozenges}>
            {options.map((option, key) => {
                const classname = classNames(styles.lozenge, {
                    [styles.lozengeSelected]: selectedValue === option.value,
                });

                return (
                    <span key={key} className={classname} onClick={() => selectedValue !== option.value && onChange(option.value)}>
                        {option.label}
                    </span>
                );
            })}
        </div>
    );
};

export default Lozenges;
