import React from 'react';
import { Tooltip } from 'react-leaflet';
import classNames from 'classnames';
import useCMSTestTypes from 'src/shared/hooks/cms/useCMSTestTypes';
import { ReactComponent as UnknownTestTypeIcon } from 'src/assets/svg/unknown-test-type.svg';
import { SamplingKitInfo } from '../../SampleMapModal';

type MarkerTooltipProps = {
    samplingKitInfo: SamplingKitInfo;
    hoveredSamplingKitId: string | null;
    showTests?: boolean;
};

const MarkerTooltip = (props: MarkerTooltipProps) => {
    const { samplingKitInfo, hoveredSamplingKitId, showTests } = props;
    const cmsTestTypes = useCMSTestTypes();

    if (hoveredSamplingKitId !== samplingKitInfo.id) {
        return null;
    }

    const getTestIcons = (testTypeKey: string) => {
        const matchedTest = cmsTestTypes?.find(cmsTestTypes => cmsTestTypes.habitatAssayKey === testTypeKey);
        if (!matchedTest || !matchedTest?.image?.thumbnail) {
            return <UnknownTestTypeIcon className='h-[38px] w-[38px' />;
        }
        return (
            <img
                className='h-[38px] w-[38px'
                src={matchedTest.image.thumbnail}
                alt={`${matchedTest.habitatName} ${matchedTest.assayName}`}
            />
        );
    };

    const displayTestTypes = showTests && samplingKitInfo.testTypes.length > 0;

    const testIcons = displayTestTypes ? (
        <div className='flex gap-2'>{samplingKitInfo.testTypes.map(testType => getTestIcons(testType.habitatAssayKey))}</div>
    ) : null;

    const offset = [-1, -6] as [number, number];

    const tooltipClasses = classNames(
        'min-w-[197px] p-4 rounder=[15px] shadow-[0 3px 14px rgb(0 0 0 / 40%)]',
        ' before:ml-[-5px] before:border-[10px solid transparent] before:border-t-[#fff]'
    );

    return (
        <Tooltip direction='top' offset={offset} className={tooltipClasses} opacity={1}>
            <div className='flex flex-col gap-3'>
                <div className='text-sm text-primary'>{samplingKitInfo.name}</div>
                {displayTestTypes && (
                    <div className='flex flex-col gap-1'>
                        <div className='text-xs text-secondary text-[#666]'>Test(s):</div>
                        {testIcons}
                    </div>
                )}
            </div>
        </Tooltip>
    );
};

export default MarkerTooltip;
