export type CMSFeatureTestConfig = {
    [key: string]: string[];
};

export type IsCMSFeatureAvailableParams = {
    featureId: CMSFeatureId;
    habitatId: string;
    assayId: string;
};

export enum CMSFeatureId {
    SPECIES_RICHNESS = 'speciesRichness',
    EVOLUTIONARY_DIVERSITY = 'evolutionaryDiversity',
    COMMUNITY_COMPARISON = 'communityComparison',
    FISH_METRICS = 'fishMetrics',
    INVASIVES = 'invasives',
    IUCN_RED_LIST = 'iucnRedList',
    FUNGAL_FUNCTIONAL_DIVERSITY = 'fungalFunctionalDiversity',
    BACTERIAL_FUNCTIONAL_DIVERSITY = 'bacterialFunctionalDiversity',
    SPECIES_HIGHLIGHT = 'speciesHighlight',
    FUNGAL_TO_BACTERIAL_RATIO = 'fungalToBacterialRatio',
}
