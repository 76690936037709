import React, { useEffect, useState } from 'react';
import startCase from 'lodash/startCase';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import useProject from 'src/shared/hooks/useProject';
import { useAppDispatch, useAppSelector } from 'src/store';
import { updateFilters, updateUiState, SampleComparisonFiltersState } from 'src/app/insights/state/sample-comparison/sampleComparisonSlice';
import { getCurrentSampleComparisonUIState } from 'src/app/insights/state/sample-comparison/sampleComparisonSelector';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { SortOrder } from 'src/shared/types';
import SamplingEventFilter from 'src/shared/components/filters/sampling-event-filter/SamplingEventFilter';
import styles from './SampleComparisonFilters.module.scss';

const sortOptions = [
    {
        label: 'Value (High to Low)',
        value: `value_${SortOrder.DESC}`,
    },
    {
        label: 'Value (Low to High)',
        value: `value_${SortOrder.ASC}`,
    },
    {
        label: 'Sample ID (Z-A)',
        value: `name_${SortOrder.DESC}`,
    },
    {
        label: 'Sample ID (A-Z)',
        value: `name_${SortOrder.ASC}`,
    },
];
const SampleComparisonFilters = () => {
    const { currentProjectFilters, currentProjectSelectedHabitatAssayType: habitatAssayType, samplingEventFilterData } = useProject();
    const dispatch = useAppDispatch();

    const uiState = useAppSelector(getCurrentSampleComparisonUIState);
    const {
        filters: { chartId, sortBy, samplingEvent },
    } = uiState;

    const [metricOptions, setMetricOptions] = useState<SelectOption[]>([
        {
            label: 'None available',
            value: 'none',
        },
    ]);

    const onFiltersChange = (fragment: Partial<SampleComparisonFiltersState>) => {
        dispatch(updateFilters(fragment));
    };

    useEffect(() => {
        if (!currentProjectFilters || !habitatAssayType) {
            return;
        }

        const { habitatAssays } = currentProjectFilters;

        const newMetricsOptions = habitatAssays
            .find(entry => entry.key === habitatAssayType?.key)
            ?.metrics.map(metric => ({
                label: startCase(metric.metricName),
                value: metric.metricKey,
            })) as SelectOption[];

        setMetricOptions(newMetricsOptions);

        if (!chartId) {
            dispatch(
                updateFilters({
                    chartId: newMetricsOptions[0].value,
                })
            );
        } else {
            dispatch(updateFilters({ chartId: chartId }));
        }

        dispatch(updateUiState({ chartTitle: newMetricsOptions.find(entry => entry.value === chartId)?.label?.toString() }));
    }, [currentProjectFilters?.habitatAssays, habitatAssayType, chartId]);

    return (
        <div className={styles.filterGroup}>
            <div className={styles.filters}>
                <div className={styles.selectGroup}>
                    <div className={styles.selectInput}>
                        <InputLabel className={styles.filterLabel}>Sampling event</InputLabel>
                        <SamplingEventFilter
                            selectedSamplingEvent={samplingEvent}
                            samplingEventFilterData={samplingEventFilterData}
                            onChange={value => onFiltersChange({ samplingEvent: value })}
                        />
                    </div>
                </div>
                <div className={styles.selectInput}>
                    <InputLabel>Sort by</InputLabel>
                    <Select
                        options={sortOptions}
                        onChange={value => onFiltersChange({ sortBy: value })}
                        selectedValue={sortBy}
                        width={250}
                    />
                </div>
                <div className={styles.selectInput}>
                    <div className={styles.labelGroup}>
                        <InputLabel>Metric</InputLabel>
                        <QuestionButtonHelp type='api' placement='right' slug='metrics-explainer' />
                    </div>
                    <Select
                        options={metricOptions}
                        onChange={value =>
                            onFiltersChange({
                                chartId: value,
                            })
                        }
                        selectedValue={chartId}
                        width={280}
                    />
                </div>
            </div>
        </div>
    );
};

export default SampleComparisonFilters;
