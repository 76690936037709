import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Access, User, Role, AssociatedCustomer } from 'src/shared/types';

export type UserProjectAccess = {
    projectId: string;
    roles: string[] | null;
    remark?: string;
};

export type NewProjectUserFormState = {
    newUserView: string;
    formState: {
        firstName: string;
        lastName: string;
        phone?: string;
        phoneCountryCode?: string;
        email: string;
        isEmailValid: boolean;
        emailIdAvailable: boolean;
        emailIdAvailabilityChecked: boolean;
        projects: UserProjectAccess[];
        disableSubmit: boolean;
        userType: string;
        selectedUser: User | null;
        roles: Role[];
        errorMessage: React.ReactNode;
    };
};

export type EditProjectUserFormState = {
    editUserView: string;
    formMode: Access;
    formState: Pick<
        NewProjectUserFormState['formState'],
        'firstName' | 'lastName' | 'email' | 'projects' | 'phoneCountryCode' | 'phone' | 'disableSubmit' | 'roles'
    > & {
        tnc_consent: boolean;
        cognitoId: string;
        status: string;
        notify: boolean;
        customers: AssociatedCustomer[];
        id: number;
        canResendEmailInvitation: boolean;
        is_internal_user: boolean;
    };
};

export type UserManagementListFilterState = {
    userNameSearchText: string;
    projectNameSearchText: string;
    userStatus: string;
    userType: string;
};

export type GeneralUIState = {
    hasCustomerAdminInUsers: boolean;
    shouldShowSuccessAlert: boolean;
    shouldShowNewUserModal: boolean;
    shouldShowEditUserModal: boolean;
    shouldShowViewUserModal: boolean;
    successStatusMessage: string;
};

export type UserManagementUIState = {
    newProjectUserForm: NewProjectUserFormState;
    editProjectUserForm: EditProjectUserFormState;
    userManagementListFilter: UserManagementListFilterState;
    generalUiState: GeneralUIState;
};

type UserManagementState = {
    uiState: UserManagementUIState;
};

export const defaultNewProjectUserFormState: NewProjectUserFormState = {
    newUserView: 'default',
    formState: {
        firstName: '',
        lastName: '',
        phone: '',
        phoneCountryCode: '',
        email: '',
        isEmailValid: false,
        emailIdAvailable: false,
        emailIdAvailabilityChecked: false,
        projects: [],
        disableSubmit: false,
        userType: '',
        selectedUser: null,
        roles: [],
        errorMessage: null,
    },
};

export const defaultEditProjectUserFormState: EditProjectUserFormState = {
    editUserView: 'default',
    formMode: Access.UPDATE,
    formState: {
        firstName: '',
        lastName: '',
        phone: '',
        phoneCountryCode: '',
        email: '',
        projects: [],
        tnc_consent: false,
        cognitoId: '',
        status: '',
        notify: false,
        disableSubmit: true,
        roles: [],
        customers: [],
        id: 0,
        canResendEmailInvitation: true,
        is_internal_user: false,
    },
};

export const defaultUserManagementListFilterState: UserManagementListFilterState = {
    userNameSearchText: '',
    projectNameSearchText: '',
    userStatus: 'All',
    userType: 'All',
};

export const defaultGeneralUiState: GeneralUIState = {
    hasCustomerAdminInUsers: false,
    shouldShowSuccessAlert: false,
    shouldShowNewUserModal: false,
    shouldShowEditUserModal: false,
    shouldShowViewUserModal: false,
    successStatusMessage: '',
};

const initialState: UserManagementState = {
    uiState: {
        newProjectUserForm: { ...defaultNewProjectUserFormState },
        editProjectUserForm: { ...defaultEditProjectUserFormState },
        userManagementListFilter: { ...defaultUserManagementListFilterState },
        generalUiState: { ...defaultGeneralUiState },
    },
};

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        setNewUserView(state, action: PayloadAction<string>) {
            state.uiState.newProjectUserForm.newUserView = action.payload;
        },
        setEditUserView(state, action: PayloadAction<string>) {
            state.uiState.editProjectUserForm.editUserView = action.payload;
        },
        setEditUserFormMode(state, action: PayloadAction<Access>) {
            state.uiState.editProjectUserForm.formMode = action.payload;
        },
        setFormState(state, action: PayloadAction<Partial<NewProjectUserFormState['formState']>>) {
            state.uiState.newProjectUserForm.formState = { ...state.uiState.newProjectUserForm.formState, ...action.payload };
        },
        setEditUserFormState(state, action: PayloadAction<Partial<EditProjectUserFormState['formState']>>) {
            state.uiState.editProjectUserForm.formState = { ...state.uiState.editProjectUserForm.formState, ...action.payload };
        },
        updaterUserManagementListFilterState(state, action: PayloadAction<Partial<UserManagementListFilterState>>) {
            state.uiState.userManagementListFilter = { ...state.uiState.userManagementListFilter, ...action.payload };
        },

        updateGeneralUiState(state, action: PayloadAction<Partial<GeneralUIState>>) {
            state.uiState.generalUiState = { ...state.uiState.generalUiState, ...action.payload };
        },

        resetUserManagementListFilterState(state) {
            state.uiState.userManagementListFilter = { ...defaultUserManagementListFilterState };
        },

        reset(state) {
            // eslint-disable-next-line
            const { hasCustomerAdminInUsers } = state.uiState.generalUiState;

            state.uiState = { ...initialState.uiState, generalUiState: { ...defaultGeneralUiState, hasCustomerAdminInUsers } };
        },
    },
});

export default userManagementSlice.reducer;

export const {
    setFormState,
    setEditUserFormState,
    setNewUserView,
    setEditUserView,
    setEditUserFormMode,
    updaterUserManagementListFilterState,
    updateGeneralUiState,
    resetUserManagementListFilterState,
    reset,
} = userManagementSlice.actions;
