import classNames from 'classnames';
import { Restore as RestoreIcon } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import L, { LatLngBoundsExpression, LatLngExpression } from 'leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import config from 'src/config';
import { MapContainer, Marker, ZoomControl, Popup, Tooltip } from 'react-leaflet';
import MapType, { mapTypes } from 'src/shared/components/map-type/MapType';
import googleMapStyles from 'src/app/maps/views/samples/components/samples-map/samplesMapFeaturesConfig';
import Control from 'react-leaflet-custom-control';
import 'src/app/maps/views/samples/components/samples-map/leaflet.css';
import {
    getPinDivIcon,
    GetPinDivIconParams,
} from 'src/app/maps/views/samples/components/samples-map/components/map-marker/mapMarkerHelpers';
import { availableAreaColors } from 'src/shared/constants/colors';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState } from 'src/shared/components/data-table/dataTableTypes';
import ListMapViewTabs, { MapOrTable } from 'src/shared/components/list-map-view-tabs/ListMapViewTabs';
import MarkerInfo from './marker-info/MarkerInfo';
import styles from './ReviewSamples.module.scss';
import { NewSurveyDesignFormState, SurveyDesignSample } from '../../../state/newSurveyDesignSlice';

type ReviewSamplesProps = Pick<NewSurveyDesignFormState, 'surveySamples' | 'sampleGroups'>;

const ReviewSamples = (props: ReviewSamplesProps) => {
    const [tab, setTab] = useState<MapOrTable>('map');

    return (
        <div className={styles.reviewSamples}>
            <ListMapViewTabs tab={tab} onTabChange={setTab} />

            {tab === 'map' && <ReviewSamplesMap {...props} />}
            {tab === 'list' && <ReviewSamplesTable {...props} />}
        </div>
    );
};

const ReviewSamplesMap = (props: ReviewSamplesProps) => {
    const { surveySamples, sampleGroups } = props;
    const [mapType, setMapType] = useState<string>(mapTypes[0].value);
    const [activeSampleId, setActiveSampleId] = useState<number | null>(null);
    const [hoverSampleId, setHoveredSampleId] = useState<number | null>(null);
    const mapRef = useRef<L.Map>(null);
    const markerRefs = useRef<L.Marker[]>([]);

    useEffect(() => {
        if (!markerRefs.current || activeSampleId === null) {
            markerRefs.current.forEach(marker => marker.closePopup());
            return;
        }
        markerRefs.current[activeSampleId].openPopup();
        markerRefs.current[activeSampleId].setOpacity(1);
        markerRefs.current.forEach((marker, i) => {
            const opacity = i === activeSampleId ? 1 : 0.25;
            marker.setOpacity(opacity);
        });
    }, [activeSampleId]);

    const getBounds = () => {
        return surveySamples.map(sample => [sample.latitude, sample.longitude].map(Number)) as LatLngBoundsExpression;
    };

    const markerEventHandlers = (index: number) => ({
        click: () => {
            return activeSampleId !== index ? (setHoveredSampleId(null), setActiveSampleId(index)) : setActiveSampleId(null);
        },
        popupclose: () => {
            markerRefs.current.forEach(marker => marker?.setOpacity(1));
        },
        popupopen: () => {
            markerRefs.current.forEach((marker, i) => {
                const opacity = i === activeSampleId ? 1 : 0.25;
                marker.setOpacity(opacity);
            });
        },
        mouseover: () => {
            return activeSampleId !== index && setHoveredSampleId(index);
        },
        mouseout: () => setHoveredSampleId(null),
    });

    return (
        <div className={styles.reviewSamplesMap}>
            <MapContainer
                ref={mapRef}
                bounds={surveySamples.length ? getBounds() : undefined}
                scrollWheelZoom={true}
                style={{ height: '100%', width: '100%' }}
                attributionControl={false}
                maxZoom={19}
                zoomControl={false}
            >
                <ReactLeafletGoogleLayer
                    key={mapType}
                    apiKey={config.googleMapsJavascriptApiKey}
                    useGoogMapsLoader={true}
                    styles={googleMapStyles}
                    type={mapType as any}
                ></ReactLeafletGoogleLayer>
                <ZoomControl position='topright' />

                <Control position='topright'>
                    <span onClick={() => mapRef.current?.fitBounds(getBounds())} className={styles.restoreContainer}>
                        <RestoreIcon className={styles.restore} style={{ fontSize: '30px', cursor: 'pointer' }} />
                    </span>
                </Control>
                <div className='leaflet-bottom leaflet-left'>
                    <div
                        className={styles.legend}
                        ref={ref => {
                            if (!ref) return;
                            L.DomEvent.disableClickPropagation(ref).disableScrollPropagation(ref);
                        }}
                    >
                        <div className={styles.title}>Key</div>
                        {sampleGroups.map((entry, index) => (
                            <span className={styles.keyLabel} key={index}>
                                <i style={{ background: availableAreaColors[index] }}></i>
                                {entry}
                            </span>
                        ))}
                    </div>
                </div>

                {surveySamples.map((sample, index) => {
                    const getIconParams: GetPinDivIconParams = {
                        color: availableAreaColors[sampleGroups.indexOf(sample.sampleGroup)],
                        groupOrder: 1,
                        isSurveyPin: true,
                    };

                    return (
                        <Marker
                            ref={(ref: L.Marker) => (markerRefs.current[index] = ref)}
                            key={index}
                            position={[sample.latitude, sample.longitude].map(Number) as LatLngExpression}
                            icon={getPinDivIcon(getIconParams)}
                            eventHandlers={markerEventHandlers(index)}
                        >
                            {hoverSampleId === index && (
                                <Tooltip direction='top' offset={[0, -6]} opacity={1} className={styles.tooltip}>
                                    <MarkerInfo sample={sample} />
                                </Tooltip>
                            )}

                            <Popup autoPan={true} offset={[0, -6]}>
                                <MarkerInfo sample={sample} />
                            </Popup>
                        </Marker>
                    );
                })}

                <div
                    className='leaflet-bottom leaflet-right'
                    style={{ pointerEvents: 'visible', right: '15px' }}
                    ref={ref => {
                        if (!ref) return;
                        L.DomEvent.disableClickPropagation(ref).disableScrollPropagation(ref);
                    }}
                >
                    <div className={styles.legend}>
                        <div className={styles.title}>Map Type </div>
                        <MapType handleChange={setMapType} value={mapType} />
                    </div>
                </div>
            </MapContainer>

            <div className={styles.sidebar}>
                <div className={styles.top}>
                    <div className={styles.sidebarTitle}>Sample list</div>
                    <div className={styles.surveyCount}>{surveySamples.length} samples</div>
                </div>
                <div className={styles.list}>
                    {surveySamples.map((item, index) => {
                        const listItemClass = classNames(styles.listItem, activeSampleId === index && styles[`listItemActive`]);
                        return (
                            <div
                                key={index}
                                className={listItemClass}
                                style={{ borderColor: availableAreaColors[sampleGroups.indexOf(item.sampleGroup)] }}
                                onClick={() => (activeSampleId !== index ? setActiveSampleId(index) : setActiveSampleId(null))}
                            >
                                {item.sampleId}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const columns: DataTableColumns = [
    {
        columnId: 'sampleId',
        title: 'Unique Sample ID',
    },
    {
        columnId: 'samplePoint',
        title: 'Sample point',
    },
    {
        columnId: 'latitude',
        title: 'Latitude',
    },
    {
        columnId: 'longitude',
        title: 'Longitude',
    },
    {
        columnId: 'sampleGroup',
        title: 'Sample group',
    },
    {
        columnId: 'Kit type',
        title: 'kitType',
    },
    {
        columnId: 'habitatTestTypes',
        title: 'Test',
    },
];

const ReviewSamplesTable = (props: ReviewSamplesProps) => {
    const { surveySamples, sampleGroups } = props;
    const [tableState, setTableState] = useState<Partial<DataTableState>>({ rowsPerPage: 10 });

    useEffect(() => {
        if (!surveySamples.length) {
            return;
        }
        const tableData: DataTableState['data'] = [];
        surveySamples.forEach((sample: SurveyDesignSample) => {
            const { sampleId, samplePoint, latitude, longitude, sampleGroup, kitType, habitatAssayTypes } = sample;

            const currentRow = [];
            currentRow.push(sampleId);
            currentRow.push(samplePoint);
            currentRow.push(latitude || '-');
            currentRow.push(longitude || '-');
            currentRow.push(
                <div className={styles.sampleGroup}>
                    <div
                        className={styles.sampleGroupColor}
                        style={{ backgroundColor: availableAreaColors[sampleGroups.indexOf(sampleGroup)] }}
                    ></div>
                    <div className={styles.sampleGroupName}>{sampleGroup}</div>
                </div>
            );
            currentRow.push(kitType);
            currentRow.push(habitatAssayTypes.map(entry => entry.habitatName + ' ' + entry.assayName).join(', '));
            tableData.push(currentRow);
        });

        setTableState({
            ...tableState,
            data: tableData,
        });
    }, [surveySamples]);

    if (!tableState.data) {
        return null;
    }
    return (
        <div className={styles.reviewSamplesTable}>
            <DataTable state={tableState} columns={columns} emptyStateMessage={'No samples to display.'} />
        </div>
    );
};

export default ReviewSamples;
