import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import useAuthService from 'src/shared/hooks/useAuthService';
import { TertiaryButton, PrimaryButton } from 'src/shared/components/button/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { openSiteMenu } from 'src/global/state/site-menu/siteMenuSlice';
import ChevronButton from 'src/shared/components/chevron-button/ChevronButton';
import styles from '../SiteMenu.module.scss';

const UserMenu = () => {
    const [shouldShowConfirmation, showConfirmation] = useState(false);
    const [isExpanded, setExpanded] = useState(false);
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const { isAuthenticated, currentUser, signout } = useAuthService();
    const dispatch = useAppDispatch();

    if (!isAuthenticated || !currentUser) {
        return null;
    }

    const getMenuClassNames = () => {
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
        });
    };

    const getUserMenuClassNames = () => {
        return classNames(styles.menuItem, styles.userMenu, {
            [styles.menuItemOpen]: isOpen,
        });
    };

    const initials = (currentUser.firstName[0] || '') + (currentUser.lastName[0] || '');
    const fullName = currentUser.firstName + ' ' + currentUser.lastName;

    const handleLogout = () => {
        signout();
    };

    const openMenu = () => {
        dispatch(openSiteMenu());
        setExpanded(!isExpanded);
    };

    const toggleExpanded = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        setExpanded(!isExpanded);
    };

    return (
        <>
            <div className={getUserMenuClassNames()} onClick={openMenu}>
                <div className={styles.userIcon}>{initials || '-'}</div>
                {isOpen && (
                    <>
                        <div className={styles.label}>{fullName}</div>
                        <ChevronButton animate={true} direction={isExpanded ? 'down' : 'right'} onClick={e => toggleExpanded(e)} />
                    </>
                )}
            </div>

            {isExpanded && isOpen && (
                <div className={getMenuClassNames()} onClick={() => showConfirmation(true)}>
                    <div className={styles.icon}></div>
                    <div className={styles.label}>Logout</div>
                </div>
            )}

            <Dialog open={shouldShowConfirmation} onClose={() => showConfirmation(false)}>
                <DialogTitle className={styles.logoutConfirmationTitle}>Are you sure you want to Sign Out?</DialogTitle>
                <DialogActions className={styles.logoutConfirmationActions}>
                    <TertiaryButton onClick={() => showConfirmation(false)}>Cancel</TertiaryButton>
                    <PrimaryButton onClick={handleLogout}>Sign Out</PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserMenu;
