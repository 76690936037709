import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export type SnackbarAlertProps = {
    message: React.ReactNode;
    severity?: AlertColor;
    autoHideDuration?: number;
    open: boolean;
    onClose?: () => void;
};
const SnackbarAlert = (props: SnackbarAlertProps) => {
    const { autoHideDuration, message, severity = 'success', open } = props;

    const action = props.onClose ? (
        <IconButton size='small' aria-label='close' color='inherit' onClick={props.onClose}>
            <CloseIcon fontSize='small' />
        </IconButton>
    ) : null;

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={() => props.onClose?.()}
            action={action}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
            <Alert severity={severity} sx={{ width: '100%' }} onClose={() => props.onClose?.()}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarAlert;
