import React from 'react';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import styles from './CustomerDetails.module.scss';

type CustomerDetailsProps = {
    customerName: string;
};

const CustomerDetails = ({ customerName }: CustomerDetailsProps) => {
    return (
        <div className={styles.customerDetails}>
            <BusinessOutlinedIcon className={styles.icon} />
            <div className={styles.description}>
                <div className={styles.label}>Organisation</div>
                <div className={styles.name}>{customerName}</div>
            </div>
        </div>
    );
};

export default CustomerDetails;
