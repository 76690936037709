export const chartExplainerSlugKey: { [key: string]: string } = {
    speciesrichness: 'species-richness',
    evolutionarydiversity: 'evolutionary-diversity',
    fishcommercialvalue: 'commercial-value-helptext',
    fishfoodchainintegrity: 'food-chain-position-helptext',
    fishprevalenceofsensitivespecies: 'prevalance-of-sensitive-species-helptext',
    bfd: 'bacterial-functional-diversity',
    ffd: 'fungal-functional-diversity',
    fb_ratio: 'soil-fungal-to-bacterial-ratio',
};
