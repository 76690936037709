import { RootState } from 'src/store';
import { NewSurveyDesignFormState, NewSurveyDesignUIState, defaultSurveyDesignFormState } from './newSurveyDesignSlice';

export const getSurveyDesignUIState = (state: RootState): NewSurveyDesignUIState | null => {
    return state.surveys.newSurveyDesign.uiState || null;
};

export const getCurrentSurveyDesignFormStep = (state: RootState): number => {
    const currentUIState = getSurveyDesignUIState(state);

    if (!currentUIState) {
        return 0;
    }

    return currentUIState.formStep;
};

export const getCurrentSurveyDesignFormState = (state: RootState): NewSurveyDesignFormState => {
    const currentUIState = getSurveyDesignUIState(state);

    if (!currentUIState) {
        return defaultSurveyDesignFormState;
    }

    return currentUIState.formState;
};
