import storageService, { StorageServiceInterface, StorageType } from 'src/services/storage-service';

export enum StorageKeys {
    TERMS_OF_USE_CONSENT = 'login.termsOfUseConsent',
    REDIRECT_PATH = 'login.redirectPath',
}

const localStorageOptions = {
    storage: StorageType.LOCAL_STORAGE,
};

export class LoginStorageService {
    private storageService: StorageServiceInterface;

    public constructor(storageService: StorageServiceInterface) {
        this.storageService = storageService;
    }

    public getTermsOfUseConsent(): boolean | null {
        return this.storageService.get(StorageKeys.TERMS_OF_USE_CONSENT, localStorageOptions);
    }

    public setTermsOfUseConsent(consent: boolean): void {
        this.storageService.remove(StorageKeys.TERMS_OF_USE_CONSENT);

        this.storageService.save(StorageKeys.TERMS_OF_USE_CONSENT, consent, localStorageOptions);
    }

    public getRedirectPath(): string | null {
        return this.storageService.get(StorageKeys.REDIRECT_PATH, localStorageOptions);
    }

    public setRedirectPath(path: string): void {
        this.storageService.remove(StorageKeys.REDIRECT_PATH);

        this.storageService.save(StorageKeys.REDIRECT_PATH, path, localStorageOptions);
    }

    public clearAll(): void {
        this.storageService.remove(StorageKeys.TERMS_OF_USE_CONSENT, localStorageOptions);
    }
}

export default new LoginStorageService(storageService);
