import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import {
    getRegisterationStatusQuery,
    GetRegisterationStatusQueryResult,
    createRegisterInterestQuery,
    CreateRegisterInterestQueryResult,
} from './queries/demoProjectBannerQuery';

const demoProjectBannerGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        getRegisterationStatus: builder.query<GetRegisterationStatusQueryResult, null>({
            query: () => ({
                body: getRegisterationStatusQuery,
            }),
        }),
        createRegisterInterest: builder.mutation<CreateRegisterInterestQueryResult, null>({
            query: () => ({
                body: createRegisterInterestQuery,
            }),
        }),
    }),
});

export const { useGetRegisterationStatusQuery, useCreateRegisterInterestMutation } = demoProjectBannerGraphSlice;
