import React, { useState } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useAppDispatch } from 'src/store';
import { NewProjectDefinitionFormState, setFormState } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSlice';
import Input from 'src/shared/components/input/Input';
import { availableAreaColors } from 'src/shared/constants/colors';
import { PrimaryButton } from 'src/shared/components/button/Button';
import commonStyles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import ConditionalField from 'src/shared/components/conditional-field/ConditionalField';
import styles from './SampleGroups.module.scss';
import useProjectDefinition from '../../../../hooks/useProjectDefinition';

const SampleGroups = () => {
    const dispatch = useAppDispatch();
    const { formState, isBasicAndGenericProject } = useProjectDefinition();
    const { sampleGroups } = formState;
    const [sampleGroupValue, setSampleGroupValue] = useState('');

    const onFormChange = (fragment: Partial<NewProjectDefinitionFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    const addSampleGroup = () => {
        if (sampleGroups.includes(sampleGroupValue)) {
            return;
        }

        onFormChange({
            sampleGroups: [...sampleGroups, sampleGroupValue],
        });
        setSampleGroupValue('');
    };

    const removeSampleGroup = (value: string) => {
        onFormChange({
            sampleGroups: [...sampleGroups].filter(item => item !== value),
        });
    };

    return (
        <div className={commonStyles.formSection}>
            <div className={commonStyles.formSectionTitle}>Sample groups</div>
            <div className={commonStyles.formInputSection}>
                <div className={commonStyles.formInputSectionTitle}>
                    Sample groups <QuestionButtonHelp type='api' slug='project-definition-sample-groups' />
                </div>
                <ConditionalField isSatisfied={!isBasicAndGenericProject}>
                    <>
                        <div className={styles.addSampleGroup}>
                            <span
                                className={styles.colorIndicator}
                                style={
                                    {
                                        '--sample-group-input-area-indicator-color': availableAreaColors[sampleGroups.length],
                                    } as React.CSSProperties
                                }
                            ></span>
                            <Input
                                className={styles.sampleGroupInput}
                                value={sampleGroupValue}
                                onChange={event => setSampleGroupValue(event.target.value)}
                                maxLength={25}
                                placeholder='Type sample group name'
                            />

                            {sampleGroups.length < 8 && (
                                <PrimaryButton className={commonStyles.addButton} onClick={addSampleGroup} disabled={!sampleGroupValue}>
                                    <span>+</span> ADD
                                </PrimaryButton>
                            )}
                        </div>
                        <div className={styles.sampleGroups}>
                            {sampleGroups.map((entry, index) => {
                                return (
                                    <div key={index} className={styles.sampleGroupTag}>
                                        <div
                                            className={styles.sampleGroupTagColor}
                                            style={{ backgroundColor: availableAreaColors[index] }}
                                        ></div>
                                        <div className={styles.sampleGroupTagValue}>{entry}</div>
                                        <ClearOutlinedIcon onClick={() => removeSampleGroup(entry)} />
                                    </div>
                                );
                            })}
                        </div>
                        {sampleGroups.length > 0 && <div className={commonStyles.maximumText}>Maximum 8 sample groups</div>}
                    </>
                </ConditionalField>
            </div>
        </div>
    );
};

export default SampleGroups;
