import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export type CommunityComparisonFiltersState = {
    samplingYear: string;
    selectedSampleGroup: string | null;
    selectedSampleId: string | null;
};

const defaultFiltersState = {
    samplingYear: '',
    selectedSampleGroup: null,
    selectedSampleId: null,
};

export type CommunityComparisonUIState = {
    filters: CommunityComparisonFiltersState;
};

export const defaultUiState = {
    filters: { ...defaultFiltersState },
};

type CommunityComparisonState = {
    uiState: {
        [projectId: string]: CommunityComparisonUIState;
    };
};
const initialState: CommunityComparisonState = {
    uiState: {},
};

export const updateFilters =
    (fragment: Partial<CommunityComparisonFiltersState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;

        if (projectId) {
            dispatch(
                communityComparisonSlice.actions.updateFilters({
                    projectId,
                    fragment,
                })
            );
        }
    };

const ensureProjectUIStatePresence = (state: CommunityComparisonState, projectId: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = {
            filters: { ...defaultFiltersState },
        };
    }
};

const communityComparisonSlice = createSlice({
    name: 'communityComparison',
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<{ projectId: string; fragment: Partial<CommunityComparisonFiltersState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);

            state.uiState[projectId].filters = {
                ...(state.uiState[projectId].filters as CommunityComparisonFiltersState),
                ...fragment,
            };
        },
    },
});

export default communityComparisonSlice.reducer;
