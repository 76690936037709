import React from 'react';
import useAuthService from 'src/shared/hooks/useAuthService';
import { redirectToShopify } from 'src/shared/utils/redirect';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import styles from '../SiteMenu.module.scss';

const ShopifyMenu = () => {
    const { isAuthenticated, currentUser } = useAuthService();

    if (!isAuthenticated || !currentUser) {
        return null;
    }

    const handleClick = () => {
        redirectToShopify();
    };

    return (
        <>
            <div className={styles.menuItem} onClick={handleClick}>
                <div className={styles.icon}>
                    <ShoppingCartOutlinedIcon />
                </div>
                <div className={styles.title}>Shop</div>
            </div>
        </>
    );
};

export default ShopifyMenu;
