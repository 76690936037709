import mergeWith from 'lodash/mergeWith';
import { JSONValue, PrimitiveValue, ArrayValue } from 'src/shared/types';
import isArray from 'lodash/isArray';
import unset from 'lodash/unset';

export enum StorageType {
    LOCAL_STORAGE = 'localstorage',
    SESSION_STORAGE = 'sessionstorage',
}

type ValueForSaving = PrimitiveValue | ArrayValue | ArrayValue[] | JSONValue;

type options = {
    storage: StorageType;
};

export interface StorageServiceInterface {
    get: (key: string, options?: options) => any;
    save: (key: string, value: ValueForSaving, options?: options) => void;
    update: (key: string, fragment: JSONValue, options?: options) => void;
    remove: (key: string, options?: options) => void;
    removeAt: (key: string, pathOrPaths: string | string[], options?: options) => void;
    clearAll: () => void;
}

const defaultOptions: options = {
    storage: StorageType.LOCAL_STORAGE,
};

// We need to overwrite the arrays instead of merging them so that it is easier to remove entries
const mergeStrategy = (currentValue: JSONValue, newValue: JSONValue, key: string, object: JSONValue) => {
    // Unset the property if the source value is undefined
    if (newValue === undefined) {
        unset(object, key);
    }

    if (Array.isArray(currentValue)) {
        return newValue;
    }
};

export class StorageService implements StorageServiceInterface {
    private localStorage: Storage;
    private sessionStorage: Storage;

    public constructor() {
        this.localStorage = window.localStorage;
        this.sessionStorage = window.sessionStorage;
    }

    private chooseStorage(storage: StorageType) {
        return storage === StorageType.SESSION_STORAGE ? this.sessionStorage : this.localStorage;
    }

    public get(key: string, options = defaultOptions) {
        const storage = this.chooseStorage(options.storage);
        const savedValue = storage.getItem(key);
        return savedValue ? JSON.parse(savedValue) : null;
    }

    public save(key: string, value: ValueForSaving, options = defaultOptions) {
        const storage = this.chooseStorage(options.storage);
        storage.setItem(key, JSON.stringify(value));
    }

    // intended only for updating part of the saved object
    public update(key: string, fragment: JSONValue, options = defaultOptions) {
        const storedData = this.get(key, options);
        if (storedData) {
            this.save(key, mergeWith(storedData, fragment, mergeStrategy), options);
        } else {
            this.save(key, fragment, options);
        }
    }

    public remove(key: string, options = defaultOptions) {
        const storage = this.chooseStorage(options.storage);
        storage.removeItem(key);
    }

    public removeAt(key: string, pathOrPaths: string | string[], options = defaultOptions) {
        const paths = isArray(pathOrPaths) ? pathOrPaths : [pathOrPaths];
        const storedData = this.get(key, options);
        unset(storedData, paths);
        this.save(key, storedData, options);
    }

    public clearAll() {
        this.localStorage.clear();
        this.sessionStorage.clear();
    }
}

const storageService = new StorageService();
export default storageService;
