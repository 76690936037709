import { RootState } from 'src/store';
import {
    HabitatInsightsEcologicalTrendsUIState,
    HabitatInsightsEcologicalTrendsFiltersState,
    defaultUiState,
} from './habitatInsightsEcologicalTrendsSlice';

export const getCurrentHabitatInsightsEcologicalTrendsUIState = (state: RootState): HabitatInsightsEcologicalTrendsUIState => {
    const currentProjectId = state.projects.projectList?.currentProjectId;

    if (!currentProjectId) {
        return defaultUiState;
    }

    return state.insights.habitatInsightsEcologicalTrends.uiState[currentProjectId] || defaultUiState;
};

export const getHabitatInsightsEcologicalTrendsFilters = (state: RootState): HabitatInsightsEcologicalTrendsFiltersState => {
    const currentUiState = getCurrentHabitatInsightsEcologicalTrendsUIState(state);

    return currentUiState.filters;
};
