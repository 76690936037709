import { find, isEmpty } from 'lodash';
import React from 'react';

import TimeSeriesReChart from 'src/shared/components/charts/time-series/TimeSeriesReChart';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader, { LinearLoader } from 'src/shared/components/loader/Loader';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { transformHabitatInsightsChartData } from 'src/app/insights/views/habitat-insights-ecological-trends/habitatInsightsEcologicalTrends.utils';
import { getCurrentHabitatInsightsEcologicalTrendsUIState } from 'src/app/insights/state/habitat-insights-ecological-trends/habitatInsightsEcologicalTrendsSelector';
import { useHabitatInsightsEcologicalTrendsQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import useProject from 'src/shared/hooks/useProject';
import { useAppSelector } from 'src/store';
import { TickFormat } from 'src/shared/types';
import HabitatInsightsEcologicalTrendsFilters from './components/habitat-insights-ecological-trends-filters/HabitatInsightsEcologicalTrendsFilters';
import styles from './HabitatInsightsEcologicalTrends.module.scss';

const chartDetailsByType: { [key: string]: { [key: string]: string } } = {
    Land_Cover: {
        explainerSlugKey: 'land-cover',
        legendTitle: 'Land Cover Type',
    },
    Habitat_Connectivity: {
        explainerSlugKey: 'habitat-connectivity',
        legendTitle: 'Connectivity Type',
    },
    Primary_Productivity: {
        explainerSlugKey: 'primary-productivity',
        legendTitle: 'Primary productivity',
    },
};

const PAGE_TITLE = 'Ecological Trends';
const HabitatInsightsEcologicalTrends = () => {
    const { currentProjectDetails, currentProjectFilters, getColourForHabitatInsights } = useProject();
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);
    const uiState = useAppSelector(getCurrentHabitatInsightsEcologicalTrendsUIState);

    const {
        filters: { metric, site, bufferArea, annualRange },
    } = uiState;

    const { currentData, isFetching } = useHabitatInsightsEcologicalTrendsQuery(
        {
            projectId: currentProjectDetails?.projectId || '',
            habitatInsightsCharts: [metric],
            siteName: [site],
        },
        {
            skip: !currentProjectDetails?.projectId || !site || !metric,
        }
    );

    if (isFetching || !currentProjectDetails) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    const chartData = currentData?.habitatInsightsEcologicalTrends;

    const tranformedChartData =
        chartData && transformHabitatInsightsChartData(chartData.data, bufferArea, annualRange, getColourForHabitatInsights);
    const timeSeriesChartData = tranformedChartData?.chartData || [];
    const legendData = tranformedChartData?.legendData || [];
    const isDataWithBufferArea = tranformedChartData?.isDataWithBufferArea || false;
    const isDataWithAnnualRange = tranformedChartData?.isDataWithAnnualRange || false;
    const metricOptions = currentProjectFilters?.habitatInsightsMetricOptions || [];
    const ticksList = tranformedChartData?.tickslist || [];

    const mainContent = (
        <div className={styles.chartContainer}>
            <div className={styles.habitatInsights}>
                <div className={styles.chartTitle}>
                    <h3>
                        {find(metricOptions, { value: metric })?.label}
                        {chartDetailsByType[metric]?.explainerSlugKey && (
                            <QuestionButtonHelp type='api' placement='right' slug={chartDetailsByType[metric].explainerSlugKey} />
                        )}
                    </h3>
                </div>

                {!isEmpty(chartData) && timeSeriesChartData.length ? (
                    <TimeSeriesReChart
                        chartData={timeSeriesChartData}
                        legendData={legendData}
                        legendTitle={chartDetailsByType[metric]?.legendTitle}
                        tickFormat={TickFormat.YEAR_ONLY}
                        ticksList={ticksList}
                    />
                ) : (
                    <h5>No Metrics to display</h5>
                )}
            </div>
        </div>
    );

    return (
        <StandardAppLayout
            mainContent={mainContent}
            otherActions={
                <HabitatInsightsEcologicalTrendsFilters
                    showBufferAreaFilter={isDataWithBufferArea}
                    showAnnualRangeFilter={isDataWithAnnualRange}
                />
            }
            breadcrumbs={currentProjectBreadcrumbDetails}
            title={PAGE_TITLE}
            subTitle='Time series by sample group and sites'
        />
    );
};

export default HabitatInsightsEcologicalTrends;
