import { useState, useEffect } from 'react';

const useMediaQuery = (mediaQuery: string): boolean | null => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const [doesMatch, setDoesMatch] = useState(mediaQueryList?.matches);

    const onChange = (event: MediaQueryListEvent) => {
        setDoesMatch(event.matches);
    };

    useEffect(() => {
        mediaQueryList?.addEventListener('change', onChange);

        return () => mediaQueryList?.removeEventListener('change', onChange);
    }, [mediaQuery]);

    return doesMatch;
};

export default useMediaQuery;
