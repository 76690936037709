import React, { ReactNode, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { useTaxonomicComparisonQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import { getCurrentTaxonomicCompositionUIState } from 'src/app/insights/state/taxonomic-composition/taxonomicCompositionSelector';
import useProject from 'src/shared/hooks/useProject';
import { useAppDispatch, useAppSelector } from 'src/store';
import * as d3 from 'd3';
import times from 'lodash/times';
import uniq from 'lodash/uniq';
import { TaxonomicCompositionFiltersState, updateFilters } from 'src/app/insights/state/taxonomic-composition/taxonomicCompositionSlice';
import SelectMultiple, { SelectMultipleOption } from 'src/shared/components/select-multiple/SelectMultiple';
import Loader, { LinearLoader } from 'src/shared/components/loader/Loader';
import NoData from 'src/shared/components/no-data/NoData';
import HexagonTaxChart from 'src/assets/images/insights/hexagon-taxchart.png';
import useOutsideClick from 'src/shared/hooks/useOutsideClick';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import {
    getBlockColors,
    getTaxonomicChartData,
    getFilteredTaxonomySummary,
    TaxonomicChartColumns,
    TaxonomicChartNodeData,
    getDefaultColumnsForAssayType,
} from './taxonomicCompositionChartHelpers';
import styles from './TaxonomicCompositionChart.module.scss';
import TaxonomicCompositionFilters from '../taxonomic-composition-filters/TaxonomicCompositionFilters';

const ROW_HEIGHT = 40;

const TaxonomicCompositionChart = () => {
    const [chartData, setChartData] = useState<TaxonomicChartNodeData[] | null>(null);
    const [kingdomOptions, setKingdomOptions] = useState<SelectMultipleOption[]>([]);
    const [classOptions, setClassOptions] = useState<SelectMultipleOption[]>([]);
    const [phylumOptions, setPhylumOptions] = useState<SelectMultipleOption[]>([]);

    const dispatch = useAppDispatch();
    const {
        currentProjectId,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        currentProjectFilters,
        samplingEventFilterData,
    } = useProject();
    const uiState = useAppSelector(getCurrentTaxonomicCompositionUIState);

    const {
        filters: {
            metric,
            areas,
            hierarchy,
            sortColumnsBy,
            kingdoms,
            phylums,
            classes,
            selectedColumns,
            highlightedBranchNodeId,
            visibleBranchNodeId,
            samplingEvent,
        },
    } = uiState;

    const { currentData, isFetching } = useTaxonomicComparisonQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                fromDate: samplingEvent?.fromDate,
                toDate: samplingEvent?.toDate,
                habitatAssay: [habitatAssayType?.key || ''],
                area: areas,
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType || !areas.length || !samplingEvent,
        }
    );

    const onFiltersChange = (fragment: Partial<TaxonomicCompositionFiltersState>) => {
        dispatch(updateFilters(fragment));
    };

    useEffect(() => {
        onFiltersChange({
            selectedColumns: selectedColumns.length
                ? selectedColumns
                : getDefaultColumnsForAssayType(habitatAssayType?.key.split('-')[1] || ''),
            samplingEvent: samplingEvent || samplingEventFilterData[0],
            areas: areas.length ? areas : [...(currentProjectFilters?.areaOptions || [])].map(entry => entry.value),
        });
    }, [habitatAssayType]);

    useEffect(() => {
        if (!currentData || !currentData?.taxonomicComparisonData?.taxonomySummary?.length) {
            return;
        }

        const { taxonomySummary } = currentData.taxonomicComparisonData;
        const filteredTaxonomicSummary = getFilteredTaxonomySummary({ taxonomySummary, classes: [], phylums: [], kingdoms: [] });
        const newKingdomsRaw = uniq(filteredTaxonomicSummary.map(entry => entry.tax_kingdom).filter(Boolean));
        const newKingdomOptions = newKingdomsRaw.sort().map(entry => ({
            label: entry as string,
            value: entry as string,
        }));

        setKingdomOptions(newKingdomOptions);
        const allKingdoms = newKingdomOptions.map(entry => entry.value);
        const newKingdoms = kingdoms.length ? kingdoms : allKingdoms;

        const newPhylumOptions = uniq(
            filteredTaxonomicSummary
                .filter(entry => newKingdoms.includes(entry.tax_kingdom || ''))
                .map(entry => entry.tax_phylum)
                .filter(Boolean)
        )
            .sort()
            .map(entry => ({
                label: entry as string,
                value: entry as string,
            }));

        setPhylumOptions(newPhylumOptions);

        const newPhylums = phylums.length ? phylums : newPhylumOptions.map(entry => entry.value);

        const newClassOptions = uniq(
            filteredTaxonomicSummary
                .filter(entry => newKingdoms.includes(entry.tax_kingdom || entry.tax_phylum || ''))
                .map(entry => entry.tax_class)
                .filter(Boolean)
        )
            .sort()
            .map(entry => ({
                label: entry as string,
                value: entry as string,
            }));

        setClassOptions(newClassOptions);

        const newClasses = classes.length ? classes : newClassOptions.map(entry => entry.value);

        onFiltersChange({
            kingdoms: newKingdoms,
            phylums: newPhylums,
            classes: newClasses,
        });
    }, [currentData, kingdoms, phylums, classes]);

    useEffect(() => {
        if (!currentData || !currentData?.taxonomicComparisonData) {
            return;
        }

        const { taxonomySummary } = currentData.taxonomicComparisonData;

        const chartData = getTaxonomicChartData({
            taxonomySummary,
            sortColumnsBy,
            metric,
            kingdoms,
            phylums,
            classes,
            hierarchy,
            selectedColumns,
            highlightedBranchNodeId,
            visibleBranchNodeId,
        });

        setChartData(chartData);
    }, [
        isFetching,
        currentData,
        classes,
        phylums,
        kingdoms,
        metric,
        sortColumnsBy,
        hierarchy,
        selectedColumns,
        highlightedBranchNodeId,
        visibleBranchNodeId,
        habitatAssayType,
    ]);

    if (isFetching || chartData === null) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    if ((chartData !== null && chartData.length === 0) || !selectedColumns.length || !areas.length) {
        return (
            <div className={styles.chartContainer}>
                <div className={styles.topContent} style={{ justifyContent: 'flex-end' }}>
                    <div className={styles.filters}>
                        <TaxonomicCompositionFilters />
                    </div>
                </div>
                <NoData image={HexagonTaxChart} text='There is no data that matches the currently selected filters.' />;
            </div>
        );
    }

    const columnBlocks: { [key in TaxonomicChartColumns]: ReactNode[] } = {
        [TaxonomicChartColumns.tax_kingdom]: [],
        [TaxonomicChartColumns.tax_phylum]: [],
        [TaxonomicChartColumns.tax_class]: [],
        [TaxonomicChartColumns.tax_order]: [],
        [TaxonomicChartColumns.tax_family]: [],
        [TaxonomicChartColumns.tax_genus]: [],
        [TaxonomicChartColumns.tax_species]: [],
    };

    const columnValues: { [key in TaxonomicChartColumns]: number[] } = {
        [TaxonomicChartColumns.tax_kingdom]: [],
        [TaxonomicChartColumns.tax_phylum]: [],
        [TaxonomicChartColumns.tax_class]: [],
        [TaxonomicChartColumns.tax_order]: [],
        [TaxonomicChartColumns.tax_family]: [],
        [TaxonomicChartColumns.tax_genus]: [],
        [TaxonomicChartColumns.tax_species]: [],
    };

    chartData.forEach(row1 => {
        columnValues[selectedColumns[0]].push(row1.value);
        row1.children?.forEach(row2 => {
            columnValues[selectedColumns[1]]?.push(row2.value);
            row2.children?.forEach(row3 => {
                columnValues[selectedColumns[2]]?.push(row3.value);
                row3.children?.forEach(row4 => {
                    columnValues[selectedColumns[3]]?.push(row4.value);
                    row4.children?.forEach(row5 => {
                        columnValues[selectedColumns[4]]?.push(row5.value);
                        row5.children?.forEach(row6 => {
                            columnValues[selectedColumns[5]]?.push(row6.value);
                            row6.children?.forEach(row7 => {
                                columnValues[selectedColumns[6]]?.push(row7.value);
                            });
                        });
                    });
                });
            });
        });
    });

    const firstColumnValues = columnValues[selectedColumns[0] || TaxonomicChartColumns.tax_kingdom];
    const lastColumnValues = columnValues[selectedColumns.at(-1) || TaxonomicChartColumns.tax_species];

    const minMaxValues = {
        min: Math.min(...(lastColumnValues.length ? lastColumnValues : [0])),
        max: Math.max(...(firstColumnValues.length ? firstColumnValues : [0])),
    };

    const columnsDisplayOrder = hierarchy === 'high_to_low' ? selectedColumns : [...selectedColumns].reverse();
    chartData.forEach(row1 => {
        columnBlocks[columnsDisplayOrder[0]]?.push(
            <ChartBlock minMaxValues={minMaxValues} columnId={columnsDisplayOrder[0]} nodeData={row1} />
        );
        row1.children?.forEach(row2 => {
            columnBlocks[columnsDisplayOrder[1]]?.push(
                <ChartBlock minMaxValues={minMaxValues} columnId={columnsDisplayOrder[1]} nodeData={row2} />
            );
            row2.children?.forEach(row3 => {
                columnBlocks[columnsDisplayOrder[2]]?.push(
                    <ChartBlock minMaxValues={minMaxValues} columnId={columnsDisplayOrder[2]} nodeData={row3} />
                );
                row3.children?.forEach(row4 => {
                    columnBlocks[columnsDisplayOrder[3]]?.push(
                        <ChartBlock minMaxValues={minMaxValues} columnId={columnsDisplayOrder[3]} nodeData={row4} />
                    );
                    row4.children?.forEach(row5 => {
                        columnBlocks[columnsDisplayOrder[4]]?.push(
                            <ChartBlock minMaxValues={minMaxValues} columnId={columnsDisplayOrder[4]} nodeData={row5} />
                        );
                        row5.children?.forEach(row6 => {
                            columnBlocks[columnsDisplayOrder[5]]?.push(
                                <ChartBlock minMaxValues={minMaxValues} columnId={columnsDisplayOrder[5]} nodeData={row6} />
                            );
                            row6.children?.forEach(row7 => {
                                columnBlocks[columnsDisplayOrder[6]]?.push(
                                    <ChartBlock minMaxValues={minMaxValues} columnId={columnsDisplayOrder[6]} nodeData={row7} />
                                );
                            });
                        });
                    });
                });
            });
        });
    });

    const allHeaderColumns: { [key in TaxonomicChartColumns]: ReactNode } = {
        [TaxonomicChartColumns.tax_kingdom]: (
            <div key='kingdom'>
                <span style={{ alignSelf: 'baseline' }}>Kingdom</span>
                <SelectMultiple
                    width={'100%'}
                    className={styles.selectContainer}
                    options={kingdomOptions}
                    onChange={values => onFiltersChange({ kingdoms: values, phylums: [], classes: [] })}
                    values={kingdoms}
                />
            </div>
        ),
        [TaxonomicChartColumns.tax_phylum]: (
            <div key='phylum'>
                <span style={{ alignSelf: 'baseline' }}>Phylum</span>
                <SelectMultiple
                    width={'100%'}
                    className={styles.selectContainer}
                    options={phylumOptions}
                    onChange={values => onFiltersChange({ phylums: values, classes: [] })}
                    values={phylums}
                />
            </div>
        ),
        [TaxonomicChartColumns.tax_class]: (
            <div key='class'>
                <span style={{ alignSelf: 'baseline' }}>Class</span>
                <SelectMultiple
                    width={'100%'}
                    className={styles.selectContainer}
                    options={classOptions}
                    onChange={values => onFiltersChange({ classes: values })}
                    values={classes}
                />
            </div>
        ),
        [TaxonomicChartColumns.tax_order]: <div key='order'>Order</div>,
        [TaxonomicChartColumns.tax_family]: <div key='family'>Family</div>,
        [TaxonomicChartColumns.tax_genus]: <div key='genus'>Genus</div>,
        [TaxonomicChartColumns.tax_species]: <div key='species'>Species</div>,
    };

    const ColorScaleBar = () => (
        <div className={styles.colorScaleBucket}>
            <p className={styles.colorScaleDescription}>
                <span>Count of species observations</span>{' '}
                <QuestionButtonHelp
                    type='api'
                    placement='right'
                    slug='count-of-species-observations'
                    detailedExplainerSlug='taxonomic-composition-te'
                />
            </p>

            <ColorScale values={minMaxValues} />
            {minMaxValues && (
                <div className={styles.colorScaleLabels} style={{ maxWidth: minMaxValues.min > 0 ? 280 : 320 }}>
                    <p>{minMaxValues?.min}</p>
                    <p>{minMaxValues?.max}</p>
                </div>
            )}
        </div>
    );

    return (
        <div className={styles.chartContainer}>
            <div className={styles.topContent}>
                <ColorScaleBar />
                <div className={styles.filters}>
                    <TaxonomicCompositionFilters />
                </div>
            </div>

            <div style={{ display: 'flex', width: '100%' }}>
                {(hierarchy === 'high_to_low' ? selectedColumns : [...selectedColumns].reverse()).map((column, index) => (
                    <div key={index} className={styles.chartColumn}>
                        <div className={styles.chartHeader}>{allHeaderColumns[column]}</div>
                        {columnBlocks[column].map((block, index) => (
                            <span key={index}>{block}</span>
                        ))}
                    </div>
                ))}
            </div>
            <div className={styles.bottomColorScaleBar}>
                <ColorScaleBar />
            </div>
        </div>
    );
};

type ChartBlockProps = {
    columnId: TaxonomicChartColumns;
    nodeData: TaxonomicChartNodeData;
    minMaxValues: {
        min: number;
        max: number;
    };
};
const ChartBlock = (props: ChartBlockProps) => {
    const [shouldShowPopup, showPopup] = useState(false);
    const popupAnchorRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector(getCurrentTaxonomicCompositionUIState);

    const {
        filters: { highlightedBranchNodeId, visibleBranchNodeId },
    } = uiState;

    const { columnId, minMaxValues, nodeData } = props;

    const { totalChildren, value, name, totalspecies, id, isOpaqued, isHidden } = nodeData;

    const closePopup = () => {
        showPopup(false);
    };

    useOutsideClick(popupAnchorRef, closePopup);

    if (isHidden) {
        return null;
    }

    const height = ROW_HEIGHT * totalChildren;

    const blockClassnames = classNames(styles.chartBlock, {
        [styles[`chartBlock${columnId}`]]: true,
        [styles.chartBlockOpaque]: isOpaqued,
    });

    const onFiltersChange = (fragment: Partial<TaxonomicCompositionFiltersState>) => {
        dispatch(updateFilters(fragment));
    };

    const onHighlightBranch = () => {
        onFiltersChange({
            highlightedBranchNodeId: highlightedBranchNodeId && !isOpaqued ? null : id,
        });
    };

    const onHideAllOtherBranch = () => {
        onFiltersChange({
            visibleBranchNodeId: visibleBranchNodeId && !isHidden ? null : id,
        });
    };

    return (
        <div className={styles.chartBlockContainer}>
            <div
                onClick={() => showPopup(true)}
                className={blockClassnames}
                style={{ height: `${height}px`, ...getBlockColors(value, minMaxValues) }}
            >
                <div className={styles.chartBlockLeft} ref={popupAnchorRef}>
                    <span className={styles.chartBlockName}>{name}</span>
                    {shouldShowPopup && (
                        <div className={styles.blockPopup}>
                            <div className={styles.topContent}>
                                <div className={styles.scientificName}>{name}</div>
                                <div className={styles.speciesDetected}>{totalspecies} species detected</div>
                            </div>
                            <div className={styles.lineSeparator}></div>
                            <div className={styles.blockPopupActions}>
                                <div className={styles.highlightBranch} onClick={onHighlightBranch}>
                                    {highlightedBranchNodeId && !isOpaqued ? 'Unhighlight branch' : 'Highlight branch'}
                                </div>
                                <div className={styles.hideOtherBranch} onClick={onHideAllOtherBranch}>
                                    {visibleBranchNodeId && !isHidden ? 'Show all other branches' : 'Hide all other branches'}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.chartBlockValue}>{value}</div>
            </div>
        </div>
    );
};

type ColorScaleProps = {
    values: {
        min: number;
        max: number;
    };
};
const ColorScale = (props: ColorScaleProps) => {
    const { values } = props;

    const colorsArray = ['#FFFFE0', '#BBE4D1', '#76C7BE', '#3EA8A6', '#208288', '#076769', '#00494B', '#002C2D'];
    let colorsCount = colorsArray.length;
    if (values.min > 0) {
        colorsArray.shift();
        colorsCount = colorsCount - 1;
    }
    const backgroundColorScale = d3
        .scaleQuantile()
        .domain([0, colorsCount])
        .range(colorsArray as Iterable<number>);
    const blocks: ReactNode[] = [];
    times(colorsCount, index => {
        const colorPalette = backgroundColorScale(index);
        blocks.push(<span key={index} style={{ backgroundColor: `${colorPalette}` }}></span>);
    });
    return <div className={styles.colorScale}>{blocks}</div>;
};

export default TaxonomicCompositionChart;
