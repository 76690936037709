import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import { createSupportRequest, CreateSupportRequestQueryResult } from './queries/globalQuery';

type CreateSupportRequestParams = {
    organisationId: string;
    projectId: string;
    requestType: string;
    description: string;
};

const globalGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        createSupportRequest: builder.mutation<CreateSupportRequestQueryResult, CreateSupportRequestParams>({
            query: params => ({
                body: createSupportRequest,
                variables: { input: params },
            }),
        }),
    }),
});

export const { useCreateSupportRequestMutation } = globalGraphSlice;
