import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import { getNewProjectUserFormState } from 'src/app/admin/views/user-management/state/userManagementSelector';
import { NewProjectUserFormState, setFormState } from 'src/app/admin/views/user-management/state/userManagementSlice';
import { useCheckUsernameAvailableQuery } from 'src/app/admin/views/user-management/state/api/userManagementGraphSlice';
import { isEmailIdValid } from 'src/shared/helpers/emailHelpers';
import useAuthService from 'src/shared/hooks/useAuthService';
import { isCountryCodeValid as checkIsCountryCodeValid } from 'src/shared/helpers/phoneNumberHelpers';
import Input from 'src/shared/components/input/Input';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';

import styles from './CreateNewUserForm.module.scss';

const CreateNewUserForm = () => {
    const formState = useAppSelector(getNewProjectUserFormState);
    const [isCountryCodeValid, setIsCountryCodeValid] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const { isCurrentUserOnboardingManager, isCurrentUserSuperAdmin } = useAuthService();

    const { isFetching, currentData } = useCheckUsernameAvailableQuery(
        {
            email: formState.email,
        },
        {
            skip: !isEmailIdValid(formState.email),
        }
    );

    const onFormChange = (fragment: Partial<NewProjectUserFormState['formState']>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    useEffect(() => {
        if (!isEmailIdValid(formState.email) || isFetching) {
            return;
        }
        dispatch(
            setFormState({
                emailIdAvailable: currentData?.usernameAvailable || false,
                emailIdAvailabilityChecked: true,
            })
        );
    }, [formState.email, isFetching]);

    const onEmailChange = async (email: string) => {
        const isEmailValid = isEmailIdValid(email);

        dispatch(
            setFormState({
                email,
                emailIdAvailabilityChecked: false,
                isEmailValid: isEmailValid,
            })
        );
    };

    const handleBlur = () => {
        setIsCountryCodeValid(!formState?.phoneCountryCode || checkIsCountryCodeValid(formState?.phoneCountryCode));
    };

    const emailHasError = Boolean(formState.email) && (!formState.emailIdAvailable || !isEmailIdValid(formState.email));
    const shouldShowEmailExistsError =
        isEmailIdValid(formState.email) && !formState.emailIdAvailable && formState.emailIdAvailabilityChecked;

    return (
        <div className={styles.createNewUserForm}>
            {!(isCurrentUserOnboardingManager || isCurrentUserSuperAdmin) && (
                <>
                    <div className={styles.stepIndicator}>Step 1 of 2</div>
                    <div className={styles.sectionHeading}>Enter details of new user</div>
                </>
            )}
            {(isCurrentUserOnboardingManager || isCurrentUserSuperAdmin) && <div className={styles.title}>Create new user</div>}
            <div className={styles.fields}>
                <Input
                    value={formState.firstName}
                    placeholder='First name'
                    autoComplete='off'
                    onChange={event => onFormChange({ firstName: event.target.value })}
                ></Input>
                <Input
                    value={formState.lastName}
                    placeholder='Last name'
                    autoComplete='off'
                    onChange={event => onFormChange({ lastName: event.target.value })}
                ></Input>
                <div className={styles.phoneInput}>
                    <Input
                        value={formState.phoneCountryCode ?? ''}
                        placeholder='+44'
                        maxLength={4}
                        hideMaxLengthLabel={true}
                        onBlur={handleBlur}
                        hasError={!isCountryCodeValid}
                        onChange={event => onFormChange({ phoneCountryCode: event.target.value })}
                    ></Input>
                    <Input
                        value={formState.phone ?? ''}
                        placeholder='Mobile number'
                        onChange={event => onFormChange({ phone: event.target.value })}
                        autoComplete='off'
                        maxLength={15}
                        hideMaxLengthLabel={true}
                        hasError={Boolean(formState.phone) && !isFinite(Number(formState.phone))}
                        onBlur={handleBlur}
                    ></Input>
                </div>
                <Input
                    value={formState.email}
                    placeholder='Email'
                    hasError={emailHasError}
                    onChange={event => onEmailChange(event.target.value)}
                ></Input>

                {shouldShowEmailExistsError && (
                    <ErrorMessage
                        message={
                            <>
                                This email address is already in use by a registered user
                                <p>Please enter a unique email address to register a new user</p>
                            </>
                        }
                    />
                )}

                {!isCountryCodeValid && (
                    <ErrorMessage message={<span>Please enter a valid country code starting with a &apos;+&apos;.</span>} />
                )}
            </div>
        </div>
    );
};

export default CreateNewUserForm;
