import { Location } from 'react-router-dom';
import analyticsTracking from 'src/services/analytics-service';
import { TrafficUserType, defaultGtagConfig } from 'src/services/google-analytics';
import { ProjectDefinition } from 'src/shared/types/graph/project';

type ConfigUpdateParams = {
    location: Location;
    isAuthenticated: boolean;
    currentProjectSummary?: ProjectDefinition | null;
    isCurrentUserInternal?: boolean;
    currentCustomerName?: string;
};

export const handleConfigUpdate = (configUpdateParams: ConfigUpdateParams) => {
    const { location, isAuthenticated, currentProjectSummary, isCurrentUserInternal, currentCustomerName } = configUpdateParams;

    const shouldRemoveCustomer = location.pathname.includes('/customers');
    const isProjectsLocation = location.pathname.includes('/projects') && !location.pathname.includes('/project-definition');
    const isAdminLocation = location.pathname.includes('/admin');
    const shouldRemoveProject = shouldRemoveCustomer || isProjectsLocation || isAdminLocation;
    const gaCustomerValue = currentCustomerName || defaultGtagConfig.customer_name;
    const gaProjectValue = currentProjectSummary?.projectName || defaultGtagConfig.customer_name;
    const gaTrafficUserTypeValue = isCurrentUserInternal ? TrafficUserType.INTERNAL : TrafficUserType.EXTERNAL;
    const gaConfigParams = {
        traffic_user_type: isAuthenticated ? gaTrafficUserTypeValue : defaultGtagConfig.traffic_user_type,
        customer_name: !shouldRemoveCustomer ? gaCustomerValue : defaultGtagConfig.customer_name,
        project: !shouldRemoveProject ? gaProjectValue : defaultGtagConfig.project,
    };
    analyticsTracking.updateConfig(gaConfigParams);
};
