import React from 'react';
import speciesImageUrl from 'src/assets/images/dashboard/species.svg?url';
import styles from './MetricCard.module.scss';
interface CardProps {
    header: string;
    logo?: React.ReactNode;
    content?: string | React.ReactNode;
    helpText?: React.ReactNode;
    total?: number;
    average?: number;
    backgroundImage?: string;
    noData?: boolean;
}

const MetricCard = ({ header, logo, content, helpText, total, noData = false, backgroundImage = speciesImageUrl }: CardProps) => {
    const backgroundImageStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '150% center',
        backgroundSize: '50%',
    };

    return (
        <div className={styles.card} style={backgroundImageStyle} data-testid='metric-card'>
            <div className={styles.header}>
                {logo}
                <span className={styles.title}>{header}</span>
                <span className={styles.questionButton}>{helpText}</span>
            </div>

            <div className={styles.content}>
                {noData ? (
                    <>
                        <div className={styles.noDataContent}>There is insufficient data to display this chart</div>
                    </>
                ) : (
                    <>
                        {total !== undefined ? <div className={styles.number}>{total}</div> : null}
                        <div className={styles.text}>{content}</div>
                    </>
                )}
            </div>

            {noData && (
                <div className={styles.footer}>
                    <span>Speak to our customer success team on how to update your sampling plan</span>
                </div>
            )}
        </div>
    );
};

export default MetricCard;
