import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';
import { SamplingEvent, SamplingKit, SamplingEventBatchAmendmentOwner } from 'src/shared/types';
import {
    samplingEventQuery,
    SamplingEventQueryResult,
    updateSampleKitQuery,
    initiateSampleManifestEventQuery,
    submitSampleKitsForProcessingQuery,
    batchApprovedByLabQuery,
    batchApprovedByLogisticsQuery,
    batchAmendmentRequiredQuery,
} from './queries/sampleManifestQueries';
import { defaultSamplingKit } from '../sampleManifestSlice';

type SamplingEventQueryParams = {
    samplingEventId: string;
    projectId: string;
};

type UpdateSamplingKitParams = {
    projectId: string;
    samplingEventId: string;
    input: SamplingKit;
};

type SubmitSampleKitsForProcessingParams = {
    projectId: string;
    samplingEventId: string;
    kitIds: string[];
    eventCompleted: boolean;
    batchId?: string | null;
};

type BatchApprovedByLogisticsParams = {
    projectId: string;
    samplingEventId: string;
    batchId: string;
};

type BatchAmendmentRequiredParams = BatchApprovedByLogisticsParams & {
    message: string;
    owner: SamplingEventBatchAmendmentOwner;
};

const sampleManifestGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        samplingEvent: builder.query<SamplingEventQueryResult, SamplingEventQueryParams>({
            query: params => ({
                body: samplingEventQuery,
                variables: params,
            }),
            transformResponse(baseQueryReturnValue) {
                const samplingEvent = baseQueryReturnValue.sampleManifest.samplingEvent.event;
                // Add a default value for all the kits
                const kits = (samplingEvent as SamplingEvent).sampleManifestInfo.kits.map(kit => {
                    const samplingKit = Object.fromEntries(Object.entries(kit).filter(([, value]) => value));
                    return { ...defaultSamplingKit, ...samplingKit };
                });

                return {
                    ...samplingEvent,
                    kits,
                };
            },
        }),
        updateSampleKit: builder.mutation<boolean, UpdateSamplingKitParams>({
            query: params => ({
                body: updateSampleKitQuery,
                variables: params,
            }),
            async onQueryStarted(arg, api) {
                const { queryFulfilled, dispatch } = api;

                const patchSamplingEvent = dispatch(
                    sampleManifestGraphSlice.util.updateQueryData(
                        'samplingEvent',
                        { samplingEventId: arg.samplingEventId, projectId: arg.projectId },
                        (draft: SamplingEventQueryResult) => {
                            if (draft) {
                                const kitIndex = draft.sampleManifestInfo.kits.findIndex(kit => kit.id === arg.input.id);
                                if (kitIndex !== -1) {
                                    draft.sampleManifestInfo.kits[kitIndex] = { ...defaultSamplingKit, ...arg.input };
                                }
                            }
                        }
                    )
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    patchSamplingEvent.undo();
                    console.error('Error updating sampling kit:', error);
                }
            },
        }),

        initiateSampleManifestEvent: builder.mutation<boolean, SamplingEventQueryParams>({
            query: params => ({
                body: initiateSampleManifestEventQuery,
                variables: params,
            }),
        }),

        submitSampleKitsForProcessing: builder.mutation<string, SubmitSampleKitsForProcessingParams>({
            query: params => ({
                body: submitSampleKitsForProcessingQuery,
                variables: params,
            }),
            transformResponse(baseQueryReturnValue) {
                return baseQueryReturnValue?.sampleManifest?.submitSampleKitsForProcessing?.batchId || '';
            },
        }),

        batchApprovedByLogistics: builder.mutation<boolean, BatchApprovedByLogisticsParams>({
            query: params => ({
                body: batchApprovedByLogisticsQuery,
                variables: params,
            }),
        }),

        batchApprovedByLab: builder.mutation<boolean, BatchApprovedByLogisticsParams>({
            query: params => ({
                body: batchApprovedByLabQuery,
                variables: params,
            }),
        }),
        batchAmendmentRequired: builder.mutation<boolean, BatchAmendmentRequiredParams>({
            query: params => ({
                body: batchAmendmentRequiredQuery,
                variables: params,
            }),
        }),
    }),
});

export const {
    useSamplingEventQuery,
    useLazySamplingEventQuery,
    useUpdateSampleKitMutation,
    useInitiateSampleManifestEventMutation,
    useSubmitSampleKitsForProcessingMutation,
    useBatchApprovedByLogisticsMutation,
    useBatchApprovedByLabMutation,
    useBatchAmendmentRequiredMutation,
} = sampleManifestGraphSlice;
export const { samplingEvent: fetchSamplingEvent } = sampleManifestGraphSlice.endpoints;
export default sampleManifestGraphSlice.reducer;
