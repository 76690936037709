import { gql } from 'graphql-request';

export const habitatInsightsUploadQuery = gql`
    mutation addSiteToSurveyDesign($input: AddSiteToSurveyDesign!) {
        addSiteToSurveyDesign(input: $input)
    }
`;

export type HabitatInsightsUploadQueryResult = {
    id: string;
};
