import React from 'react';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import SampleReceptionFilters from './components/sample-reception-filters/SampleReceptionFilters';
import SampleReceptionList from './components/sample-reception-list/SampleReceptionList';

const SampleReceptionHome = () => {
    const mainContent = (
        <div className='flex flex-col gap-4'>
            <SampleReceptionFilters />
            <SampleReceptionList />
        </div>
    );

    return <StandardAppLayout title='Sample Reception' subTitle='View customer sample manifests' mainContent={mainContent} />;
};

export default SampleReceptionHome;
