import React, { ReactNode } from 'react';
import Chip from 'src/shared/components/chip/Chip';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { MetricsChartData } from 'src/shared/types';
import RichnessReChart from 'src/shared/components/charts/richness-chart/RichnessReChart';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import MetricCard from 'src/shared/components/metric-card/MetricCard';
import useProject from 'src/shared/hooks/useProject';
import NoData from 'src/shared/components/no-data/NoData';
import HexagonRichness from 'src/assets/images/dashboard/hexagon-richness.png';
import classNames from 'classnames';
import { upperFirst } from 'lodash';
import styles from './TimeseriesCardTemplate.module.scss';

export type TimeseriesCardTemplateProps = {
    title: string;
    titleHelpSlug?: string;
    description?: ReactNode;
    tabs?: ReactNode;
    averageValueDescription?: string;
    metricsChartData: MetricsChartData;
    metricCardImageUrl?: string;
    metricCardContent?: string;
    footerDescriptionMetricName?: string;
};

const TimeseriesCardTemplate = (props: TimeseriesCardTemplateProps) => {
    const {
        title,
        titleHelpSlug,
        description,
        tabs,
        averageValueDescription,
        metricsChartData,
        metricCardImageUrl,
        metricCardContent,
        footerDescriptionMetricName,
    } = props;

    const metricsData = metricsChartData?.data?.[0]?.data;

    const { isSingleTimepoint } = useProject();

    if (!metricsChartData || !metricsChartData.data || !metricsChartData.data?.[0]?.data?.length) {
        return isSingleTimepoint ? (
            <MetricCard
                header={title}
                helpText={titleHelpSlug && <QuestionButtonHelp type='api' placement='right' slug={titleHelpSlug} />}
                noData={true}
            />
        ) : (
            <div className={styles.timeseriesCardTemplate}>
                <div>
                    <div className={styles.title}>
                        {title}
                        {titleHelpSlug && <QuestionButtonHelp type='api' placement='right' slug={titleHelpSlug} />}
                    </div>
                    <NoData
                        image={HexagonRichness}
                        text='There is insufficient data to display this chart.'
                        subText='Speak to our Customer Success team on how to update your sampling plan'
                        height='40vh'
                    />
                </div>
            </div>
        );
    }

    const chartData = metricsData.flatMap(entry =>
        entry.group
            .filter(item => item.type === 'all')
            .map(point => ({
                value: point.value,
                date: Number(entry.datetime),
            }))
    );

    if (isSingleTimepoint) {
        return (
            <MetricCard
                header={title}
                content={metricCardContent}
                helpText={titleHelpSlug && <QuestionButtonHelp type='api' placement='right' slug={titleHelpSlug} />}
                total={chartData[0].value}
                backgroundImage={metricCardImageUrl}
            />
        );
    }

    const timePoints = uniq(metricsData.flatMap(entry => moment(Number(entry.datetime))));

    const firstYearMeanEndDatetime = moment(timePoints[0]).add(1, 'year').valueOf();
    const firstYearValues = metricsData
        .filter(entry => Number(entry.datetime) < firstYearMeanEndDatetime)
        .flatMap(entry => entry.group.filter(item => item.type === 'all').map(point => point.value));

    let firstYearAverage =
        firstYearValues.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / firstYearValues.length;
    firstYearAverage = Number(firstYearAverage.toFixed(2));
    const endYearWithQuarter = 'Q' + timePoints.at(-1)?.format('Q YYYY') || '-';

    const latestQuarterMeasurement = metricsData.at(-1)?.group.find(item => item.type === 'all')?.value || 0;

    let meanPercentageDifference = Math.round(((latestQuarterMeasurement - firstYearAverage) / firstYearAverage) * 100);
    if (isNaN(meanPercentageDifference)) {
        meanPercentageDifference = 0;
    }
    const percentageDifferenceChipClass =
        meanPercentageDifference < -5 ? styles.red : meanPercentageDifference > 5 ? styles.green : styles.amber;
    const signedMeanPercentageDifference = meanPercentageDifference > 0 ? `+${meanPercentageDifference}%` : `${meanPercentageDifference}%`;

    // Calculate the percentage difference between first and last sampling event values
    const firstSamplingEventValue = metricsData[0].group[0].value;
    let latestSamplePercentageDifference = Math.round(
        ((latestQuarterMeasurement - firstSamplingEventValue) / firstSamplingEventValue) * 100
    );
    if (isNaN(latestSamplePercentageDifference)) {
        latestSamplePercentageDifference = 0;
    }

    const averageValue = latestQuarterMeasurement === 0 ? '0' : latestQuarterMeasurement.toFixed(2);

    return (
        <div className={styles.timeseriesCardTemplate}>
            <div className={styles.top}>
                <div className={styles.title}>
                    {title}
                    {titleHelpSlug && <QuestionButtonHelp type='api' placement='right' slug={titleHelpSlug} />}
                </div>
                {description && <div>{description}</div>}
                {tabs && <div className={styles.tabs}>{tabs}</div>}
            </div>
            <div className={styles.averageWrapper}>
                <div className={styles.averageValue}>{averageValue}</div>
                <div className={styles.averageValueDescription}>
                    {averageValueDescription?.replace('[endYearWithQuarter]', endYearWithQuarter)}
                </div>
            </div>
            <div className={styles.mean}>
                <div className={styles.meanLeft}>
                    <Chip className={classNames(styles.chip, percentageDifferenceChipClass)} label={signedMeanPercentageDifference || ''} />
                    vs Mean of first 12 months of samples
                </div>
                <div className={styles.meanRight}>
                    <div>--- first year mean</div>
                    <QuestionButtonHelp type='api' placement='left' slug='first-year-mean-explainer' />
                </div>
            </div>
            <div>
                <RichnessReChart chartData={chartData} baseLineValue={firstYearAverage} />
                <CMSArticle
                    slug='metrics-timeseries-chart-footer-description'
                    substitutions={{
                        metric: footerDescriptionMetricName || upperFirst(title.toLowerCase()),
                        percentageDifference: String(latestSamplePercentageDifference),
                        increasedOrDecreased: latestSamplePercentageDifference > 0 ? 'increased' : 'decreased',
                    }}
                />
            </div>
        </div>
    );
};

export default TimeseriesCardTemplate;
