import storageService, { StorageServiceInterface, StorageType } from 'src/services/storage-service';
import { CMSFeatureTestConfig } from 'src/shared/types';

export enum StorageKeys {
    FEATURE_TEST_CONFIG = 'features.featureTestConfig',
}

const localStorageOptions = {
    storage: StorageType.LOCAL_STORAGE,
};

export class GlobalStorageService {
    private storageService: StorageServiceInterface;

    public constructor(storageService: StorageServiceInterface) {
        this.storageService = storageService;
    }

    public getFeatureTestConfig(): CMSFeatureTestConfig {
        return this.storageService.get(StorageKeys.FEATURE_TEST_CONFIG, localStorageOptions);
    }

    public setFeatureTestConfig(featureTestConfig: CMSFeatureTestConfig): void {
        this.storageService.remove(StorageKeys.FEATURE_TEST_CONFIG);
        this.storageService.save(StorageKeys.FEATURE_TEST_CONFIG, featureTestConfig, localStorageOptions);
    }

    public clearAll(): void {
        this.storageService.remove(StorageKeys.FEATURE_TEST_CONFIG, localStorageOptions);
    }
}

export default new GlobalStorageService(storageService);
