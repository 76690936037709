import React, { type HTMLProps } from 'react';
import classNames from 'classnames';

import styles from './Table.module.scss';

type Props = HTMLProps<HTMLTableElement> & {
    stickyHeader?: boolean;
};

const Table = (props: Props) => {
    const { stickyHeader, className, ...otherProps } = props;

    const tableClasses = classNames(
        styles.table,
        {
            [styles.tableWithStickyHeader]: stickyHeader,
        },
        className
    );

    return <table className={tableClasses} {...otherProps} data-testid='table' />;
};

export default Table;
