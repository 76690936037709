import React, { type DetailedHTMLProps, type ButtonHTMLAttributes } from 'react';

import classNames from 'classnames';
import styles from './Button.module.scss';

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
export const PrimaryButton = (props: ButtonProps) => {
    const className = classNames(styles.primaryButton, props.className);

    return <Button {...props} className={className} />;
};

export const SecondaryButton = (props: ButtonProps) => (
    <Button {...props} className={classNames(styles.secondaryButton, props.className)} />
);

export const TertiaryButton = (props: ButtonProps) => <Button {...props} className={classNames(styles.tertiaryButton, props.className)} />;

const Button = (props: ButtonProps) => {
    return (
        <button {...props} className={classNames(styles.button, props.className)}>
            {props.children}
        </button>
    );
};

export default Button;
