export type SubscriptionCategory = {
    name: string;
    label: string;
    projectTypes: string[];
    tags: SubscriptionTag[];
};

export type SubscriptionCategories = SubscriptionCategory[];

export enum SubscriptionTag {
    PROJECT = 'project',
    SURVEY = 'survey',
}
