export enum AdminUserType {
    ADMIN = 'admin',
    ONBOARDING_MANAGER = 'onboarding_manager',
    SUPER_ADMIN = 'super_admin',
}

export enum UserType {
    INTERNAL = 'Internal',
    CUSTOMER = 'Customer',
}
