export type CountriesData = { [countryName: string]: [string, number[]] };

export const countries: CountriesData = {
    Afghanistan: ['AF', [60.49, 29.39, 74.89, 38.47]],
    Aland: ['AX', [19.5, 59.8, 21.0, 60.4]],
    Albania: ['AL', [19.27, 39.64, 21.04, 42.65]],
    Algeria: ['DZ', [-8.68, 18.98, 11.97, 37.09]],
    'American Samoa': ['AS', [-171.09, -14.34, -168.14, -11.05]],
    Andorra: ['AD', [1.41, 42.43, 1.77, 42.65]],
    Angola: ['AO', [11.67, -18.04, 24.08, -4.39]],
    Anguilla: ['AI', [-63.17, 18.17, -62.97, 18.28]],
    Antarctica: ['AQ', [-180.0, -90.0, 180.0, -60.0]],
    'Antigua and Barbuda': ['AG', [-61.89, 16.99, -61.67, 17.17]],
    Argentina: ['AR', [-73.58, -55.08, -53.64, -21.78]],
    Armenia: ['AM', [43.45, 38.84, 46.63, 41.3]],
    Aruba: ['AW', [-70.06, 12.42, -69.88, 12.63]],
    'Ashmore and Cartier Islands': ['AU', [123.58, -12.44, 123.6, -12.43]],
    Australia: ['AU', [112.92, -54.75, 159.11, -9.23]],
    Austria: ['AT', [9.52, 46.38, 17.15, 49.01]],
    Azerbaijan: ['AZ', [44.77, 38.86, 50.39, 41.91]],
    Bahrain: ['BH', [50.45, 25.79, 50.65, 26.24]],
    'Bajo Nuevo Bank (Petrel Is.)': ['UM', [-79.99, 15.79, -79.98, 15.8]],
    Bangladesh: ['BD', [88.03, 20.74, 92.67, 26.63]],
    Barbados: ['BB', [-59.65, 13.05, -59.43, 13.34]],
    Belarus: ['BY', [23.17, 51.24, 32.72, 56.16]],
    Belgium: ['BE', [2.52, 49.5, 6.37, 51.5]],
    Belize: ['BZ', [-89.23, 15.89, -87.78, 18.5]],
    Benin: ['BJ', [0.76, 6.21, 3.84, 12.4]],
    Bermuda: ['BM', [-64.87, 32.25, -64.65, 32.39]],
    Bhutan: ['BT', [88.73, 26.7, 92.13, 28.33]],
    Bolivia: ['BO', [-69.65, -22.9, -57.47, -9.68]],
    'Bonaire, Sint Eustatius and Saba': ['BQ', [-63.33, 12.03, -62.98, 17.63]],
    'Bosnia and Herzegovina': ['BA', [15.72, 42.56, 19.62, 45.28]],
    Botswana: ['BW', [19.98, -26.89, 29.35, -17.78]],
    'Bouvet Island': ['BV', [3.34, -54.53, 3.49, -54.38]],
    Brazil: ['BR', [-73.99, -33.75, -34.79, 5.26]],
    'British Indian Ocean Territory': ['IO', [71.26, -7.47, 72.49, -5.24]],
    'British Virgin Islands': ['VG', [-64.62, 18.38, -64.26, 18.75]],
    Brunei: ['BN', [114.07, 4.0, 115.36, 5.05]],
    Bulgaria: ['BG', [22.36, 41.24, 28.6, 44.22]],
    'Burkina Faso': ['BF', [-5.52, 9.39, 2.39, 15.08]],
    Burundi: ['BI', [28.99, -4.47, 30.85, -2.3]],
    Cambodia: ['KH', [102.35, 10.41, 107.63, 14.57]],
    Cameroon: ['CM', [8.49, 1.66, 16.19, 13.08]],
    Canada: ['CA', [-141.0, 41.68, -52.62, 83.11]],
    'Cape Verde': ['CV', [-25.36, 14.81, -22.67, 17.2]],
    'Cayman Islands': ['KY', [-81.42, 19.26, -81.09, 19.39]],
    'Central African Republic': ['CF', [14.39, 2.24, 27.44, 11.0]],
    Chad: ['TD', [13.47, 7.46, 23.98, 23.44]],
    Chile: ['CL', [-75.64, -55.98, -66.42, -17.51]],
    China: ['CN', [73.56, 18.16, 134.77, 53.56]],
    'Christmas Island': ['CX', [105.533, -10.57, 105.713, -10.412]],
    'Cocos (Keeling) Islands': ['CC', [96.817, -12.199, 96.929, -12.072]],
    Colombia: ['CO', [-79.03, -4.23, -66.87, 12.44]],
    Comoros: ['KM', [43.22, -12.47, 44.54, -11.36]],
    'Cook Islands': ['CK', [-165.83, -21.94, -157.32, -10.03]],
    'Costa Rica': ['CR', [-87.1, 8.04, -82.55, 11.22]],
    Croatia: ['HR', [13.38, 42.39, 19.45, 46.54]],
    Cuba: ['CU', [-84.95, 19.82, -74.13, 23.33]],
    Curacao: ['CW', [-69.17, 12.04, -68.74, 12.39]],
    Cyprus: ['CY', [32.25, 34.53, 34.0, 35.67]],
    'Czech Republic': ['CZ', [12.09, 48.56, 18.85, 51.06]],
    'Democratic Republic of the Congo': ['CD', [12.21, -13.46, 31.31, 5.37]],
    Denmark: ['DK', [8.08, 54.56, 15.15, 57.75]],
    Djibouti: ['DJ', [41.77, 10.92, 43.42, 12.71]],
    Dominica: ['DM', [-61.49, 15.2, -61.25, 15.63]],
    'Dominican Republic': ['DO', [-72.0, 17.54, -68.32, 19.93]],
    'East Timor': ['TL', [124.03, -9.5, 127.31, -8.13]],
    Ecuador: ['EC', [-92.01, -5.02, -75.23, 1.44]],
    Egypt: ['EG', [24.7, 21.73, 36.87, 31.67]],
    'El Salvador': ['SV', [-90.12, 13.16, -87.69, 14.45]],
    'Equatorial Guinea': ['GQ', [5.44, -1.48, 11.35, 3.77]],
    Eritrea: ['ER', [36.43, 12.36, 43.14, 18.0]],
    Estonia: ['EE', [21.83, 57.52, 28.21, 59.67]],
    Ethiopia: ['ET', [32.99, 3.4, 47.98, 14.88]],
    'Falkland Islands': ['FK', [-61.35, -52.64, -57.74, -51.1]],
    'Faroe Islands': ['FO', [-7.7, 61.39, -6.22, 62.4]],
    Fiji: ['FJ', [176.85, -20.68, -178.46, -16.02]],
    Finland: ['FI', [20.56, 59.81, 31.58, 70.09]],
    France: ['FR', [-5.14, 41.34, 9.56, 51.11]],
    'French Polynesia': ['PF', [-151.65, -27.64, -134.45, -7.93]],
    Gabon: ['GA', [8.8, -3.94, 14.53, 2.33]],
    Gambia: ['GM', [-16.83, 13.06, -13.82, 13.82]],
    Georgia: ['GE', [39.98, 41.06, 46.68, 43.59]],
    Germany: ['DE', [5.87, 47.27, 15.04, 55.06]],
    Ghana: ['GH', [-3.25, 4.74, 1.2, 11.16]],
    Gibraltar: ['GI', [-5.36, 36.11, -5.33, 36.14]],
    Greece: ['GR', [19.37, 34.8, 29.64, 41.75]],
    Greenland: ['GL', [-73.05, 59.81, -11.32, 83.62]],
    Grenada: ['GD', [-61.8, 12.0, -61.38, 12.31]],
    Guadeloupe: ['GP', [-61.55, 15.86, -61.0, 16.52]],
    Guam: ['GU', [144.62, 13.24, 144.95, 13.65]],
    Guatemala: ['GT', [-92.23, 13.73, -88.22, 17.82]],
    Guernsey: ['GG', [-2.67, 49.41, -2.5, 49.51]],
    Guinea: ['GN', [-15.08, 7.2, -7.64, 12.68]],
    'Guinea-Bissau': ['GW', [-16.71, 10.93, -13.66, 12.68]],
    Guyana: ['GY', [-61.39, 1.18, -56.47, 8.56]],
    Haiti: ['HT', [-74.48, 18.02, -71.63, 19.92]],
    Honduras: ['HN', [-89.35, 12.98, -83.15, 16.01]],
    'Hong Kong': ['HK', [113.81, 22.18, 114.42, 22.51]],
    Hungary: ['HU', [16.11, 45.74, 22.9, 48.57]],
    Iceland: ['IS', [-24.54, 63.39, -13.49, 66.53]],
    India: ['IN', [68.14, 6.75, 97.4, 35.67]],
    Indonesia: ['ID', [95.01, -10.94, 141.02, 5.97]],
    Iran: ['IR', [44.03, 25.07, 63.32, 39.78]],
    Iraq: ['IQ', [38.8, 29.1, 48.57, 37.38]],
    Ireland: ['IE', [-10.47, 51.43, -6.01, 55.37]],
    'Isle of Man': ['IM', [-4.79, 54.05, -4.28, 54.41]],
    Israel: ['IL', [34.27, 29.49, 35.86, 33.34]],
    Italy: ['IT', [6.62, 35.49, 18.51, 47.09]],
    'Ivory Coast': ['CI', [-8.6, 4.34, -2.5, 10.73]],
    Jamaica: ['JM', [-78.37, 17.7, -76.2, 18.53]],
    Japan: ['JP', [122.94, 24.25, 153.99, 45.55]],
    Jersey: ['JE', [-2.26, 49.15, -2.01, 49.26]],
    Jordan: ['JO', [34.96, 29.19, 39.3, 33.37]],
    Kazakhstan: ['KZ', [46.5, 40.59, 87.32, 55.43]],
    Kenya: ['KE', [33.91, -4.67, 41.89, 5.03]],
    Kiribati: ['KI', [169.55, -11.46, -174.53, 4.73]],
    'North Korea': ['KP', [124.26, 37.67, 130.7, 43.0]],
    'South Korea': ['KR', [126.1, 33.19, 129.58, 38.61]],
    Kuwait: ['KW', [46.55, 28.52, 48.42, 30.06]],
    Kyrgyzstan: ['KG', [69.21, 39.19, 80.28, 43.26]],
    Laos: ['LA', [100.1, 13.92, 107.64, 22.5]],
    Latvia: ['LV', [20.97, 55.67, 28.24, 58.08]],
    Lebanon: ['LB', [35.1, 33.06, 36.62, 34.69]],
    Lesotho: ['LS', [27.03, -30.67, 29.47, -28.57]],
    Liberia: ['LR', [-11.52, 4.34, -7.37, 8.55]],
    Libya: ['LY', [9.3, 19.5, 25.15, 33.18]],
    Liechtenstein: ['LI', [9.48, 47.05, 9.63, 47.27]],
    Lithuania: ['LT', [20.94, 53.88, 26.59, 56.37]],
    Luxembourg: ['LU', [5.73, 49.44, 6.53, 50.18]],
    Macau: ['MO', [113.52, 22.11, 113.61, 22.22]],
    Madagascar: ['MG', [43.22, -25.6, 50.49, -12.04]],
    Malawi: ['MW', [32.68, -17.13, 35.91, -9.37]],
    Malaysia: ['MY', [99.64, 0.85, 119.27, 7.36]],
    Maldives: ['MV', [72.69, -0.69, 73.75, 7.1]],
    Mali: ['ML', [-12.24, 10.15, 4.27, 25.0]],
    Malta: ['MT', [14.18, 35.8, 14.57, 36.08]],
    'Marshall Islands': ['MH', [160.85, 4.57, 172.06, 14.61]],
    Martinique: ['MQ', [-61.23, 14.37, -60.81, 14.88]],
    Mauritania: ['MR', [-17.05, 14.72, -4.83, 27.29]],
    Mauritius: ['MU', [56.51, -20.53, 63.5, -10.34]],
    Mexico: ['MX', [-118.37, 14.54, -86.71, 32.72]],
    Micronesia: ['FM', [137.23, 0.85, 163.04, 10.09]],
    'Midway Islands': ['UM', [-177.39, 28.18, -177.34, 28.22]],
    Moldova: ['MD', [26.6, 45.44, 30.13, 48.49]],
    Monaco: ['MC', [7.38, 43.72, 7.44, 43.75]],
    Mongolia: ['MN', [87.74, 41.59, 119.92, 52.15]],
    Montenegro: ['ME', [18.45, 41.85, 20.35, 43.57]],
    Montserrat: ['MS', [-62.25, 16.67, -62.14, 16.82]],
    Morocco: ['MA', [-17.03, 21.42, -1.03, 36.02]],
    Mozambique: ['MZ', [30.22, -26.86, 40.84, -10.38]],
    Myanmar: ['MM', [92.17, 9.78, 101.17, 28.55]],
    Namibia: ['NA', [11.72, -28.97, 25.26, -16.95]],
    Nauru: ['NR', [166.9, -0.55, 166.96, -0.49]],
    Nepal: ['NP', [80.03, 26.36, 88.2, 30.42]],
    Netherlands: ['NL', [3.33, 50.76, 7.23, 53.57]],
    'New Caledonia': ['NC', [163.59, -22.68, 167.29, -20.11]],
    'New Zealand': ['NZ', [166.36, -47.31, -175.31, -34.39]],
    Nicaragua: ['NI', [-87.69, 10.73, -82.7, 15.03]],
    Niger: ['NE', [0.15, 11.7, 15.98, 23.51]],
    Nigeria: ['NG', [2.67, 4.27, 14.58, 13.89]],
    Niue: ['NU', [-169.95, -19.15, -169.77, -18.95]],
    'Norfolk Island': ['NF', [167.91, -29.09, 167.99, -29.01]],
    'North Macedonia': ['MK', [20.46, 40.86, 23.04, 42.36]],
    Norway: ['NO', [4.66, 57.98, 31.08, 71.21]],
    Oman: ['OM', [52.0, 16.65, 59.84, 26.39]],
    Pakistan: ['PK', [60.88, 23.69, 77.05, 37.06]],
    Palau: ['PW', [131.13, 2.98, 134.73, 8.45]],
    Palestine: ['PS', [34.22, 31.21, 35.57, 32.55]],
    Panama: ['PA', [-83.05, 7.2, -77.17, 9.61]],
    'Papua New Guinea': ['PG', [140.84, -11.64, 155.96, -1.35]],
    Paraguay: ['PY', [-62.65, -27.6, -54.26, -19.29]],
    Peru: ['PE', [-81.35, -18.35, -68.67, -0.03]],
    Philippines: ['PH', [116.93, 4.65, 126.6, 21.12]],
    'Pitcairn Islands': ['PN', [-130.75, -25.08, -124.77, -23.89]],
    Poland: ['PL', [14.12, 49.0, 24.15, 54.83]],
    Portugal: ['PT', [-31.28, 32.28, -6.19, 42.14]],
    'Puerto Rico': ['PR', [-67.27, 17.92, -65.58, 18.52]],
    Qatar: ['QA', [50.75, 24.55, 51.64, 26.15]],
    Romania: ['RO', [20.27, 43.63, 29.63, 48.27]],
    Russia: ['RU', [19.66, 41.19, 180.0, 81.85]],
    Rwanda: ['RW', [28.85, -2.83, 30.9, -1.06]],
    'Saint Barthelemy': ['BL', [-62.87, 17.87, -62.78, 17.93]],
    'Saint Helena': ['SH', [-14.42, -16.02, -5.64, -7.88]],
    'Saint Kitts and Nevis': ['KN', [-62.86, 17.1, -62.53, 17.42]],
    'Saint Lucia': ['LC', [-61.08, 13.71, -60.88, 14.11]],
    'Saint Martin': ['MF', [-63.15, 18.06, -63.0, 18.13]],
    'Saint Pierre and Miquelon': ['PM', [-56.45, 46.76, -56.13, 47.14]],
    'Saint Vincent and the Grenadines': ['VC', [-61.46, 12.58, -61.11, 13.37]],
    Samoa: ['WS', [-172.78, -14.05, -171.4, -13.43]],
    'San Marino': ['SM', [12.41, 43.89, 12.52, 43.99]],
    'Sao Tome and Principe': ['ST', [6.46, -0.01, 7.47, 1.7]],
    'Saudi Arabia': ['SA', [34.49, 16.39, 55.67, 32.15]],
    Senegal: ['SN', [-17.54, 12.3, -11.46, 16.69]],
    Serbia: ['RS', [18.81, 42.23, 23.01, 46.18]],
    Seychelles: ['SC', [46.2, -9.75, 56.29, -3.8]],
    'Sierra Leone': ['SL', [-13.31, 6.92, -10.27, 9.99]],
    Singapore: ['SG', [103.64, 1.22, 104.0, 1.45]],
    Slovakia: ['SK', [16.85, 47.75, 22.56, 49.6]],
    Slovenia: ['SI', [13.38, 45.43, 16.51, 46.87]],
    'Solomon Islands': ['SB', [155.51, -12.29, 168.82, -6.6]],
    Somalia: ['SO', [40.98, -1.68, 51.39, 11.98]],
    'South Africa': ['ZA', [16.46, -34.82, 32.89, -22.12]],
    'South Sudan': ['SS', [23.44, 3.49, 35.9, 12.23]],
    Spain: ['ES', [-18.17, 27.64, 4.32, 43.78]],
    'Sri Lanka': ['LK', [79.66, 5.92, 81.89, 9.84]],
    Sudan: ['SD', [21.81, 8.68, 38.6, 22.0]],
    Suriname: ['SR', [-58.08, 1.83, -53.98, 6.0]],
    Swaziland: ['SZ', [30.8, -27.32, 32.14, -25.72]],
    Sweden: ['SE', [11.1, 55.34, 24.16, 69.06]],
    Switzerland: ['CH', [5.95, 45.82, 10.48, 47.81]],
    Syria: ['SY', [35.57, 32.31, 42.38, 37.3]],
    Taiwan: ['TW', [119.63, 21.87, 122.0, 25.28]],
    Tajikistan: ['TJ', [67.39, 36.67, 75.15, 41.04]],
    Tanzania: ['TZ', [29.32, -11.73, 40.44, -0.99]],
    Thailand: ['TH', [97.35, 5.61, 105.64, 20.45]],
    'Timor-Leste': ['TL', [124.04, -9.5, 127.34, -8.15]],
    Togo: ['TG', [-0.15, 6.1, 1.81, 11.14]],
    Tokelau: ['TK', [-172.58, -9.42, -171.2, -8.55]],
    Tonga: ['TO', [-176.22, -21.46, -173.91, -15.56]],
    'Trinidad and Tobago': ['TT', [-61.95, 10.0, -60.9, 10.89]],
    Tunisia: ['TN', [7.5, 30.24, 11.54, 37.54]],
    Turkey: ['TR', [25.91, 35.81, 44.8, 42.11]],
    Turkmenistan: ['TM', [52.45, 35.14, 66.67, 42.79]],
    Tuvalu: ['TV', [179.1, -9.98, 179.26, -9.25]],
    Uganda: ['UG', [29.57, -1.48, 35.01, 4.24]],
    Ukraine: ['UA', [22.14, 44.35, 40.23, 52.37]],
    'United Arab Emirates': ['AE', [51.53, 22.63, 56.38, 26.08]],
    'United Kingdom': ['GB', [-8.63, 49.91, 1.77, 60.85]],
    'United States': ['US', [-179.15, 18.93, 179.78, 71.39]],
    Uruguay: ['UY', [-58.45, -34.95, -53.06, -30.08]],
    Uzbekistan: ['UZ', [55.98, 37.18, 73.13, 45.58]],
    Vanuatu: ['VU', [166.52, -20.25, 170.24, -13.08]],
    'Vatican City': ['VA', [12.44, 41.9, 12.46, 41.91]],
    Venezuela: ['VE', [-73.37, 0.65, -59.8, 12.2]],
    Vietnam: ['VN', [102.14, 8.3, 109.47, 23.35]],
    'Wallis and Futuna': ['WF', [-178.19, -14.33, -176.13, -12.98]],
    'Western Sahara': ['EH', [-17.11, 20.77, -8.67, 27.67]],
    Yemen: ['YE', [42.57, 12.11, 53.11, 19.0]],
    Zambia: ['ZM', [21.99, -18.08, 33.69, -8.24]],
    Zimbabwe: ['ZW', [25.24, -22.42, 33.06, -15.61]],
};
