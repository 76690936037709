'use client';

import { useNavigate } from 'react-router-dom';
import customersStorageService from 'src/app/customers/services/customers-storage-service';
import projectsStorageService from 'src/app/projects/services/projects-storage-service';
import { SampleManifestFormViews } from 'src/app/samples/sample-manifest/sample-manifest-form/SampleManifestForm';
import {
    AdminViews,
    AppViews,
    InsightViews,
    LoginViews,
    MapViews,
    ProjectViews,
    SamplesViews,
    SurveyViews,
    TableViews,
    SampleManifestViews,
    SampleReceptionViews,
} from 'src/routes/routesConfig';

type RouteParams = {
    customerId: string;
    projectId: string;
    view: string;
    samplingEventId: string;
    batchId: string;
};

export const navRoutesConfig = {
    home: '/',
    [AppViews.CUSTOMERS]: '/customers',
    [AppViews.PROJECTS]: (params: { customerId: string }) => `/projects?customerId=${params.customerId}`,
    [AppViews.DASHBOARD]: (params: { projectId: string; customerId: string }) =>
        `/dashboard?projectId=${params.projectId}&customerId=${params.customerId}`,

    [MapViews.SAMPLES]: (params: { projectId: string; customerId: string }) =>
        `/map/samples?projectId=${params.projectId}&customerId=${params.customerId}`,
    [MapViews.HABITAT_INSIGHTS]: (params: { projectId: string; customerId: string }) =>
        `/map/habitat-insights?projectId=${params.projectId}&customerId=${params.customerId}`,

    [SurveyViews.SURVEY_ADMIN]: (params: { customerId: string }) => `/survey/survey-admin?customerId=${params.customerId}`,
    [SurveyViews.SURVEY_DESIGN_SUMMARY]: (params: { customerId: string }) =>
        `/survey/survey-design-summary?customerId=${params.customerId}`,
    [SurveyViews.NEW_SURVEY_DESIGN]: (params: { customerId: string }) => `/survey/new-survey-design?customerId=${params.customerId}`,
    [SurveyViews.HABITAT_INSIGHTS_UPLOAD]: (params: { customerId: string }) =>
        `/survey/habitat-insights-upload?customerId=${params.customerId}`,

    [TableViews.SAMPLE_METRICS]: (params: { projectId: string; customerId: string }) =>
        `/tables/sample-metrics?projectId=${params.projectId}&customerId=${params.customerId}`,
    [TableViews.HABITAT_INSIGHTS_METRICS]: (params: { projectId: string; customerId: string }) =>
        `/tables/habitat-insights-metrics?projectId=${params.projectId}&customerId=${params.customerId}`,

    [LoginViews.FORGOT_PASSWORD]: '/login/forgot-password',
    [LoginViews.SET_NEW_PASSWORD]: '/login/set-new-password',
    [LoginViews.VERIFY_CUSTOM_CHALLENGE]: '/login/verify-custom-challenge',
    [LoginViews.VERIFY_PHONE_NUMBER]: '/login/verify-phone-number',
    [LoginViews.TERMS_OF_USE]: '/login/terms-of-use',
    [LoginViews.TERMS_OF_USE_DECLINED]: '/login/terms-of-use-declined',

    [AdminViews.USER_MANAGEMENT]: (params: { customerId: string }) => `/admin/user-management?customerId=${params.customerId}`,

    [InsightViews.ECOLOGICAL_TRENDS]: (params: { projectId: string; customerId: string }) =>
        `/insights/ecological-trends?projectId=${params.projectId}&customerId=${params.customerId}`,
    [InsightViews.HABITAT_INSIGHTS_ECOLOGICAL_TRENDS]: (params: { projectId: string; customerId: string }) =>
        `/insights/habitat-insights-ecological-trends?projectId=${params.projectId}&customerId=${params.customerId}`,
    [InsightViews.SAMPLE_COMPARISON]: (params: { projectId: string; customerId: string }) =>
        `/insights/sample-comparison?projectId=${params.projectId}&customerId=${params.customerId}`,
    [InsightViews.STATISTICAL_COMPARISON]: (params: { projectId: string; customerId: string }) =>
        `/insights/statistical-comparison?projectId=${params.projectId}&customerId=${params.customerId}`,
    [InsightViews.TAXONOMIC_COMPOSITION]: (params: { projectId: string; customerId: string }) =>
        `/insights/taxonomic-composition?projectId=${params.projectId}&customerId=${params.customerId}`,
    [InsightViews.COMMUNITY_COMPARISON]: (params: { projectId: string; customerId: string }) =>
        `/insights/community-comparison?projectId=${params.projectId}&customerId=${params.customerId}`,

    [ProjectViews.PROJECT_ADMIN]: (params: { customerId: string }) => `/projects/project-admin?customerId=${params.customerId}`,
    [ProjectViews.PROJECT_DEFINITION]: (params: { customerId: string }) => `/projects/project-definition?customerId=${params.customerId}`,
    [ProjectViews.NEW_PROJECT_DEFINITION]: (params: { customerId: string }) =>
        `/projects/new-project-definition?customerId=${params.customerId}`,
    [SamplesViews.SAMPLE_MANIFEST]: (params: { projectId: string; customerId: string }) =>
        `/samples/sample-manifest?projectId=${params.projectId}&customerId=${params.customerId}`,
    [SampleManifestViews.FORM]: (params: {
        projectId: string;
        customerId: string;
        view: string;
        samplingEventId: string;
        batchId?: string;
    }) =>
        `/samples/sample-manifest/form?projectId=${params.projectId}&customerId=${params.customerId}&samplingEventId=${
            params.samplingEventId
        }&view=${params.view}${params.batchId ? `&batchId=${params.batchId}` : ''}`,
    [SampleReceptionViews.VIEW_SAMPLE_MANIFEST]: (params: { projectId: string; customerId: string; samplingEventId: string }) =>
        `/samples/sample-reception/view?projectId=${params.projectId}&customerId=${params.customerId}&samplingEventId=${params.samplingEventId}`,
    [SampleReceptionViews.HOME]: `/samples/sample-reception`,
    [SamplesViews.SAMPLE_BATCH_PRINT]: (params: { projectId: string; customerId: string; batchId: string; samplingEventId: string }) =>
        `/samples/sample-batch-print/?projectId=${params.projectId}&customerId=${params.customerId}&batchId=${params.batchId}&samplingEventId=${params.samplingEventId}&printLayout=true`,
};

const useAppNavigation = () => {
    const navigate = useNavigate();
    const back = () => navigate(-1);

    const getCurrentCustomerId = () => customersStorageService.getCurrentCustomerId() || '';
    const getCurrentProjectId = () => projectsStorageService.getCurrentProjectId() || '';

    const getPath = (route: keyof typeof navRoutesConfig, params?: Record<string, string>) => {
        const routerBuilder = navRoutesConfig[route];
        if (typeof routerBuilder === 'string') {
            return routerBuilder;
        }
        return routerBuilder(params as RouteParams);
    };

    const handlers = {
        toHome: () => navigate(navRoutesConfig.home),
        toCustomers: () => navigate(navRoutesConfig.customers),
        toProjects: (params?: { customerId: string }) =>
            navigate(navRoutesConfig.projects({ customerId: params?.customerId || getCurrentCustomerId() })),
        toDashboard: () => navigate(navRoutesConfig.dashboard({ projectId: getCurrentProjectId(), customerId: getCurrentCustomerId() })),

        toForgotPassword: () => navigate(navRoutesConfig.forgotPassword),
        toSetNewPassword: () => navigate(navRoutesConfig.setNewPassword),
        toVerifyCustomChallenge: () => navigate(navRoutesConfig.verifyCustomChallenge),
        toVerifyPhoneNumber: () => navigate(navRoutesConfig.verifyPhoneNumber),
        toTermsOfUse: () => navigate(navRoutesConfig.termsOfUse),
        toTermsOfUseDeclined: () => navigate(navRoutesConfig.termsOfUseDeclined),

        toSampleMetricsTable: () =>
            navigate(
                navRoutesConfig[TableViews.SAMPLE_METRICS]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),
        toHabitatInsightsMetricsTable: () =>
            navigate(
                navRoutesConfig[TableViews.HABITAT_INSIGHTS_METRICS]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),

        toSamplesMap: () =>
            navigate(
                navRoutesConfig[MapViews.SAMPLES]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),
        toHabitatInsightsMap: () =>
            navigate(
                navRoutesConfig[MapViews.HABITAT_INSIGHTS]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),
        toUserManagement: () => navigate(navRoutesConfig[AdminViews.USER_MANAGEMENT]({ customerId: getCurrentCustomerId() })),

        toEcologicalTrends: () =>
            navigate(
                navRoutesConfig[InsightViews.ECOLOGICAL_TRENDS]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),
        toHabitatInsightsEcologicalTrends: () =>
            navigate(
                navRoutesConfig[InsightViews.HABITAT_INSIGHTS_ECOLOGICAL_TRENDS]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),
        toSampleComparison: () =>
            navigate(
                navRoutesConfig[InsightViews.SAMPLE_COMPARISON]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),
        toStatisticalComparison: () =>
            navigate(
                navRoutesConfig[InsightViews.STATISTICAL_COMPARISON]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),
        toTaxonomicComposition: () =>
            navigate(
                navRoutesConfig[InsightViews.TAXONOMIC_COMPOSITION]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),
        toCommunityComparison: () =>
            navigate(
                navRoutesConfig[InsightViews.COMMUNITY_COMPARISON]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),

        // Projects
        toProjectAdmin: () => navigate(navRoutesConfig[ProjectViews.PROJECT_ADMIN]({ customerId: getCurrentCustomerId() })),
        toProjectDefinition: () => navigate(navRoutesConfig[ProjectViews.PROJECT_DEFINITION]({ customerId: getCurrentCustomerId() })),
        toNewProjectDefinition: () =>
            navigate(navRoutesConfig[ProjectViews.NEW_PROJECT_DEFINITION]({ customerId: getCurrentCustomerId() })),

        // Surveys
        toSurveyDesignSummary: () => navigate(navRoutesConfig[SurveyViews.SURVEY_DESIGN_SUMMARY]({ customerId: getCurrentCustomerId() })),
        toNewSurveyDesign: () => navigate(navRoutesConfig[SurveyViews.NEW_SURVEY_DESIGN]({ customerId: getCurrentCustomerId() })),
        toSurveyAdmin: () => navigate(navRoutesConfig[SurveyViews.SURVEY_ADMIN]({ customerId: getCurrentCustomerId() })),
        toHabitatInsightsUpload: () =>
            navigate(navRoutesConfig[SurveyViews.HABITAT_INSIGHTS_UPLOAD]({ customerId: getCurrentCustomerId() })),

        // Samples
        toSampleManifest: () =>
            navigate(
                navRoutesConfig[SamplesViews.SAMPLE_MANIFEST]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                })
            ),

        toSampleManifestForm: (params: { view?: SampleManifestFormViews; samplingEventId: string; batchId?: string }) =>
            navigate(
                navRoutesConfig[SampleManifestViews.FORM]({
                    projectId: getCurrentProjectId(),
                    customerId: getCurrentCustomerId(),
                    view: params?.view || SampleManifestFormViews.ADD_FIELD_DATA,
                    samplingEventId: params.samplingEventId,
                    batchId: params.batchId,
                })
            ),
        toSampleReception: () => navigate(navRoutesConfig[SampleReceptionViews.HOME]),
        toViewSampleManifest: (params: { projectId: string; customerId: string; samplingEventId: string }) =>
            navigate(
                navRoutesConfig[SampleReceptionViews.VIEW_SAMPLE_MANIFEST]({
                    projectId: params.projectId,
                    customerId: params.customerId,
                    samplingEventId: params.samplingEventId,
                })
            ),
    };

    return {
        to: navigate,
        getPath,
        back,
        ...handlers,
    };
};

export default useAppNavigation;
