import { gql } from 'graphql-request';

export const ecologicalTrendsQuery = gql`
    query iucnMetricsData($projectId: String!, $chartId: String!, $habitatAssay: String!) {
        ecologicalTrends: chart(projectId: $projectId, chartId: $chartId, habitatAssay: $habitatAssay) {
            chartId
            chartType
            chartTitle
            description
            data
            metadata
        }
    }
`;

type EcologicalTrendsData = {
    chartId: string;
    chartType: string;
    chartTitle: string;
    description: string;
    data: {
        data: {
            datetime: string;
            group: {
                type: string;
                value: number;
            }[];
        }[];
        metric: string;
    }[];
};

export type EcologicalTrendsQueryResult = {
    ecologicalTrends: EcologicalTrendsData;
};
