import React from 'react';
import { SamplingEventBatch, SamplingEventBatchStatus } from 'src/shared/types';
import { ReactComponent as DataControlIcon } from 'src/assets/svg/general/data-control.svg';
import { getBatchNumberFromBatchId } from 'src/app/samples/samplesHelpers';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import { ReactComponent as WarningCircleIcon } from 'src/assets/svg/general/warning-circle.svg';
import { ReactComponent as CompleteIcon } from 'src/assets/svg/general/complete.svg';
import { ReactComponent as TickIcon } from 'src/assets/svg/general/tick.svg';
import moment from 'moment';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import DataAmendmentModal from './components/data-amendment-modal/DataAmendmentModal';

type DataControlProps = {
    samplingEventBatch: SamplingEventBatch;
};

const BATCH_AMENDMENT_MESSAGE = 'Batch currently being amended by customer';

const DataControl = (props: DataControlProps) => {
    const { samplingEvent, refetchSamplingEvent, approveBatchByLogistics, approveBatchByLab, isKitsInBatchProcessed } = useSamplingEvent();
    const { samplingEventBatch } = props;

    if (!samplingEvent) {
        return null;
    }

    const batchNumber = getBatchNumberFromBatchId(samplingEventBatch.id, samplingEvent.identifier);
    const hasLabApproved = Boolean(samplingEventBatch.status === SamplingEventBatchStatus.APPROVED_BY_LAB);
    const hasLogisticsApproved = Boolean(samplingEventBatch.status === SamplingEventBatchStatus.APPROVED_BY_LOGISTICS);
    const requireCustomerAmendment = samplingEventBatch.status === SamplingEventBatchStatus.REQUIRED_CUSTOMER_AMENDMENT;
    const isBatchProcessed = isKitsInBatchProcessed(samplingEventBatch.id);

    if (hasLabApproved) {
        return null;
    }

    const onLabApproval = async () => {
        await approveBatchByLab(samplingEventBatch.id);
        refetchSamplingEvent();
    };

    const onLogisticsApproval = async () => {
        await approveBatchByLogistics(samplingEventBatch.id);
        refetchSamplingEvent();
    };

    return (
        <div className='flex flex-col gap-4 bg-white p-4 mt-2 border-primary border rounded-md'>
            <div className='flex justify-between'>
                <div className='flex gap-2'>
                    <DataControlIcon />
                    <div className='flex flex-col gap-2'>
                        <div className='text-lg text-primary'>Data Control (Batch {batchNumber})</div>
                        <div className='text-grey-80'>
                            Decide whether NatureMetrics will accept this sample batch data or send to customer for amendment
                        </div>
                    </div>
                </div>
                <div>
                    <SecondaryButton disabled={true}>Help</SecondaryButton>
                </div>
            </div>

            <div className='flex gap-2'>
                <DataControlBox
                    icon={<WarningCircleIcon />}
                    title='Amendment required'
                    content={<DataAmendmentModal {...samplingEventBatch} />}
                    borderClass='border-[#CF8515]'
                    footer={requireCustomerAmendment && <span className='text-sm'>{BATCH_AMENDMENT_MESSAGE}</span>}
                />

                <DataControlBox
                    icon={<CompleteIcon />}
                    title='Check 1 (Logistics)'
                    content={
                        <PrimaryButton
                            className='flex gap-2'
                            disabled={hasLogisticsApproved || requireCustomerAmendment}
                            onClick={onLogisticsApproval}
                        >
                            <TickIcon className={hasLogisticsApproved || requireCustomerAmendment ? 'fill-white' : 'fill-[#2EAD0E]'} />{' '}
                            {hasLogisticsApproved && !requireCustomerAmendment ? 'Data accepted' : 'Accept data'}
                        </PrimaryButton>
                    }
                    footer={
                        hasLogisticsApproved ? (
                            <div>Accepted by logistics on {moment(samplingEventBatch.logisticsApprovalAt).format('DD MMM YYYY')}</div>
                        ) : requireCustomerAmendment ? (
                            <span className='text-sm'>{BATCH_AMENDMENT_MESSAGE}</span>
                        ) : null
                    }
                    borderClass={hasLogisticsApproved ? 'border-grey-20' : 'border-[#2EAD0E]'}
                />

                <DataControlBox
                    icon={<CompleteIcon />}
                    title='Check 2 (Lab)'
                    content={
                        <PrimaryButton
                            className='flex gap-2'
                            disabled={!hasLogisticsApproved || hasLabApproved || !isBatchProcessed}
                            onClick={onLabApproval}
                        >
                            <TickIcon
                                className={
                                    !hasLogisticsApproved || hasLabApproved || requireCustomerAmendment ? 'fill-white' : 'fill-[#2EAD0E]'
                                }
                            />{' '}
                            {hasLabApproved ? 'Data accepted' : 'Accept data'}
                        </PrimaryButton>
                    }
                    footer={
                        hasLabApproved ? (
                            <div>Accepted by lab on {moment(samplingEventBatch.labApprovalAt).format('DD MMM YYYY')}</div>
                        ) : requireCustomerAmendment ? (
                            <span className='text-sm'>{BATCH_AMENDMENT_MESSAGE}</span>
                        ) : !hasLogisticsApproved ? (
                            <div className='text-sm'>Must be accepted by logistics first</div>
                        ) : null
                    }
                    borderClass={hasLabApproved ? 'border-grey-20' : hasLogisticsApproved ? 'border-[#2EAD0E]' : 'border-primary'}
                />
            </div>
        </div>
    );
};

type DataControlBoxProps = {
    icon: React.ReactNode;
    title: string;
    content: React.ReactNode;
    footer?: React.ReactNode;
    borderClass?: string;
};

const DataControlBox = (props: DataControlBoxProps) => {
    return (
        <div className={`flex flex-1 flex-col p-2 gap-2 bg-grey-0 border rounded-lg ${props.borderClass || 'border-grey-20'}`}>
            <div className='flex items-center gap-2'>
                {props.icon}
                <div className='text-lg'>{props.title}</div>
            </div>
            <div>{props.content}</div>
            {props.footer && <div>{props.footer}</div>}
        </div>
    );
};

export default DataControl;
