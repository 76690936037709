import React from 'react';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability, getSamplesRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation } from 'react-router-dom';
import { samplesRouteSettings } from 'src/app/samples/Samples';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import { ReactComponent as OpenBoxIcon } from 'src/assets/svg/general/open-box.svg';
import styles from '../SiteMenu.module.scss';

const SamplesMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);
    const samplesRoutesAvailability = useAppSelector(getSamplesRoutesAvailability);

    const navigate = useAppNavigation();
    const { pathname } = useLocation();

    if (!appRoutesAvailability.samples) {
        return null;
    }

    const getClassNames = (menuItemPath: string) => {
        const itemPath = '/samples' + menuItemPath.replace('/*', '');
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: pathname.startsWith(itemPath),
        });
    };

    return (
        <>
            {samplesRoutesAvailability.sampleManifest && (
                <div className={getClassNames(samplesRouteSettings.sampleManifest.path)} onClick={navigate.toSampleManifest}>
                    <div className={styles.icon}>
                        <FolderOutlinedIcon />
                    </div>
                    <div className={styles.label}>Sample Manifest</div>
                </div>
            )}

            {samplesRoutesAvailability.sampleReception && (
                <div className={getClassNames(samplesRouteSettings.sampleReception.path)} onClick={navigate.toSampleReception}>
                    <div className={styles.icon}>
                        <OpenBoxIcon />
                    </div>
                    <div className={styles.label}>Sample Reception</div>
                </div>
            )}
        </>
    );
};

export default SamplesMenu;
