import React, { ButtonHTMLAttributes } from 'react';
import DownloadIcon from '@mui/icons-material/Download';

import { SecondaryButton } from '../button/Button';

export type DownloadButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const DownloadButton = (props: DownloadButtonProps) => {
    const { className, onClick } = props;

    return (
        <div className={className}>
            <SecondaryButton onClick={onClick}>
                <span>Download</span>
                <DownloadIcon />
            </SecondaryButton>
        </div>
    );
};

export default DownloadButton;
