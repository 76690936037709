import { gql } from 'graphql-request';

export const getRegisterationStatusQuery = gql`
    query checkIfInterestRegistred {
        haveRegisteredMyInterest
    }
`;

export type GetRegisterationStatusQueryResult = {
    haveRegisteredMyInterest: boolean;
};

export const createRegisterInterestQuery = gql`
    mutation registerInterestForInsights {
        registerInterestForInsights
    }
`;

export type CreateRegisterInterestQueryResult = {
    registerInterestForInsights: boolean;
};
