import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';

type PopoverProps = {
    content: React.ReactNode;
    icon?: React.ReactNode;
};

const PopOver = ({ content, icon }: PopoverProps) => {
    const [isPopoverVisible, setPopoverVisible] = useState(false);

    const showPopover = () => setPopoverVisible(true);
    const hidePopover = () => setPopoverVisible(false);

    return (
        <div className='relative inline-block' onMouseEnter={showPopover} onMouseLeave={hidePopover}>
            {icon || <InfoIcon />}
            {isPopoverVisible && (
                <div className='absolute z-10 top-0 left-full w-[750px] bg-white  rounded-lg shadow-lg' role='tooltip'>
                    <div className='p-4 text-grey-80'>{content}</div>
                </div>
            )}
        </div>
    );
};

export default PopOver;
