import React, { useEffect } from 'react';
import { Container, Typography, Grid } from '@mui/material';
import useAuthService from 'src/shared/hooks/useAuthService';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import PagenotfoundImage from 'src/assets/images/general/404.webp';
import styles from './PageNotFound.module.scss';

const PageNotFound = () => {
    const { redirectIfNotAuthenticated } = useAuthService();

    useEffect(() => {
        redirectIfNotAuthenticated();
    }, []);

    const mainContent = (
        <Container className={styles.container}>
            <Grid container>
                <Grid xs={5} item className={styles.leftCol}>
                    <Typography className={styles.title}>Oops!</Typography>
                    <Typography className={styles.subTitle}>Something went wrong and we cannot find that page.</Typography>
                    <Typography className={styles.description}>
                        Please try refreshing your browser or contact NatureMetrics support if the issue persists.
                    </Typography>
                </Grid>
                <Grid item>
                    <img src={PagenotfoundImage} />
                </Grid>
            </Grid>
        </Container>
    );

    return <StandardAppLayout mainContent={mainContent} />;
};
export default PageNotFound;
