import { RootState } from 'src/store';
import { TaxonomicCompositionUIState, TaxonomicCompositionFiltersState, defaultUiState } from './taxonomicCompositionSlice';

export const getCurrentTaxonomicCompositionUIState = (state: RootState): TaxonomicCompositionUIState => {
    const currentProjectId = state.projects?.projectList.currentProjectId;
    const habitatAssayType = state.projects.projectList.uiState[currentProjectId as string]?.filters?.habitatAssayType || '';

    if (!currentProjectId || !habitatAssayType) {
        return defaultUiState;
    }

    return state.insights.taxonomicComposition.uiState[currentProjectId]?.[habitatAssayType.key] || defaultUiState;
};

export const getTaxonomicCompositionFilters = (state: RootState): TaxonomicCompositionFiltersState => {
    const currentUiState = getCurrentTaxonomicCompositionUIState(state);

    return currentUiState.filters;
};

export const getTaxonomicCompositionFiltersDraft = (state: RootState): Partial<TaxonomicCompositionFiltersState> => {
    const currentUiState = getCurrentTaxonomicCompositionUIState(state);

    return currentUiState.filtersDraft;
};
