import React from 'react';
import { MapContainer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { LatLngBounds } from 'leaflet';
import config from 'src/config';

export interface MapCardProps {
    css?: CssProps;
    boundsArray: number[];
}
interface CssProps {
    [key: string]: string | number;
}

const MapCard = ({ css, boundsArray }: MapCardProps) => {
    const [west, south, east, north] = boundsArray;
    const bounds: LatLngBounds = new LatLngBounds([south, west], [north, east]);

    return (
        <MapContainer
            scrollWheelZoom={false}
            attributionControl={false}
            zoomControl={false}
            zoom={5}
            bounds={bounds}
            dragging={false}
            doubleClickZoom={false}
            style={{ ...css, width: css?.width || '100%', aspectRatio: 16 / 9 }}
        >
            <ReactLeafletGoogleLayer useGoogMapsLoader={true} apiKey={config.googleMapsJavascriptApiKey} />
        </MapContainer>
    );
};

export default MapCard;
