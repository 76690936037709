import { RootState } from 'src/store';
import { SampleComparisonUIState, SampleComparisonFiltersState, defaultUiState } from './sampleComparisonSlice';

export const getCurrentSampleComparisonUIState = (state: RootState): SampleComparisonUIState => {
    const currentProjectId = state.projects.projectList?.currentProjectId;

    if (!currentProjectId) {
        return defaultUiState;
    }

    return state.insights.sampleComparison.uiState[currentProjectId] || defaultUiState;
};

export const getCurrentSampleComparisonFilters = (state: RootState): SampleComparisonFiltersState => {
    const currentUiState = getCurrentSampleComparisonUIState(state);

    return currentUiState.filters;
};
