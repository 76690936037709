import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import { getSurveyRoutesAvailability } from 'src/routes/state/routesSelector';
import { useAppSelector } from 'src/store';
import { IndividualRouteSetting, SurveyViews } from 'src/routes/routesConfig';
import SurveyAdmin from './views/survey-admin/SurveyAdmin';
import NewSurveyDesign from './views/new-survey-design/NewSurveyDesign';
import SurveyDesignSummary from './views/survey-design-summary/SurveyDesignSummary';
import HabitatInsightsUpload from './views/habitat-insights-upload/HabitatInsightsUpload';

export const surveyRouteSettings: { [key in SurveyViews]: IndividualRouteSetting } = {
    surveyAdmin: {
        name: 'Survey admin',
        path: '/survey-admin',
        handler: <SurveyAdmin />,
    },
    newSurveyDesign: {
        name: 'New survey design',
        path: '/new-survey-design',
        handler: <NewSurveyDesign />,
    },
    surveyDesignSummary: {
        name: 'Survey design summary',
        path: '/survey-design-summary',
        handler: <SurveyDesignSummary />,
    },
    habitatInsightsUpload: {
        name: 'Habitat Insights Upload',
        path: '/habitat-insights-upload',
        handler: <HabitatInsightsUpload />,
    },
};

const Survey = () => {
    const routesConfig = useAppSelector(getSurveyRoutesAvailability);

    if (!routesConfig) {
        return null;
    }

    return (
        <Routes>
            {Object.keys(routesConfig).map(routeKey => {
                const isAvailable = routesConfig[routeKey as SurveyViews];
                const routeSetting = surveyRouteSettings[routeKey as SurveyViews];
                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={'/projects'}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default Survey;
