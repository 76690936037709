import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';
import { ProjectSampleManifestStatus, SortOrder, SubscriptionType } from 'src/shared/types';
import { samplingEventsQuery, SamplingEventsQueryResult } from './sampleReceptionQueries';
import { SampleReceptionPendingAction } from '../sampleReceptionSlice';

export enum SampleReceptionSortField {
    KITS_SENT_AT = 'events.kitsSentAt',
    CUSTOMER_NAME = 'customerName',
}

type SampleReceptionProjectListFilter = {
    filter: {
        query: string;
        subscriptionTypes: SubscriptionType[];
        sampleManifestStatuses: ProjectSampleManifestStatus[];
        pendingActionFilter: SampleReceptionPendingAction[];
    };
    pagination?: {
        page: number;
        pageSize: number;
    };
    sortBy: {
        field: SampleReceptionSortField;
        order: SortOrder;
    };
};

const sampleReceptionGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        samplingReceptionProjectList: builder.query<SamplingEventsQueryResult, SampleReceptionProjectListFilter>({
            query: params => ({
                body: samplingEventsQuery,
                variables: {
                    filter: params.filter || {},
                    pagination: params.pagination || {},
                    sortBy: params.sortBy || '',
                },
            }),
            transformResponse(baseQueryReturnValue) {
                return baseQueryReturnValue.sampleManifest.samplingReceptionProjectList;
            },
        }),
    }),
});

export const { useSamplingReceptionProjectListQuery } = sampleReceptionGraphSlice;

export default sampleReceptionGraphSlice.reducer;
