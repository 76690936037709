import React from 'react';
import { useSearchParams } from 'react-router-dom';
import StepIndicator from 'src/shared/components/step-indicator/StepIndicator';
import { sampleManifestFormViewSettings } from '../../SampleManifestForm';

const SampleManifestStepIndicator = () => {
    const [searchParams] = useSearchParams();
    const viewFromUrl = searchParams.get('view');

    const currentStep = sampleManifestFormViewSettings[viewFromUrl as keyof typeof sampleManifestFormViewSettings]?.step ?? 0;

    return (
        <StepIndicator currentStep={currentStep} steps={['Add field data', 'Add test(s)', 'Submit']} checkIconForCompletedSteps={false} />
    );
};

export default SampleManifestStepIndicator;
