import React from 'react';
import { getCurrentCommunityComparisonUIState } from 'src/app/insights/state/community-comparison/communityComparisonSelector';
import { useAppSelector } from 'src/store';

import { SingleYearCommunityComparisonData } from 'src/app/insights/state/api/queries/communityComparisonQuery';
import AllSampleGroupComparison from './components/AllSampleGroupComparison';
import AsideBottomContent from './components/AsideBottomContent';
import SelectedSampleGroupComparison from './components/SelectedSampleGroupComparison';
import SingleSampleAsideInfo from './components/SingleSampleAsideInfo';

import styles from './CommunityComparisonAside.module.scss';
import DifferenceIndicator from '../difference-indicator/DifferenceIndicator';

type CommunityComparisonAsideProps = {
    selectedYearData: SingleYearCommunityComparisonData[];
};
const CommunityComparisonAside = (props: CommunityComparisonAsideProps) => {
    const { selectedYearData } = props;

    return (
        <div className={styles.aside}>
            <AsideTopContent selectedYearData={selectedYearData} />
            <div className={styles.sectionSeparator}></div>
            <AsideBottomContent />
        </div>
    );
};

const AsideTopContent = (props: CommunityComparisonAsideProps) => {
    const { selectedYearData } = props;

    const uiState = useAppSelector(getCurrentCommunityComparisonUIState);

    const {
        filters: { selectedSampleGroup, selectedSampleId },
    } = uiState;

    const isSignificantlyDifferent = selectedYearData.every(entry => entry.comparisons.every(comparison => comparison.sig_diff));

    let content = null;

    if (selectedSampleId) {
        content = <SingleSampleAsideInfo />;
    } else if (selectedSampleGroup) {
        content = <SelectedSampleGroupComparison />;
    } else {
        content = (
            <div>
                <DifferenceIndicator isDifferent={isSignificantlyDifferent} />
                <div className={styles.sectionSeparator}></div>
                <AllSampleGroupComparison />
            </div>
        );
    }

    if (!content) {
        return null;
    }
    return <div className={styles.asideTop}>{content}</div>;
};

export default CommunityComparisonAside;
