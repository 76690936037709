import React, { useState } from 'react';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useAppDispatch, useAppSelector } from 'src/store';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';
import Checkbox from 'src/shared/components/checkbox/Checkbox';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import { useUnassignUserFromOrganisationMutation } from '../../../state/api/userManagementGraphSlice';
import { reset, setEditUserView, updateGeneralUiState } from '../../../state/userManagementSlice';
import { getEditProjectUserFormState } from '../../../state/userManagementSelector';
import UserDetails from '../../user-details/UserDetails';
import styles from '../EditProjectUser.module.scss';

const UnassignUserFromOrganisation = () => {
    const dispatch = useAppDispatch();
    const formState = useAppSelector(getEditProjectUserFormState);
    const { currentCustomerId } = useCustomer();
    const { firstName, lastName, email, status, phone } = formState;
    const [notify, setNotify] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const onStepChange = (step: string) => {
        dispatch(setEditUserView(step));
    };

    const [unassignUserFromOrganisation] = useUnassignUserFromOrganisationMutation();

    const resetForm = (message: string, shouldShowSuccessAlert = true) => {
        dispatch(reset());
        dispatch(
            updateGeneralUiState({
                shouldShowEditUserModal: false,
                shouldShowSuccessAlert: shouldShowSuccessAlert,
                successStatusMessage: message,
            })
        );
    };

    const unassignUser = async () => {
        setErrorMessage('');
        try {
            await unassignUserFromOrganisation({
                id: formState.id,
                notify: notify,
                customerId: currentCustomerId,
            }).unwrap();

            resetForm('User unassigned successfully.');
        } catch (error) {
            setErrorMessage('An error occurred while unassigning user.');
        }
    };

    return (
        <div className={styles.unassignUser}>
            <div>Are you sure you want to unassign the following user from this organisation?</div>
            <span className={styles.sectionDivider}></span>
            <UserDetails firstName={firstName} lastName={lastName} email={email} status={status} phone={phone} />
            <span className={styles.sectionDivider}></span>
            <InfoMessage
                message={
                    <div>
                        This user will lose access to their assigned projects within this organisation on the NatureMetrics platform.
                        <br />
                        <br />
                        You can reassign this user at a later time
                    </div>
                }
            />
            <Checkbox
                label={'Send user an email to notify them of this change'}
                checked={notify}
                onChange={value => setNotify(value)}
                className={styles.notifyUser}
            />
            <div className={styles.footerActions}>
                <SecondaryButton onClick={() => onStepChange('default')}>
                    <ChevronLeft /> <span className={styles.buttonText}>Back</span>
                </SecondaryButton>
                <PrimaryButton onClick={unassignUser}>
                    <PersonOffIcon /> Unassign
                </PrimaryButton>
            </div>
            {errorMessage && <ErrorMessage message={errorMessage} />}
        </div>
    );
};

export default UnassignUserFromOrganisation;
