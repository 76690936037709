import { RootState } from 'src/store';
import { CommunityComparisonUIState, CommunityComparisonFiltersState, defaultUiState } from './communityComparisonSlice';

export const getCurrentCommunityComparisonUIState = (state: RootState): CommunityComparisonUIState => {
    const currentProjectId = state.projects.projectList.currentProjectId;

    if (!currentProjectId) {
        return defaultUiState;
    }

    return state.insights.communityComparison.uiState[currentProjectId] || defaultUiState;
};

export const getCommunityComparisonFilters = (state: RootState): CommunityComparisonFiltersState => {
    const currentUiState = getCurrentCommunityComparisonUIState(state);

    return currentUiState.filters;
};
