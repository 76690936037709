import React from 'react';
import * as d3 from 'd3';

type PercentageBarProps = {
    score: number;
    width?: number;
    domain?: [number, number];
    scoreUnit?: string;
    scoreTextPosition?: number;
    scoreTextColor?: string;
    backboneColor?: string;
    progressBarColor?: string;
};

const PercentageBar = (props: PercentageBarProps) => {
    const {
        score,
        scoreUnit = '%',
        width = 270,
        domain = [0, 100],
        scoreTextPosition,
        scoreTextColor = '#fff',
        backboneColor = '#07676930',
        progressBarColor = '#076769',
    } = props;

    const scale = d3.scaleLinear().domain(domain).range([0, width]);

    const scoreLength = scale(score);
    const textPositionX = scoreTextPosition || (scoreLength - 30 < 0 ? scoreLength + 5 : scoreLength - 40);
    return (
        <div>
            <svg width={width} height='20' viewBox={`0 0 ${width} 20`} fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect x={0} width={width} height='20' fill={backboneColor} />
                <rect x={0} y={0} width={scoreLength} height={20} fill={progressBarColor} />
                <text x={textPositionX} y='15' fill={scoreTextColor}>
                    {score}
                    {scoreUnit}
                </text>
            </svg>
        </div>
    );
};

export default PercentageBar;
