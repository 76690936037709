import React from 'react';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import useProjectSummary from 'src/shared/hooks/useProjectSummary';
import { SecondaryButton } from 'src/shared/components/button/Button';

import { ReactComponent as BackArrowIcon } from 'src/assets/svg/arrows/back-arrow.svg';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import moment from 'moment';
import ViewSampleManifestContent from './components/view-sample-manifest-content/ViewSampleManifestContent';
import Overview from './components/overview/Overview';

const ViewSampleManifest = () => {
    const navigation = useAppNavigation();
    const { projectSummary } = useProjectSummary();
    const { samplingEvent } = useSamplingEvent();
    const mainContent = (
        <div className='flex flex-col gap-8 overflow-y-auto'>
            <Overview />
            <ViewSampleManifestContent />
        </div>
    );

    const title = 'View Sample Manifest';

    const breadcrumbs = [
        {
            label: 'Sample Reception',
            callback: navigation.toSampleReception,
        },
        {
            label: projectSummary?.projectName || '',
        },
    ];

    const lozenges = (
        <div className='flex flex-col gap-1 items-end text-grey-80'>
            <span>Event trigger date: {moment(samplingEvent?.sampleManifestInfo.kitsSentAt).format('DD MMM YYYY')}</span>
            <span>Event start date: {samplingEvent?.fromDate ? moment(samplingEvent?.fromDate).format('DD MMM YYYY') : 'N/A'}</span>
            <span>
                Event completion date: {samplingEvent?.processedDate ? moment(samplingEvent?.processedDate).format('DD MMM YYYY') : 'N/A'}
            </span>
        </div>
    );

    const otherActions = (
        <SecondaryButton onClick={navigation.toSampleReception} className='flex gap-2'>
            {' '}
            <BackArrowIcon /> Back to sample reception
        </SecondaryButton>
    );

    return (
        <StandardAppLayout
            mainContent={mainContent}
            title={title}
            subTitle='Submitted project sampling data'
            breadcrumbs={breadcrumbs}
            otherActions={otherActions}
            lozenges={lozenges}
        />
    );
};

export default ViewSampleManifest;
