import React from 'react';

type LegendDashedIconProps = {
    color: string;
};

const LegendDashedIcon = ({ color }: LegendDashedIconProps) => {
    return (
        <svg width='14' height='11' viewBox='0 0 14 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='4' height='2' fill={color} />
            <rect x='5' width='4' height='2' fill={color} />
            <rect x='10' width='4' height='2' fill={color} />
        </svg>
    );
};

export default LegendDashedIcon;
