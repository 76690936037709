import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import { useSearchParams } from 'react-router-dom';

const useProjectSummary = () => {
    const [searchParams] = useSearchParams();
    const projectIdFromUrl = searchParams.get('projectId');
    const customerIdFromUrl = searchParams.get('customerId');

    const { currentData: currentCustomerProjects, error } = useProjectsQuery(
        {
            customerId: customerIdFromUrl || '',
        },
        {
            skip: !customerIdFromUrl,
        }
    );

    const getCurrentCustomerProjects = () => {
        return currentCustomerProjects?.customer.projects;
    };

    const getCurrentProjectSummary = () => {
        return getCurrentCustomerProjects()?.find(project => project.projectId === projectIdFromUrl);
    };

    if (error) {
        console.error(error);
        throw {
            error: true,
            message: 'Unable to fetch projects list.',
        };
    }

    return {
        projectSummary: getCurrentProjectSummary(),
        customerId: currentCustomerProjects?.customer.customerId,
    };
};

export default useProjectSummary;
