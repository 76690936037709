import { Covariate, Metric } from 'src/shared/types';
import { HorizontalBarChartData } from 'src/shared/components/charts/horizontal-bar-chart/HorizontalBarChart';
import { SortOrder } from 'src/shared/types/sort';

interface IndividualSampleInput {
    sampleId: string;
    metrics: Metric[];
    covariates: Covariate[];
}

export const transformData = (
    input: IndividualSampleInput[],
    metric: string,
    getColourForArea: (area: string) => string,
    sortBy: keyof HorizontalBarChartData & string,
    sortOrder: string
): HorizontalBarChartData[] => {
    const output: HorizontalBarChartData[] = [];

    input.forEach(inputObj => {
        const { metrics } = inputObj;
        const metricObj = metrics.find(item => item.metricKey === metric);
        if (metricObj) {
            const outputObj = {
                name: inputObj.sampleId,
                value: metricObj.metricValue,
                color: getColourForArea(inputObj.covariates[0].covariateValue),
                type: inputObj.covariates[0].covariateValue,
                label: inputObj.covariates[0].covariateValue,
                data: null,
            };
            output.push(outputObj);
        }
    });

    const customSort = (a: HorizontalBarChartData, b: HorizontalBarChartData) => {
        const valueA = a[sortBy];
        const valueB = b[sortBy];
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return sortOrder === SortOrder.ASC ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        } else {
            return sortOrder === SortOrder.ASC ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
        }
    };

    output.sort(customSort);

    return output;
};
