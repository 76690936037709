import { RootState } from 'src/store';
import { initialRoutesState } from './routesSlice';

export const getRoutesState = (state: RootState) => {
    return state.routes || initialRoutesState;
};

export const getAllRoutesConfig = (state: RootState) => {
    return getRoutesState(state).routesConfig;
};

export const getAppRoutesAvailability = (state: RootState) => {
    return getAllRoutesConfig(state).appRoutesAvailability;
};

export const getMapRoutesAvailability = (state: RootState) => {
    return getAllRoutesConfig(state).mapRoutesAvailability;
};

export const getInsightRoutesAvailability = (state: RootState) => {
    return getAllRoutesConfig(state).insightRoutesAvailability;
};

export const getProjectRoutesAvailability = (state: RootState) => {
    return getAllRoutesConfig(state).projectRoutesAvailability;
};

export const getSamplesRoutesAvailability = (state: RootState) => {
    return getAllRoutesConfig(state).samplesRoutesAvailability;
};

export const getSurveyRoutesAvailability = (state: RootState) => {
    return getAllRoutesConfig(state).surveyRoutesAvailability;
};

export const getTableRoutesAvailability = (state: RootState) => {
    return getAllRoutesConfig(state).tableRoutesAvailability;
};

export const getAdminRoutesAvailability = (state: RootState) => {
    return getAllRoutesConfig(state)?.adminRoutesAvailability;
};
