import { AppName } from 'src/global/globalConfig';
import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import useProject from 'src/shared/hooks/useProject';

const useProjectAnalytics = () => {
    const { currentProjectDetails } = useProject();

    const sendTrackEvent = (ga: AnalyticsOptions) => {
        analyticsTracking.trackEvent({
            ...ga,
            project: ga.project || currentProjectDetails?.projectName,
        });
    };

    const trackReportDownload = (projectName: string) => {
        sendTrackEvent({
            action: 'project_report_download',
            category: 'project-reports',
            app: AppName.PROJECTS,
            label: projectName,
            project: projectName,
        });
    };

    return {
        trackReportDownload,
    };
};

export default useProjectAnalytics;
