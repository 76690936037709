export type SurveySamplesGeoJSON = {
    type: string;
    name: string;
    crs: {
        type: string;
        properties: {
            name: string;
        };
    };
    features: {
        type: string;
        properties: {
            'Sample point': string;
            'Unique Sample ID': string;
            Latitude: string;
            Longitude: string;
            'Sample Group': string;
            'Kit type': string;
            'Habitat/Test types': string;
        };
        geometry: {
            type: string;
            coordinates: number[];
        };
    }[];
};

export const surveySamplesAjvValidationSchema = {
    type: 'object',
    properties: {
        features: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    type: { type: ['string', 'null'] },
                    properties: {
                        type: 'object',
                        properties: {
                            'Sample point': { type: ['number', 'string', 'null'] },
                            'Unique Sample ID': { type: ['string', 'null'] },
                            Latitude: { type: ['number', 'string', 'null'] },
                            Longitude: { type: ['number', 'string', 'null'] },
                            'Sample Group': { type: ['string', 'null'] },
                            'Kit type': { type: ['string', 'null'] },
                            'Habitat/Test types': { type: ['string', 'null'] },
                        },
                        required: [
                            'Sample point',
                            'Unique Sample ID',
                            'Latitude',
                            'Longitude',
                            'Sample Group',
                            'Kit type',
                            'Habitat/Test types',
                        ],
                    },
                },
                required: ['properties'],
            },
        },
    },
    required: ['features'],
};

export type HabitatInsightsGeoJsonFile = {
    type: string;
    name: string;
    crs: {
        type: string;
        properties: {
            name: string;
        };
    };
    features: HabitatInsightsGeoJson[];
};

export type HabitatInsightsGeoJson = {
    type: string;
    properties: {
        name: string | null;
        metrics:
            | {
                  metric: string;
                  url: string;
                  chart: {
                      data: {
                          year: string;
                          value: number;
                      }[];
                  };
              }[]
            | null;
    };
    geometry: {
        type: string;
        coordinates: [number, number][][];
    };
};

export const habitatInsightsSamplesAjvValidationSchema = {
    type: 'object',
    properties: {
        type: {
            type: 'string',
            const: 'FeatureCollection',
        },
        name: {
            type: 'string',
        },
        crs: {
            type: 'object',
            properties: {
                type: {
                    type: 'string',
                    const: 'name',
                },
                properties: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            const: 'urn:ogc:def:crs:OGC:1.3:CRS84',
                        },
                    },
                    required: ['name'],
                    additionalProperties: false,
                },
            },
            required: ['type', 'properties'],
            additionalProperties: false,
        },
        features: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    type: {
                        type: 'string',
                        const: 'Feature',
                    },
                    properties: {
                        type: 'object',
                        properties: {
                            id: {
                                type: ['null', 'number'],
                            },
                            name: {
                                type: 'string',
                            },
                        },
                        required: ['name'],
                        additionalProperties: false,
                    },
                    geometry: {
                        type: 'object',
                        properties: {
                            type: {
                                type: 'string',
                                const: 'Polygon',
                            },
                            coordinates: {
                                type: 'array',
                                items: {
                                    type: 'array',
                                    items: {
                                        type: 'array',
                                        items: {
                                            type: 'number',
                                        },
                                        minItems: 2,
                                        maxItems: 2,
                                    },
                                },
                                minItems: 1,
                            },
                        },
                        required: ['type', 'coordinates'],
                        additionalProperties: false,
                    },
                },
                required: ['type', 'properties', 'geometry'],
                additionalProperties: false,
            },
        },
    },
    required: ['type', 'name', 'features'],
    additionalProperties: false,
};
