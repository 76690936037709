import { useState, useEffect } from 'react';
import cmsService from 'src/services/cms-service';
import { CMSHabitatAssayType } from 'src/shared/types';

const useCMSTestTypes = () => {
    const [testTypes, setTestTypes] = useState<CMSHabitatAssayType[]>([]);

    useEffect(() => {
        // Fetch habitat assay types from the CMS API
        const fetchTestTypes = async () => {
            try {
                const allHabitatAssays = await cmsService.getAllHabitatAssayTypes();
                setTestTypes(allHabitatAssays as CMSHabitatAssayType[]);
            } catch (error) {
                console.error('Error fetching habitat assay types:', error);
            }
        };

        fetchTestTypes();
    }, []);

    return testTypes;
};

export default useCMSTestTypes;
