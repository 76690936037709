import React from 'react';

type LegendAreaIconProps = {
    color: string;
};

const LegendAreaIcon = ({ color }: LegendAreaIconProps) => {
    return (
        <svg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='14' height='11' fill={color} />
        </svg>
    );
};

export default LegendAreaIcon;
