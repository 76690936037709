import React from 'react';

import { SecondaryButton } from 'src/shared/components/button/Button';
import usePermissions from 'src/shared/hooks/usePermissions';
import { Resources, Access } from 'src/shared/types';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

const ProjectAdminButton = () => {
    const { currentCustomerId } = useCustomer();
    const { hasPermission } = usePermissions();
    const navigate = useAppNavigation();

    const canAdminProject = hasPermission({
        to: [Access.CREATE, Access.UPDATE],
        customerId: currentCustomerId,
        resource: Resources.PROJECT_DEFINITION,
    });

    const projectAdminBtn = canAdminProject ? (
        <div style={{ marginBottom: '40px' }}>
            <SecondaryButton onClick={navigate.toProjectAdmin}>Go to project admin</SecondaryButton>
        </div>
    ) : null;

    return projectAdminBtn;
};

export default ProjectAdminButton;
