import React from 'react';

import { ReactComponent as TickIcon } from 'src/assets/svg/general/green-tick.svg';
import { ReactComponent as RedCrossIcon } from 'src/assets/svg/general/red-cross.svg';
import { getKitTypeFromBarcode, isValidKitBarcode } from 'src/shared/helpers/kitHelpers';

type KitTypeMessageProps = {
    kitBarcode: string;
};

const KitTypeMessage = (props: KitTypeMessageProps) => {
    const { kitBarcode } = props;

    const isValidBarcode = isValidKitBarcode(kitBarcode);

    if (!isValidBarcode) {
        return null;
    }

    const kitType = getKitTypeFromBarcode(kitBarcode);

    if (!kitType) {
        return (
            <div className='flex gap-2 items-center'>
                <RedCrossIcon data-testid='red-cross-icon' />
                <div className='text-sm'>No NM kit found.</div>
            </div>
        );
    }

    return (
        <div className='flex gap-2 items-center'>
            <TickIcon data-testid='tick-icon' />
            <div className='text-sm'>NM {kitType} kit</div>
        </div>
    );
};

export default KitTypeMessage;
