import React, { useState, useEffect, useRef, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { Restore as RestoreIcon } from '@mui/icons-material';
import { MapContainer, ZoomControl } from 'react-leaflet';
import L, { LatLngBoundsExpression } from 'leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Control from 'react-leaflet-custom-control';
import Modal from 'src/shared/components/modal/Modal';
import MapOutlined from '@mui/icons-material/MapOutlined';
import classNames from 'classnames';
import { SamplingKit } from 'src/shared/types';
import { LoaderInline } from 'src/shared/components/loader/Loader';
import MapType from 'src/shared/components/map-type/MapType';
import config from 'src/config';
import googleMapStyles from 'src/app/maps/views/samples/components/samples-map/samplesMapFeaturesConfig';
import styles from 'src/app/maps/views/samples/components/samples-map/SamplesMap.module.scss';
import MapMarker from './components/map-marker/MapMarker';
import MapKeysBlock from './components/map-keys-block/MapKeysBlock';
import useSamplingEvent from '../../hooks/useSamplingEvent';
import 'src/app/maps/views/samples/components/samples-map/leaflet.css';

type SampleMapModalProps = {
    onClose?: () => void;
    showTestsInTootip?: boolean;
};

export type SamplingKitInfo = Pick<SamplingKit, 'id' | 'name' | 'latitude' | 'longitude' | 'testTypes'>;

export type SamplingKitMapMarker = {
    kitInfo: SamplingKitInfo;
    colour: string;
};

const SampleMapModal = (props: SampleMapModalProps) => {
    const { showTestsInTootip, onClose } = props;

    const [samplingKitMapMarkers, setSamplingKitMapMarkers] = useState<SamplingKitMapMarker[]>([]);
    const { samplingEvent, unsubmittedKits, submittedKits, isFetching } = useSamplingEvent();
    const [mapType, setMapType] = useState('roadmap');
    const [hoveredSamplingKitId, setHoveredSamplingKitId] = useState<string | null>(null);
    const [hasSubmittedSamplingKits, setHasSubmittedSamplingKits] = useState<boolean>(false);
    const [hasUnsubmittedSamplingKits, setHasUnsubmittedSamplingKits] = useState<boolean>(false);

    useEffect(() => {
        if (!submittedKits || !unsubmittedKits) {
            return;
        }
        const newSubmittedSampleWithLatLongs =
            submittedKits
                .filter(kit => kit.latitude && kit.longitude)
                .map(kit => ({
                    kitInfo: { id: kit.id, name: kit.name, latitude: kit.latitude, longitude: kit.longitude, testTypes: kit.testTypes },
                    colour: '#BC4E7C',
                })) || [];

        const newUnsubmittedSamplesWithLatLongs =
            unsubmittedKits
                .filter(kit => kit.latitude && kit.longitude)
                .map(kit => ({
                    kitInfo: { id: kit.id, name: kit.name, latitude: kit.latitude, longitude: kit.longitude, testTypes: kit.testTypes },
                    colour: '#0FC0C7',
                })) || [];

        if (newSubmittedSampleWithLatLongs.length) {
            setHasSubmittedSamplingKits(true);
        }
        if (newUnsubmittedSamplesWithLatLongs.length) {
            setHasUnsubmittedSamplingKits(true);
        }

        setSamplingKitMapMarkers([...newSubmittedSampleWithLatLongs, ...newUnsubmittedSamplesWithLatLongs]);
    }, [samplingEvent]);

    const mapRef = useRef<L.Map>(null);

    const getBounds = () => {
        const bounds = samplingKitMapMarkers.map(kit => [Number(kit.kitInfo.latitude), Number(kit.kitInfo.longitude)]);
        return bounds as LatLngBoundsExpression;
    };

    // Hook responsible to fit the bounds once the markers are filtered
    useEffect(() => {
        if (samplingKitMapMarkers.length) {
            const bounds = getBounds();
            if ((bounds as []).length) {
                mapRef.current?.fitBounds(bounds);
            }
        }
    }, [samplingKitMapMarkers]);

    const groupedMarkers = useMemo(() => {
        // For pins that share the same lat/long
        return Object.values(groupBy(samplingKitMapMarkers, sampleKit => [sampleKit.kitInfo.latitude, sampleKit.kitInfo.longitude]));
    }, [samplingKitMapMarkers]);

    const handleMarkerMouseOver = (sampleKitId: string | null) => {
        setHoveredSamplingKitId(sampleKitId);
    };

    const modalClasses = {
        body: classNames('w-4/5 h-4/5 p-8'),
    };

    const noMapData = (
        <div className='flex flex-col gap-4 text-center'>
            <div className='text-2xl text-primary'>No map data to display</div>
            <div className='text-lg text-[#666]'>Samples will appear as pins on the map when you have added latitudes and longitudes</div>
        </div>
    );

    const mapData = (
        <MapContainer
            ref={mapRef}
            bounds={samplingKitMapMarkers.length ? getBounds() : undefined}
            scrollWheelZoom={true}
            style={{ height: '100%', width: '100%' }}
            attributionControl={false}
            maxZoom={19}
            zoomControl={false}
        >
            <ReactLeafletGoogleLayer
                key={mapType}
                apiKey={config.googleMapsJavascriptApiKey}
                useGoogMapsLoader={true}
                styles={googleMapStyles}
                type={mapType as any}
            ></ReactLeafletGoogleLayer>
            <ZoomControl position='topright' />

            <div className='leaflet-bottom leaflet-left pl-4 pb-4'>
                <MapKeysBlock hasSubmittedSamplingKits={hasSubmittedSamplingKits} hasUnsubmittedSamplingKits={hasUnsubmittedSamplingKits} />
            </div>
            <Control position='topright'>
                <span onClick={() => mapRef.current?.fitBounds(getBounds())} className={styles.restoreContainer}>
                    <RestoreIcon className={styles.restore} style={{ fontSize: '30px', cursor: 'pointer' }} />
                </span>
            </Control>

            {groupedMarkers.map((markerGroup, index) => {
                return map(markerGroup, (entry: SamplingKitMapMarker & { color: string }, ind: number) => {
                    return (
                        <span key={`${entry.kitInfo.id}-${index}`}>
                            <MapMarker
                                samplingKitInfo={entry.kitInfo}
                                color={entry.colour}
                                groupOrder={ind}
                                hoveredSamplingKitId={hoveredSamplingKitId}
                                handleMarkerMouseOver={handleMarkerMouseOver}
                                showTestsInTootip={showTestsInTootip}
                            />
                        </span>
                    );
                });
            })}
            <div
                className='leaflet-bottom leaflet-right'
                style={{ pointerEvents: 'visible', right: '15px' }}
                ref={ref => {
                    if (!ref) return;
                    L.DomEvent.disableClickPropagation(ref).disableScrollPropagation(ref);
                }}
            >
                <div className={styles.legend}>
                    <div className={styles.title}>Map Type </div>
                    <MapType handleChange={setMapType} value={mapType} />
                </div>
            </div>
        </MapContainer>
    );

    return (
        <Modal dismissOnClickOutside={true} classNames={modalClasses} onClose={onClose}>
            <div className='flex flex-col gap-4 h-full'>
                <div className='flex gap-2'>
                    <MapOutlined />
                    Map view
                </div>
                <div className='items-center w-full grow border-0 border-current rounded-lg overflow-hidden'>
                    {isFetching && <LoaderInline />}
                    {!isFetching && !Boolean(samplingKitMapMarkers.length) && noMapData}
                    {!isFetching && samplingKitMapMarkers && mapData}
                </div>
            </div>
        </Modal>
    );
};

export default SampleMapModal;
