import React, { useEffect } from 'react';
import { FileUpload, InsertDriveFileOutlined } from '@mui/icons-material';
import { useAppDispatch } from 'src/store';
import { FileTransformedToString, Upload } from 'src/shared/components/upload';
import useHabitatInsightsUpload from 'src/app/surveys/views/habitat-insights-upload/hooks/useHabitatInsightsUpload';
import { validateHabitatInsightsGeoJSON } from 'src/app/surveys/views/habitat-insights-upload/habitatInsightsUploadHelpers';
import { HabitatInsightsGeoJsonFile } from 'src/shared/types';
import { SecondaryButton, PrimaryButton } from 'src/shared/components/button/Button';
import { resetHabitatInsightsUpload } from 'src/app/surveys/views/habitat-insights-upload/state/habitatInsightsUploadSlice';
import Modal from 'src/shared/components/modal/Modal';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import styles from './HabitatInsightsSamplesUpload.module.scss';

export const HabitatInsightsSamplesUpload = () => {
    const { onFormChange, formState } = useHabitatInsightsUpload();
    const [showModal, setShowModal] = React.useState(false);

    const { uploadedFileErrors, uploadedFileContent, uploadedFileName } = formState;

    const dispatch = useAppDispatch();

    const onUpload = (file: FileTransformedToString) => {
        onFormChange({
            uploadedFileContent: file.content || '',
            uploadedFileErrors: file.content ? validateHabitatInsightsGeoJSON(file.content) : [],
            uploadedFileName: file.filename || '',
        });
    };

    useEffect(() => {
        const habitatInsightsSamplesData: HabitatInsightsGeoJsonFile = JSON.parse(JSON.stringify(formState.uploadedFileContent));
        if (habitatInsightsSamplesData && habitatInsightsSamplesData.features && !formState.uploadedFileErrors.length) {
            onFormChange({
                sites: habitatInsightsSamplesData.features,
            });
        }
    }, [formState.uploadedFileContent, formState.uploadedFileErrors, onFormChange]);

    return (
        <div className={styles.uploadWrapper}>
            <div className={styles.top}>
                <div className={styles.topLeft}>
                    <div className={styles.uploadIcon}>
                        <FileUpload />
                    </div>
                    <div className={styles.uploadTitle}>Upload data</div>
                    <div className={styles.uploadFileType}>Supported file type: .geojson</div>
                </div>
                <div className={styles.topRight}>
                    <QuestionButtonHelp
                        type='api'
                        placement='right'
                        slug='how-do-i-prepare-my-file-for-upload'
                        label='How do I prepare my file for upload?'
                    />
                </div>
            </div>
            {uploadedFileContent.length > 0 && !uploadedFileErrors.length ? (
                <div className={styles.uploadedFileContainer}>
                    <div className={styles.uploadedFileIconContainer}>
                        <InsertDriveFileOutlined fontSize={'large'} />
                        <div>
                            <div>Uploaded file:</div>
                            <div className={styles.uploadedFileNameText}>{uploadedFileName}</div>
                        </div>
                    </div>
                    <div>
                        <SecondaryButton onClick={() => setShowModal(true)}>Replace with new data upload</SecondaryButton>
                    </div>
                </div>
            ) : (
                <div className={styles.bottom}>
                    <div className={styles.uploadInput}>
                        <Upload onUpload={onUpload} transformTo='text' accept='application/geo+json,.geojson' />
                    </div>
                </div>
            )}
            {showModal && (
                <Modal>
                    <div className={styles.modalContainer}>
                        <div className={styles.title}>Are you sure you want to replace this data with a new upload?</div>
                        <div className={styles.subTitle}>The existing data will be deleted</div>
                        <div className={styles.buttonContainer}>
                            <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
                            <PrimaryButton
                                onClick={() => {
                                    setShowModal(false);
                                    dispatch(resetHabitatInsightsUpload());
                                }}
                            >
                                Yes
                            </PrimaryButton>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default HabitatInsightsSamplesUpload;
