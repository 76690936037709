import React, { useEffect, useState } from 'react';

import { Access } from 'src/shared/types';
import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import {
    NewProjectUserFormState,
    EditProjectUserFormState,
    UserProjectAccess,
} from 'src/app/admin/views/user-management/state/userManagementSlice';
import Loader from 'src/shared/components/loader/Loader';
import { TertiaryButton } from 'src/shared/components/button/Button';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import styles from './AssignProjects.module.scss';
import AssignProjectCard from './AssignProjectCard';

export type AssignProjectProps = {
    formState: Pick<NewProjectUserFormState['formState'], 'projects'> & {
        status?: EditProjectUserFormState['formState']['status'];
        is_internal_user: boolean;
    };
    onFormChange: (fragment: Partial<NewProjectUserFormState['formState']>) => void;
    disabled?: boolean;
    formMode?: Access;
};

const AssignProjects = ({ formState, onFormChange, disabled = false, formMode = Access.UPDATE }: AssignProjectProps) => {
    const { projects, is_internal_user } = formState;
    const [shouldAllowNewProject, allowNewProject] = useState(false);

    const addNewProject = () => {
        onFormChange({
            projects: [...projects, { projectId: '', roles: [], remark: '' }],
        });
    };

    const { currentCustomerId } = useCustomer();
    const { isFetching, currentData } = useProjectsQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !currentCustomerId,
        }
    );

    const projectIdsForCurrentCustomer = currentData?.customer.projects.map(project => project.projectId) || [];
    const currentCustomerProjectCards = projects.filter(
        project => projectIdsForCurrentCustomer.includes(project.projectId) || project.projectId === ''
    );

    useEffect(() => {
        allowNewProject(currentCustomerProjectCards.length ? currentCustomerProjectCards.every(entry => Boolean(entry.projectId)) : false);
    }, [projects, projectIdsForCurrentCustomer]);

    if (isFetching) {
        return (
            <div>
                <Loader padding='0' />
            </div>
        );
    }

    if (!currentData) {
        return null;
    }

    return (
        <div className={styles.assignProjects}>
            {projects.map(
                (project: UserProjectAccess, index: number) =>
                    (project.projectId === '' || projectIdsForCurrentCustomer.includes(project.projectId)) && (
                        <div key={index}>
                            <AssignProjectCard
                                index={index}
                                projectsData={currentData}
                                formState={formState}
                                onFormChange={onFormChange}
                                formMode={formMode}
                                disabled={disabled}
                                isInternalUser={is_internal_user}
                            />
                        </div>
                    )
            )}
            {currentCustomerProjectCards.length === 0 && [Access.UPDATE, Access.CREATE].includes(formMode) && (
                <AssignProjectCard
                    projectsData={currentData}
                    index={projects.length}
                    formState={formState}
                    onFormChange={onFormChange}
                    disabled={disabled}
                    isInternalUser={is_internal_user}
                />
            )}
            {[Access.UPDATE, Access.CREATE].includes(formMode) && (
                <div className={styles.addProjectButtonContainer}>
                    <TertiaryButton disabled={!shouldAllowNewProject} onClick={addNewProject} className={styles.addProjectButton}>
                        <span>+</span> Add to another project
                    </TertiaryButton>
                </div>
            )}
        </div>
    );
};
export default AssignProjects;
