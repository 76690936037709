import React from 'react';
import { SecondaryButton } from 'src/shared/components/button/Button';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

const SaveAndExit = () => {
    const navigation = useAppNavigation();

    return <SecondaryButton onClick={navigation.toSampleManifest}>Exit</SecondaryButton>;
};

export default SaveAndExit;
