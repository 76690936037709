import { useEffect, useRef } from 'react';

function useScrollToTop(value: number | string) {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const scrollToTop = () => {
            if (ref.current) {
                ref.current.scrollIntoView();
            }
        };

        scrollToTop();

        const handleValueChange = () => {
            scrollToTop();
        };

        // Attach the event listener to handle formStep changes
        const valueChangeListener = () => {
            handleValueChange();
        };

        window.addEventListener('scroll', valueChangeListener);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', valueChangeListener);
        };
    }, [value]);

    return ref;
}

export default useScrollToTop;
