import React from 'react';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setFormState } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSlice';
import Input from 'src/shared/components/input/Input';
import { getCurrentProjectDefinitionFormState } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSelector';

import projectDefinitionstyles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import styles from './CustomerContacts.module.scss';

export const CustomerContacts = () => {
    const formState = useAppSelector(getCurrentProjectDefinitionFormState);
    const { projectLeadRole } = formState;

    const dispatch = useAppDispatch();

    return (
        <div className={projectDefinitionstyles.formSection}>
            <div className={projectDefinitionstyles.formSectionTitle}>Project contact</div>

            <div className={styles.projectContact}>
                <div className={styles.userIcon}>
                    <PersonOutlineOutlinedIcon />
                </div>
                <div className={styles.contactDetails}>
                    <div className={styles.contactDetailsName}>{formState.projectLeadName || 'Not set'}</div>
                    <div>{formState.projectLeadEmail || 'Not set'}</div>
                </div>
                <div className={styles.role}>
                    <div className={styles.roleTitle}>
                        Job title <QuestionButtonHelp type='api' slug='project-definition-role' />
                    </div>
                    <Input
                        placeholder='Type job title'
                        value={projectLeadRole}
                        onChange={event => dispatch(setFormState({ projectLeadRole: event.target.value }))}
                    />
                </div>
            </div>
        </div>
    );
};
export default CustomerContacts;
