import React, { ReactNode } from 'react';
import { Alert } from '@mui/material';
import classNames from 'classnames';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import styles from './ConditionalField.module.scss';

interface ConditionalFieldProps {
    children: ReactNode;
    isSatisfied: boolean;
    showWarning?: boolean;
}

const ConditionalField = ({ children, isSatisfied, showWarning = true }: ConditionalFieldProps) => {
    const message = 'This field is not included in this subscription type';
    const conditionalFieldClass = classNames(styles.conditionalField, !showWarning && styles.conditionalFieldNoWarning);

    if (!isSatisfied) {
        const alertStyle = !showWarning ? { backgroundColor: 'transparent' } : {};
        return (
            <span className={conditionalFieldClass}>
                <Alert severity='warning' icon={<CancelOutlinedIcon />} style={alertStyle}>
                    {message}
                </Alert>
            </span>
        );
    }

    return <>{children}</>;
};

export default ConditionalField;
