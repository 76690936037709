import React from 'react';
import classNames from 'classnames';
import Tabs from '../Tabs/Tabs';
import { SecondaryButton } from '../button/Button';

type CarouselTab = {
    icon?: React.ReactNode;
    title: string;
    subTitle: string;
    content: React.ReactNode;
    contentImage?: React.ReactNode;
};

type TabbedCarouselProps = {
    tabs: CarouselTab[];
    classNames?: {
        tabsContainer?: string;
        tab?: string;
    };
    showArrowControls?: boolean;
};

const TabbedCarousel = (props: TabbedCarouselProps) => {
    const { tabs, showArrowControls } = props;
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

    const tabsContainerClassName = classNames('border border-grey-60 rounded-lg', props.classNames?.tabsContainer);
    const tabClassName = classNames('w-[250px] pt-2 pb-1 px-4 flex-1', props.classNames?.tab);
    const selectedTabData = tabs[selectedTabIndex];
    const hasImage = selectedTabData.contentImage !== undefined;

    const TabContent = () => (
        <div className='flex gap-2 flex-1'>
            {hasImage && <div>{selectedTabData.contentImage}</div>}
            <div className='flex flex-col gap-2'>
                <div className='flex flex-col'>
                    <div className='text-md font-semibold text-primary'>{selectedTabData.title}</div>
                    <div className='text-xs text-grey-60'>{selectedTabData.subTitle}</div>
                </div>
                <div>{selectedTabData.content}</div>
            </div>
        </div>
    );

    const onLeftControlClick = () => {
        const newSelectedTab = selectedTabIndex - 1;
        setSelectedTabIndex(newSelectedTab);
    };

    const onRightControlClick = () => {
        const newSelectedTab = selectedTabIndex + 1;
        setSelectedTabIndex(newSelectedTab);
    };

    return (
        <div className='flex flex-col gap-4 flex-1'>
            <Tabs
                tabs={tabs.map((tab, index) => ({
                    label: (
                        <div className='flex gap-1'>
                            <span className='pt-1'>{tab.icon}</span>
                            <div className=''>
                                <div className='text-sm font-semibold text-primary'>{tab.title}</div>
                                <div className='text-xs text-grey-60'>{tab.subTitle}</div>
                            </div>
                        </div>
                    ),
                    id: index.toString(),
                }))}
                classNames={{
                    tabsContainer: tabsContainerClassName,
                    default: tabClassName,
                    selected: 'bg-grey-20',
                }}
                selectedTab={String(selectedTabIndex)}
                onTabChange={value => setSelectedTabIndex(parseInt(value))}
            />
            {showArrowControls ? (
                <div className='flex gap-x-6'>
                    <SecondaryButton className='self-center' onClick={onLeftControlClick} disabled={selectedTabIndex === 0}>
                        &lt;
                    </SecondaryButton>
                    <TabContent />
                    <SecondaryButton className='self-center' onClick={onRightControlClick} disabled={selectedTabIndex === tabs.length - 1}>
                        &gt;
                    </SecondaryButton>
                </div>
            ) : (
                <TabContent />
            )}
        </div>
    );
};

export default TabbedCarousel;
