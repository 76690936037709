import { ChevronRight } from '@mui/icons-material';
import React, { useState } from 'react';
import { PrimaryButton } from 'src/shared/components/button/Button';
import { useAppSelector } from 'src/store';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import SuccessfullySubmittedModal from './components/successfully-submitted-modal/SuccessfullySubmittedModal';
import SubmitionErrorModal from './components/submission-error-modal/SubmitionErrorModal';
import { getCurrentSampleManifestState } from '../../../state/sampleManifestSelector';

const SubmitData = () => {
    const [submissionDetails, setSubmissionDetails] = useState({
        result: '',
        batchId: '',
    });

    const { isSamplingEventCompleted, isTermsAndConditionsAccepted } = useAppSelector(getCurrentSampleManifestState);
    const { submitSamplingKits, isBatchOnlySubmit } = useSamplingEvent();

    const onSubmit = async () => {
        const submittedBatchId = await submitSamplingKits({
            isSamplingEventCompleted: isSamplingEventCompleted as boolean,
        });

        if (submittedBatchId) {
            setSubmissionDetails({
                result: 'success',
                batchId: submittedBatchId,
            });
        } else {
            setSubmissionDetails({
                result: 'error',
                batchId: '',
            });
        }
    };

    return (
        <div>
            <PrimaryButton onClick={onSubmit} disabled={isSamplingEventCompleted === null || !isTermsAndConditionsAccepted}>
                {isBatchOnlySubmit ? 'Resubmit' : 'Submit'} data to Naturemetrics <ChevronRight />
            </PrimaryButton>
            {submissionDetails.result === 'success' && <SuccessfullySubmittedModal batchId={submissionDetails.batchId} />}
            {submissionDetails.result === 'error' && (
                <SubmitionErrorModal
                    onClose={() =>
                        setSubmissionDetails({
                            result: '',
                            batchId: '',
                        })
                    }
                />
            )}
        </div>
    );
};

export default SubmitData;
