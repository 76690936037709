import React from 'react';
import { useCommunityComparisonSamplesQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import { getCurrentCommunityComparisonUIState } from 'src/app/insights/state/community-comparison/communityComparisonSelector';
import { STANDARD_DATE_FORMAT } from 'src/shared/constants/dateConstants';
import useProject from 'src/shared/hooks/useProject';
import { useAppSelector } from 'src/store';

import moment from 'moment';
import styles from '../CommunityComparisonAside.module.scss';

const SingleSampleAsideInfo = () => {
    const uiState = useAppSelector(getCurrentCommunityComparisonUIState);

    const { currentProjectId, currentProjectSelectedHabitatAssayType } = useProject();

    const {
        filters: { selectedSampleId },
    } = uiState;

    const { currentData, isFetching } = useCommunityComparisonSamplesQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                sampleIds: [selectedSampleId || ''],
            },
        },
        {
            skip: !currentProjectId || !selectedSampleId,
        }
    );

    if (!selectedSampleId || isFetching || !currentData) {
        return null;
    }

    const selectedSample = currentData.communityComparisonSamples.samples[0];

    const selectedAssayId = currentProjectSelectedHabitatAssayType?.assay.assayId;

    return (
        <div className={styles.sampleInfo}>
            <span className={styles.sampleId}>{selectedSampleId}</span>
            <div className={styles.dateReceived}>
                <span className={styles.dateReceivedLabel}>Date received:</span>
                <span className={styles.dateReceivedValue}>{moment(selectedSample.sampleReceivedDate).format(STANDARD_DATE_FORMAT)}</span>
            </div>
            <div className={styles.metric}>
                <span className={styles.metricTitle}>Species Richness</span>
                <span className={styles.metricValue}>
                    {
                        selectedSample.metrics.find(metric => metric.metricKey === 'speciesrichness' && metric.assayId === selectedAssayId)
                            ?.metricValue
                    }
                </span>
                <span className={styles.metricValueLabel}>species detected</span>
            </div>
        </div>
    );
};

export default SingleSampleAsideInfo;
