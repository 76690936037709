import moment from 'moment';
import { SamplingEvent } from 'src/shared/types';

type SamplingCadenceEvent = Pick<SamplingEvent, 'name' | 'fromDate' | 'toDate'> & {
    year: number;
    event: number;
};

export const getEventIndexAndName = (
    date: string,
    events: SamplingCadenceEvent[]
): { year: number; event: number; name: string } | null => {
    const dateMoment = moment(date);
    for (let i = 0; i < events.length; i++) {
        const event = events[i];
        const fromDateMoment = moment(event.fromDate);
        const toDateMoment = moment(event.toDate);
        if (dateMoment.isBetween(fromDateMoment, toDateMoment, undefined, '[]')) {
            return { year: event.year, name: event.name, event: event.event };
        }
    }

    return null;
};
