import { useState, useRef, useCallback, MutableRefObject } from 'react';

const useRefWithRerender = <T>(initialValue: T | null): [MutableRefObject<T | null>, (value: T | null) => void] => {
    const [, setRenderCount] = useState(0); // for force rerendering
    const ref = useRef<typeof initialValue>(initialValue);

    const callbackRef = useCallback((value: typeof initialValue) => {
        if (value !== ref.current) {
            ref.current = value;
            setRenderCount(prevCount => prevCount + 1); // to force a re-render
        }
    }, []);

    return [ref, callbackRef];
};

export default useRefWithRerender;
