import React from 'react';
import MapOutlined from '@mui/icons-material/MapOutlined';
import GridOnOutlined from '@mui/icons-material/GridOnOutlined';
import styles from './ListMapViewTabs.module.scss';
import Tabs from '../Tabs/Tabs';

export type MapOrTable = 'map' | 'list';

type ListMapViewTabsProps = {
    tab: MapOrTable;
    onTabChange: (tab: MapOrTable) => void;
    isMapFirst?: boolean;
    disableMap?: boolean;
    disableList?: boolean;
};

const tabs = [
    {
        id: 'map',
        label: (
            <>
                <MapOutlined /> Map view
            </>
        ),
    },
    {
        id: 'list',
        label: (
            <>
                <GridOnOutlined /> List view
            </>
        ),
    },
];

const ListMapViewTabs = (props: ListMapViewTabsProps) => {
    const { tab, onTabChange, isMapFirst, disableList, disableMap } = props;

    const orderedTabs = isMapFirst ? tabs : [...tabs].reverse();

    return (
        <Tabs
            tabs={orderedTabs.map(tab => ({ ...tab, isDisabled: tab.id === 'map' ? disableMap : disableList }))}
            onTabChange={value => onTabChange(value as MapOrTable)}
            selectedTab={tab}
            classNames={{
                default: styles.tabItem,
                selected: styles.tabItemSelected,
                tabsContainer: styles.tabsContainer,
            }}
        ></Tabs>
    );
};

export default ListMapViewTabs;
