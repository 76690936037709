import React from 'react';
import CopyToClipboard from 'src/shared/components/copy-to-clipboard/CopyToClipboard';
import { formatSamplingEventCode } from 'src/app/samples/samplesHelpers';
import { useSearchParams } from 'react-router-dom';

const SamplingEventPinCode = () => {
    const [searchParams] = useSearchParams();
    const samplingEventIdFromUrl = searchParams.get('samplingEventId') as string;

    const idToDisplay = formatSamplingEventCode(samplingEventIdFromUrl);
    return (
        <div className='flex flex-col gap-2'>
            <div className='text-grey-60 text-sm'>Sampling event PIN Code</div>
            <div className='flex gap-3 items-center'>
                <div className='text-2xl'>{idToDisplay}</div>
                <div>
                    <CopyToClipboard text={samplingEventIdFromUrl} />
                </div>
            </div>
        </div>
    );
};

export default SamplingEventPinCode;
