export enum AppName {
    CUSTOMERS = 'Customers',
    PROJECTS = 'Projects',
    DASHBOARD = 'Dashboard',
    ECOLOGICAL_TRENDS = 'Ecological trends',
    STATISTICAL_COMPARISON = 'Statistical comparison',
    TAXONOMIC_COMPOSITION = 'Taxonomic composition',
    COMMUNITY_COMPARISON = 'Community Comparison',
    SAMPLE_METRICS_TABLE = 'Sample metrics table',
    SAMPLE_COMPARISON = 'Sample comparison',
    MAP = 'Map',
}
