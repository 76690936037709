import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import React, { useEffect, useState } from 'react';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import { useEcologicalTrendsQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import { transformData } from 'src/app/insights/views/ecological-trends/EcologicalTrends.utils';
import { AppName } from 'src/global/globalConfig';
import TimeSeriesReChart from 'src/shared/components/charts/time-series/TimeSeriesReChart';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader, { LinearLoader } from 'src/shared/components/loader/Loader';
import HabitatAssayLozenges from 'src/shared/components/lozenges/HabitatAssayLozenges';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import useProject from 'src/shared/hooks/useProject';
import { useAppDispatch, useAppSelector } from 'src/store';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { chartExplainerSlugKey } from 'src/shared/constants/chartExplainerKeys';
import InputLabel from '@mui/material/InputLabel';
import styles from './EcologicalTrends.module.scss';
import { updateFilters } from '../../state/ecological-trends/EcologicalTrendsSlice';
import { getCurrentEcologicalTrendsUIState } from '../../state/ecological-trends/EcologicalTrendsSelector';

const PAGE_TITLE = 'Ecological Trends';

const EcologicalTrends = () => {
    const {
        currentProjectDetails,
        currentProjectFilters,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        setCurrentProjectHabitatAssayType,
        getColourForArea,
    } = useProject();
    const currentProjectBreadcrumbDetails = useProjectBreadcrumb(PAGE_TITLE);

    const dispatch = useAppDispatch();

    const [metricOptions, setMetricOptions] = useState<SelectOption[]>([
        {
            label: 'None available',
            value: 'none',
        },
    ]);

    const uiState = useAppSelector(getCurrentEcologicalTrendsUIState);

    const {
        filters: { chartId },
    } = uiState;

    useEffect(() => {
        if (!currentProjectFilters || !habitatAssayType) {
            return;
        }

        const { habitatAssays } = currentProjectFilters;

        const newMetricsOptions = habitatAssays
            .find(entry => entry.key === habitatAssayType?.key)
            ?.metrics.map(metric => ({
                label: startCase(metric.metricName),
                value: metric.metricKey,
            })) as SelectOption[];

        setMetricOptions(newMetricsOptions);
        if (!chartId) {
            dispatch(
                updateFilters({
                    chartId: newMetricsOptions[0].value,
                })
            );
        }
    }, [currentProjectFilters?.habitatAssays, habitatAssayType, chartId]);

    const { currentData, isFetching } = useEcologicalTrendsQuery(
        {
            projectId: currentProjectDetails?.projectId || '',
            chartId,
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectDetails?.projectId || !habitatAssayType || !chartId,
        }
    );

    if (!currentData || isFetching || !currentProjectDetails) {
        return (
            <>
                <LinearLoader />
                <Loader />
            </>
        );
    }

    const { ecologicalTrends: speciesRichnessInsightsData } = currentData;

    const timeSeriesData = speciesRichnessInsightsData && transformData(speciesRichnessInsightsData.data[0].data, getColourForArea);

    const onMetricChange = (value: string) => {
        dispatch(
            updateFilters({
                chartId: value,
            })
        );
    };

    const otherActions = (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputLabel style={{ marginRight: '8x' }}>Metric</InputLabel>
                <QuestionButtonHelp type='api' placement='right' slug='metrics-explainer' detailedExplainerSlug='ecological-trends-te' />
            </div>
            <Select options={metricOptions} onChange={onMetricChange} selectedValue={chartId} width={280} />
        </>
    );

    const mainContent = (
        <>
            <div className={styles.chartContainer}>
                <Card className={styles.insights}>
                    <CardContent>
                        <div className={styles.chartTitle}>
                            <div className={styles.titleValue}>{find(metricOptions, { value: chartId })?.label}</div>
                            {chartExplainerSlugKey[chartId] && (
                                <QuestionButtonHelp type='api' placement='right' slug={chartExplainerSlugKey[chartId]} />
                            )}
                        </div>
                        {!isEmpty(speciesRichnessInsightsData) ? (
                            <TimeSeriesReChart chartData={timeSeriesData} />
                        ) : (
                            <Typography variant='h5' component='h5'>
                                No Metrics to display
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </div>
        </>
    );

    const onHabitatAssayTypeChange = (value: string) => {
        onMetricChange('');
        setCurrentProjectHabitatAssayType(value);
    };

    const lozenges = (
        <HabitatAssayLozenges
            options={currentProjectFilters?.habitatAssayTypeOptions || []}
            selectedValue={habitatAssayType?.key || ''}
            onChange={onHabitatAssayTypeChange}
            app={AppName.ECOLOGICAL_TRENDS}
        />
    );

    return (
        <StandardAppLayout
            lozenges={lozenges}
            mainContent={mainContent}
            otherActions={otherActions}
            breadcrumbs={currentProjectBreadcrumbDetails}
            title={PAGE_TITLE}
            subTitle='Time series by sample group'
            page={AppName.ECOLOGICAL_TRENDS}
        />
    );
};

export default EcologicalTrends;
