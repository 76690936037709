import { useSearchParams } from 'react-router-dom';
import { useCustomerQuery } from 'src/app/customers/state/api/customersGraphSlice';
import useProjectSummary from 'src/shared/hooks/useProjectSummary';

const useSamplingCadence = () => {
    const [searchParams] = useSearchParams();
    const customerIdFromUrl = searchParams.get('customerId');
    const { currentData: customerData } = useCustomerQuery(
        {
            customerId: customerIdFromUrl || '',
        },
        {
            skip: !customerIdFromUrl,
        }
    );
    const { projectSummary } = useProjectSummary();
    const samplingCadence = projectSummary?.samplingCadence;

    return {
        samplingCadence,
        customerData,
    };
};

export default useSamplingCadence;
