import { combineReducers } from 'redux';

import statisticalComparisonSlice from './statistical-comparison/statisticalComparisonSlice';
import taxonomicCompositionSlice from './taxonomic-composition/taxonomicCompositionSlice';
import ecologicalTrendsSlice from './ecological-trends/EcologicalTrendsSlice';
import insightsGraphSlice from './api/insightsGraphSlice';
import communityComparisonSlice from './community-comparison/communityComparisonSlice';
import sampleComparisonSlice from './sample-comparison/sampleComparisonSlice';
import habitatInsightsEcologicalTrendsSlice from './habitat-insights-ecological-trends/habitatInsightsEcologicalTrendsSlice';

export default combineReducers({
    statisticalComparison: statisticalComparisonSlice,
    taxonomicComposition: taxonomicCompositionSlice,
    insightsGraph: insightsGraphSlice,
    ecologicalTrends: ecologicalTrendsSlice,
    communityComparison: communityComparisonSlice,
    sampleComparison: sampleComparisonSlice,
    habitatInsightsEcologicalTrends: habitatInsightsEcologicalTrendsSlice,
});
