import React from 'react';
import L from 'leaflet';

type MapKeysBlockProps = {
    hasSubmittedSamplingKits: boolean;
    hasUnsubmittedSamplingKits: boolean;
};

const MapKeysBlock = ({ hasSubmittedSamplingKits, hasUnsubmittedSamplingKits }: MapKeysBlockProps) => {
    return (
        <div
            className='flex flex-col gap-2 min-w-[200px] max-w-[250px] p-3 mb-6 text-sm text-black bg-white rounded-lg shadow-md shadow-[rgb(24, 39, 75)]'
            ref={ref => {
                if (!ref) return;
                L.DomEvent.disableClickPropagation(ref).disableScrollPropagation(ref);
            }}
        >
            <div className='mb-2'>Key</div>
            {hasSubmittedSamplingKits && (
                <div className='flex flex-col gap-2'>
                    <span className='flex items-center break-words'>
                        <i className='flex shrink-0 rounded-full w-[18px] h-[18px] mr-2 bg-[#BC4E7C]'></i>
                        Submitted Samples
                    </span>
                </div>
            )}
            {hasUnsubmittedSamplingKits && (
                <div className='flex flex-col gap-2'>
                    <span className='flex items-center break-words'>
                        <i className='flex shrink-0 rounded-full w-[18px] h-[18px] mr-2 bg-[#0FC0C7]'></i>
                        Remaining Samples
                    </span>
                </div>
            )}
        </div>
    );
};

export default MapKeysBlock;
