import { gql } from 'graphql-request';
import { ChartIds, MetricsChartData } from 'src/shared/types';
export const evolutionaryMetricsQuery = gql`
    query evolutionaryMetricsData($projectId: String!, $fromDate: String, $toDate: String, $habitatAssay: String!) {
        evolutionaryMetrics: chart(
            projectId: $projectId, 
            chartId: "${ChartIds.EVOLUTIONARY_DIVERSITY}", 
            habitatAssay: $habitatAssay,
            fromDate: $fromDate, 
            toDate: $toDate
        ) {
            data
        }
    }
`;

export type EvolutionaryMetricsQueryResult = {
    evolutionaryMetrics: MetricsChartData;
};
