import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import useProject, { SamplingEventFilterData } from 'src/shared/hooks/useProject';
import useIsViewed from 'src/shared/hooks/useIsViewed';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import useRefWithRerender from 'src/shared/hooks/useRefWithRerender';
import useFeatures from 'src/shared/hooks/useFeatures';

import { useAppDispatch, useAppSelector } from 'src/store';
import { useSpeciesMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { getCurrentDashboardUIState } from 'src/app/dashboard/state/dashboardGeneralSelectors';
import { updateIucnMetricUiState } from 'src/app/dashboard/state/dashboardGeneralSlice';

import IucnTable from 'src/app/dashboard/components/metrics/metrics-table/components/IucnTable';
import {
    filterByRedListStatus,
    filterByGRIISList,
    filterByCHEGDStatus,
} from 'src/app/dashboard/components/metrics/metrics-table/metricsTableHelpers';
import useDashboardAnalytics from 'src/app/dashboard/hooks/useDashboardAnalytics';

import SamplingEventFilter from 'src/shared/components/filters/sampling-event-filter/SamplingEventFilter';
import styles from './MetricsTable.module.scss';
import ChegdTable from './components/ChegdTable';

const MetricsTable = () => {
    const {
        currentProjectId,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        isSingleTimepoint,
        samplingEventFilterData,
    } = useProject();
    const uiState = useAppSelector(getCurrentDashboardUIState);
    const dispatch = useAppDispatch();
    const [ref, callbackRef] = useRefWithRerender<HTMLDivElement>(null);
    const isViewed = useIsViewed(ref);
    const { trackIUCNTableViewed } = useDashboardAnalytics();
    const { hasIucnRedListFeature, hasInvasivesFeature, hasChegdFeature } = useFeatures();

    const hideDateFilter = isSingleTimepoint;

    const {
        iucnMetric: { samplingEvent },
    } = uiState;

    const { currentData, isFetching } = useSpeciesMetricsQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                fromDate: samplingEvent?.fromDate,
                toDate: samplingEvent?.toDate,
                habitatAssay: habitatAssayType?.key || '',
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType || !samplingEvent,
        }
    );

    useEffect(() => {
        if (isViewed) {
            trackIUCNTableViewed();
        }
    }, [isViewed]);

    useEffect(() => {
        if (!samplingEventFilterData || samplingEventFilterData.length === 0) {
            return;
        }

        if (!samplingEvent) {
            onTimePointChange(samplingEventFilterData[0]);
        }
    }, [samplingEventFilterData]);

    const onTimePointChange = (samplingEventValue: SamplingEventFilterData) => {
        dispatch(updateIucnMetricUiState({ samplingEvent: samplingEventValue }));
    };

    if (!currentData || isFetching) {
        return <LinearLoader />;
    }

    const { speciesMetrics: speciesMetricsData } = currentData;
    const { taxonomySummary } = speciesMetricsData;

    const iucnRedLists = filterByRedListStatus(taxonomySummary);
    const invasiveLists = filterByGRIISList(taxonomySummary);
    const chegdSpeciesList = filterByCHEGDStatus(taxonomySummary);

    const header = (
        <div>
            <div className={styles.filter} ref={callbackRef}>
                <div className={styles.title}>Results in this table are only displayed for one quarter</div>
                <div>|</div>
                <div className={styles.filter}>
                    <InputLabel className={styles.filterLabel}>Display detections in</InputLabel>
                    <div className={styles.filterSelect}>
                        <SamplingEventFilter
                            selectedSamplingEvent={samplingEvent}
                            samplingEventFilterData={samplingEventFilterData}
                            onChange={value => onTimePointChange(value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.iucnMetrics}>
            {!hideDateFilter && header}
            <div>
                {hasIucnRedListFeature && (
                    <IucnTable data={[...iucnRedLists]} title='IUCN Red List species' type='redLists' helpArticleSlug='iucn-status' />
                )}
                {hasChegdFeature && <ChegdTable data={chegdSpeciesList} />}
                {!hasChegdFeature && hasInvasivesFeature && (
                    <IucnTable data={[...invasiveLists]} title='Invasive species' type='invasiveLists' helpArticleSlug='invasive-species' />
                )}
            </div>
        </div>
    );
};

export default MetricsTable;
