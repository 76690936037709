import moment from 'moment';
import { SelectOption } from '../components/select/Select';

export const getQuarterDates = (startDate: string, endDate: string) => {
    const quarterStartDates = [];
    const quarterEndDates = [];
    const startMDate = moment(startDate);
    const endMDate = moment(endDate);
    const endOfQuarter = Math.floor((endMDate.month() + 3) / 3);
    const endOfQuarterDate = endMDate.clone().quarter(endOfQuarter).endOf('quarter');

    for (let date = startMDate.clone().startOf('quarter'); date.isBefore(endMDate); date.add(3, 'months').startOf('quarter')) {
        quarterStartDates.push(date.valueOf());
    }

    for (let date = startMDate.clone().endOf('quarter'); date.isSameOrBefore(endOfQuarterDate); date.add(3, 'months').endOf('quarter')) {
        quarterEndDates.push(date.valueOf());
    }

    const startDateOptions = quarterStartDates.reverse().map(date => ({
        label: 'Q' + moment(date).format('Q YYYY'),
        value: String(date),
    }));
    const endDateOptions = quarterEndDates.reverse().map(date => ({
        label: 'Q' + moment(date).format('Q YYYY'),
        value: String(date),
    }));

    return {
        startDateOptions,
        endDateOptions,
    };
};

export const getQuarterDateRange = (quarter: string | null): { quarterStart: string; quarterEnd: string } | null => {
    if (!quarter) {
        return null;
    }

    let quarterMoment;
    if (quarter.includes('-')) {
        quarterMoment = moment(quarter);
    } else {
        quarterMoment = moment(Number(quarter));
    }

    const quarterStart = quarterMoment.format('YYYY-MM-DD');
    const quarterEnd = quarterMoment.endOf('quarter').format('YYYY-MM-DD');
    return { quarterStart, quarterEnd };
};

export const getDateQuarterSelectOption = (dateTime: number, useEndDate = false): SelectOption => {
    const momentDate = moment(dateTime);

    return {
        label: 'Q' + momentDate.format('Q YYYY'),
        value: (useEndDate ? momentDate.endOf('quarter') : momentDate.startOf('quarter')).format('YYYY-MM-DD'),
    };
};
