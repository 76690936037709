export enum Role {
    ADMIN = 'ADMIN',
    CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
    PROJECT_VIEWER = 'PROJECT_VIEWER',
    NM_CUSTOMER_MANAGER = 'NM_CUSTOMER_MANAGER',
    NM_PROJECT_ADMIN = 'NM_PROJECT_ADMIN',
    PROJECT_ADMIN = 'PROJECT_ADMIN',
}

export type AssociatedCustomer = {
    customerId: string;
    roles: Role[] | null;
    active: boolean;
    updatedAt: string;
};

export type UserProjectAccess = {
    projectId: string;
    roles: Role[] | null;
};

export type User = {
    id: number;
    cognitoId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    phoneCountryCode: string;
    customers: AssociatedCustomer[];
    projects: UserProjectAccess[] | null;
    tnc_consent: boolean;
    is_internal_user: boolean;
    status: string;
};

export type CreateUserInput = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    projects: UserProjectAccess[];
    customers: AssociatedCustomer[];
    tnc_consent: boolean;
    isNatureMetricsUser: boolean;
};

export type UpdateUserInput = Omit<CreateUserInput, 'email' | 'isNatureMetricsUser'>;

export enum Access {
    VIEW = 'view',
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
}

export type ResourceAccess = {
    [Access.VIEW]: string[];
    [Access.CREATE]: string[];
    [Access.UPDATE]: string[];
    [Access.DELETE]: string[];
};

export type CustomerLevelPermissions = {
    customerId: string;
    roles: Role[];
    permissions: ResourceAccess;
};

export type ProjectLevelPermissions = {
    projectId: string;
    roles: Role[];
    permissions: ResourceAccess;
};

export type UserPermissions = {
    customerLevelPermissions: CustomerLevelPermissions[];
    projectLevelPermission: ProjectLevelPermissions[];
    tnc_consent: boolean;
    is_internal_user: boolean;
};
