import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { SamplingEventFilterData } from 'src/shared/hooks/useProject';
import { RootState } from 'src/store';

export type SamplesMapFiltersState = {
    habitatAssayTypes: string[];
    areas: string[];
    metric: string | null;
    metricLabel: string | null;
    speciesOfInterest: string;
    speciesHighlight: string | null;
    surveyPoints: string;
    samplingEvent: SamplingEventFilterData | null;
};

const defaultFiltersState = {
    habitatAssayTypes: [],
    areas: [],
    metric: 'none',
    metricLabel: '',
    speciesOfInterest: 'all',
    speciesHighlight: null,
    surveyPoints: 'hide',
    samplingEvent: null,
};

export type SamplesMapUIState = {
    filters: SamplesMapFiltersState;
    isSidebarOpen: boolean;
    activeSampleId: string | null;
    activeSurveySampleId: string | null;
    hoveredSampleId: string | null;
    hoveredSurveySampleId: string | null;
    focusedSpeciesScientificName: string | null;
    mapType: string;
};

export const defaultUiState = {
    filters: { ...defaultFiltersState },
    isSidebarOpen: true,
    activeSampleId: null,
    activeSurveySampleId: null,
    hoveredSampleId: null,
    hoveredSurveySampleId: null,
    focusedSpeciesScientificName: null,
    mapType: 'roadmap',
};

type SamplesState = {
    uiState: {
        [projectId: string]: SamplesMapUIState;
    };
};
const initialState: SamplesState = {
    uiState: {},
};

export const updateFilters =
    (fragment: Partial<SamplesMapFiltersState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;

        if (projectId) {
            dispatch(
                samplesMapSlice.actions.updateFilters({
                    projectId,
                    fragment,
                })
            );
        }
    };

export const updateUiState =
    (fragment: Partial<SamplesMapUIState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;

        if (projectId) {
            dispatch(
                samplesMapSlice.actions.updateUIState({
                    projectId,
                    fragment,
                })
            );
        }
    };

export const resetFilters = (): ThunkAction<void, RootState, void, Action<string>> => (dispatch, getState) => {
    const state = getState();

    const projectId = state.projects.projectList.currentProjectId;

    if (projectId) {
        dispatch(
            samplesMapSlice.actions.resetFilters({
                projectId,
            })
        );
    }
};

const ensureProjectUIStatePresence = (state: SamplesState, projectId: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = { ...defaultUiState };
    }
};

const samplesMapSlice = createSlice({
    name: 'samples',
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<{ projectId: string; fragment: Partial<SamplesMapFiltersState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);

            state.uiState[projectId].filters = {
                ...(state.uiState[projectId].filters as SamplesMapFiltersState),
                ...fragment,
            };

            state.uiState[projectId].hoveredSampleId = null;
            state.uiState[projectId].activeSampleId = null;
        },

        updateUIState(state, action: PayloadAction<{ projectId: string; fragment: Partial<SamplesMapUIState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);

            state.uiState[projectId] = {
                ...(state.uiState[projectId] as SamplesMapUIState),
                ...fragment,
            };
        },
        resetFilters(state, action: PayloadAction<{ projectId: string }>) {
            const { projectId } = action.payload;
            state.uiState[projectId].filters = { ...defaultFiltersState };
        },
    },
});

export default samplesMapSlice.reducer;
