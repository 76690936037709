import React from 'react';
import { ReactComponent as CopyIcon } from 'src/assets/svg/general/copy.svg';
import { SecondaryButton } from '../button/Button';

type CopyToClipboardProps = {
    text: string;
};

const CopyToClipboard = (props: CopyToClipboardProps) => {
    const { text } = props;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
    };

    return (
        <SecondaryButton onClick={copyToClipboard}>
            <CopyIcon className='fill-primary' />
        </SecondaryButton>
    );
};

export default CopyToClipboard;
