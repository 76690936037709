import { CMSHabitatAssayTypeWithoutImage } from '../cms';

export enum ProjectSampleManifestStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS_NO_DATA_SUBMITTED = 'IN_PROGRESS_NO_DATA_SUBMITTED',
    IN_PROGRESS_MORE_SAMPLES_REQUIRED = 'IN_PROGRESS_MORE_SAMPLES_REQUIRED',
    IN_PROGRESS_ALL_SAMPLES_SUBMITTED = 'IN_PROGRESS_ALL_SAMPLES_SUBMITTED',
    COMPLETED_ALL_EVENTS_COMPLETE = 'COMPLETED_ALL_EVENTS_COMPLETE',
    COMPLETED_FUTURE_EVENT_PLANNED = 'COMPLETED_FUTURE_EVENT_PLANNED',
    COMPLETED_NO_FUTURE_EVENT_PLANNED = 'COMPLETED_NO_FUTURE_EVENT_PLANNED',
}

export enum SamplingEventStatus {
    READY_TO_SAMPLE = 'READY_TO_SAMPLE',
    SAMPLING_IN_PROGRESS = 'SAMPLING_IN_PROGRESS',
    SUBMISSION_IN_PROGRESS = 'SUBMISSION_IN_PROGRESS',
    SUBMITTED = 'SUBMITTED',
    COMPLETED = 'COMPLETED',
    ACCEPTED = 'ACCEPTED',
}

export type SamplingEventBatch = {
    id: string;
    kits: string[];
    status: SamplingEventBatchStatus;
    createdAt: string;
    logisticsApprovalAt: string;
    labApprovalAt: string;
    amendmentMessage: string;
};

export type SamplingEvent = {
    identifier: string;
    name: string;
    fromDate: string;
    toDate: string;
    year: number;
    processed: boolean;
    processedDate: string;
    status: SamplingEventStatus;
    sampleManifestInfo: SampleManifestInfo;
};

export enum SamplingKitStatus {
    READY_TO_SAMPLE = 'READY_TO_SAMPLE',
    SAMPLING_IN_PROGRESS = 'SAMPLING_IN_PROGRESS',
    SUBMITTED = 'SUBMITTED',
    APPROVED_BY_LOGISTICS = 'APPROVED_BY_LOGISTICS',
    APPROVED_BY_LAB = 'APPROVED_BY_LAB',
    FLAGGED = 'FLAGGED',
    REJECTED_BY_LAB = 'REJECTED_BY_LAB',
}

export type SamplingKit = {
    id: string;
    name: string;
    barcode: string;
    latitude: string;
    longitude: string;
    sampler: string;
    sampledAt: string;
    sampledTime: string;
    habitat: string;
    notes: string;
    volumeFiltered: number | null;
    combinedVolumeFiltered: number | null;
    noOfSubSamples: number | null;
    preservationMethod: string;
    collectionMethod: string;
    samplingDuration: number | null;
    testTypes: CMSHabitatAssayTypeWithoutImage[];
    status: SamplingKitStatus;
};

export enum SamplingEventBatchStatus {
    DEFAULT = 'DEFAULT',
    CREATED = 'CREATED',
    APPROVED_BY_LOGISTICS = 'APPROVED_BY_LOGISTICS',
    APPROVED_BY_LAB = 'APPROVED_BY_LAB',
    REQUIRED_CUSTOMER_AMENDMENT = 'REQUIRED_CUSTOMER_AMENDMENT',
    CUSTOMER_AMENDMENT_REQUESTED = 'CUSTOMER_AMENDMENT_REQUESTED',
}

export enum SamplingEventBatchAmendmentOwner {
    LAB = 'LAB',
    LOGISTICS = 'LOGISTICS',
}

export enum FieldDataStatus {
    NONE = 'None',
    INCOMPLETE = 'Incomplete',
    COMPLETE = 'Complete',
}

export type EstimatedSampleCountForTestType = {
    type: CMSHabitatAssayTypeWithoutImage;
    count: number;
};

export type SampleManifestSampleCountInfo = {
    kitsCount: number;
    countByTestTypes: EstimatedSampleCountForTestType[];
};
export type SampleManifestInfo = {
    kits: SamplingKit[];
    kitsSentAt: string;
    batches: SamplingEventBatch[];
    countInfo: SampleManifestSampleCountInfo;
};
