import React from 'react';

import useDataTable from 'src/shared/components/data-table/hooks/useDataTable';
import styles from 'src/shared/components/data-table/DataTable.module.scss';
import TableRow from '../table-row/TableRow';

const TableBody = () => {
    const { getSortedCurrentPageRows, filteredRows, emptyStateMessage, columns } = useDataTable();

    if (!filteredRows.length && emptyStateMessage) {
        return (
            <tbody>
                <tr className={styles.row}>
                    <td colSpan={columns.length}>{emptyStateMessage}</td>
                </tr>
            </tbody>
        );
    }

    // Filter the rows that need to be displayed on the current page
    const rowsThisPage = getSortedCurrentPageRows();

    return (
        <tbody data-testid='table-body'>
            {rowsThisPage.map((row, index) => {
                const { rowId, cells } = row;

                return <TableRow key={index} rowData={cells || []} rowId={rowId as string} />;
            })}
        </tbody>
    );
};

export default TableBody;
