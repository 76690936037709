import React from 'react';
import { useCommunityComparisonChartDataQuery, useCommunityComparisonSamplesQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import { getCurrentCommunityComparisonUIState } from 'src/app/insights/state/community-comparison/communityComparisonSelector';
import Loader from 'src/shared/components/loader/Loader';
import useProject from 'src/shared/hooks/useProject';
import { useAppSelector } from 'src/store';
import PercentageBar from '../../percentage-bar/PercentageBar';

import styles from '../CommunityComparisonAside.module.scss';

const AllSampleGroupComparison = () => {
    const uiState = useAppSelector(getCurrentCommunityComparisonUIState);

    const { currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();

    const {
        filters: { samplingYear },
    } = uiState;

    const { currentData: chartData, isFetching: isFetchingChartData } = useCommunityComparisonChartDataQuery(
        {
            projectId: currentProjectId || '',
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    const { currentData: allSamplesData, isFetching: isFetchingOtherSamples } = useCommunityComparisonSamplesQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                habitatAssay: [habitatAssayType?.key || ''],
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (isFetchingOtherSamples || isFetchingChartData) {
        return <Loader />;
    }

    if (!chartData || !allSamplesData) {
        return null;
    }

    const selectedYearData = chartData.communityComparison.data[0].years[Number(samplingYear)].data;

    const sampleGroups = selectedYearData.map(entry => entry.variable.value);

    const taxonomyFromAllSamples = allSamplesData.communityComparisonSamples.taxonomySummary.filter(entry => entry.taxonomy.tax_species);

    const allSamples = allSamplesData.communityComparisonSamples.samples;

    const sampleIdGroupMap: { [key: string]: string } = {};

    allSamples.forEach(sample => {
        sampleIdGroupMap[sample.sampleId] = sample.covariates.find(entry => entry.covariateKey === 'site')?.covariateValue || '';
    });

    let speciesFoundInAllSampleGroups = 0;

    taxonomyFromAllSamples.forEach(taxonomy => {
        const { samples } = taxonomy;

        const isSpecisPresentInAllGroups = sampleGroups.every(group => {
            return samples.some(sampleId => sampleIdGroupMap[sampleId] === group);
        });

        if (isSpecisPresentInAllGroups) {
            speciesFoundInAllSampleGroups++;
        }
    });

    const totalSpecies = taxonomyFromAllSamples.length;

    let percentageDifference = Math.floor((speciesFoundInAllSampleGroups * 100) / totalSpecies);
    if (isNaN(percentageDifference)) {
        percentageDifference = 0;
    }

    return (
        <div className={styles.sampleGroupStats}>
            <span className={styles.textAboveBar}>How many species were detected in every sample group?</span>
            <span className={styles.percentageBar}>
                <PercentageBar score={percentageDifference} />
            </span>
            <span className={styles.textBelowBar}>
                {speciesFoundInAllSampleGroups} of {totalSpecies} species detected appear in every sample group
            </span>
        </div>
    );
};

export default AllSampleGroupComparison;
