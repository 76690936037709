import { RootState } from 'src/store';
import { EcologicalTrendsUIState, EcologicalTrendsFiltersState, defaultUiState } from './EcologicalTrendsSlice';

export const getCurrentEcologicalTrendsUIState = (state: RootState): EcologicalTrendsUIState => {
    const currentProjectId = state.projects.projectList?.currentProjectId;

    if (!currentProjectId) {
        return defaultUiState;
    }

    return state.insights.ecologicalTrends.uiState[currentProjectId] || defaultUiState;
};

export const getEcologicalTrendsFilters = (state: RootState): EcologicalTrendsFiltersState => {
    const currentUiState = getCurrentEcologicalTrendsUIState(state);

    return currentUiState.filters;
};
