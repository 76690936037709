import React from 'react';
import ChevronRight from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';

type BackNextNavigationProps = {
    onBack: () => void;
    onNext: () => void;
    disableNext?: boolean;
    disableBack?: boolean;
    hideNext?: boolean;
    hideBack?: boolean;
};

const BackNextNavigation = (props: BackNextNavigationProps) => {
    const { onBack, onNext, disableNext, disableBack, hideBack, hideNext } = props;

    return (
        <div className='flex gap-2'>
            {!hideBack && (
                <SecondaryButton onClick={onBack} disabled={disableBack}>
                    <ChevronLeft /> Back
                </SecondaryButton>
            )}

            {!hideNext && (
                <PrimaryButton onClick={onNext} disabled={disableNext}>
                    Next <ChevronRight />
                </PrimaryButton>
            )}
        </div>
    );
};

export default BackNextNavigation;
