import React from 'react';

import styles from 'src/app/surveys/views/new-survey-design/NewSurveyDesign.module.scss';
import GeneralSurveyDesignAims from './components/general-survey-design-aims/GeneralSurveyDesignAims';
import SpeciesOfInterest from './components/species-of-interest/SpeciesOfInterest';

import SampleGroups from './components/sample-groups/SampleGroups';
import HabitatAssayTypes from './components/habitat-assay-types/HabitatAssayTypes';

export const SurveyDesignAims = () => {
    return (
        <div className={styles.formView}>
            <div className={styles.formViewTitle}>Project target</div>
            <GeneralSurveyDesignAims />
            <SampleGroups />
            <SpeciesOfInterest />
            <HabitatAssayTypes />
        </div>
    );
};

export default SurveyDesignAims;
