import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Chip.module.scss';

export type ChipProps = {
    label: ReactNode;
    backgroundColor?: string;
    labelColor?: string;
    className?: string;
};

const Chip = (props: ChipProps) => {
    const { label, backgroundColor, labelColor, className } = props;

    const chipClassName = classNames(styles.chip, className);
    return (
        <div
            className={chipClassName}
            style={{ '--chip-background-color': backgroundColor, '--chip-text-color': labelColor } as CSSProperties}
        >
            {label}
        </div>
    );
};

export default Chip;
