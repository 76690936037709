import { CustomDimensionsOptions } from 'src/shared/helpers/analyticsHelper';
import loadGoogleAnalytics from './loadGoogleAnalytics';

type TrackPageView = {
    page_title?: string;
    page_location?: string;
    page_path: string;
};

export type TrackEventParams = CustomDimensionsOptions & {
    event_action: string;
    event_category: string;
    event_label?: string;
    non_interaction?: boolean;
    value?: number;
};

export enum TrafficUserType {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
    NOT_SET = 'not set',
}

export type GtagConfig = {
    traffic_user_type: TrafficUserType;
    customer_name: string;
    project: string;
    debug_mode?: boolean;
};

export const defaultGtagConfig: GtagConfig = { traffic_user_type: TrafficUserType.NOT_SET, customer_name: 'not set', project: 'not set' };

/**
 * The interface of the GoogleAnalytics class below
 * replicates the interface of the ReactGA library
 */
class GoogleAnalytics {
    static initialize(googleAnalyticsKey: string) {
        loadGoogleAnalytics(googleAnalyticsKey);
    }

    // reference: example from Google's docs for gtag.js
    // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    static pageview(params: TrackPageView) {
        window.gtag('event', 'page_view', params);
    }

    // reference: Measure Google Analytics Events gtag.js
    // https://developers.google.com/analytics/devguides/collection/gtagjs/events
    static event(eventParams: TrackEventParams) {
        window.gtag('event', eventParams.event_action, eventParams);
    }

    static updateConfig(googleAnalyticsKey: string, newConfig: GtagConfig) {
        window.gtag('config', googleAnalyticsKey, newConfig);
    }
}

export default GoogleAnalytics;
