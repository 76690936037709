import { RootState } from 'src/store';
import { DashboardUIState, defaultUiState } from './dashboardGeneralSlice';

export const getCurrentDashboardUIState = (state: RootState): DashboardUIState => {
    const currentProjectId = state.projects.projectList?.currentProjectId;

    if (!currentProjectId) {
        return defaultUiState;
    }
    return state.dashboard.general.uiState[currentProjectId] || defaultUiState;
};
