import { KitHabitatTestTypesMapping } from 'src/services/cms-service';
import storageService, { StorageServiceInterface, StorageType } from 'src/services/storage-service';

export enum StorageKeys {
    KIT_TEST_TYPES_MAPPINGS = 'samples.kitTestTypesMapping',
}

const localStorageOptions = {
    storage: StorageType.LOCAL_STORAGE,
};

export class SamplesStorageService {
    private storageService: StorageServiceInterface;

    public constructor(storageService: StorageServiceInterface) {
        this.storageService = storageService;
    }

    public getKitTestTypesMapping(): KitHabitatTestTypesMapping[] {
        return this.storageService.get(StorageKeys.KIT_TEST_TYPES_MAPPINGS, localStorageOptions) || [];
    }

    public setKitTestTypesMapping(kitTestTypesMapping: KitHabitatTestTypesMapping[]): void {
        this.storageService.remove(StorageKeys.KIT_TEST_TYPES_MAPPINGS);
        this.storageService.save(StorageKeys.KIT_TEST_TYPES_MAPPINGS, kitTestTypesMapping, localStorageOptions);
    }

    public clearAll(): void {
        this.storageService.remove(StorageKeys.KIT_TEST_TYPES_MAPPINGS, localStorageOptions);
    }
}

export default new SamplesStorageService(storageService);
