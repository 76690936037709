export const downloadTextAsFile = async (content: string | string[], fileName: string, options?: BlobPropertyBag) => {
    if (typeof content === 'string') {
        content = [content];
    }
    const blob = content instanceof Blob ? content : new Blob(content, options);

    await downloadBlobAsFile(blob, fileName);
};

// should accept Blob or File objects generated elsewhere in the code
export const downloadBlobAsFile = async (blob: Blob, fileName: string) => {
    const blobUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = fileName;
    downloadLink.click();

    const cleanupPromise = new Promise(resolve => {
        setTimeout(() => {
            URL.revokeObjectURL(blobUrl);
            resolve(null);
        }, 100);
    });

    await cleanupPromise;
};

export const downloadFileFromLink = async (url: string, filename: string) => {
    let blobContent;

    try {
        const blobContentFetch = await fetch(url);
        blobContent = await blobContentFetch.blob();
    } catch (error) {
        console.error('File content not available for download.', error);
        return;
    }

    if (blobContent) await downloadBlobAsFile(blobContent, filename);
};
