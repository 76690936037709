import React, { useEffect } from 'react';
import useProject from 'src/shared/hooks/useProject';
import useFeatures from 'src/shared/hooks/useFeatures';
import fishMetricsImageUrl from 'src/assets/images/dashboard/fish_background.webp';
import FishMetricsChart from './components/fish-metrics-chart/FishMetricsChart';
import FishMetricsSingleChart from './components/firsh-metric-single-chart/FishMetricsSingleChart';
import styles from './FishMetrics.module.scss';
import { useFishMetricsQuery } from '../../../state/metrics/metricsGraphSlice';

const FishMetrics = ({ isSingleTimepoint }: { isSingleTimepoint: boolean }) => {
    const { currentProjectSelectedDateRange, currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();

    const { hasFishMetricsFeature } = useFeatures();

    const { currentData: fishMetrics, isFetching } = useFishMetricsQuery(
        {
            projectId: currentProjectId || '',
            fromDate: currentProjectSelectedDateRange?.startDate,
            toDate: currentProjectSelectedDateRange?.endDate,
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !hasFishMetricsFeature,
        }
    );

    useEffect(() => {
        if (isFetching) {
            return;
        }
    }, [isFetching]);

    if (isFetching || !fishMetrics || !Object.values(fishMetrics).every(Boolean)) {
        return null;
    }

    const backgroundImageStyle = {
        backgroundImage: `url(${fishMetricsImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };

    return isSingleTimepoint ? (
        <FishMetricsSingleChart data={fishMetrics} />
    ) : (
        <div className={styles.fishMetric}>
            <div className={styles.backDrop} style={backgroundImageStyle}>
                <div style={backgroundImageStyle} />
            </div>

            <div className={styles.chart}>
                <FishMetricsChart data={fishMetrics} />
            </div>
        </div>
    );
};

export default FishMetrics;
