import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ContactSupportOutlined, AccountCircleOutlined } from '@mui/icons-material';

import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { ReactComponent as ContactIcon } from 'src/assets/svg/contact-icon.svg';
import Modal from 'src/shared/components/modal/Modal';

import { SecondaryButton } from 'src/shared/components/button/Button';
import config from 'src/config';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';
import authService from 'src/services/auth-service';
import {
    useGetCustomerAdminUsersQuery,
    useHasCustomerAdminUserQuery,
} from 'src/app/admin/views/user-management/state/api/userManagementGraphSlice';
import useSupportAnalytics from 'src/global/hooks/useSupportAnalytics';
import SupportMenuContactForm from './SupportMenuContactForm';
import styles from './SupportMenu.module.scss';
import siteMenuStyles from '../SiteMenu.module.scss';

const SupportMenu = () => {
    const isSiteMenuOpen = useAppSelector(getIsSiteMenuOpen);
    const [isSupportModalOpen, setIsSupportModalOpen] = React.useState(false);

    const getUserMenuClassNames = () => {
        return classNames(siteMenuStyles.menuItem, siteMenuStyles.userMenu, {
            [siteMenuStyles.menuItemOpen]: isSiteMenuOpen,
        });
    };

    const onClickHandler = () => {
        if (authService.getCurrentUserData()?.isAdmin) {
            window.open(config.supportUrl);
        } else {
            setIsSupportModalOpen(true);
        }
    };

    return (
        <>
            <div className={getUserMenuClassNames()} onClick={onClickHandler}>
                <div className={styles.contactIcon}>
                    <ContactIcon />
                </div>
                <div className={styles.contactLabel}>Support</div>
            </div>
            {isSupportModalOpen && (
                <Modal onClose={() => setIsSupportModalOpen(false)}>
                    <SupportModal />
                </Modal>
            )}
        </>
    );
};

const SupportModal = () => {
    const [shouldShowOrgAdminContactForm, setShouldShowOrgAdminContactForm] = React.useState(false);
    const { currentCustomerId } = useCustomer();
    const { trackSupportType } = useSupportAnalytics();

    const openNMSupport = () => {
        trackSupportType('NatureMetrics customer support');
        window.open(config.supportUrl);
    };

    const openAdminContactForm = () => {
        trackSupportType('My organisation`s administrator');
        setShouldShowOrgAdminContactForm(true);
    };

    const { currentData, isFetching } = useGetCustomerAdminUsersQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !currentCustomerId,
        }
    );

    const { currentData: hasCustomerAdminData, isFetching: isFetchingCustomerAdminPresence } = useHasCustomerAdminUserQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !currentCustomerId,
        }
    );
    const hasCustomerAdmin = hasCustomerAdminData?.hasCustomerAdmin || false;

    const { customerAdmins = [] } = currentData || {};

    const isCurrentUserCustomerAdmin = customerAdmins.some(admin => admin.cognitoId === authService.getCurrentUserData()?.cognitoId);

    if (shouldShowOrgAdminContactForm) {
        return <SupportMenuContactForm />;
    }

    const canContactOrgAdmin = Boolean(currentCustomerId) && !isCurrentUserCustomerAdmin && hasCustomerAdmin;

    return (
        <div className={styles.supportModal}>
            <div className={styles.header}>
                <HelpOutlineIcon />
                <div className={styles.title}>Support</div>
            </div>
            <div className={styles.content}>
                <div className={styles.subTitle}>I want to contact</div>
                <div className={styles.contactOptions}>
                    <SecondaryButton onClick={openNMSupport}>
                        NatureMetrics customer support <ContactSupportOutlined />
                    </SecondaryButton>
                    <SecondaryButton disabled={!canContactOrgAdmin || isFetching} onClick={openAdminContactForm}>
                        My organisation&apos;s administrator <AccountCircleOutlined />
                    </SecondaryButton>
                </div>
            </div>
            {!Boolean(currentCustomerId) && (
                <InfoMessage message='To contact your organisation’s administrator for support, please select an organisation' />
            )}
            {isCurrentUserCustomerAdmin && (
                <InfoMessage
                    message={
                        <>
                            You are the administor for this organisation.
                            <br />
                            For support requests, please contact NatureMetrics customer support
                        </>
                    }
                />
            )}
            {!isFetchingCustomerAdminPresence && currentCustomerId && !hasCustomerAdmin && (
                <InfoMessage
                    message={
                        <>
                            An administrator for your organisation has not been registered yet.
                            <br />
                            For support requests, please contact NatureMetrics customer support
                        </>
                    }
                />
            )}
        </div>
    );
};

export default SupportMenu;
