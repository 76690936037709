import React from 'react';
import { useSpeciesRichnessMetricsQuery } from 'src/app/dashboard/state/metrics/metricsGraphSlice';
import { LinearLoader } from 'src/shared/components/loader/Loader';
import useProject from 'src/shared/hooks/useProject';
import speciesImageUrl from 'src/assets/images/dashboard/species.svg?url';
import TimeseriesCardTemplate from '../components/TimeseriesCardTemplate';
import styles from '../Metrics.module.scss';

const SpeciesRichness = () => {
    const chartTitle = 'Species Richness';
    const {
        currentProjectSelectedDateRange,
        currentProjectId,
        currentProjectSelectedHabitatAssayType: habitatAssayType,
        isSingleTimepoint,
    } = useProject();

    const { currentData, isFetching } = useSpeciesRichnessMetricsQuery(
        {
            projectId: currentProjectId || '',
            fromDate: currentProjectSelectedDateRange?.startDate,
            toDate: currentProjectSelectedDateRange?.endDate,
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    if (isFetching) {
        return (
            <>
                <LinearLoader />
            </>
        );
    }

    if (!currentData) {
        return null;
    }

    const content = (
        <TimeseriesCardTemplate
            title={chartTitle}
            titleHelpSlug='species-richness'
            averageValueDescription='Average per sample [endYearWithQuarter]'
            metricsChartData={currentData.speciesRichness}
            metricCardImageUrl={speciesImageUrl}
            metricCardContent='average species per sample'
        />
    );

    if (isSingleTimepoint) {
        return content;
    }

    return <div className={styles.timeseriesCard}>{content}</div>;
};

export default SpeciesRichness;
