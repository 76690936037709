import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const labelStyle = {
    fill: 'white',
    fontSize: '13px',
    padding: '2px 6px',
};

const CustomLabel = (props: any) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const textRef = useRef<SVGTextElement>(null);
    const rectRef = useRef<SVGRectElement>(null);

    useEffect(() => {
        if (!svgRef.current || !textRef.current || !rectRef.current) {
            return;
        }

        const textElement = d3.select(textRef.current);
        if (!textElement.node()) {
            return;
        }
        const rectElement = d3.select(rectRef.current);

        const textBBox = textElement?.node()?.getBBox();
        const rectWidth = textBBox ? textBBox.width + 10 : 0;
        const rectHeight = textBBox ? textBBox.height + 10 : 0;

        rectElement.attr('width', rectWidth).attr('height', rectHeight);
    }, [props]);

    return (
        <svg ref={svgRef}>
            <rect ref={rectRef} x={0} y={props.viewBox.y - 15} fill='#aaa' rx={10} ry={10} role='rect' />
            <text ref={textRef} x={5} y={props.viewBox.y} style={labelStyle} dominantBaseline='middle'>
                {props.label}
            </text>
        </svg>
    );
};

export default CustomLabel;
