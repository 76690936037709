import React from 'react';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { SamplingEventBatchStatus } from 'src/shared/types';
import Chip from '../chip/Chip';

type SamplingEventBatchStatusIndicatorProps = {
    status: SamplingEventBatchStatus;
};

const statusConfig = {
    [SamplingEventBatchStatus.DEFAULT]: {
        title: 'Unsubmitted',
        helpSlug: 'sample-reception-batch-status-unsubmitted',
        backgroundColor: '#E6E6E6',
        textColor: '#212121',
    },
    [SamplingEventBatchStatus.CREATED]: {
        title: 'Submitted, Pending NM approval',
        helpSlug: 'sample-reception-batch-status-submitted',
        backgroundColor: '#FFEED3',
        textColor: '#CF8515',
    },
    [SamplingEventBatchStatus.REQUIRED_CUSTOMER_AMENDMENT]: {
        title: 'Submitted, Pending customer amendment',
        helpSlug: 'sample-reception-batch-status-amendment',
        backgroundColor: '#FFEED3',
        textColor: '#CF8515',
    },
    [SamplingEventBatchStatus.CUSTOMER_AMENDMENT_REQUESTED]: {
        title: 'Submitted, amendment requested',
        helpSlug: 'sample-reception-batch-status-amendment',
        backgroundColor: '#FFEED3',
        textColor: '#CF8515',
    },
    [SamplingEventBatchStatus.APPROVED_BY_LOGISTICS]: {
        title: 'Pending NM approval',
        helpSlug: 'sample-reception-batch-status-logistics',
        backgroundColor: '#FFEED3',
        textColor: '#CF8515',
    },
    [SamplingEventBatchStatus.APPROVED_BY_LAB]: {
        title: 'Submitted & Accepted',
        helpSlug: 'sample-reception-batch-status-lab',
        backgroundColor: '#DDFFD4',
        textColor: '#2EAD0E',
    },
};

const SamplingEventBatchStatusIndicator = (props: SamplingEventBatchStatusIndicatorProps) => {
    const { status } = props;
    const config = statusConfig[status];

    const content = (
        <div className='flex items-center gap-1'>
            <span className='text-sm'>{config?.title}</span>
            <QuestionButtonHelp slug={config?.helpSlug} type='api' />
        </div>
    );

    return <Chip label={content} backgroundColor={config?.backgroundColor} labelColor={config?.textColor} className='rounded-full px-2' />;
};

export default SamplingEventBatchStatusIndicator;
