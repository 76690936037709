import React from 'react';

import styles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';
import GeneralProjectAims from './components/general-project-aims/GeneralProjectAims';
import SpeciesOfInterest from './components/species-of-interest/SpeciesOfInterest';

import SampleGroups from './components/sample-groups/SampleGroups';
import HabitatAssayTypes from './components/habitat-assay-types/HabitatAssayTypes';

export const ProjectAims = () => {
    return (
        <div className={styles.formView}>
            <div className={styles.formViewTitle}>Project target</div>
            <GeneralProjectAims />
            <SampleGroups />
            <SpeciesOfInterest />
            <HabitatAssayTypes />
        </div>
    );
};

export default ProjectAims;
