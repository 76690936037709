import React, { useState } from 'react';
import classNames from 'classnames';
import Autocomplete from 'src/shared/components/autocomplete/Autocomplete';
import styles from 'src/app/surveys/views/new-survey-design/NewSurveyDesign.module.scss';
import { Search } from '@mui/icons-material';
import Select from 'src/shared/components/select/Select';
import Textarea from 'src/shared/components/textarea/Textarea';
import { countries } from 'src/shared/data/countries';
import noop from 'lodash/noop';
import Input from 'src/shared/components/input/Input';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import useSurveyDesign from 'src/app/surveys/views/new-survey-design/hooks/useSurveyDesign';
import { useCustomerProjectsInBCQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import BasicDatePicker from 'src/shared/components/basic-datepicker/BasicDatePicker';
import useSubscriptionTypes from 'src/app/projects/views/new-project-definition/hooks/useSubscriptionTypes';

export const BasicInformation = () => {
    const { formState, canCurrentUserContinueForm, isInProgressByDifferentUser, isCheckingSurveyDesignPresence, onFormChange } =
        useSurveyDesign();
    const { currentCustomerId } = useCustomer();
    const [projectNameSearchText, setProjectNameSearchText] = useState('');
    const [countrySearchText, setCountrySearchText] = useState('');

    const { data: projectsData } = useCustomerProjectsInBCQuery(
        {
            customerId: currentCustomerId,
        },
        {
            skip: !currentCustomerId,
        }
    );

    const { surveySubscriptionTypeOptions } = useSubscriptionTypes(null);

    const onProjectChange = (projectName: string | null) => {
        if (!projectName) {
            return;
        }

        const selectedProjectData = projectsData?.customerProjectsInBC.projects.find(entry => entry.projectName === projectName);

        if (!selectedProjectData) {
            return;
        }

        onFormChange({
            projectId: selectedProjectData.projectId,
            projectName: projectName,
            projectCode: selectedProjectData.projectCode || 'Not set',
            projectLeadEmail: selectedProjectData.leadEmail || 'Not set',
            projectLeadName: selectedProjectData.leadName || 'Not set',
        });
    };

    const projectNameOptions = (projectsData?.customerProjectsInBC.projects || []).map(entry => entry.projectName) || [];
    const customerName = projectsData?.customerProjectsInBC.customerName;

    if (!customerName) {
        return null;
    }

    const errorMessage = isInProgressByDifferentUser ? (
        <>
            <p>
                An incomplete survey design has already been created for this project by{' '}
                {formState.surveyDesignCompletionStatus?.metadata?.creatorName}.
            </p>
            <br />
            <p>A new survey design cannot be created until the existing one is deleted or expires. </p>
        </>
    ) : (
        <>
            <p>A survey design has already been created for this project.</p>
            <br />
            <p>Go back to the survey admin page to find this survey design or select a new project in the search field above.</p>
        </>
    );

    return (
        <div className={styles.formSection}>
            <div className={styles.formSectionTitle}>Basic information</div>
            <div className={styles.formInputSection}>
                <div className={styles.formInputSectionTitle}>Customer name</div>
                <Input value={customerName} onChange={noop} disabled={true} />
            </div>
            <div
                className={classNames(styles.formInputSection, {
                    [styles.formInputSectionError]: formState.projectName && !isCheckingSurveyDesignPresence && !canCurrentUserContinueForm,
                })}
            >
                <div className={styles.formInputSectionTitle}>Survey design name</div>
                <Autocomplete
                    options={['', ...projectNameOptions] || []}
                    placeholder='Search project name'
                    prefixIcon={<Search />}
                    onChange={onProjectChange}
                    onInputChange={setProjectNameSearchText}
                    inputValue={projectNameSearchText}
                    value={formState.projectName}
                />
                <div style={{ marginTop: '15px' }}>
                    {formState.projectName && !isCheckingSurveyDesignPresence && !canCurrentUserContinueForm && (
                        <ErrorMessage message={errorMessage} />
                    )}
                </div>
            </div>

            {!isCheckingSurveyDesignPresence && canCurrentUserContinueForm && (
                <>
                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>Project code</div>
                        <Input value={formState.projectCode} onChange={noop} disabled={true} />
                    </div>

                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>Subscription type</div>
                        <Select
                            className={{ container: styles.selectContainer }}
                            options={surveySubscriptionTypeOptions}
                            selectedValue={formState.subscriptionType}
                            onChange={value => onFormChange({ subscriptionType: value as string })}
                            width='100%'
                        />
                    </div>

                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>Survey publish date</div>
                        <BasicDatePicker
                            value={formState.surveyPublishDate ? new Date(formState.surveyPublishDate) : null}
                            onChange={value => onFormChange({ surveyPublishDate: value?.toDateString() })}
                            disablePast={true}
                        />
                    </div>

                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>Country</div>
                        <Autocomplete
                            options={['', ...Object.keys(countries).sort()]}
                            placeholder='Search country name'
                            prefixIcon={<Search />}
                            onChange={value => onFormChange({ country: value || '' })}
                            value={formState.country}
                            onInputChange={setCountrySearchText}
                            inputValue={countrySearchText}
                        />
                    </div>

                    <div className={styles.formInputSection}>
                        <div className={styles.formInputSectionTitle}>
                            Area
                            <QuestionButtonHelp type='api' slug='project-definition-area' />
                        </div>
                        <Textarea
                            placeholder='Short description of area'
                            value={formState.area}
                            resizable={false}
                            maxLength={40}
                            onChange={event => onFormChange({ area: event.target.value })}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default BasicInformation;
