import moment from 'moment';

export const getAxisTicks = (dates: { startDate: number; endDate: number }) => {
    const monthsInbetween = Math.ceil(moment(dates.endDate).diff(moment(dates.startDate), 'months', true));
    if (dates.endDate < dates.startDate) {
        return null;
    }

    const ticks: (string | number)[] = [];
    if (monthsInbetween < 7) {
        const currentDate = dates.startDate;
        ticks.push(currentDate);
        for (let i = 1; i <= monthsInbetween; i++) {
            ticks.push(moment(currentDate).add(i, 'M').valueOf());
        }
    } else {
        let totalTicks = 0,
            minGap = 0;
        for (let gap = 1; gap <= monthsInbetween; gap++) {
            if (monthsInbetween / gap > 6) {
                continue;
            }
            minGap = gap;
            break;
        }
        let counter = 0;
        while (counter <= monthsInbetween) {
            totalTicks += 1;
            counter += minGap;
        }
        const currentDate = dates.startDate;
        ticks.push(currentDate);
        for (let i = 1; i < totalTicks; i++) {
            ticks.push(
                moment(currentDate)
                    .add(minGap * i, 'M')
                    .valueOf()
            );
        }
    }
    return ticks;
};
