import React from 'react';
import classNames from 'classnames';

import ChevronDownFilled from '@mui/icons-material/ArrowDropDown';
import ChevronDownOutlined from '@mui/icons-material/KeyboardArrowDown';

import styles from './Chevron.module.scss';

export type Direction = 'up' | 'down' | 'left' | 'right';

export type Props = {
    direction: Direction;
    animate: boolean;
    variation: 'filled' | 'outlined';
    className?: string;
};

const Chevron = (props: Props) => {
    const isNonDefaultDirection = props.direction !== 'down';

    const chevronClasses = classNames(
        styles.chevron,
        { [styles[`chevron_${props.direction}`]]: isNonDefaultDirection },
        { [styles.chevron_animated]: props.animate },
        props.className
    );
    return props.variation === 'filled' ? (
        <ChevronDownFilled className={chevronClasses} data-testid='chevron-component' />
    ) : (
        <ChevronDownOutlined className={chevronClasses} data-testid='chevron-component' />
    );
};

Chevron.defaultProps = {
    animate: false,
    variation: 'filled',
};

export default Chevron;
