import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import BackNextNavigation from 'src/shared/components/back-next-navigation/BackNextNavigation';
import { noop } from 'lodash';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { SampleManifestFormViews, sampleManifestFormViewSettings } from '../../SampleManifestForm';
import SubmitData from '../submit-data/SubmitData';

const SampleManifestBackNextNavigation = () => {
    const [searchParams] = useSearchParams();
    const viewFromUrl = searchParams.get('view');
    const samplingEventId = searchParams.get('samplingEventId') as string;
    const batchId = searchParams.get('batchId') as string;
    const navigation = useAppNavigation();
    const { kitsReadyToAddTests, readyToSubmitKits, isBatchOnlySubmit, hasIncompleteSamplesInBatch, hasIncompleteTestTypesInBatch } =
        useSamplingEvent();

    const currentStep = sampleManifestFormViewSettings[viewFromUrl as SampleManifestFormViews]?.step ?? 0;

    const nextFormView = Object.keys(sampleManifestFormViewSettings).find(
        key => sampleManifestFormViewSettings[key as SampleManifestFormViews].step === currentStep + 1
    );
    const previousFormView = Object.keys(sampleManifestFormViewSettings).find(
        key => sampleManifestFormViewSettings[key as SampleManifestFormViews].step === currentStep - 1
    );

    const totalSteps = Object.keys(sampleManifestFormViewSettings).length;

    const shouldDisableNext = () => {
        const isAddTestsPage = nextFormView === SampleManifestFormViews.ADD_TESTS;
        const isSubmitPage = nextFormView === SampleManifestFormViews.SUBMIT;
        const isLastStep = currentStep === totalSteps - 1;

        const noKitsReadyToAddTests = kitsReadyToAddTests.length === 0;
        const hasIncompleteSamples = hasIncompleteSamplesInBatch;
        const noKitsReadyToSubmit = readyToSubmitKits.length === 0;
        const hasIncompleteTestTypes = hasIncompleteTestTypesInBatch;

        const disableAddTestsPage =
            isAddTestsPage && ((noKitsReadyToAddTests && !isBatchOnlySubmit) || (isBatchOnlySubmit && hasIncompleteSamples));
        const disableSubmitPage =
            isSubmitPage && ((noKitsReadyToSubmit && !isBatchOnlySubmit) || (isBatchOnlySubmit && hasIncompleteTestTypes));

        return disableAddTestsPage || disableSubmitPage || isLastStep;
    };

    const shouldDisableNextButton = shouldDisableNext();

    const shouldHideNext = currentStep === totalSteps - 1;

    return (
        <div className='flex gap-2'>
            <BackNextNavigation
                onBack={
                    previousFormView
                        ? () =>
                              navigation.toSampleManifestForm({
                                  view: previousFormView as SampleManifestFormViews,
                                  samplingEventId,
                                  batchId,
                              })
                        : navigation.toSampleManifest
                }
                onNext={
                    nextFormView
                        ? () => navigation.toSampleManifestForm({ view: nextFormView as SampleManifestFormViews, samplingEventId, batchId })
                        : noop
                }
                disableNext={shouldDisableNextButton}
                hideNext={shouldHideNext}
            />

            {shouldHideNext && <SubmitData />}
        </div>
    );
};

export default SampleManifestBackNextNavigation;
