import React, { useState } from 'react';
import Search from '@mui/icons-material/Search';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { NewProjectDefinitionFormState, setFormState } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSlice';
import Autocomplete from 'src/shared/components/autocomplete/Autocomplete';
import { useAppDispatch } from 'src/store';
import { PrimaryButton } from 'src/shared/components/button/Button';
import commonStyles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import gbifService from 'src/services/gbif-service';
import ConditionalField from 'src/shared/components/conditional-field/ConditionalField';
import styles from './SpeciesOfInterest.module.scss';
import useProjectDefinition from '../../../../hooks/useProjectDefinition';

const SpeciesOfInterest = () => {
    const dispatch = useAppDispatch();
    const { formState, isBasicAndGenericProject } = useProjectDefinition();
    const { speciesOfInterest } = formState;
    const [selectedSpecies, setSelectedSpecies] = useState<string | null>('');
    const [speciesSearchText, setSpeciesSearchText] = useState<string>('');
    const [speciesOptions, setSpeciesOptions] = useState<string[]>([]);

    const onFormChange = (fragment: Partial<NewProjectDefinitionFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    const addSpeciesOfInterest = () => {
        if (!selectedSpecies) {
            return;
        }
        if (speciesOfInterest.includes(selectedSpecies)) {
            return;
        }

        onFormChange({
            speciesOfInterest: [...speciesOfInterest, selectedSpecies],
        });
        setSelectedSpecies('');
    };

    const removeSpeciesOfInterest = (value: string) => {
        onFormChange({
            speciesOfInterest: [...speciesOfInterest].filter(item => item !== value),
        });
    };

    const onSpeciesSearchTextChange = async (value: string) => {
        setSpeciesSearchText(value);
        if (value) {
            const results = await gbifService.getSpeciesByScientificName(value);
            setSpeciesOptions([...results]);
        }
    };

    return (
        <div className={commonStyles.formSection}>
            <div className={commonStyles.formSectionTitle}>Species of interest</div>
            <div className={commonStyles.formInputSection}>
                <div className={commonStyles.formInputSectionTitle}>
                    Species <span>(optional)</span> <QuestionButtonHelp type='api' slug='project-definition-species-of-interest' />
                </div>
                <ConditionalField isSatisfied={!isBasicAndGenericProject}>
                    <>
                        <div className={styles.addSpecies}>
                            <Autocomplete
                                options={speciesOptions}
                                placeholder='Search species'
                                prefixIcon={<Search />}
                                onChange={setSelectedSpecies}
                                value={selectedSpecies || ''}
                                inputValue={speciesSearchText}
                                customStyle={{ fontStyle: 'italic' }}
                                onInputChange={value => onSpeciesSearchTextChange(value)}
                            />
                            {speciesOfInterest.length < 5 && (
                                <PrimaryButton
                                    className={commonStyles.addButton}
                                    onClick={addSpeciesOfInterest}
                                    disabled={!selectedSpecies}
                                >
                                    <span>+</span> ADD
                                </PrimaryButton>
                            )}
                        </div>
                        <div className={styles.speciesOfInterestList}>
                            {speciesOfInterest.map((entry, index) => {
                                return (
                                    <div key={index} className={styles.speciesOfInterest}>
                                        <div className={styles.speciesOfInterestNames}>
                                            <div className={styles.scientificName}>{entry}</div>
                                        </div>
                                        <ClearOutlinedIcon onClick={() => removeSpeciesOfInterest(entry)} />
                                    </div>
                                );
                            })}
                        </div>
                        {speciesOfInterest.length > 0 && <div className={commonStyles.maximumText}>Maximum 5 species of interest</div>}
                    </>
                </ConditionalField>
            </div>
        </div>
    );
};

export default SpeciesOfInterest;
