import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useProjectsQuery } from 'src/app/projects/views/project-list/state/api/projectListGraphSlice';
import { getCurrentCustomerId, getCurrentCustomerDetails } from 'src/app/customers/state/customersSelector';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState, TableCellRendererParams, TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader from 'src/shared/components/loader/Loader';
import { PrimaryButton } from 'src/shared/components/button/Button';
import AddIcon from '@mui/icons-material/Add';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Input from 'src/shared/components/input/Input';
import { SecondaryButton } from 'src/shared/components/button/Button';
import SearchIcon from '@mui/icons-material/Search';
import { ProjectDefinition, Status, CMSHabitatAssayType, Access, Resources, ProgressStatus } from 'src/shared/types';
import StatusIndicator from 'src/shared/components/status-indicator/StatusIndicator';
import usePermissions from 'src/shared/hooks/usePermissions';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import styles from './ProjectAdmin.module.scss';
import { setCurrentProjectId } from '../project-list/state/projectListSlice';
import { setFormState as setNewProjectDefinitionFormState } from '../new-project-definition/state/newProjectDefinitionSlice';

interface NavigationStateProps {
    sourcePage: string;
}

const getProjectStatusFromEnum = (status: string) => {
    switch (status) {
        case ProgressStatus.COMPLETED:
            return Status.SUCCESS;
        case ProgressStatus.PENDING:
            return Status.WARNING;
        default:
            return Status.UNKNOWN;
    }
};

const filterAndSortProjects = (projects: ProjectDefinition[]) => {
    return projects
        .filter(project => project.status === ProgressStatus.PENDING)
        .sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())
        .concat(
            projects
                .filter(project => project.status === ProgressStatus.COMPLETED)
                .sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())
        );
};

const ProjectAdmin = () => {
    const customerId = useAppSelector(getCurrentCustomerId) || '';
    const currentCustomerDetails = useAppSelector(getCurrentCustomerDetails);
    const title = 'Project Admin';
    const subTitle = 'Manage my project definitions';
    const navigate = useAppNavigation();
    const dispatch = useAppDispatch();
    const { hasPermission } = usePermissions();
    const [columns, setColumns] = useState<DataTableColumns>([]);
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        fixedHeader: true,
        hiddenColumnIds: new Set(['projectId', 'creatorName', 'updatorName']),
    });
    const [searchQuery, setSearchQuery] = useState<string>('');
    const location = useLocation();
    const navigationState = location.state as NavigationStateProps;

    const { isFetching, currentData } = useProjectsQuery(
        {
            customerId,
        },
        {
            skip: !customerId,
        }
    );

    useEffect(() => {
        dispatch(setCurrentProjectId(null));
    }, []);

    const canCreateProject = hasPermission({
        to: [Access.CREATE],
        customerId: customerId,
        resource: Resources.PROJECT_DEFINITION,
    });

    useEffect(() => {
        if (!currentData) {
            return;
        }

        const columns: DataTableColumns = [
            {
                columnId: 'projectId',
                title: 'Id',
            },
            {
                columnId: 'projectName',
                title: 'Name',
                width: '20%',
            },
            {
                columnId: 'country',
                title: 'Sampling country',
                width: '15%',
            },
            {
                columnId: 'status',
                title: 'Status',
                width: '9%',
                renderer: (params: TableCellRendererParams) => {
                    const status = params.cellData as string;
                    const createdAt = params.rowData[5] as string;
                    const statusText = status === ProgressStatus.PENDING ? 'Incomplete' : 'Completed';

                    return (
                        <div className={styles.statusCell}>
                            <StatusIndicator status={getProjectStatusFromEnum(status)} statusText={statusText} />
                            {getProjectStatusFromEnum(status) !== Status.SUCCESS && (
                                <p>Expires at {moment(createdAt).add(5, 'days').format('DD MMM, HH:mm A')}</p>
                            )}
                        </div>
                    );
                },
            },
            {
                columnId: 'projectType',
                title: 'Test',
                width: '20%',
            },
            {
                columnId: 'createdAt',
                title: 'Created',
                width: '14%',
                renderer: (params: TableCellRendererParams) => {
                    const creatorName = params.rowData[6] as string;
                    return (
                        <>
                            <p>{moment(params.cellData as string).format('DD MMM YYYY')}</p>
                            <p className={styles.creatorName}>By {creatorName}</p>
                        </>
                    );
                },
            },
            {
                columnId: 'creatorName',
                title: 'creatorName',
            },
            {
                columnId: 'lastUpdatedAt',
                title: 'Last updated',
                width: '14%',
                renderer: (params: TableCellRendererParams) => {
                    const updatorName = params.rowData[8] as string;
                    if (!params.cellData) {
                        return <p className={styles.na}>N/A</p>;
                    }
                    return (
                        <>
                            <p>{moment(params.cellData as string).format('DD MMM YYYY')}</p>
                            <p className={styles.creatorName}>By {updatorName}</p>
                        </>
                    );
                },
            },
            {
                columnId: 'updatorName',
                title: 'updatorName',
            },
            {
                columnId: 'actions',
                title: 'Actions',
                width: '8%',
            },
        ];

        setColumns(columns);

        const tableData: DataTableState['data'] = [];
        const projects = filterAndSortProjects(currentData?.customer?.projects || []);
        projects.forEach((project: ProjectDefinition) => {
            const { projectName, habitatAssay, country, createdAt, creatorName, projectId, status, samplesCount, updatedAt, updaterName } =
                project;

            if (!!projectName && !!habitatAssay) {
                // Disable edit button if status is pending or samples are present
                const isEditDisabled = status === ProgressStatus.PENDING || samplesCount > 0;
                const editAction = (
                    <SecondaryButton
                        disabled={isEditDisabled}
                        onClick={e => {
                            e.stopPropagation();
                            onRowSelect([projectId, projectName, country, ProgressStatus.PENDING]);
                        }}
                    >
                        <CreateOutlinedIcon /> <span>EDIT</span>
                    </SecondaryButton>
                );

                const currentRow = [
                    projectId,
                    projectName,
                    country,
                    status,
                    habitatAssay.map((item: CMSHabitatAssayType) => `${item.habitatName} ${item.assayName}`).join(', '),
                    createdAt,
                    creatorName || 'Automation',
                    updatedAt,
                    updaterName || 'Unknown',
                    editAction,
                ];

                tableData.push(currentRow);
            }
        });

        setTableState({
            ...tableState,
            data: tableData,
        });
    }, [currentData?.customer?.projects, navigationState?.sourcePage]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const queryString = e.target.value;
        setSearchQuery(queryString);
        setTableState({
            ...tableState,
            searchText: queryString,
        });
    };

    const onRowSelect = (rowData: TableRowData) => {
        const [projectId, projectName, , status] = rowData;

        if (status === 'PENDING') {
            navigate.toNewProjectDefinition();
            setTimeout(() => {
                dispatch(
                    setNewProjectDefinitionFormState({
                        projectId: projectId as string,
                        projectName: projectName as string,
                    })
                );
            }, 10);
            return;
        }
        dispatch(setCurrentProjectId(projectId as string));
        setTimeout(() => {
            navigate.toProjectDefinition();
        }, 1);
    };

    if (isFetching || !currentData || !tableState.data) {
        return <Loader />;
    }

    const createProjectDefinitionBtn = (
        <PrimaryButton onClick={navigate.toNewProjectDefinition} data-testid='create-new-proj-def-btn'>
            Create new project Definition &nbsp; <AddIcon />
        </PrimaryButton>
    );

    const customerDetails = (
        <div className={styles.customerInfo}>
            <div className={styles.header}>Customer:</div>
            <div className={styles.name} data-testid='customer-name'>
                {currentCustomerDetails?.customerName}
            </div>
        </div>
    );

    if (!currentData?.customer?.projects?.length) {
        const content = (
            <div className={styles.noDataContainer}>
                <div className={styles.content}>
                    <div>
                        <h4>No Projects</h4>
                    </div>
                    <div>
                        <span>You have not created or been assigned to any project definitions for this customer</span>
                    </div>
                    {canCreateProject && <div>{createProjectDefinitionBtn}</div>}
                </div>
            </div>
        );
        return <StandardAppLayout mainContent={content} title={title} subTitle={subTitle} otherActions={customerDetails} />;
    }

    const mainContent = (
        <div>
            <div className={styles.container}>
                <div className={styles.searchBar}>
                    <div className={styles.searchInputContainer}>
                        <span className={styles.searchIcon}>
                            <SearchIcon />
                        </span>
                        <Input
                            className={styles.searchInput}
                            value={searchQuery}
                            onChange={handleSearch}
                            placeholder='Search project definitions'
                        />
                    </div>
                </div>
                {canCreateProject && <div className={styles.button}>{createProjectDefinitionBtn}</div>}
            </div>
            <div>
                <DataTable state={tableState} columns={columns} onRowSelect={onRowSelect} />
            </div>
        </div>
    );

    return <StandardAppLayout mainContent={mainContent} title={title} subTitle={subTitle} otherActions={customerDetails} />;
};

export default ProjectAdmin;
