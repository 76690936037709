import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import classNames from 'classnames';
import styles from './StepIndicator.module.scss';

type StepIndicatorProps = {
    currentStep: number;
    steps: string[];
    checkIconForCompletedSteps?: boolean;
};

const StepIndicator = (props: StepIndicatorProps) => {
    const { currentStep, steps, checkIconForCompletedSteps = true } = props;

    const getStepClassnames = (step: number) => {
        return classNames(styles.step, {
            [styles.stepCompleted]: step <= currentStep,
            [styles.stepActive]: step === currentStep,
        });
    };

    return (
        <div className={styles.stepIndicator}>
            {steps.map((step, index) => {
                const isLastStep = steps.length === index + 1;
                return (
                    <div className={getStepClassnames(index)} key={index}>
                        <div className='flex flex-col gap-2 items-center'>
                            <span className={styles.stepBullet}>
                                {checkIconForCompletedSteps && currentStep > index ? <CheckIcon data-testid='check-icon' /> : index + 1}
                            </span>
                            <span className={styles.stepText}>{step}</span>
                        </div>
                        {!isLastStep && <span className={styles.stepConnector}></span>}
                    </div>
                );
            })}
        </div>
    );
};

export default StepIndicator;
