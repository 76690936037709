import { SelectOption } from 'src/shared/components/select/Select';

export const getCollectionMethodOptions = (habitat: string): SelectOption[] => {
    switch (habitat) {
        case 'Terrestrial':
            return [
                { label: 'Malaise trap', value: 'Malaise trap' },
                { label: 'Pan trap', value: 'Pan trap' },
                { label: 'Pitfall trap', value: 'Pitfall trap' },
                { label: 'Sweep net', value: 'Sweep net' },
                { label: 'Other (Please specify)', value: 'Other' },
            ];
        case 'Freshwater':
            return [
                { label: 'Kick sample', value: 'Kick sample' },
                { label: 'Other (Please specify)', value: 'Other' },
            ];
        case 'Marine water':
            return [
                { label: 'Marine structure scrape', value: 'Marine structure scrape' },
                { label: 'Plankton net', value: 'Plankton net' },
                { label: 'Other (Please specify)', value: 'Other' },
            ];
        default:
            return [];
    }
};
