import { gql } from 'graphql-request';

export const habitatInsightsMetricsTableQuery = gql`
    query getHabitatInsightsMetricsData($projectId: String!, $hlcaCharts: [HlcaChartType!], $siteName: [String!]) {
        habitatInsightsMetricsTableData: chart(
            projectId: $projectId
            chartId: "habitatinsightschart"
            hlcaCharts: $hlcaCharts
            siteName: $siteName
        ) {
            data
        }
    }
`;

type HabitatInsightsMetricsTableData = {
    data: {
        chartType: string;
        datetime: string;
        group: {
            type: string;
            value: number;
            bufferArea?: number;
        }[];
        site: string;
    }[];
};

export type HabitatInsightsMetricsTableQueryResult = {
    habitatInsightsMetricsTableData: HabitatInsightsMetricsTableData;
};
