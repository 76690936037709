import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';
import { habitatInsightsMapSidebarDataQuery, HabitatInsightsMapSidebarDataQueryResult } from './queries/habitatInsightsQuery';

export enum HabitatInsightsChartType {
    UNKNOWN_CHART_TYPE = 'Unknown_Chart_Type',
    HABITAT_CONNECTIVITY = 'Habitat_Connectivity',
    LAND_COVER = 'Land_Cover',
    PRIMARY_PRODUCTIVITY = 'Primary_Productivity',
}

type HabitatInsightsMapSidebarDataQueryParams = {
    projectId: string;
    siteName: string[];
    hlcaCharts: HabitatInsightsChartType[];
    fromDate?: string;
    toDate?: string;
};

const habitatInsightsGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        habitatInsightsMapSidebarData: builder.query<HabitatInsightsMapSidebarDataQueryResult, HabitatInsightsMapSidebarDataQueryParams>({
            query: params => ({
                body: habitatInsightsMapSidebarDataQuery,
                variables: params,
            }),
        }),
    }),
});

export const { useHabitatInsightsMapSidebarDataQuery } = habitatInsightsGraphSlice;
