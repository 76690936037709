import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getCurrentCustomerId, getCurrentCustomerDetails } from 'src/app/customers/state/customersSelector';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState, TableCellRendererParams, TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import Loader from 'src/shared/components/loader/Loader';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import AddIcon from '@mui/icons-material/Add';
import Input from 'src/shared/components/input/Input';
import SearchIcon from '@mui/icons-material/Search';
import { HabitatInsightsProcessingStatus, Site, SurveyDesign, Status, ProgressStatus, SubscriptionType } from 'src/shared/types';
import StatusIndicator from 'src/shared/components/status-indicator/StatusIndicator';
import { setCurrentProjectId } from 'src/app/projects/views/project-list/state/projectListSlice';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Tooltip from 'src/shared/components/tooltip/Tooltip';
import styles from './SurveyAdmin.module.scss';
import { setFormState as setNewSurveyDesignFormState } from '../new-survey-design/state/newSurveyDesignSlice';
import { useSurveyDesignsQuery } from '../../state/api/surveyGraphSlice';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
interface NavigationStateProps {
    sourcePage: string;
}

const getSurveyDesignStatusFromEnum = (status: string) => {
    switch (status) {
        case 'COMPLETED':
            return Status.SUCCESS;
        case 'PENDING':
            return Status.WARNING;
        default:
            return Status.UNKNOWN;
    }
};

const columns: DataTableColumns = [
    {
        columnId: 'projectId',
        title: 'Id',
        width: '0',
    },
    {
        columnId: 'projectName',
        title: 'Name',
        width: '15%',
    },
    {
        columnId: 'country',
        title: 'Sampling country',
        width: '15%',
    },
    {
        columnId: 'subscriptionType',
        title: 'Subscription type',
        width: '150px',
    },
    {
        columnId: 'status',
        title: 'Survey design status',
        width: '15%',
        renderer: (params: TableCellRendererParams) => {
            const status = params.cellData as string;
            const createdAt = params.rowData[5] as string;
            const statusText = status === 'PENDING' ? 'Incomplete' : 'Completed';

            return (
                <div className={styles.statusCell}>
                    <StatusIndicator status={getSurveyDesignStatusFromEnum(status)} statusText={statusText} />
                    <p>Expires at {moment(createdAt).add(5, 'days').format('DD MMM, HH:mm A')}</p>
                </div>
            );
        },
    },
    {
        columnId: 'created',
        title: 'Created',
        width: '20%',
    },
    {
        columnId: 'habitatInsights_status',
        title: 'Habitat Insights status',
        width: '260px',
    },
    {
        columnId: 'habitatInsights_actions',
        title: 'Habitat Insights actions',
        width: '150px',
    },
];

const SurveyAdmin = () => {
    const customerId = useAppSelector(getCurrentCustomerId) || '';
    const currentCustomerDetails = useAppSelector(getCurrentCustomerDetails);
    const title = 'Survey Admin';
    const subTitle = 'Manage my survey designs';
    const navigate = useAppNavigation();
    const dispatch = useAppDispatch();
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        fixedHeader: true,
        hiddenColumnIds: new Set(['projectId']),
        rowsPerPage: 10,
    });
    const [searchQuery, setSearchQuery] = useState<string>('');
    const location = useLocation();
    const navigationState = location.state as NavigationStateProps;

    const { isFetching, currentData, error } = useSurveyDesignsQuery(
        {
            customerId,
        },
        {
            skip: !customerId,
        }
    );

    useEffect(() => {
        dispatch(setCurrentProjectId(null));
    }, []);

    useEffect(() => {
        if (!currentData) {
            return;
        }

        const getHabitatInsightsColumnStatus = (survey: SurveyDesign) => {
            const isHabitatInsightsSubscription = survey.subscriptionType === SubscriptionType.SURVEY_WITH_HABITAT_INSIGHTS;

            const siteStatuses = survey.sites?.map(site => site.properties.status) || [];
            if (survey.status === ProgressStatus.PENDING) {
                return <StatusIndicator status={Status.WARNING} statusText='Pending survey design completion' />;
            }

            if (!isHabitatInsightsSubscription) {
                return <StatusIndicator status={Status.DISABLED} statusText='Not included in subscription' />;
            }
            const hasHabitatInsightsSites = Boolean(survey.sites?.length);
            const isHabitatInsightsReadyToUpload = !hasHabitatInsightsSites;

            if (isHabitatInsightsReadyToUpload) {
                return <StatusIndicator status={Status.WARNING} statusText='Ready for upload' />;
            }

            const hasErrorSite = siteStatuses.includes(HabitatInsightsProcessingStatus.ERROR);

            if (hasErrorSite) {
                const errorSite = survey.sites?.find(site => site.properties?.status === HabitatInsightsProcessingStatus.ERROR) as Site;
                return (
                    <div className={styles.detailedStatus}>
                        <div className={styles.detailedStatusLeft}>
                            <StatusIndicator status={Status.ERROR} statusText='' />
                        </div>
                        <div className={styles.detailedStatusRight}>
                            <div className={styles.detailedStatusRightTop}>Processing error</div>
                            <div className={styles.detailedStatusMiddle}>
                                <Tooltip placement={'bottom'} content={errorSite.properties.statusMessage}>
                                    <span className={styles.moreInfo}>More info</span>
                                </Tooltip>
                            </div>
                            <div className={styles.detailedStatusRightBottom}>Retry data upload</div>
                        </div>
                    </div>
                );
            }

            const hasProcessingSite =
                siteStatuses.includes(HabitatInsightsProcessingStatus.REQUESTED) ||
                siteStatuses.includes(HabitatInsightsProcessingStatus.UPLOADED) ||
                siteStatuses.includes(HabitatInsightsProcessingStatus.PROCESSING);

            if (hasProcessingSite) {
                return (
                    <div className={styles.detailedStatus}>
                        <div className={styles.detailedStatusLeft}>
                            <StatusIndicator status={Status.WARNING} statusText='' />
                        </div>
                        <div className={styles.detailedStatusRight}>
                            <div className={styles.detailedStatusRightTop}>Processing</div>
                            <div className={styles.detailedStatusMiddle}>Typically takes about 60 minutes</div>
                        </div>
                    </div>
                );
            }

            if (!survey.sites) {
                return null;
            }

            return (
                <div className={styles.detailedStatus}>
                    <div className={styles.detailedStatusLeft}>
                        <StatusIndicator status={Status.SUCCESS} statusText='' />
                    </div>
                    <div className={styles.detailedStatusRight}>
                        <div className={styles.detailedStatusRightTop}>
                            Completed {moment(survey.sites[0]?.uploadedAt).format('DD MMM YYYY')}
                        </div>
                        <div className={styles.detailedStatusMiddle}>
                            <span className={styles.lightText}>By {survey.sites[0]?.uploadedBy}</span>
                        </div>
                        <div className={styles.detailedStatusRightBottom}>
                            <span className={styles.lightText}>Results available</span>
                        </div>
                    </div>
                </div>
            );
        };

        const getHabitatInsightsColumnActions = (survey: SurveyDesign) => {
            const isHabitatInsightsSubscription = survey.subscriptionType === SubscriptionType.SURVEY_WITH_HABITAT_INSIGHTS;

            if (survey.status === ProgressStatus.PENDING) {
                return null;
            }

            if (!isHabitatInsightsSubscription) {
                return null;
            }
            const hasHabitatInsightsSites = Boolean(survey.sites?.length);
            const isHabitatInsightsReadyToUpload = !hasHabitatInsightsSites;

            const onUploadClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                dispatch(setCurrentProjectId(survey.projectId as string));
                setTimeout(() => {
                    navigate.toHabitatInsightsUpload();
                }, 1);
            };
            return (
                <div className={styles.habitatInsights}>
                    <div className={styles.habitatInsightsAction}>
                        {isHabitatInsightsReadyToUpload && (
                            <SecondaryButton onClick={onUploadClick} className={styles.actionButton}>
                                <FileUploadOutlinedIcon /> UPLOAD
                            </SecondaryButton>
                        )}

                        {!isHabitatInsightsReadyToUpload && (
                            <SecondaryButton onClick={onUploadClick} className={styles.actionButton}>
                                <EditOutlinedIcon /> EDIT
                            </SecondaryButton>
                        )}
                    </div>
                </div>
            );
        };

        const tableData: DataTableState['data'] = [];
        currentData.listSurveyDesigns.forEach((survery: SurveyDesign) => {
            const { projectName, country, createdAt, creatorName, projectId, status, subscription } = survery;

            if (!!projectName) {
                const currentRow = [];
                currentRow.push(projectId);
                currentRow.push(projectName);
                currentRow.push(country);
                currentRow.push(
                    subscription?.name === 'Survey_HabitatInsights' ? 'Survey design + Habitat Insights' : 'Survey design only'
                );
                currentRow.push(status);
                currentRow.push(
                    <>
                        <p>{moment(createdAt).format('DD MMM YYYY')}</p>
                        <p className={styles.lightText}>By {creatorName}</p>
                    </>
                );

                currentRow.push(getHabitatInsightsColumnStatus(survery));
                currentRow.push(getHabitatInsightsColumnActions(survery));

                tableData.push(currentRow);
            }
        });

        setTableState({
            ...tableState,
            data: tableData,
        });
    }, [currentData, navigationState?.sourcePage]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const queryString = e.target.value;
        setSearchQuery(queryString);
        setTableState({
            ...tableState,
            searchText: queryString,
        });
    };

    const onRowSelect = (rowData: TableRowData) => {
        const [projectId, projectName, , , status] = rowData;

        if (status === 'PENDING') {
            navigate.toNewSurveyDesign();
            setTimeout(() => {
                dispatch(
                    setNewSurveyDesignFormState({
                        projectId: projectId as string,
                        projectName: projectName as string,
                    })
                );
            }, 10);
            return;
        }

        dispatch(setCurrentProjectId(projectId as string));
        setTimeout(() => {
            navigate.toSurveyDesignSummary();
        }, 1);
    };

    if (isFetching) {
        return <Loader />;
    }

    if (error) {
        throw {
            error: true,
            message: 'Unable to fetch the survey designs.',
        };
    }

    if (!tableState.data) {
        return null;
    }

    const createSurveyDesignButton = (
        <PrimaryButton onClick={navigate.toNewSurveyDesign}>
            Create new survey design &nbsp; <AddIcon />
        </PrimaryButton>
    );

    const customerDetails = (
        <div className={styles.customerInfo}>
            <div className={styles.header}>Customer:</div>
            <div className={styles.name} data-testid='customer-name'>
                {currentCustomerDetails?.customerName}
            </div>
        </div>
    );

    if (!currentData?.listSurveyDesigns?.length) {
        const content = (
            <div className={styles.noDataContainer}>
                <div className={styles.content}>
                    <div>
                        <h4>No survey designs</h4>
                    </div>
                    <div>
                        <span>You have not created any survey designs for this customer</span>
                    </div>
                    <div>{createSurveyDesignButton}</div>
                </div>
            </div>
        );
        return <StandardAppLayout mainContent={content} title={title} subTitle={subTitle} otherActions={customerDetails} />;
    }

    const mainContent = (
        <div>
            <div className={styles.container}>
                <div className={styles.searchBar}>
                    <div className={styles.searchInputContainer}>
                        <span className={styles.searchIcon}>
                            <SearchIcon />
                        </span>
                        <Input
                            className={styles.searchInput}
                            value={searchQuery}
                            onChange={handleSearch}
                            placeholder='Search survey designs'
                        />
                    </div>
                </div>
                <div className={styles.button}>{createSurveyDesignButton}</div>
            </div>
            <div>
                <DataTable
                    state={tableState}
                    columns={columns}
                    onRowSelect={onRowSelect}
                    emptyStateMessage='There were no survey designs found matching this search. Adjust the search term to find survey designs for this customer.'
                />
            </div>
        </div>
    );

    return <StandardAppLayout mainContent={mainContent} title={title} subTitle={subTitle} otherActions={customerDetails} />;
};

export default SurveyAdmin;
