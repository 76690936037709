import React from 'react';
import classNames from 'classnames';
import useFeatures from 'src/shared/hooks/useFeatures';
import styles from './SpeciesTags.module.scss';
import { SpeciesListTableData } from '../species-list-table/SpeciesListTable';

type SpeciesTagsProps = Pick<SpeciesListTableData, 'iucnStatusType' | 'isInvasive' | 'iucnStatusLabel' | 'chegdGroup'> & {
    shortedLabel?: boolean;
};

const SpeciesTags = (props: SpeciesTagsProps) => {
    const { iucnStatusType, isInvasive, iucnStatusLabel, shortedLabel = false, chegdGroup } = props;
    const { hasProjectLevelIucnRedlistFeature, hasProjectLevelInvasivesFeature, hasChegdFeature } = useFeatures();

    return (
        <>
            {hasProjectLevelIucnRedlistFeature && iucnStatusType && (
                <span className={classNames([styles.chip, styles[iucnStatusType]])}>{iucnStatusLabel}</span>
            )}
            &nbsp;
            {hasProjectLevelInvasivesFeature && isInvasive && (
                <span className={classNames([styles.chip, styles.invasive])}>{shortedLabel ? 'INV' : 'Invasive'}</span>
            )}
            {hasChegdFeature && chegdGroup && (
                <span className={classNames([styles.chip, styles.chegd])}>{shortedLabel ? chegdGroup[0] : chegdGroup}</span>
            )}
        </>
    );
};

export default SpeciesTags;
