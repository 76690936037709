import React from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';

import styles from './QuestionButton.module.scss';

type QuestionButtonProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const QuestionButton = (props: QuestionButtonProps) => {
    return (
        <span {...props} className={styles.questionButton}>
            <IconButton>
                <HelpOutlineIcon />
            </IconButton>
        </span>
    );
};

export default QuestionButton;
