import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface CustomAccordionProps {
    title: React.ReactNode;
    content: React.ReactNode;
}

const CustomAccordion = ({ title, content }: CustomAccordionProps) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel-content'
                id='panel-header'
                sx={{
                    backgroundColor: '#F6F6F6',

                    height: '10px',
                }}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>{content}</AccordionDetails>
        </Accordion>
    );
};

export default CustomAccordion;
