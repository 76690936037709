export type TimeSeriesData = {
    datetime: string;
    group: IndividualTypeValue[];
    site?: string;
    chartType?: string;
};

export type IndividualTypeValue = {
    type: string;
    value: number | number[];
    boundary?: number;
    annualRange?: { min: number; max: number };
};

export enum ChartIds {
    FISH_METRICS = 'fishmetrics',
    IUCN_THREATENED_SPECIES = 'iucnthreatenedspecies',
    EVOLUTIONARY_DIVERSITY = 'evolutionarydiversity',
    SPECIES_RICHNESS = 'speciesrichness',
    STATISTICAL_COMPARISON = 'statcomparison',
    COMMUNITY_COMPARISON = 'communitycomparison',
    BACTERIAL_FUNCTIONAL_DIVERSITY = 'bfd',
    FUNGAL_FUNCTIONAL_DIVERSITY = 'ffd',
    FUNGAL_TO_BACTERIAL_RATIO = 'fb_ratio',
}

export type MetricsChartData = {
    data: {
        data: {
            datetime: string;
            group: {
                type: string;
                value: number;
            }[];
        }[];
        metric: string;
    }[];
};
