import React from 'react';
import Select from 'src/shared/components/select/Select';
import { useAppDispatch } from 'src/store';
import { NewProjectDefinitionFormState, setFormState } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSlice';
import styles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';
import Textarea from 'src/shared/components/textarea/Textarea';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import useSubscriptionTypes from 'src/app/projects/views/new-project-definition/hooks/useSubscriptionTypes';
import ConditionalField from 'src/shared/components/conditional-field/ConditionalField';
import useProjectDefinition from '../../../../hooks/useProjectDefinition';

const GeneralProjectAims = () => {
    const dispatch = useAppDispatch();
    const { formState, isBasicAndGenericProject } = useProjectDefinition();
    const { projectTypeOptions } = useSubscriptionTypes(formState.subscriptionType);

    const onFormChange = (fragment: Partial<NewProjectDefinitionFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    return (
        <div className={styles.formSection}>
            <div className={styles.formInputSection}>
                <div className={styles.formInputSection}>
                    <div className={styles.formInputSectionTitle}>
                        Project type <QuestionButtonHelp type='api' slug='project-definition-project-type' />
                    </div>
                    <Select
                        className={{ container: styles.selectContainer }}
                        options={projectTypeOptions}
                        selectedValue={formState.projectType as string}
                        onChange={value => onFormChange({ projectType: value as string })}
                        width='100%'
                        placeholder='Select project type'
                    />
                </div>

                <div className={styles.formInputSection}>
                    <div className={styles.formInputSectionTitle}>
                        Target outcomes <QuestionButtonHelp type='api' slug='project-definition-target-outcomes' />
                    </div>
                    <ConditionalField isSatisfied={!isBasicAndGenericProject}>
                        <Textarea
                            maxLength={1000}
                            value={formState.targetOutcomes}
                            onChange={event => onFormChange({ targetOutcomes: event.target.value })}
                            resizable={false}
                        />
                    </ConditionalField>
                </div>
            </div>
        </div>
    );
};

export default GeneralProjectAims;
