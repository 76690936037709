import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
    classificationNamingOptions,
    columnOrderOptions,
    sortOptions,
    TaxonomicCompositionFiltersState,
    updateFilters,
    updateFiltersDraft,
} from 'src/app/insights/state/taxonomic-composition/taxonomicCompositionSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getCurrentTaxonomicCompositionUIState } from 'src/app/insights/state/taxonomic-composition/taxonomicCompositionSelector';
import Select from 'src/shared/components/select/Select';
import InputLabel from '@mui/material/InputLabel';
import { Divider } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import styles from './TaxonomicCompositionFilters.module.scss';
import TaxonomicCompositionInlineFilters from '../taxonomic-composition-inline-filters/TaxonomicCompositionInlineFilters';

const FILTER_WIDTH = '100%';

const TaxonomicCompositionFilters = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const uiState = useAppSelector(getCurrentTaxonomicCompositionUIState);
    const {
        filtersDraft: { hierarchy, sortColumnsBy, classificationNaming },
    } = uiState;
    const onFiltersChange = (fragment: Partial<TaxonomicCompositionFiltersState>) => {
        dispatch(updateFiltersDraft(fragment));
    };

    useEffect(() => {
        dispatch(updateFiltersDraft({ ...uiState.filters }));
    }, []);

    const onApply = () => {
        dispatch(updateFilters({ ...uiState.filters, ...uiState.filtersDraft, highlightedBranchNodeId: null, visibleBranchNodeId: null }));
        setOpen(false);
    };

    const closeWithoutApplying = () => {
        dispatch(updateFiltersDraft({ ...uiState.filters }));
        setOpen(false);
    };

    return (
        <div className={styles.filtersContainer}>
            <TaxonomicCompositionInlineFilters />

            <SecondaryButton aria-describedby={'#filters'} onClick={() => setOpen(true)} ref={setAnchorEl}>
                <MoreVertIcon />
            </SecondaryButton>

            <Popover
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={'#filters'}
                open={open}
                anchorEl={anchorEl}
                onClose={closeWithoutApplying}
            >
                <div className={styles.filters}>
                    <span className={styles.closeButton} onClick={closeWithoutApplying}>
                        <CloseIcon />
                    </span>
                    <div className={styles.filterTitle}>DISPLAY</div>

                    <div className={styles.selectInput}>
                        <InputLabel className={styles.filterLabel}>Classification naming</InputLabel>
                        <Select
                            isDisabled
                            width={FILTER_WIDTH}
                            options={classificationNamingOptions}
                            onChange={value => onFiltersChange({ classificationNaming: value })}
                            selectedValue={classificationNaming}
                        />
                    </div>
                    <Divider></Divider>

                    <div>SORT</div>
                    <div className={styles.selectInput}>
                        <InputLabel className={styles.filterLabel}>Sort columns by</InputLabel>
                        <Select
                            width={FILTER_WIDTH}
                            options={columnOrderOptions}
                            onChange={value => onFiltersChange({ hierarchy: value })}
                            selectedValue={hierarchy}
                        />
                    </div>

                    <div className={styles.selectInput}>
                        <InputLabel className={styles.filterLabel}>Sort rows by</InputLabel>
                        <Select
                            width={FILTER_WIDTH}
                            options={sortOptions}
                            onChange={value => onFiltersChange({ sortColumnsBy: value })}
                            selectedValue={sortColumnsBy}
                        />
                    </div>

                    <PrimaryButton onClick={onApply}>Apply</PrimaryButton>
                </div>
            </Popover>
        </div>
    );
};

export default TaxonomicCompositionFilters;
