import React from 'react';
import { useCommunityComparisonChartDataQuery, useCommunityComparisonSamplesQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import { getCurrentCommunityComparisonUIState } from 'src/app/insights/state/community-comparison/communityComparisonSelector';
import Loader from 'src/shared/components/loader/Loader';
import useProject from 'src/shared/hooks/useProject';
import { useAppSelector } from 'src/store';
import PercentageBar from '../../percentage-bar/PercentageBar';

import styles from '../CommunityComparisonAside.module.scss';

const SelectedSampleGroupComparison = () => {
    const uiState = useAppSelector(getCurrentCommunityComparisonUIState);

    const { currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();

    const {
        filters: { samplingYear, selectedSampleGroup },
    } = uiState;

    const { currentData: chartData, isFetching: isFetchingChartData } = useCommunityComparisonChartDataQuery(
        {
            projectId: currentProjectId || '',
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    let selectedSampleIdsToQuery: string[] = [];
    let otherSampleIdsToQuery: string[] = [];
    if (selectedSampleGroup && chartData) {
        const selectedYearData = chartData.communityComparison.data[0].years[Number(samplingYear)].data;
        selectedSampleIdsToQuery =
            selectedYearData?.find(entry => entry.variable.value === selectedSampleGroup)?.points.map(point => point.sampleId) || [];
        otherSampleIdsToQuery =
            selectedYearData
                ?.filter(entry => entry.variable.value !== selectedSampleGroup)
                ?.flatMap(entry => entry.points.map(point => point.sampleId)) || [];
    }

    const { currentData: otherSamplesData, isFetching: isFetchingOtherSamples } = useCommunityComparisonSamplesQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                habitatAssay: [habitatAssayType?.key || ''],
                sampleIds: otherSampleIdsToQuery,
            },
        },
        {
            skip: !currentProjectId || !habitatAssayType || !otherSampleIdsToQuery.length,
        }
    );

    const { currentData: selectedSamplesData, isFetching: isFetchingSelectedSamples } = useCommunityComparisonSamplesQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                habitatAssay: [habitatAssayType?.key || ''],
                sampleIds: selectedSampleIdsToQuery,
            },
        },
        {
            skip: !currentProjectId || !selectedSampleIdsToQuery.length,
        }
    );

    if (isFetchingOtherSamples || isFetchingSelectedSamples || isFetchingChartData) {
        return <Loader />;
    }

    if (!otherSamplesData || !selectedSamplesData || !chartData) {
        return null;
    }

    const speciesInOtherSamples = otherSamplesData.communityComparisonSamples.taxonomySummary
        .filter(entry => entry.taxonomy.tax_species)
        .map(entry => entry.taxonomy.tax_species);

    const speciesInSelectedSampleGroup = selectedSamplesData.communityComparisonSamples.taxonomySummary
        .filter(entry => entry.taxonomy.tax_species)
        .map(entry => entry.taxonomy.tax_species);

    const matchedSpecies = speciesInSelectedSampleGroup.filter(entry => speciesInOtherSamples.includes(entry)).length;

    let percentageDifference = Math.floor((matchedSpecies * 100) / speciesInSelectedSampleGroup.length);
    if (isNaN(percentageDifference)) {
        percentageDifference = 0;
    }

    return (
        <div className={styles.sampleGroupStats}>
            <span className={styles.textAboveBar}>
                How many Named Species in <span className={styles.textHighlight}>{selectedSampleGroup}</span> appear in all other Sample
                Groups?
            </span>
            <span className={styles.percentageBar}>
                <PercentageBar score={percentageDifference} backboneColor='#0FC0C730' progressBarColor='#0FC0C7' />
            </span>
            <span className={styles.textBelowBar}>
                {matchedSpecies} of {speciesInSelectedSampleGroup.length} Named Species appear in all other Sample Groups.
            </span>
        </div>
    );
};

export default SelectedSampleGroupComparison;
