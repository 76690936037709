import React from 'react';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import classNames from 'classnames';
import useScrollToTop from 'src/shared/hooks/useScrollToTop';
import StepIndicator from 'src/shared/components/step-indicator/StepIndicator';
import useProject from 'src/shared/hooks/useProject';
import useHabitatInsightsUpload from './hooks/useHabitatInsightsUpload';
import HabitatInsightsSamples from './components/habitat-insights-samples/HabitatInsightsSamples';
import ReviewAndSubmit from './components/review-and-submit/ReviewAndSubmit';
import BottomNavigation from './components/bottom-navigation/BottomNavigation';
import styles from './HabitatInsightsUpload.module.scss';

export const HabitatInsightsUpload = () => {
    const { formStep } = useHabitatInsightsUpload();
    const { currentProjectDetails } = useProject();

    const formViewClassNames = classNames(styles.formView, styles.formViewFullWidth);

    const ref = useScrollToTop(formStep);

    const mainContent = (
        <div className={styles.habitatInsightsUpload} ref={ref}>
            <StepIndicator currentStep={formStep} steps={['Upload data', 'Review and  Submit']} />
            <div className={formViewClassNames}>
                <div className={styles.formViewTitle}>Remote sensing - Areas of interest</div>
                <div className={styles.formSection}>{formStep === 0 && <HabitatInsightsSamples />}</div>
                {formStep === 1 && <ReviewAndSubmit />}
                {formStep !== 1 && <BottomNavigation />}
            </div>
        </div>
    );

    return (
        <StandardAppLayout
            mainContent={mainContent}
            title='Survey design - Habitat Insights upload'
            subTitle={currentProjectDetails?.projectName}
        />
    );
};

export default HabitatInsightsUpload;
