import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { getAdminRoutesAvailability } from 'src/routes/state/routesSelector';
import { useAppSelector } from 'src/store';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import { AdminViews, IndividualRouteSetting } from 'src/routes/routesConfig';
import UserManagement from './views/user-management/UserManagement';

export const adminRouteSettings: { [key in AdminViews]: IndividualRouteSetting } = {
    userManagement: {
        name: 'Admin User Mgt.',
        path: '/admin/user-management',
        handler: <UserManagement />,
    },
};

const Admin = () => {
    const routesConfig = useAppSelector(getAdminRoutesAvailability);

    if (!routesConfig) {
        return null;
    }

    return (
        <Routes>
            {Object.keys(routesConfig).map(routeKey => {
                const isAvailable = routesConfig[routeKey as AdminViews];
                const routeSetting = adminRouteSettings[routeKey as AdminViews];

                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={'/customers'}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default Admin;
