import useProject from 'src/shared/hooks/useProject';
interface Breadcrumb {
    label: string;
    path?: string;
}
const useProjectBreadcrumb = (label: string): Breadcrumb[] => {
    const pageName = label ?? '';
    const { currentProjectDetails } = useProject();
    return [
        { label: 'Projects', path: '/projects' },
        { label: currentProjectDetails?.projectName ?? '', path: '/map/samples' },
        { label: pageName },
    ];
};

export default useProjectBreadcrumb;
