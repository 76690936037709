import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export type HideShowType = 'show' | 'hide';

export type HabitatInsightsEcologicalTrendsFiltersState = {
    metric: string;
    site: string;
    bufferArea: HideShowType;
    annualRange: HideShowType;
};

const defaultFiltersState = {
    metric: '',
    site: '',
    bufferArea: 'hide' as HideShowType,
    annualRange: 'show' as HideShowType,
};

export type HabitatInsightsEcologicalTrendsUIState = {
    filters: HabitatInsightsEcologicalTrendsFiltersState;
};

export const defaultUiState = {
    filters: { ...defaultFiltersState },
};

type HabitatInsightsEcologicalTrendsState = {
    uiState: {
        [projectId: string]: HabitatInsightsEcologicalTrendsUIState;
    };
};
const initialState: HabitatInsightsEcologicalTrendsState = {
    uiState: {},
};

export const updateFilters =
    (fragment: Partial<HabitatInsightsEcologicalTrendsFiltersState>): ThunkAction<void, RootState, void, Action<string>> =>
    (dispatch, getState) => {
        const state = getState();

        const projectId = state.projects.projectList.currentProjectId;

        if (projectId) {
            dispatch(
                HabitatInsightsEcologicalTrendsSlice.actions.updateFilters({
                    projectId,
                    fragment,
                })
            );
        }
    };

const ensureProjectUIStatePresence = (state: HabitatInsightsEcologicalTrendsState, projectId: string) => {
    if (!state.uiState[projectId]) {
        state.uiState[projectId] = {
            filters: { ...defaultFiltersState },
        };
    }
};

const HabitatInsightsEcologicalTrendsSlice = createSlice({
    name: 'habitatInsightsEcologicalTrends',
    initialState,
    reducers: {
        updateFilters(state, action: PayloadAction<{ projectId: string; fragment: Partial<HabitatInsightsEcologicalTrendsFiltersState> }>) {
            const { projectId, fragment } = action.payload;

            ensureProjectUIStatePresence(state, projectId);

            state.uiState[projectId].filters = {
                ...(state.uiState[projectId].filters as HabitatInsightsEcologicalTrendsFiltersState),
                ...fragment,
            };
        },
    },
});

export default HabitatInsightsEcologicalTrendsSlice.reducer;
