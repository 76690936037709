import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';
import {
    updateUserDetailsMutation,
    UpdateUserDetailsMutationResult,
    getUserPermissions,
    GetUserPermissionsResult,
} from './queries/loginQuery';

type UpdateUserDetailsParams = {
    tncConsent?: boolean;
    cognitoId: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
};

const loginGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        updateUserDetails: builder.mutation<UpdateUserDetailsMutationResult, UpdateUserDetailsParams>({
            query: params => ({
                body: updateUserDetailsMutation,
                variables: params,
            }),
        }),

        getUserPermissions: builder.query<GetUserPermissionsResult, undefined>({
            query: params => ({
                body: getUserPermissions,
                variables: params,
            }),
        }),
    }),
});

export const { useUpdateUserDetailsMutation, useGetUserPermissionsQuery, useLazyGetUserPermissionsQuery } = loginGraphSlice;
