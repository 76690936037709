import React from 'react';
import { getSelectedSamplingKitId } from 'src/app/samples/sample-manifest/state/sampleManifestSelector';
import { useAppSelector } from 'src/store';
import NoSampleSelected from './components/no-sample-selected/NoSampleSelected';
import FieldDataForm from './components/field-data-form/FieldDataForm';

const FieldDataAsideContent = () => {
    const selectedSamplingKitId = useAppSelector(getSelectedSamplingKitId);

    return selectedSamplingKitId !== '' ? <FieldDataForm /> : <NoSampleSelected />;
};

export default FieldDataAsideContent;
