import React, { ReactNode } from 'react';
import classNames from 'classnames';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { createPortal } from 'react-dom';
import styles from './Modal.module.scss';

type Props = {
    onClose?: () => void;
    children: ReactNode;
    classNames?: {
        background?: string;
        body?: string;
    };
    dismissOnClickOutside?: boolean;
};

const Modal = (props: Props) => {
    const { dismissOnClickOutside = false } = props;

    const preventEventBubbling = (event: React.SyntheticEvent) => {
        event.stopPropagation();
    };

    const backgroundClasses = classNames(styles.background, props.classNames?.background);

    const bodyClasses = classNames(styles.body, props.classNames?.body);

    const handleBackgroundClick = () => {
        if (dismissOnClickOutside && props.onClose) {
            props.onClose();
        }
    };
    const modal = (
        <div className={backgroundClasses} onClick={handleBackgroundClick}>
            <div className={bodyClasses} onClick={preventEventBubbling}>
                {props.onClose && (
                    <div className={styles.close}>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                )}
                <div className={styles.content}>{props.children}</div>
            </div>
        </div>
    );

    return createPortal(modal, document.body);
};

export default Modal;
