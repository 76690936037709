import analyticsTracking from 'src/services/analytics-service';
import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import useProject from 'src/shared/hooks/useProject';

const useSupportAnalytics = () => {
    const { currentProjectDetails } = useProject();

    const sendTrackEvent = (ga: AnalyticsOptions) => {
        analyticsTracking.trackEvent({
            ...ga,
            project: currentProjectDetails?.projectName,
        });
    };

    const trackSupportType = (supportType: string) => {
        sendTrackEvent({
            action: 'support_type',
            category: 'support',
            label: supportType,
        });
    };

    const trackSupportRequest = (requestType: string) => {
        sendTrackEvent({
            action: 'support_request',
            category: 'support',
            label: requestType,
        });
    };

    return {
        trackSupportType,
        trackSupportRequest,
    };
};

export default useSupportAnalytics;
