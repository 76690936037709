import { gql } from 'graphql-request';
import { ChartIds } from 'src/shared/types';
export const fungalToBacterialRatioMetricsQuery = gql`
    query fungalToBacterialRatioMetricsQueryMetricsData($projectId: String!, $fromDate: String, $toDate: String, $habitatAssay: String!) {
        fungalToBacterialRatio: chart(
            projectId: $projectId, 
            chartId: "${ChartIds.FUNGAL_TO_BACTERIAL_RATIO}", 
            habitatAssay: $habitatAssay,
            fromDate: $fromDate, 
            toDate: $toDate
        ) {
            data
        }
    }
`;

export type FungalToBacterialRatioMetricsChartData = {
    data: {
        data: {
            datetime: string;
            group: {
                type: string;
                value: number;
            }[];
        }[];
        metric: string;
    }[];
};

export type FungalToBacterialRatioMetricsQueryResult = {
    fungalToBacterialRatio: FungalToBacterialRatioMetricsChartData;
};
