import {
    CognitoUser,
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUserSession,
    IAuthenticationCallback,
    CookieStorage,
} from 'amazon-cognito-identity-js';
import storageService from 'src/services/storage-service';
import config from 'src/config';
import noop from 'lodash/noop';

type SigninParams = {
    email: string;
    password: string;
    onSuccess: IAuthenticationCallback['onSuccess'];
    onFailure: IAuthenticationCallback['onFailure'];
    newPasswordRequired: IAuthenticationCallback['newPasswordRequired'];
    customChallenge: IAuthenticationCallback['customChallenge'];
};

type ChangePasswordParams = {
    password: string;
    onSuccess: IAuthenticationCallback['onSuccess'];
    onFailure: IAuthenticationCallback['onFailure'];
    customChallenge: IAuthenticationCallback['customChallenge'];
};

type VerifyMFACode = Pick<SigninParams, 'onSuccess' | 'onFailure'> & {
    verificationCode: string;
    onSuccess: () => void;
};

export enum UserGroup {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ONBOARDING_MANAGER = 'ONBOARDING_MANAGER',
    ORGANISATIONAL_VIEWER = 'ORGANISATIONAL_VIEWER',
}

export type CustomChallengeParameters = {
    identity: string;
    mode: 'code' | 'sms' | 'email';
};

export type UserAuthData = {
    cognitoId: string;
    accessToken: string;
    refreshToken: string;
    jwtToken: string;
    email: string;
    isAdmin: boolean;
    lastName: string;
    firstName: string;
    groups: UserGroup[];
};
export class AuthService {
    private cognitoUser: CognitoUser | null = null;
    private cognitoUserPool: CognitoUserPool;
    public cognitoUserSession: CognitoUserSession | null = null;
    public isRefreshing = true;
    private emailId = '';
    private password = '';
    private challengeParameters: CustomChallengeParameters | null = null;

    constructor() {
        this.cognitoUserPool = new CognitoUserPool({
            UserPoolId: config.cognitoUserPoolId,
            ClientId: config.cognitoClientId,
            Storage: new CookieStorage({
                domain: config.cookiesDomain,
                expires: config.cookiesExpires,
                path: config.cookiesPath,
                secure: config.cookiesSecure,
            }),
        });

        this.restoreCurrentUser();
    }

    public restoreCurrentUser() {
        const cognitoUser = this.cognitoUserPool.getCurrentUser();

        if (cognitoUser && cognitoUser !== null) {
            this.cognitoUser = cognitoUser;
            this.cognitoUser.getSession((error: Error | null, session: CognitoUserSession | null) => {
                if (error) {
                    this.signout();
                    this.isRefreshing = false;
                } else if (session) {
                    this.cognitoUserSession = session;
                    if (!this.cognitoUserSession?.isValid()) {
                        this.refreshSession();
                    } else {
                        this.isRefreshing = false;
                    }
                }
            });
        } else {
            this.isRefreshing = false;
        }
    }

    public setCognitoUser(cognitoUser: CognitoUser) {
        this.cognitoUser = cognitoUser;
    }

    public refreshSession() {
        const refreshToken = this.cognitoUserSession?.getRefreshToken();

        if (refreshToken) {
            this.isRefreshing = true;
            this.cognitoUser?.refreshSession(refreshToken, (error: Error | null, session: CognitoUserSession | null) => {
                this.isRefreshing = false;
                if (error) {
                    this.signout();
                    throw error;
                } else if (session) {
                    this.cognitoUserSession = session;
                }
            });
        } else {
            this.isRefreshing = false;
        }
    }

    public getIsRefreshing() {
        return this.isRefreshing;
    }

    public getCognitoUser() {
        return this.cognitoUser;
    }

    public getCognitoUserSession() {
        return this.cognitoUserSession;
    }

    public setCognitoUserPool(cognitoUser: CognitoUser) {
        this.cognitoUser = cognitoUser;
    }

    public getCognitoUserPool() {
        return this.cognitoUserPool;
    }

    public signin = async (params: SigninParams) => {
        const { email, password, onSuccess, onFailure, newPasswordRequired, customChallenge } = params;

        this.emailId = email;
        this.password = password;
        this.cognitoUser = new CognitoUser({
            Username: email,
            Pool: this.cognitoUserPool,
            Storage: new CookieStorage({
                domain: config.cookiesDomain,
                path: config.cookiesPath,
                secure: config.cookiesSecure,
                expires: config.cookiesExpires,
            }),
        });
        this.cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

        const authData = {
            Username: email,
            Password: password,
            AuthParameters: {
                USERNAME: email,
                PASSWORD: password,
                CHALLENGE_NAME: 'SRP_A',
            },
            ValidationData: {
                deviceKey: this.getDeviceKey(),
            },
        };

        const authDetails = new AuthenticationDetails(authData);

        this.cognitoUser.authenticateUser(authDetails, {
            onSuccess: cognitoUserSession => {
                this.cognitoUserSession = cognitoUserSession;
                this.emailId = '';
                this.password = '';

                onSuccess(cognitoUserSession);
            },
            onFailure,
            newPasswordRequired,
            customChallenge: (challengeParameters: CustomChallengeParameters) => {
                if (challengeParameters.mode === 'code') {
                    this.cognitoUser?.sendCustomChallengeAnswer(challengeParameters.identity, {
                        onSuccess: cognitoUserSession => {
                            this.cognitoUserSession = cognitoUserSession;
                            this.emailId = '';
                            this.password = '';
                            onSuccess(cognitoUserSession);
                        },
                        onFailure,
                    });
                    return;
                }

                this.challengeParameters = challengeParameters;
                customChallenge?.(challengeParameters);
            },
        });
    };

    public signout(callback?: () => void) {
        this.cognitoUser?.signOut(() => {
            callback?.();
            this.cognitoUser = null;
            this.cognitoUserSession = null;
            storageService.clearAll();
            if (window.location.pathname !== '/') {
                window.location.replace('/');
            }
        });
    }

    public completeNewPasswordChallenge(params: ChangePasswordParams) {
        const { password, onSuccess, onFailure, customChallenge } = params;

        const cognitoUser = this.getCognitoUser();
        this.password = password;
        cognitoUser?.completeNewPasswordChallenge(
            password,
            {},
            {
                onSuccess: cognitoUserSession => {
                    this.cognitoUserSession = cognitoUserSession;
                    this.password = password;
                    onSuccess(cognitoUserSession);
                },
                onFailure,
                customChallenge,
            }
        );
    }

    public getCurrentUserData(): UserAuthData | null {
        if (!this.cognitoUserSession) {
            return null;
        }

        const accessToken = this.cognitoUserSession.getAccessToken();
        const refreshToken = this.cognitoUserSession.getRefreshToken();
        const cognitoIdToken = this.cognitoUserSession.getIdToken();
        return {
            cognitoId: cognitoIdToken.payload.sub,
            accessToken: accessToken.getJwtToken(),
            refreshToken: refreshToken.getToken(),
            jwtToken: cognitoIdToken.getJwtToken(),
            email: cognitoIdToken.payload.email,
            isAdmin: cognitoIdToken.payload['custom:isAdmin'] === 'true',
            groups: cognitoIdToken.payload['cognito:groups'] || [],
            lastName: cognitoIdToken.payload.family_name,
            firstName: cognitoIdToken.payload.given_name,
        };
    }
    public getJWTtoken() {
        return this.getCurrentUserData()?.accessToken;
    }

    public getCustomChallangeParameters() {
        return this.challengeParameters;
    }

    public verifyCustomChallenge(params: VerifyMFACode) {
        const { onFailure, verificationCode, onSuccess } = params;

        return this.cognitoUser?.sendCustomChallengeAnswer(verificationCode, {
            onSuccess: cognitoUserSession => {
                this.cognitoUserSession = cognitoUserSession;
                this.emailId = '';
                this.password = '';
                onSuccess(cognitoUserSession);
            },
            onFailure,
        });
    }

    public resendCustomChallengeCode(params: { onFailure: (e: Error) => void; onSuccess: () => void }) {
        this.signin({
            email: this.emailId,
            password: this.password,
            onFailure: params.onFailure,
            onSuccess: noop,
            customChallenge: params.onSuccess,
            newPasswordRequired: noop,
        });
    }

    public getDeviceKey(): string {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            const [key, value] = cookie.split('=');
            if (key.endsWith('deviceKey')) {
                return decodeURIComponent(value);
            }
        }
        return '';
    }
}
const authService = new AuthService();
export default authService;
