import React, { useEffect } from 'react';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import { useSearchParams } from 'react-router-dom';
import Loader from 'src/shared/components/loader/Loader';
import useProject from 'src/shared/hooks/useProject';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import { SamplesViews } from 'src/routes/routesConfig';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import SamplingEventPinCode from './components/pin-code/SamplingEventPinCode';
import Help from './components/help/Help';
import SaveAndExit from './components/save-and-exit/SaveAndExit';
import InviteTeam from './views/invite-team/InviteTeam';
import FieldData from './views/field-data/FieldData';
// import ReviewSamples from './views/review-samples/ReviewSamples';
import AddTestsToSample from './views/add-tests-to-sample/AddTestsToSample';
import Submit from './views/submit/Submit';
import SampleManifestStepIndicator from './components/sample-manifest-step-idicator/SampleManifestStepIndicator';
import SampleManifestBackNextNavigation from './components/sample-manifest-back-next-navigation/SampleManifestBackNextNavigation';

export enum SampleManifestFormViews {
    // INVITE_TEAM = 'invite-team',
    ADD_FIELD_DATA = 'add-field-data',
    // REVIEW_SAMPLES = 'review-samples',
    ADD_TESTS = 'add-tests',
    SUBMIT = 'submit',
}

export const sampleManifestFormViewSettings: { [key in SampleManifestFormViews]: { handler: JSX.Element; step: number } } = {
    // [SampleManifestFormViews.INVITE_TEAM]: { step: 0, handler: <InviteTeam /> },
    [SampleManifestFormViews.ADD_FIELD_DATA]: { step: 0, handler: <FieldData /> },
    // [SampleManifestFormViews.REVIEW_SAMPLES]: { step: 1, handler: <ReviewSamples /> },
    [SampleManifestFormViews.ADD_TESTS]: { step: 1, handler: <AddTestsToSample /> },
    [SampleManifestFormViews.SUBMIT]: { step: 2, handler: <Submit /> },
};

const SampleManifestForm = () => {
    const title = 'Sample Manifest';
    const { currentProjectDetails } = useProject();
    const [searchParams] = useSearchParams();
    const viewFromUrl = searchParams.get('view');
    const samplingEventIdFromUrl = searchParams.get('samplingEventId') as string;

    const navigation = useAppNavigation();

    useEffect(() => {
        if (!samplingEventIdFromUrl) {
            navigation.toSampleManifest();
        }
    }, [samplingEventIdFromUrl]);

    const { isFetching, isBatchOnlySubmit } = useSamplingEvent();

    const lozenges = (
        <div className='flex gap-6 items-end'>
            <SamplingEventPinCode />
            <div className='flex gap-2'>
                <Help />
                <SaveAndExit />
            </div>
        </div>
    );

    const navigationHeaderHint = {
        [SampleManifestFormViews.ADD_FIELD_DATA]: 'Only samples with ‘complete’ field data can be submitted',
        [SampleManifestFormViews.ADD_TESTS]: 'You must add test(s) to samples before you can submit them',
        [SampleManifestFormViews.SUBMIT]: isBatchOnlySubmit
            ? 'Agree to the terms of submission before resubmitting'
            : 'Complete each section before submitting',
    };

    const mainContentTopRight = (
        <div className='flex flex-col gap-2 items-end'>
            <div className='text-primary'>{navigationHeaderHint[viewFromUrl as SampleManifestFormViews]}</div>
            <div className='justify-items-end'>
                <SampleManifestBackNextNavigation />
            </div>
        </div>
    );

    const mainContentTopLeft = <SampleManifestStepIndicator />;

    const mainContentTop = (
        <div className='flex justify-between pb-2 border-b border-b-grey-40'>
            {mainContentTopLeft}
            {mainContentTopRight}
        </div>
    );

    const mainContent = isFetching ? (
        <Loader />
    ) : (
        <div className='flex flex-col gap-6  mb-10 overflow-hidden'>
            {mainContentTop}
            {sampleManifestFormViewSettings[viewFromUrl as SampleManifestFormViews]?.handler || <InviteTeam />}
        </div>
    );

    const breadcrumbs = [
        { label: 'Projects', path: '/projects' },
        {
            label: currentProjectDetails?.projectName ?? '',
            path: navigation.getPath(SamplesViews.SAMPLE_MANIFEST, {
                projectId: searchParams.get('projectId') || '',
                customerId: searchParams.get('customerId') || '',
            }),
        },
        { label: title },
    ];

    return <StandardAppLayout title={title} breadcrumbs={breadcrumbs} lozenges={lozenges} mainContent={mainContent} />;
};

export default SampleManifestForm;
