import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';

type MapTypeProps = {
    handleChange: (value: string) => void;
    value: string;
};

export const mapTypes = [
    { label: 'Default', value: 'roadmap' },
    { label: 'Terrain', value: 'terrain' },
    { label: 'Satellite', value: 'satellite' },
];

const MapType = ({ handleChange, value }: MapTypeProps) => {
    return (
        <FormControl>
            <RadioGroup name='map-type' value={value} onChange={e => handleChange(e.target.value)}>
                {mapTypes.map(({ label, value }) => (
                    <FormControlLabel key={value} value={value} control={<Radio size='small' />} label={label} />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default MapType;
