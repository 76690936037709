import { gql } from 'graphql-request';
import { ChartIds } from 'src/shared/types';

export const speciesRichnessMetricsQuery = gql`
    query speciesRichnessData($projectId: String!, $fromDate: String, $toDate: String, $habitatAssay: String!) {
        speciesRichness: chart(
            projectId: $projectId
            chartId: "${ChartIds.SPECIES_RICHNESS}"
            habitatAssay: $habitatAssay
            fromDate: $fromDate
            toDate: $toDate
        ) {
            data
        }
    }
`;

export type SpeciesRichnessData = {
    data: {
        data: {
            datetime: string;
            group: {
                type: string;
                value: number;
            }[];
        }[];
        metric: string;
    }[];
};

export type SpeciesRichnessMetricsQueryResult = {
    speciesRichness: SpeciesRichnessData;
};
