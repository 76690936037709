import { CMSImage, CMSSpecies, CMSFeatureTestConfig, TestType } from 'src/shared/types';

import config from 'src/config';
import { KitType } from 'src/shared/helpers/kitHelpers';
import apiService from './api-service';

type GetHighlightedSpeciesProps = {
    projectId: string;
    habitat: string;
    assay: string;
};

type GetHighlightedSpeciesResponse = {
    data: {
        attributes: {
            species: {
                data: {
                    attributes: CMSSpecies;
                };
            };
        };
    }[];
};

type GetAllHabitatAssayTypesResponse = {
    data: {
        attributes: {
            image: CMSImage;
            habitatAssayKey: string;
            habitat: {
                data: {
                    attributes: {
                        habitatName: string;
                    };
                };
            };
            assay: {
                data: {
                    attributes: {
                        assayName: string;
                    };
                };
            };
        };
    }[];
};

type GetFeatureTestConfigResponse = {
    data: {
        attributes: {
            isAvailable: boolean;
            feature: {
                data: {
                    attributes: {
                        featureId: string;
                        featureName: string;
                    };
                };
            };
            habitatAndAssayType: {
                data: {
                    attributes: {
                        habitatAssayKey: string;
                        assay: {
                            data: {
                                attributes: {
                                    assayId: string;
                                    assayName: string;
                                };
                            };
                        };
                        habitat: {
                            data: {
                                attributes: {
                                    habitatId: string;
                                    habitatName: string;
                                };
                            };
                        };
                    };
                };
            };
        };
    }[];
};

type GetKitTestsMappingResponse = {
    data: {
        id: number;
        attributes: {
            kitPrefix: string;
            kitType: string;
            kitName: string;
            createdAt: string;
            updatedAt: string;
            habitatAndAssayTypes: {
                data: {
                    id: number;
                    attributes: {
                        habitatAssayKey: string;
                        createdAt: string;
                        updatedAt: string;
                    };
                }[];
            };
        };
    }[];
};

export type KitHabitatTestTypesMapping = {
    kitType: KitType;
    kitName: string;
    kitPrefix: string;
    testsTypes: TestType[];
};

export class CMSService {
    public async getArticleBySlug(slug: string): Promise<{ content: string; title: string } | null> {
        try {
            const response = await apiService.fetch(`${config.cmsApiUrl}/articles/slug/${slug}`);
            if (!response?.data?.attributes.content) {
                return null;
            }
            return {
                content: response?.data?.attributes.content,
                title: response?.data?.attributes.title,
            };
        } catch (error: any) {
            if (error.status !== 404) {
                console.error('Failed to fetch  content:', error);
            }
            return null;
        }
    }

    public async getSpeciesByScientificName(scientificName: string): Promise<CMSSpecies | null> {
        try {
            const response = await apiService.fetch(`${config.cmsApiUrl}/species/scientific_name/${scientificName}?populate=*`);

            if (!response.data) {
                return null;
            }

            return {
                ...response?.data?.attributes,
                image: this.processImageURLs(response?.data?.attributes?.image),
            };
        } catch (e) {
            // Don't throw an error as we may not be having any details about this species
            return null;
        }
    }

    public async getHighlightedSpecies(props: GetHighlightedSpeciesProps): Promise<CMSSpecies | null> {
        const { projectId, habitat, assay } = props;
        try {
            const response = (await apiService.fetch(
                `${config.cmsApiUrl}/` +
                    `projects-species-highlights?` +
                    `filters[project][projectId][$eq]=${projectId}&` +
                    `filters[habitat][habitatId][$eq]=${habitat}&` +
                    `filters[assay][assayId][$eq]=${assay}&` +
                    `populate=species`
            )) as GetHighlightedSpeciesResponse;
            const speciesScientificName = response?.data?.[0]?.attributes?.species.data.attributes.scientific_name;

            if (!speciesScientificName) {
                return null;
            }

            return this.getSpeciesByScientificName(speciesScientificName);
        } catch (e) {
            throw e;
        }
    }

    public async getAllHabitatAssayTypes() {
        try {
            const response = (await apiService.fetch(
                `${config.cmsApiUrl}/` + `habitat-and-assay-types?` + `populate=*&pagination[pageSize]=999`
            )) as GetAllHabitatAssayTypesResponse;

            return response?.data?.map(entry => {
                const habitatName = entry.attributes.habitat.data.attributes.habitatName;
                const assayName = entry.attributes.assay.data.attributes.assayName;
                const habitatAssayKey = entry.attributes.habitatAssayKey;

                return {
                    habitatName,
                    assayName,
                    habitatAssayKey,
                    image: this.processImageURLs(entry.attributes?.image) || {},
                };
            });
        } catch (e) {
            throw e;
        }
    }

    public async getFeatureTestConfig() {
        try {
            const featureField = '[populate][0]=feature';
            const habitatAndAssayTypeField = '[populate][1]=habitatAndAssayType';
            const habitatField = '[populate][2]=habitatAndAssayType.habitat';
            const assayField = '[populate][3]=habitatAndAssayType.assay';
            const populateFields = [featureField, habitatAndAssayTypeField, habitatField, assayField].join('&');
            const response = (await apiService.fetch(
                `${config.cmsApiUrl}/feature-test-configs?${populateFields}&pagination[pageSize]=999`,
                { noCache: true }
            )) as GetFeatureTestConfigResponse;

            const featureTestConfig: CMSFeatureTestConfig = {};
            response?.data?.forEach(entry => {
                const featureId = entry.attributes.feature.data.attributes.featureId;
                const habitatId = entry.attributes.habitatAndAssayType.data.attributes.habitat.data.attributes.habitatId;
                const assayId = entry.attributes.habitatAndAssayType.data.attributes.assay.data.attributes.assayId;

                if (!featureTestConfig[featureId]) {
                    featureTestConfig[featureId] = [];
                }

                if (entry.attributes.isAvailable) {
                    featureTestConfig[featureId].push(`${habitatId}-${assayId}`);
                }
            });

            return featureTestConfig;
        } catch (e) {
            //handle error to allow login to proceed
            console.error('Failed to load feature test config', e);
            return {};
        }
    }

    public substituteArticlePlaceholders(content: string, substitutions: { [key: string]: string }) {
        const keys = Object.keys(substitutions);

        keys.forEach(key => {
            content = content.replaceAll(`[[${key}]]`, substitutions[key]);
        });

        return content;
    }

    private processImageURLs(image: CMSImage | null) {
        if (!image) {
            return null;
        }

        if (!image.data?.attributes?.formats && !image.data?.attributes?.url) {
            return null;
        }

        const large = image.data.attributes.formats?.large?.url;
        const medium = image.data.attributes.formats?.medium?.url;
        const small = image.data.attributes.formats?.small?.url;
        const thumbnail = image.data.attributes.formats?.thumbnail?.url;
        const original = image.data.attributes.url;

        return {
            large: large || medium || small || thumbnail || original,
            medium: medium || large || small || thumbnail || original,
            small: small || medium || large || thumbnail || original,
            thumbnail: thumbnail || small || medium || large || original,
        };
    }

    public async getProjectById(projectId: string): Promise<any | null> {
        try {
            const response = await apiService.fetch(`${config.cmsApiUrl}/projects?filters[projectId][$eq]=${projectId}`);
            return {
                isDemo: response?.data[0]?.attributes?.isDemo || false,
            };
        } catch (error: any) {
            if (error.status !== 404) {
                console.error('Failed to fetch  content:', error);
            }
            return {
                isDemo: false,
            };
        }
    }

    public async getKitTestsMapping(): Promise<KitHabitatTestTypesMapping[] | null> {
        const allHabitatAssayTypes = await this.getAllHabitatAssayTypes();

        try {
            const response: GetKitTestsMappingResponse = await apiService.fetch(`${config.cmsApiUrl}/kits?populate=*`);

            const kitTestsMapping = [...response.data].map((item: any) => {
                return {
                    kitType: item.attributes.kitType as KitType,
                    kitName: item.attributes.kitName as string,
                    kitPrefix: item.attributes.kitPrefix as string,
                    testsTypes: item.attributes.habitatAndAssayTypes.data.map((habitatAssayType: any) => {
                        return allHabitatAssayTypes.find(
                            (habitatAssayTypeItem: any) =>
                                habitatAssayTypeItem.habitatAssayKey === habitatAssayType.attributes.habitatAssayKey
                        );
                    }) as TestType[],
                };
            });

            return kitTestsMapping;
        } catch (error: any) {
            if (error.status !== 404) {
                console.error('Failed to fetch  content:', error);
            }
            return null;
        }
    }
}

export default new CMSService();
