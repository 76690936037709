import * as Sentry from '@sentry/react';
import config from 'src/config';

interface ErrorServiceInterface {
    report: (error: Error) => void;
}

export class ErrorService implements ErrorServiceInterface {
    private isReportingServiceInitialized = false;

    public constructor() {
        this.initializeReportingService();
    }

    public report(error: Error) {
        if (this.isReportingServiceInitialized) {
            Sentry.captureException(error);
        } else {
            console.log(error); // eslint-disable-line no-console
        }
    }

    public initializeReportingService() {
        if (this.isReportingServiceInitialized) {
            return;
        }
        if (config.shouldReportErrors) {
            Sentry.init({
                environment: config.deploymentEnv,
                dsn: config.sentryDsn,
                ignoreErrors: ['AbortError'],
            });
            this.isReportingServiceInitialized = true;
        }
    }
}

const errorService = new ErrorService();
export default errorService;
