import React from 'react';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability, getProjectRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation } from 'react-router-dom';
import { projectRouteSettings } from 'src/app/projects/Projects';
import styles from '../SiteMenu.module.scss';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

const ProjectsMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);
    const projectRoutesAvailability = useAppSelector(getProjectRoutesAvailability);

    const navigate = useAppNavigation();
    const { pathname } = useLocation();

    if (!appRoutesAvailability.projects) {
        return null;
    }

    const getClassNames = (menuItemPath: string) => {
        const itemPath = '/projects' + menuItemPath;
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: pathname === itemPath || pathname + '/' === itemPath,
        });
    };

    return (
        <>
            {projectRoutesAvailability.projects && (
                <div className={getClassNames(projectRouteSettings.projects.path)} onClick={() => navigate.toProjects()}>
                    <div className={styles.icon}>
                        <FolderOutlinedIcon />
                    </div>
                    <div className={styles.label}>Projects</div>
                </div>
            )}

            {projectRoutesAvailability.projectDefinition && (
                <div className={getClassNames(projectRouteSettings.projectDefinition.path)} onClick={navigate.toProjectDefinition}>
                    <div className={styles.icon}>
                        <FolderOutlinedIcon />
                    </div>
                    <div className={styles.label}>Project Definition</div>
                </div>
            )}
        </>
    );
};

export default ProjectsMenu;
