import React, { useEffect, useState } from 'react';

import ChevronButton from 'src/shared/components/chevron-button/ChevronButton';
import Input from 'src/shared/components/input/Input';

import styles from './Pagination.module.scss';

export type PaginationProps = {
    currentPageNumber: number;
    lastPageNumber: number;
    onChange: (pageNumber: number) => void;
};

const Pagination = (props: PaginationProps) => {
    const { currentPageNumber, lastPageNumber } = props;
    const [pageNumber, setPageNumber] = useState<number | ''>(currentPageNumber);

    useEffect(() => {
        setPageNumber(currentPageNumber);
    }, [currentPageNumber]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pageNumberFromInput = Number(event.target.value);

        if (event.target.value === '') {
            setPageNumber('');
            return;
        }

        if (isNaN(pageNumberFromInput) || pageNumberFromInput > lastPageNumber || pageNumberFromInput < 1) {
            event.preventDefault();
            return;
        }

        setPageNumber(pageNumberFromInput);
        props.onChange(pageNumberFromInput ? pageNumberFromInput : 1);
    };

    const onBlur = () => {
        setPageNumber(pageNumber ? pageNumber : 1);
        props.onChange(pageNumber ? pageNumber : 1);
    };

    return (
        <div className={styles.pagination}>
            <ChevronButton
                direction='left'
                className={styles.chevron}
                disabled={currentPageNumber <= 1}
                onClick={() => props.onChange(currentPageNumber - 1)}
            />
            <div className={styles.inputWrapper}>
                <Input
                    value={pageNumber}
                    onBlur={onBlur}
                    onChange={onChange}
                    className={styles.inputBox}
                    disabled={lastPageNumber === 1}
                    data-testid='page-number-input'
                />
            </div>
            of {lastPageNumber}
            <ChevronButton
                direction='right'
                className={styles.chevron}
                disabled={currentPageNumber >= lastPageNumber}
                onClick={() => props.onChange(currentPageNumber + 1)}
            />
        </div>
    );
};

export default Pagination;
