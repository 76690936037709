import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import NMLogo from 'src/assets/images/naturemetrics_logo.png';
import NMbackground from 'src/assets/images/img-login-background.webp';

import Signin from 'src/app/login/views/Signin';
import ForgotPassword from 'src/app/login/views/ForgotPassword';
import VerifyCustomChallenge from 'src/app/login/views/VerifyCustomChallenge';
import SetNewPassword from 'src/app/login/views/SetNewPassword';

import cmsService from 'src/services/cms-service';
import { sample } from 'lodash';
import styles from './Login.module.scss';
import TermsOfUseDeclined from './views/terms-of-use/TermsOfUseDeclined';

// Lazy load the terms of use component
const TermsOfUseComponent = lazy(() => import('./views/terms-of-use/TermsOfUse'));

const Login = () => {
    const { pathname } = useLocation();
    const isTermsOfUsePage = pathname.includes('terms-of-use');

    const [backgroundImageUrl, setBackgroundImageUrl] = React.useState<string>('');

    useEffect(() => {
        const getImageURLFromCMS = async () => {
            try {
                const response = await cmsService.getArticleBySlug('platform-login-background-image');
                if (response && response.content) {
                    // parse image url from the content
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(response.content, 'text/html');
                    const imgElements = htmlDoc.querySelectorAll('img');
                    const randomImageElement = sample(Array.from(imgElements));
                    if (randomImageElement) {
                        // verifiy if the image url is valid
                        const img = new Image();
                        img.src = randomImageElement.src;
                        img.onload = () => {
                            setBackgroundImageUrl(randomImageElement.src);
                        };
                        img.onerror = () => {
                            setBackgroundImageUrl(NMbackground);
                        };
                    } else {
                        setBackgroundImageUrl(NMbackground);
                    }
                }
            } catch {
                setBackgroundImageUrl(NMbackground);
            }
        };
        getImageURLFromCMS();
    }, []);

    return (
        <div className={styles.loginContainer} style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
            <div className={styles.centerBoxContainer}>
                <div className={!isTermsOfUsePage ? styles.centerBox : undefined}>
                    {!isTermsOfUsePage && (
                        <div className={styles.logoContainer}>
                            <img src={NMLogo} alt='Nature Metrics' />
                        </div>
                    )}
                    <Routes>
                        <Route path={'/forgot-password'} element={<ForgotPassword />} />
                        <Route path={'/verify-custom-challenge'} element={<VerifyCustomChallenge />} />
                        <Route path={'/set-new-password'} element={<SetNewPassword />} />
                        <Route
                            path={'/terms-of-use'}
                            element={
                                <Suspense fallback='Loading...'>
                                    <TermsOfUseComponent />
                                </Suspense>
                            }
                        />
                        <Route path={'/terms-of-use-declined'} element={<TermsOfUseDeclined />} />
                        <Route path={'/'} element={<Signin />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};
export default Login;
