import React from 'react';
import Stack from '@mui/material/Stack';
import { Container, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

type LoaderProps = {
    padding?: string;
};

export const Loader = ({ padding }: LoaderProps) => {
    const boxPadding = padding || '20rem';
    return (
        <Container>
            <Box sx={{ textAlign: 'center', paddingY: boxPadding }} data-testid='loader'>
                <CircularProgress sx={{ color: '#27D8CE' }} />
                <Typography style={{ color: '#BFBFBF' }} variant='subtitle2'>
                    loading...
                </Typography>
            </Box>
        </Container>
    );
};
export const LoaderInline = () => {
    return (
        <Stack sx={{ color: 'grey.500' }} spacing={2} direction='row' data-testid='inline-loader'>
            <CircularProgress color='secondary' />
        </Stack>
    );
};

export const LinearLoader = () => {
    return (
        <Box maxWidth='xl' className='linearLoader' data-testid='linear-loader'>
            <Stack spacing={2}>
                <LinearProgress color='success' />
            </Stack>
        </Box>
    );
};

export default Loader;
