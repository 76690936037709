import authService from 'src/services/auth-service';
import config from 'src/config';
import loginStorageService from 'src/app/login/services/login-storage-service';

export const redirectToShopify = () => {
    const jwtToken = authService.getCognitoUserSession()?.getIdToken().getJwtToken();
    const redirectUrlTemplate = config.shopifyRedirectUrl;
    if (redirectUrlTemplate && jwtToken) {
        const redirectUrl = redirectUrlTemplate.replace('{jwtToken}', jwtToken);
        loginStorageService.setRedirectPath('');
        window.location.assign(redirectUrl);
    }
};
