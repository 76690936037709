import React, { ReactNode } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const WarningMessage = ({ message }: { message: ReactNode }) => {
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity='warning' color='warning'>
                {message}
            </Alert>
        </Stack>
    );
};

export default WarningMessage;
