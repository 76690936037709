export const habitatInsightsLandCoverTypes = [
    'Water',
    'Grass',
    'Trees',
    'Flooded Vegetation',
    'Crops',
    'Shrub and Scrub',
    'Built',
    'Bare',
    'Snow and Ice',
];

export const habitatInsightsConnectivityTypes = [
    'Grass',
    'Grass - Annual Variability',
    'Trees',
    'Trees - Annual Variability',
    'All Green',
    'All Green - Annual Variability',
];

export const habitatInsightsPrimaryProductivityTypes = ['Annual Average', 'Annual Average - Annual Range'];
