import React from 'react';
import Modal from 'src/shared/components/modal/Modal';
import { PrimaryButton } from 'src/shared/components/button/Button';
import { ReactComponent as ErrorIcon } from 'src/assets/svg/icon-err-dark.svg';

type SubmitionErrorModalProps = {
    onClose: () => void;
};

const SubmitionErrorModal = (props: SubmitionErrorModalProps) => {
    const { onClose } = props;
    return (
        <Modal onClose={onClose} dismissOnClickOutside={true}>
            <div className='flex flex-col gap-6'>
                <div className='flex gap-2 text-red-100'>
                    <ErrorIcon /> Error
                </div>
                <div>
                    An error occurred while submitting your data.
                    <br />
                    <br />
                    Please try again later.
                </div>
                <div className='flex gap-3'>
                    <PrimaryButton className='flex gap-2 w-full' onClick={onClose}>
                        OK
                    </PrimaryButton>
                </div>
            </div>
        </Modal>
    );
};

export default SubmitionErrorModal;
