import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import { SurveyDesignsQueryResult, surveyDesignsQuery } from './queries/surveyQuery';

type SurveyDesignsQueryParams = {
    customerId: string;
    SurveyDesignStatus?: string;
};
const SurveyDesignListGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        surveyDesigns: builder.query<SurveyDesignsQueryResult, SurveyDesignsQueryParams>({
            query: params => ({
                body: surveyDesignsQuery,
                variables: params,
            }),
        }),
    }),
});

export const { useLazySurveyDesignsQuery, useSurveyDesignsQuery } = SurveyDesignListGraphSlice;

export const { surveyDesigns: fetchSurveyDesigns } = SurveyDesignListGraphSlice.endpoints;
