import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';

import {
    ProjectsQueryResult,
    projectsQuery,
    ProjectQueryResult,
    projectQuery,
    ProjectFiltersQueryResult,
    projectFiltersQuery,
    customerProjectsInBCQuery,
    CustomerProjectsInBCQueryResult,
} from './queries/projectsListQuery';

type ProjectsQueryParams = {
    customerId: string;
};

type ProjectQueryParams = {
    projectId: string;
    samples?: {
        fromDate: string;
        toDate: string;
        assay: string;
    };
};

type ProjectFiltersQueryParams = {
    projectId: string;
};

type CustomerProjectsQueryParams = {
    customerId: string;
};

const projectListGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        projects: builder.query<ProjectsQueryResult, ProjectsQueryParams>({
            query: params => ({
                body: projectsQuery,
                variables: params,
            }),
            transformResponse: (response: ProjectsQueryResult) => {
                return {
                    customer: {
                        ...response.customer,
                        projects: response.customer.projects.map(project => {
                            return {
                                ...project,
                                projectCode: !project.projectCode || project.projectCode === 'Not set' ? 'Missing' : project.projectCode,
                            };
                        }),
                    },
                };
            },
        }),
        customerProjectsInBC: builder.query<CustomerProjectsInBCQueryResult, CustomerProjectsQueryParams>({
            query: params => ({
                body: customerProjectsInBCQuery,
                variables: params,
            }),
        }),
        project: builder.query<ProjectQueryResult, ProjectQueryParams>({
            query: params => ({
                body: projectQuery,
                variables: params,
            }),
        }),
        projectFilters: builder.query<ProjectFiltersQueryResult, ProjectFiltersQueryParams>({
            query: params => ({
                body: projectFiltersQuery,
                variables: params,
            }),
        }),
    }),
});

export const { useLazyProjectsQuery, useProjectsQuery, useProjectQuery, useProjectFiltersQuery, useCustomerProjectsInBCQuery } =
    projectListGraphSlice;

export const { projects: fetchProjects, project: fetchProject } = projectListGraphSlice.endpoints;
