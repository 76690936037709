import React, { useEffect } from 'react';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';

import { useAppSelector, useAppDispatch } from 'src/store';
import NMlogo from 'src/assets/images/naturemetrics_logo_icon.png';
import NMlogoFull from 'src/assets/images/naturemetrics_logo.png';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import useAuthService from 'src/shared/hooks/useAuthService';
import ProjectsMenu from './components/ProjectsMenu';
import AdminMenu from './components/AdminMenu';
import MapMenu from './components/MapMenu';
import DashboardMenu from './components/DashboardMenu';
import InsightsMenu from './components/InsightsMenu';
import TablesMenu from './components/TablesMenu';
import UserMenu from './components/UserMenu';
import CustomersMenu from './components/CustomersMenu';
import SurveyMenu from './components/SurveyMenu';
import SamplesMenu from './components/SamplesMenu';
import styles from './SiteMenu.module.scss';
import SupportMenu from './components/SupportMenu';
import ShopifyMenu from './components/ShopifyMenu';
import { getIsSiteMenuOpen } from '../../state/site-menu/siteMenuSelector';
import { closeSiteMenu, openSiteMenu } from '../../state/site-menu/siteMenuSlice';

const SiteMenu = () => {
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const { isAuthenticated } = useAuthService();
    const { pathname } = useLocation();
    const isLoginPages = pathname.includes('login') || pathname === '/';

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(closeSiteMenu());
    }, [pathname]);

    const sitemenuClassNames = classNames(styles.siteMenu, {
        [styles.siteMenuOpen]: isOpen,
    });

    if (isLoginPages || !isAuthenticated) {
        return null;
    }

    return (
        <>
            <div className={sitemenuClassNames}>
                <div className={styles.logo}>{isOpen ? <img src={NMlogoFull} alt='logo' /> : <img src={NMlogo} alt='logo' />}</div>
                {!isOpen ? (
                    <div className={styles.toggle} onClick={() => dispatch(openSiteMenu())}>
                        <KeyboardDoubleArrowRight />
                    </div>
                ) : (
                    <span></span>
                )}
                <div className={styles.menuItems}>
                    <CustomersMenu />
                    <AdminMenu />
                    <ProjectsMenu />
                    <SamplesMenu />
                    <SurveyMenu />
                    <MapMenu />
                    <DashboardMenu />
                    <InsightsMenu />
                    <TablesMenu />
                </div>
                <div></div>
                <div className={classNames(styles.menuItems, styles.footer)}>
                    <SupportMenu />
                    <ShopifyMenu />
                    <UserMenu />
                </div>
            </div>
            {isOpen && <div className={styles.siteMenuOverlay} onClick={() => dispatch(closeSiteMenu())}></div>}
        </>
    );
};

export default SiteMenu;
