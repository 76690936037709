import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { SamplingKitStatus, SamplingKit } from 'src/shared/types';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';

type ProcessingControlProps = {
    samplingKitId: string;
    isDisabled: boolean;
};

const ProcessingControl = ({ samplingKitId, isDisabled }: ProcessingControlProps) => {
    const [kitStatus, setKitStatus] = useState<SamplingKitStatus>(SamplingKitStatus.SUBMITTED);
    const { saveSamplingKit, samplingEvent } = useSamplingEvent();
    const kit = useMemo(() => {
        return samplingEvent?.sampleManifestInfo.kits.find((kit: SamplingKit) => kit.id === samplingKitId);
    }, [samplingEvent, samplingKitId]);

    const isKitDisabled = isDisabled || kit?.status === SamplingKitStatus.REJECTED_BY_LAB;

    useEffect(() => {
        if (kit) {
            setKitStatus(kit.status);
        }
    }, []);

    const saveKitStatus = async (status: SamplingKitStatus) => {
        if (kit && kit.id) {
            await saveSamplingKit({ ...kit, status: status });
        }
    };

    const toggleIcon = (status: SamplingKitStatus) => {
        const newStatus = kitStatus === status ? SamplingKitStatus.SUBMITTED : status;
        setKitStatus(newStatus);
        saveKitStatus(newStatus);
    };

    const statusClasses = useMemo(() => {
        const baseClass = 'flex items-center justify-center w-10 h-7 p-1 rounded-md border';
        return {
            base: { baseClass },
            accept: classNames(
                baseClass,
                'border-green-100',
                kitStatus === SamplingKitStatus.APPROVED_BY_LAB ? 'text-white bg-green-100' : 'text-green-100'
            ),
            acceptDisabled: classNames(
                baseClass,
                'border-grey-40',
                kitStatus === SamplingKitStatus.APPROVED_BY_LAB ? 'text-white bg-grey-40' : 'text-grey-40'
            ),
            flag: classNames(
                baseClass,
                'border-red-100',
                kitStatus === SamplingKitStatus.FLAGGED || kitStatus === SamplingKitStatus.REJECTED_BY_LAB
                    ? 'text-white bg-red-100'
                    : 'text-red-100'
            ),
            flagDisabled: classNames(
                baseClass,
                'border-grey-40',
                kitStatus === SamplingKitStatus.FLAGGED || kitStatus === SamplingKitStatus.REJECTED_BY_LAB
                    ? 'text-white bg-grey-40'
                    : 'text-grey-40'
            ),
        };
    }, [kitStatus]);

    return (
        <div className='flex space-x-4'>
            <div
                className={classNames('cursor-pointer', { 'pointer-events-none': isKitDisabled })}
                onClick={!isKitDisabled ? () => toggleIcon(SamplingKitStatus.APPROVED_BY_LAB) : undefined}
            >
                <div className={isKitDisabled ? statusClasses.acceptDisabled : statusClasses.accept}>
                    <DoneIcon />
                </div>
            </div>
            <div
                className={classNames('cursor-pointer', { 'pointer-events-none': isKitDisabled })}
                onClick={!isKitDisabled ? () => toggleIcon(SamplingKitStatus.FLAGGED) : undefined}
            >
                <div className={isDisabled ? statusClasses.flagDisabled : statusClasses.flag}>
                    <CloseIcon />
                </div>
            </div>
        </div>
    );
};

export default ProcessingControl;
