import React from 'react';
import Select from 'src/shared/components/select/Select';
import { useAppDispatch, useAppSelector } from 'src/store';
import { NewSurveyDesignFormState, setFormState } from 'src/app/surveys/views/new-survey-design/state/newSurveyDesignSlice';
import { getCurrentSurveyDesignFormState } from 'src/app/surveys/views/new-survey-design/state/newSurveyDesignSelector';
import styles from 'src/app/surveys/views/new-survey-design/NewSurveyDesign.module.scss';
import Textarea from 'src/shared/components/textarea/Textarea';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import useSubscriptionTypes from 'src/app/projects/views/new-project-definition/hooks/useSubscriptionTypes';

const GeneralSurveyDesignAims = () => {
    const dispatch = useAppDispatch();
    const formState = useAppSelector(getCurrentSurveyDesignFormState);
    const { projectTypeOptions } = useSubscriptionTypes(formState.subscriptionType);
    const onFormChange = (fragment: Partial<NewSurveyDesignFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    return (
        <div className={styles.formSection}>
            <div className={styles.formInputSection}>
                <div className={styles.formInputSection}>
                    <div className={styles.formInputSectionTitle}>
                        Project type <QuestionButtonHelp type='api' slug='project-definition-project-type' />
                    </div>
                    <Select
                        className={{ container: styles.selectContainer }}
                        options={projectTypeOptions}
                        selectedValue={formState.projectType as string}
                        onChange={value => onFormChange({ projectType: value as string })}
                        width='100%'
                        placeholder='Select project type'
                    />
                </div>

                <div className={styles.formInputSection}>
                    <div className={styles.formInputSectionTitle}>Project outline</div>
                    <Textarea
                        maxLength={600}
                        value={formState.projectOutline}
                        onChange={event => onFormChange({ projectOutline: event.target.value })}
                        resizable={false}
                        style={{ height: '295px' }}
                    />
                </div>

                <div className={styles.formInputSection}>
                    <div className={styles.formInputSectionTitle}>
                        Target outcomes <QuestionButtonHelp type='api' slug='project-definition-target-outcomes' />
                    </div>
                    <Textarea
                        maxLength={300}
                        value={formState.targetOutcomes}
                        onChange={event => onFormChange({ targetOutcomes: event.target.value })}
                        resizable={false}
                        style={{ height: '164px' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default GeneralSurveyDesignAims;
