import React, { useState, useEffect } from 'react';
import Modal from 'src/shared/components/modal/Modal';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { ReactComponent as SubmitIcon } from 'src/assets/svg/general/submit.svg';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import useSamplingEvent from 'src/app/samples/hooks/useSamplingEvent';
import { SamplingEventBatchStatus, SamplingEventBatch, SamplingEventBatchAmendmentOwner } from 'src/shared/types';
import Textarea from 'src/shared/components/textarea/Textarea';
import useBoolean from 'src/shared/hooks/useBoolean';

const DataAmendmentModal = ({ id, status, amendmentMessage }: SamplingEventBatch) => {
    const [message, setMessage] = useState('');
    const { refetchSamplingEvent, amendmentRequiredForBatch, isKitsInBatchProcessed } = useSamplingEvent();
    const [isModalOpen, modalSwitch] = useBoolean(false);
    const requireCustomerAmendment = status === SamplingEventBatchStatus.REQUIRED_CUSTOMER_AMENDMENT;
    const approveBatchByLogistics = status === SamplingEventBatchStatus.APPROVED_BY_LOGISTICS;
    const isBatchProcessed = isKitsInBatchProcessed(id);
    const isDisabled = approveBatchByLogistics && !isBatchProcessed;

    useEffect(() => {
        if (isModalOpen && !requireCustomerAmendment) {
            setMessage(prev => (amendmentMessage && !prev.includes(amendmentMessage) ? `${amendmentMessage}\n\n${prev}`.trim() : prev));
        }
    }, [isModalOpen, amendmentMessage, requireCustomerAmendment]);

    const onSubmitHandler = () => {
        const owner = SamplingEventBatchStatus.APPROVED_BY_LOGISTICS
            ? SamplingEventBatchAmendmentOwner.LAB
            : SamplingEventBatchAmendmentOwner.LOGISTICS;
        amendmentRequiredForBatch(id, message, owner);
        refetchSamplingEvent();
        modalSwitch.off();
    };

    return (
        <>
            <SecondaryButton className='flex gap-2' onClick={() => modalSwitch.on()} disabled={isDisabled}>
                <SubmitIcon className='fill-grey-60' />{' '}
                {requireCustomerAmendment ? 'View data amendment request' : 'Send for data amendment'}
            </SecondaryButton>
            {isModalOpen && (
                <Modal
                    classNames={{
                        body: 'w-[650px]',
                    }}
                    onClose={modalSwitch.off}
                >
                    <div className='flex flex-col gap-6'>
                        <div className='flex flex-row gap-1 text-2xl text-teal-100'>
                            <span className='w-10 h-10 rounded-full bg-white flex justify-center items-center border '>
                                {requireCustomerAmendment ? <ChatBubbleOutlineOutlinedIcon /> : <SendOutlinedIcon />}
                            </span>
                            &nbsp; <span>{requireCustomerAmendment ? 'View data amendment request ' : 'Sample Batch Amendment'}</span>
                        </div>
                        <div className='flex flex-col gap-4'>
                            {requireCustomerAmendment ? (
                                <p className='text-grey-60'>The text below was sent to the customer as an email</p>
                            ) : (
                                <>
                                    <p>Provide details about why the sample batch needs to be amended</p>
                                    <p className='text-grey-60'>The text entered below will be sent to the customer as an email</p>
                                    {/*Todo: Add the email address of the customer*/}
                                    <p className='text-grey-60'>Sending to: &lt;name@customer.com&gt;</p>
                                </>
                            )}
                        </div>

                        <Textarea
                            value={requireCustomerAmendment ? amendmentMessage : message}
                            resizable={true}
                            maxLength={800}
                            onChange={event => setMessage(event.target.value)}
                        />
                        {!requireCustomerAmendment && (
                            <div className='flex gap-3'>
                                <PrimaryButton
                                    className='flex gap-2'
                                    onClick={onSubmitHandler}
                                    disabled={!message?.length || requireCustomerAmendment}
                                >
                                    Send to Customer for amendment <SubmitIcon className='fill-white' />
                                </PrimaryButton>
                            </div>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default DataAmendmentModal;
