import { gql } from 'graphql-request';

export const habitatInsightsEcologicalTrendsQuery = gql`
    query getHabitatInsightsEcologicalTrends($projectId: String!, $hlcaCharts: [HlcaChartType!], $siteName: [String!]) {
        habitatInsightsEcologicalTrends: chart(
            projectId: $projectId
            chartId: "habitatinsightschart"
            hlcaCharts: $hlcaCharts
            siteName: $siteName
        ) {
            data
        }
    }
`;

type HabitatInsightsEcologicalTrendsData = {
    data: {
        chartType: string;
        datetime: string;
        group: {
            type: string;
            value: number;
            bufferArea?: number;
        }[];
        site: string;
    }[];
};

export type HabitatInsightsEcologicalTrendsQueryResult = {
    habitatInsightsEcologicalTrends: HabitatInsightsEcologicalTrendsData;
};
