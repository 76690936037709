import { gql } from 'graphql-request';
import { ProjectSampleManifestStatus, SamplingEvent, SamplingEventBatch } from 'src/shared/types';

export const samplingEventsQuery = gql`
    query samplingEvents($filter: SampleReceptionProjectListFilter!, $pagination: PaginationInput!, $sortBy: SortByInput) {
        sampleManifest {
            samplingReceptionProjectList(filter: $filter, pagination: $pagination, sortBy: $sortBy) {
                projects {
                    projectId
                    projectName
                    projectCode
                    customerId
                    customerName
                    subscriptionType
                    sampleManifestStatus
                    ongoingEvent {
                        identifier
                        processed
                        processedDate
                        status
                        sampleManifestInfo {
                            batches {
                                id
                                status
                                createdAt
                                logisticsApprovalAt
                                labApprovalAt
                            }
                        }
                    }
                    otherEvents {
                        identifier
                        processed
                        processedDate
                        status
                        sampleManifestInfo {
                            batches {
                                id
                                status
                                createdAt
                                logisticsApprovalAt
                                labApprovalAt
                            }
                        }
                    }
                }
                pagination {
                    total
                    pageSize
                    page
                }
            }
        }
    }
`;

type PartialOtherSamplingEvent = Pick<SamplingEvent, 'status' | 'identifier'> & {
    sampleManifestInfo: {
        batches: Pick<SamplingEventBatch, 'id' | 'status' | 'createdAt' | 'logisticsApprovalAt' | 'labApprovalAt'>[];
    };
};
type ReceptionListSamplingEvent = Pick<SamplingEvent, 'identifier' | 'processed' | 'processedDate'> & {
    sampleManifestInfo: {
        batches: Pick<SamplingEventBatch, 'id' | 'status' | 'createdAt' | 'logisticsApprovalAt' | 'labApprovalAt'>[];
    };
};

type IndividualProject = {
    projectId: string;
    projectName: string;
    projectCode: string;
    customerId: string;
    customerName: string;
    subscriptionType: string;
    sampleManifestStatus: ProjectSampleManifestStatus;
    ongoingEvent: ReceptionListSamplingEvent;
    otherEvents: PartialOtherSamplingEvent[];
};
export type SamplingEventsQueryResult = {
    projects: IndividualProject[];
    pagination: {
        total: number;
        pageSize: number;
        page: number;
    };
};
