import { RootState } from 'src/store';
import {
    HabitatInsightsUploadFormState,
    HabitatInsightsUploadFormUIState,
    defaultHabitatInsightsUploadFormState,
} from './habitatInsightsUploadSlice';

export const getHabitatInsightsUploadUIState = (state: RootState): HabitatInsightsUploadFormUIState | null => {
    return state.surveys.habitatInsightsUpload.uiState || null;
};

export const getCurrentHabitatInsightsUploadFormStep = (state: RootState): number => {
    const currentUIState = getHabitatInsightsUploadUIState(state);

    if (!currentUIState) {
        return 0;
    }

    return currentUIState.formStep;
};

export const getCurrentHabitatInsightsUploadFormState = (state: RootState): HabitatInsightsUploadFormState => {
    const currentUIState = getHabitatInsightsUploadUIState(state);

    if (!currentUIState) {
        return defaultHabitatInsightsUploadFormState;
    }

    return currentUIState.formState;
};
