import React from 'react';
import { Access, Role, UserType } from 'src/shared/types';
import { useAppSelector, useAppDispatch } from 'src/store';
import {
    getNewProjectUserFormState,
    getUserManagementGeneralUiState,
} from 'src/app/admin/views/user-management/state/userManagementSelector';
import { NewProjectUserFormState, setFormState } from 'src/app/admin/views/user-management/state/userManagementSlice';
import useProject from 'src/shared/hooks/useProject';
import useAuthService from 'src/shared/hooks/useAuthService';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import UserDetails from 'src/app/admin/views/user-management/components/user-details/UserDetails';
import AssignProjects from 'src/app/admin/views/user-management/components/assign-projects/AssignProjects';
import AssignAdminRoles from 'src/app/admin/views/user-management/components/assign-admin-roles/AssignAdminRoles';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';
import styles from './UserProjectsForm.module.scss';

const UserProjectsForm = () => {
    const formState = useAppSelector(getNewProjectUserFormState);
    const dispatch = useAppDispatch();
    const { isCurrentUserOnboardingManager, isCurrentUserSuperAdmin } = useAuthService();
    const { currentCustomerId } = useCustomer();
    const { currentCustomerProjects } = useProject();
    const generalUiState = useAppSelector(getUserManagementGeneralUiState);
    const { hasCustomerAdminInUsers } = generalUiState;

    const userRoles = formState?.selectedUser?.customers.find(customer => customer.customerId === currentCustomerId)?.roles;
    //dont show Customer Admin Badge permisions if user is not admin and usertype is internal
    const isCustomerAdmin =
        (isCurrentUserOnboardingManager || isCurrentUserSuperAdmin) &&
        formState.userType !== UserType.INTERNAL &&
        (Boolean(userRoles?.includes(Role.CUSTOMER_ADMIN)) || formState.roles.includes(Role.CUSTOMER_ADMIN));

    const onFormChange = (fragment: Partial<NewProjectUserFormState['formState']>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    let userType = UserType.CUSTOMER;
    if (isCurrentUserSuperAdmin && formState.userType === UserType.INTERNAL) {
        userType = UserType.INTERNAL;
    }

    const userDetailsProps = formState.selectedUser
        ? { ...formState.selectedUser, userType: UserType.INTERNAL }
        : {
              firstName: formState.firstName,
              lastName: formState.lastName,
              email: formState.email,
              phone: `${formState.phoneCountryCode} ${formState.phone}`,
              userType: userType,
              isCustomerAdmin,
              status: 'Active',
              contactInfoClass: styles.userContact,
          };

    //disable projects permisions if customer has no projects
    const disableProjectPermissions = (currentCustomerProjects?.length ?? 0) < 1;

    return (
        <div className={styles.userProjectForm}>
            <div className={styles.stepIndicator}>Step 2 of 2</div>
            <div className={styles.sectionContainer}>
                <div className={styles.section}>
                    <div className={styles.sectionHeading}>Projects and roles</div>
                    <UserDetails {...userDetailsProps} />
                </div>
                {(isCurrentUserOnboardingManager || isCurrentUserSuperAdmin) && (
                    <div className={styles.section}>
                        {!hasCustomerAdminInUsers && (
                            <InfoMessage
                                className={styles.firstUserInfo}
                                message='The first external user created for this organisation must be the customer admin and/or a project admin'
                            />
                        )}
                        <AssignAdminRoles onChange={onFormChange} roles={formState.roles} defaultRoles={userRoles} />
                    </div>
                )}
                <div className={styles.section}>
                    <div className={styles.sectionHeading}>Projects permissions</div>
                    <AssignProjects
                        formState={{ ...formState, is_internal_user: formState.userType === UserType.INTERNAL }}
                        onFormChange={onFormChange}
                        formMode={Access.CREATE}
                        disabled={disableProjectPermissions}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserProjectsForm;
