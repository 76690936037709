import React from 'react';
import { FormControlLabel, FormControlLabelProps, Switch as MUISwitch, SwitchProps as MUISwitchProps } from '@mui/material';

type SwitchProps = {
    checked: boolean;
    className?: string;
    label: string;
    color?: MUISwitchProps['color'];
    size?: MUISwitchProps['size'];
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    onChange: (checked: boolean) => void;
};

const Switch = (props: SwitchProps) => {
    const { checked, className, onChange, label, labelPlacement = 'end', color, size } = props;

    return (
        <div className={className}>
            <FormControlLabel
                style={{ marginLeft: 0 }}
                control={<MUISwitch checked={checked} onChange={e => onChange(e.target.checked)} color={color} size={size} />}
                label={label}
                labelPlacement={labelPlacement}
            />
        </div>
    );
};

Switch.defaultProps = {
    color: 'primary',
    size: 'small',
};

export default Switch;
