import { RootState } from 'src/store';
import {
    GeneralUIState,
    NewProjectUserFormState,
    UserManagementListFilterState,
    UserManagementUIState,
    defaultGeneralUiState,
    defaultNewProjectUserFormState,
    defaultUserManagementListFilterState,
    defaultEditProjectUserFormState,
    EditProjectUserFormState,
} from './userManagementSlice';

export const getUserManagementUIState = (state: RootState): UserManagementUIState => {
    return state.admin.userManagement.uiState;
};

export const getNewProjectUserFormStep = (state: RootState): string => {
    const currentUIState = getUserManagementUIState(state);

    if (!currentUIState) {
        return 'default';
    }

    return currentUIState.newProjectUserForm.newUserView;
};

export const getEditProjectUserFormStep = (state: RootState): string => {
    const currentUIState = getUserManagementUIState(state);

    if (!currentUIState) {
        return 'default';
    }

    return currentUIState.editProjectUserForm.editUserView;
};

export const getNewProjectUserFormState = (state: RootState): NewProjectUserFormState['formState'] => {
    const currentUIState = getUserManagementUIState(state);

    if (!currentUIState) {
        return defaultNewProjectUserFormState.formState;
    }

    return currentUIState.newProjectUserForm.formState;
};

export const getEditProjectUserFormState = (state: RootState): EditProjectUserFormState['formState'] => {
    const currentUIState = getUserManagementUIState(state);

    if (!currentUIState) {
        return defaultEditProjectUserFormState.formState;
    }

    return currentUIState.editProjectUserForm.formState;
};

export const getUserManagementListFilterState = (state: RootState): UserManagementListFilterState => {
    const currentUIState = getUserManagementUIState(state);

    if (!currentUIState) {
        return defaultUserManagementListFilterState;
    }

    return currentUIState.userManagementListFilter;
};

export const getUserManagementGeneralUiState = (state: RootState): GeneralUIState => {
    const currentUIState = getUserManagementUIState(state);

    if (!currentUIState) {
        return defaultGeneralUiState;
    }

    return currentUIState.generalUiState;
};
