import { gql } from 'graphql-request';
import { MetricsChartData } from 'src/shared/types';

export const fishMetricsQuery = gql`
    query fishMetricsData($projectId: String!, $fromDate: String, $toDate: String, $habitatAssay: String!) {
        fishcommercialvalue: chart(
            projectId: $projectId
            chartId: "fishcommercialvalue"
            fromDate: $fromDate
            toDate: $toDate
            habitatAssay: $habitatAssay
        ) {
            data
        }
        fishprevalenceofsensitivespecies: chart(
            projectId: $projectId
            chartId: "fishprevalenceofsensitivespecies"
            fromDate: $fromDate
            toDate: $toDate
            habitatAssay: $habitatAssay
        ) {
            data
        }
        fishfoodchainintegrity: chart(
            projectId: $projectId
            chartId: "fishfoodchainintegrity"
            fromDate: $fromDate
            toDate: $toDate
            habitatAssay: $habitatAssay
        ) {
            data
        }
    }
`;

export type FishMetricsQueryResult = {
    fishcommercialvalue: MetricsChartData;
    fishprevalenceofsensitivespecies: MetricsChartData;
    fishfoodchainintegrity: MetricsChartData;
};
