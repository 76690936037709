import React from 'react';
import { FishMetricsQueryResult } from 'src/app/dashboard/state/api/queries/fishMetricsQuery';
import Tabs from 'src/shared/components/Tabs/Tabs';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import styles from './FishMetricsChart.module.scss';
import TimeseriesCardTemplate, { TimeseriesCardTemplateProps } from '../../../components/TimeseriesCardTemplate';

const fishMetricsChartTabs = [
    {
        label: 'Commercial value',
        id: 'fish-commercial-value',
    },
    {
        label: 'Food chain integrity',
        id: 'food-chain-integrity',
    },
    {
        label: 'Prevalence of sensitive species',
        id: 'fish-prevalance-of-sensitive-species',
    },
];

const FishMetricsChart = ({ data }: { data: FishMetricsQueryResult }) => {
    const [selectedTab, setSelectedTab] = React.useState(fishMetricsChartTabs[0].id);

    const tabs = (
        <div className={styles.tabsWrapper}>
            <Tabs
                tabs={fishMetricsChartTabs}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                classNames={{
                    tabsContainer: styles.tabs,
                    default: styles.tabDefault,
                    selected: styles.tabSelected,
                }}
            />
            <QuestionButtonHelp type='api' placement='left' slug={selectedTab} />
        </div>
    );

    const config: { [key: string]: Partial<TimeseriesCardTemplateProps> } = {
        'fish-commercial-value': {
            metricsChartData: data.fishcommercialvalue,
            averageValueDescription: 'Number of high and very high commercial species per sample [endYearWithQuarter]',
        },

        'food-chain-integrity': {
            metricsChartData: data.fishfoodchainintegrity,
            averageValueDescription: 'Average food chain integrity per sample [endYearWithQuarter]',
        },

        'fish-prevalance-of-sensitive-species': {
            metricsChartData: data.fishprevalenceofsensitivespecies,
            averageValueDescription: 'Average prevalence of sensitive species per sample [endYearWithQuarter]',
        },
    };

    return (
        <div className={styles.timeseriesCard}>
            <TimeseriesCardTemplate
                title='Fish metrics'
                tabs={tabs}
                footerDescriptionMetricName={fishMetricsChartTabs.find(tab => tab.id === selectedTab)?.label}
                description='A description of the overall condition of fish communities within an ecosystem'
                averageValueDescription={config[selectedTab].averageValueDescription}
                metricsChartData={config[selectedTab].metricsChartData as TimeseriesCardTemplateProps['metricsChartData']}
            />
        </div>
    );
};

export default FishMetricsChart;
