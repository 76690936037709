import React from 'react';
import { useCommunityComparisonChartDataQuery, useCommunityComparisonSamplesQuery } from 'src/app/insights/state/api/insightsGraphSlice';
import { getCurrentCommunityComparisonUIState } from 'src/app/insights/state/community-comparison/communityComparisonSelector';
import Loader from 'src/shared/components/loader/Loader';
import useProject from 'src/shared/hooks/useProject';
import { useAppSelector } from 'src/store';
import Table from 'src/shared/components/table/Table';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import PercentageBar from '../../percentage-bar/PercentageBar';
import styles from '../CommunityComparisonAside.module.scss';

const AsideBottomContent = () => {
    const { currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();

    const uiState = useAppSelector(getCurrentCommunityComparisonUIState);

    const {
        filters: { samplingYear, selectedSampleId, selectedSampleGroup },
    } = uiState;

    const { currentData: chartData, isFetching: isFetchingChartData } = useCommunityComparisonChartDataQuery(
        {
            projectId: currentProjectId || '',
            habitatAssay: habitatAssayType?.key || '',
        },
        {
            skip: !currentProjectId || !habitatAssayType,
        }
    );

    let querySampleIds = [];

    if (selectedSampleId) {
        querySampleIds = [selectedSampleId];
    } else {
        const selectedYearData = chartData?.communityComparison.data[0].years[Number(samplingYear)].data;
        querySampleIds =
            selectedYearData?.find(entry => entry.variable.value === selectedSampleGroup)?.points.map(point => point.sampleId) || [];
    }

    const { currentData, isFetching } = useCommunityComparisonSamplesQuery(
        {
            projectId: currentProjectId || '',
            samples: {
                sampleIds: querySampleIds,
                habitatAssay: [habitatAssayType?.key || ''],
            },
        },
        {
            skip: !currentProjectId || !querySampleIds.length || !habitatAssayType,
        }
    );

    if (!querySampleIds.length) {
        return (
            <div className={styles.bottomContentPlaceholder}>
                Select a sample group in the key to view the species within that community
            </div>
        );
    }

    if (isFetching || isFetchingChartData) {
        return <Loader />;
    }

    if (!currentData) {
        return null;
    }

    const { taxonomySummary } = currentData.communityComparisonSamples;
    const maxSampleCount = Math.max(...taxonomySummary.map(entry => entry.sampleCount));
    return (
        <div className={styles.asideBottom}>
            <Table className={styles.speciesListTable}>
                <tbody>
                    <tr>
                        <div>
                            <span>Named Species</span>
                            <QuestionButtonHelp type='api' slug='map-named-species' placement='right' />
                        </div>
                        {!selectedSampleId && (
                            <th style={{ textAlign: 'right' }} align='right'>
                                Named Species in {selectedSampleGroup}
                                <span className={styles.cube}></span>
                            </th>
                        )}
                    </tr>
                    {taxonomySummary
                        .filter(entry => entry.taxonomy.tax_species)
                        .sort((a, b) => b.sampleCount - a.sampleCount)
                        .map((entry, index) => (
                            <tr key={index}>
                                <td className={styles.speciesName} style={selectedSampleId ? undefined : { maxWidth: '150px' }}>
                                    {entry.taxonomy.tax_species}
                                </td>
                                {!selectedSampleId && (
                                    <td>
                                        <PercentageBar
                                            score={entry.sampleCount}
                                            scoreTextPosition={5}
                                            scoreUnit=''
                                            domain={[0, maxSampleCount]}
                                        />
                                    </td>
                                )}
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    );
};

export default AsideBottomContent;
