import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import { IndividualRouteSetting, SampleReceptionViews } from 'src/routes/routesConfig';
import SampleReceptionHome from './views/sample-reception-home/SampleReceptionHome';
import ViewSampleManifest from './views/view-sample-manifest/ViewSampleManifest';

export const samplesReceptionRouteSettings: { [key in SampleReceptionViews]: IndividualRouteSetting } = {
    sampleReceptionHome: {
        name: 'Ready to start?',
        path: '/',
        handler: <SampleReceptionHome />,
    },
    viewSampleManifest: {
        name: 'View Sample Manifest',
        path: '/view',
        handler: <ViewSampleManifest />,
    },
};

const SampleReception = () => {
    return (
        <Routes>
            {Object.keys(samplesReceptionRouteSettings).map(routeKey => {
                const routeSetting = samplesReceptionRouteSettings[routeKey as SampleReceptionViews];

                return <Route path={routeSetting.path} element={routeSetting.handler} key={routeSetting.name} />;
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default SampleReception;
