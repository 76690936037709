import React, { ReactNode, useState, useEffect } from 'react';
import classNames from 'classnames';

import Chevron from 'src/shared/components/chevron/Chevron';

import styles from './ExpandableSection.module.scss';

export type ExpandableSectionProps = {
    collapsedContent: ReactNode;
    expandedContent: ReactNode;
    isExpanded: boolean;
    toggleControlOverride?: {
        expanded: ReactNode;
        collapsed: ReactNode;
    };
    classNames?: {
        wrapper?: string;
        expanded?: string;
        collapsed?: string;
        chevron?: string;
        toggle?: string;
    };
    onToggle?: (isExpanded: boolean) => void;
};

const ExpandableSection = (props: ExpandableSectionProps) => {
    const { toggleControlOverride } = props;

    const [isExpanded, setIsExpanded] = useState(props.isExpanded);

    useEffect(() => {
        if (isExpanded !== props.isExpanded) {
            setIsExpanded(props.isExpanded);
        }
    }, [props.isExpanded]);

    const wrapperClassNames = classNames(styles.expandableSection, props.classNames?.wrapper);

    const expandedContentClassNames = classNames(styles.expandedContent, props.classNames?.expanded);

    const collapsedContentClassNames = classNames(styles.collapsedContent, props.classNames?.collapsed);

    const chevronClassNames = classNames(styles.chevron, props.classNames?.chevron);

    const toggleClassNames = classNames(styles.toggle, props.classNames?.toggle);

    const toggleExpanded = () => {
        props.onToggle?.(!isExpanded);
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={wrapperClassNames}>
            {props.expandedContent && (
                <div className={toggleClassNames} onClick={toggleExpanded}>
                    {!toggleControlOverride && (
                        <Chevron direction={isExpanded ? 'up' : 'down'} animate={true} className={chevronClassNames} variation='outlined' />
                    )}

                    {toggleControlOverride && isExpanded ? toggleControlOverride.expanded : toggleControlOverride?.collapsed}
                </div>
            )}

            {isExpanded ? (
                <div className={expandedContentClassNames}>{props.expandedContent}</div>
            ) : (
                <div className={collapsedContentClassNames}>{props.collapsedContent}</div>
            )}
        </div>
    );
};

ExpandableSection.defaultProps = {
    isExpanded: false,
};

export default ExpandableSection;
