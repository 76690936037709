import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'src/store';
import { NewProjectDefinitionFormState, setFormState } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSlice';
import { getCurrentProjectDefinitionFormState } from 'src/app/projects/views/new-project-definition/state/newProjectDefinitionSelector';
import Input from 'src/shared/components/input/Input';
import BasicDatePicker from 'src/shared/components/basic-datepicker/BasicDatePicker';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import { ProjectSamplingCadenceType, SamplingEvent, SubscriptionType } from 'src/shared/types';
import commonStyles from 'src/app/projects/views/new-project-definition/NewProjectDefinition.module.scss';
import RadioGroup from 'src/shared/components/radio-group/RadioGroup';
import Select from 'src/shared/components/select/Select';
import times from 'lodash/times';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import Checkbox from 'src/shared/components/checkbox/Checkbox';
import styles from './SamplingCadence.module.scss';
import { validateSamplingEvent } from './samplingCadence.utils';

const samplingCadenceOptions = [
    { label: 'Single year', value: ProjectSamplingCadenceType.SINGLE_YEAR },
    { label: 'Multi year', value: ProjectSamplingCadenceType.MULTI_YEAR },
];

const numberOfEventOptions = [...times(12, index => ({ label: index + 1, value: String(index + 1) }))];

const SamplingCadence = () => {
    const dispatch = useAppDispatch();
    const formState = useAppSelector(getCurrentProjectDefinitionFormState);
    const { samplingCadence, samplingEvents, samplingEventsPerYear, projectLifespanYears, subscriptionType, hasDefinedSampleEvents } =
        formState;

    const onFormChange = (fragment: Partial<NewProjectDefinitionFormState>) => {
        const samplingEventsFragment = samplingEvents.slice(0, samplingEventsPerYear);

        dispatch(
            setFormState({
                ...fragment,
                samplingEvents: samplingEventsFragment,
            })
        );
    };

    const onSamplingCadenceChange = (fragment: Partial<NewProjectDefinitionFormState>) => {
        const shouldResetForSingleYear = fragment.samplingCadence === ProjectSamplingCadenceType.SINGLE_YEAR;
        const shouldResetForMultiYear = fragment.samplingCadence === ProjectSamplingCadenceType.MULTI_YEAR && projectLifespanYears === 1;

        const updatedFragment = {
            ...fragment,
        };

        if (shouldResetForSingleYear || shouldResetForMultiYear) {
            updatedFragment.projectLifespanYears = shouldResetForSingleYear ? 1 : 0;
        }
        onFormChange(updatedFragment);
    };

    const handleCheckboxChange = (isChecked: boolean) => {
        const newFragment: Partial<NewProjectDefinitionFormState> = {
            hasDefinedSampleEvents: isChecked,
            ...(!isChecked && { samplingEventsPerYear: 0, samplingEvents: [] }),
        };

        dispatch(setFormState(newFragment));
    };

    const shouldShowSamplingEventsSection = subscriptionType === SubscriptionType.INSIGHTS || hasDefinedSampleEvents;

    return (
        <div className={commonStyles.formView}>
            <div className={commonStyles.formViewTitle}>Sampling cadence</div>
            <div className={commonStyles.formSection}>
                <div className={commonStyles.formInputSection}>
                    <div className={commonStyles.formInputSectionTitle}>Will this be a multi year or single year project?</div>
                    <RadioGroup
                        options={samplingCadenceOptions}
                        onChange={value => onSamplingCadenceChange({ samplingCadence: value as ProjectSamplingCadenceType })}
                        selectedOption={samplingCadence}
                    />

                    {samplingCadence === ProjectSamplingCadenceType.MULTI_YEAR && (
                        <InfoMessage message={<CMSArticle slug='multi-year-project-sampling-events' />} />
                    )}
                </div>

                {samplingCadence === ProjectSamplingCadenceType.MULTI_YEAR && (
                    <div className={commonStyles.formInputSection}>
                        <div className={commonStyles.formInputSectionTitle}>
                            Number of sampling years
                            <QuestionButtonHelp type='api' slug='project-definition-sampling-years' />
                        </div>
                        <Input
                            value={String(projectLifespanYears || '')}
                            placeholder='Type a number'
                            autoComplete='off'
                            onChange={event => onFormChange({ projectLifespanYears: Number(event.target.value) })}
                            footerHint='Minimum 2, maximum 30'
                        ></Input>
                        {projectLifespanYears
                            ? (projectLifespanYears < 2 || projectLifespanYears > 30) && (
                                  <ErrorMessage message='Number of sampling years must be a whole number between 2 and 30' />
                              )
                            : null}
                    </div>
                )}

                {subscriptionType === SubscriptionType.BASIC && (
                    <div className={commonStyles.formInputSection}>
                        <div className={commonStyles.formInputSectionTitle}>Is the sampling event data known?</div>
                        <Checkbox label='Yes' onChange={handleCheckboxChange} checked={hasDefinedSampleEvents} />
                    </div>
                )}

                {shouldShowSamplingEventsSection && (
                    <>
                        <div className={commonStyles.formInputSection}>
                            <div className={commonStyles.formInputSectionTitle}>
                                Number of sampling events per year
                                <QuestionButtonHelp type='api' slug='project-definition-sampling-cadence' />
                            </div>

                            <Select
                                className={{ container: commonStyles.selectContainer }}
                                options={numberOfEventOptions}
                                selectedValue={String(samplingEventsPerYear || '')}
                                onChange={value => onFormChange({ samplingEventsPerYear: Number(value) })}
                                placeholder='Select number'
                                width='100%'
                            />
                        </div>

                        <div className={commonStyles.formInputSection}>
                            {samplingEventsPerYear > 0 && samplingCadence === ProjectSamplingCadenceType.MULTI_YEAR && (
                                <div className={styles.samplingEventsNote}>
                                    <span className={styles.samplingEventsNoteTitle}>
                                        The following events are the first year events only.
                                    </span>
                                    <span className={styles.samplingEventsNoteText}>
                                        Events in the following year(s) will retain the same day and month selections
                                    </span>
                                </div>
                            )}

                            {times(samplingEventsPerYear, index => {
                                return <SamplingEventForm key={index} index={index} />;
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const SamplingEventForm = (props: { index: number }) => {
    const { index } = props;

    const dispatch = useAppDispatch();
    const formState = useAppSelector(getCurrentProjectDefinitionFormState);

    const { samplingEvents } = formState;
    const samplingEventData = samplingEvents[index] || {
        name: '',
        fromDate: '',
        toDate: '',
    };

    const onFormChange = (fragment: Partial<NewProjectDefinitionFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    const onSampleEventChange = (samplingEventFragment: Partial<SamplingEvent>) => {
        const samplingEventsCopy = [...samplingEvents];
        samplingEventsCopy[index] = { ...samplingEventData, ...samplingEventFragment };

        onFormChange({ samplingEvents: samplingEventsCopy });
    };

    const { showErrorBorder, errorMessages } = validateSamplingEvent(formState, index);

    const samplingEventClassNames = classNames(styles.samplingEvent, {
        [styles.samplingEventHasError]: showErrorBorder,
    });

    const getProjectEndDateError = () => {
        const { samplingCadence, projectLifespanYears, projectEndDate } = formState;
        const isMultiyear = samplingCadence === ProjectSamplingCadenceType.MULTI_YEAR;
        const totalSamplingYears = !isMultiyear ? 1 : projectLifespanYears || 1;
        const lastEventToDate = moment(samplingEventData.toDate)
            .clone()
            .add(totalSamplingYears - 1, 'years');
        return (
            <div>
                The project end date must occur within 6 months of the final sampling event end date <br />
                <br />
                Last date of sampling: {lastEventToDate.format('DD/MM/YYYY')}
                <br />
                Project end date: {moment(projectEndDate).format('DD/MM/YYYY')}
            </div>
        );
    };

    return (
        <div>
            <div className={samplingEventClassNames}>
                <div className={styles.samplingEventTitle}>
                    Year 1, Event {index + 1} <QuestionButtonHelp type='api' slug='project-definition-sampling-event' />
                </div>
                <div>
                    <div className={styles.inputLabel}>Sampling event name</div>
                    <Input
                        value={samplingEventData.name}
                        onChange={event => onSampleEventChange({ name: event.target.value })}
                        maxLength={25}
                    />
                </div>
                <div>
                    <div className={styles.expectedDates}>Expected dates</div>
                    <div className={styles.dateSelectors}>
                        <div className={styles.dateSelector}>
                            <div className={styles.fromTo}>From</div>
                            <BasicDatePicker
                                value={samplingEventData.fromDate ? new Date(samplingEventData.fromDate) : null}
                                onChange={value => onSampleEventChange({ fromDate: value?.toDateString() })}
                            />
                        </div>
                        <div className={styles.dateSelector}>
                            <div className={styles.fromTo}>To</div>
                            <div>
                                <BasicDatePicker
                                    value={samplingEventData.toDate ? new Date(samplingEventData.toDate) : null}
                                    onChange={value => onSampleEventChange({ toDate: value?.toDateString() })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {errorMessages &&
                errorMessages.map((message, index) => (
                    <ErrorMessage key={index} message={message === 'project end date error' ? getProjectEndDateError() : message} />
                ))}
        </div>
    );
};

export default SamplingCadence;
