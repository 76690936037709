import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { HabitatInsightsGeoJson } from 'src/shared/types';

export type HabitatInsightsGeoJsonValidationError = {
    type: string;
    message: string;
};

export type HabitatInsightsUploadFormState = {
    // Basic information
    projectId: string;
    // Survey samples
    uploadedFileContent: string;
    uploadedFileErrors: HabitatInsightsGeoJsonValidationError[] | [];
    uploadedFileName: string;
    sites: HabitatInsightsGeoJson[] | [];
};

export const defaultHabitatInsightsUploadFormState = {
    projectId: '',
    uploadedFileContent: '',
    uploadedFileErrors: [],
    uploadedFileName: '',
    sites: [],
};

export type HabitatInsightsUploadFormUIState = {
    formStep: number;
    formState: HabitatInsightsUploadFormState;
};

type HabitatInsightsUploadState = {
    uiState: HabitatInsightsUploadFormUIState;
};

const initialState: HabitatInsightsUploadState = {
    uiState: { formStep: 0, formState: { ...defaultHabitatInsightsUploadFormState } },
};

export const resetHabitatInsightsUpload = (): ThunkAction<void, RootState, void, Action<string>> => dispatch => {
    dispatch(habitatInsightsUploadSlice.actions.reset());
};

const habitatInsightsUploadSlice = createSlice({
    name: 'habitatInsightsUpload',
    initialState,
    reducers: {
        setCurrentStep(state, action: PayloadAction<number>) {
            state.uiState.formStep = action.payload;
        },
        setFormState(state, action: PayloadAction<Partial<HabitatInsightsUploadFormState>>) {
            state.uiState.formState = { ...state.uiState.formState, ...action.payload };
        },

        reset(state) {
            // eslint-disable-next-line
            state.uiState = { ...initialState.uiState };
        },
    },
});

export default habitatInsightsUploadSlice.reducer;

export const { setFormState, setCurrentStep, reset } = habitatInsightsUploadSlice.actions;
