import React from 'react';
import {
    PersonOutlineOutlined,
    CorporateFareOutlined,
    FolderOutlined,
    AccountCircleOutlined,
    DoneOutlined,
    AddOutlined,
} from '@mui/icons-material';

import { PrimaryButton } from 'src/shared/components/button/Button';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import useProject from 'src/shared/hooks/useProject';
import Select from 'src/shared/components/select/Select';
import Textarea from 'src/shared/components/textarea/Textarea';
import authService from 'src/services/auth-service';
import { useCreateSupportRequestMutation } from 'src/global/state/api/globalGraphSlice';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import useSupportAnalytics from 'src/global/hooks/useSupportAnalytics';
import styles from './SupportMenu.module.scss';

const requestTypeOptions = [
    {
        value: 'Request edits to my profile account',
        label: 'Request edits to my profile account',
    },
    {
        value: 'Request help to understand report(s)',
        label: 'Request help to understand report(s)',
    },
    {
        value: 'Request help to understand a project',
        label: 'Request help to understand a project',
    },
    {
        value: 'Request access for one of my colleagues',
        label: 'Request access for one of my colleagues',
    },
    {
        value: 'Request support with something else',
        label: 'Request support with something else',
    },
];

const SupportMenuContactForm = () => {
    const currentUser = authService.getCurrentUserData();

    const name = currentUser?.firstName + ' ' + currentUser?.lastName;
    const { currentCustomerName, currentCustomerId } = useCustomer();
    const { currentCustomerProjects, currentProjectId } = useProject();
    const [requestType, setRequestType] = React.useState<string>('');
    const [description, setDescription] = React.useState<string>('');
    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const { trackSupportRequest } = useSupportAnalytics();

    const currentProjectDefinition = currentCustomerProjects?.find(project => project.projectId === currentProjectId);

    const canSubmit = requestType && description;

    const [createSupportRequest] = useCreateSupportRequestMutation();

    const submitForm = async () => {
        setErrorMessage('');
        try {
            const response = await createSupportRequest({
                organisationId: currentCustomerId,
                projectId: currentProjectId || '',
                requestType: requestType,
                description: description,
            }).unwrap();

            if (response) {
                setRequestType('');
                setDescription('');
                setIsSubmitted(true);
            }
        } catch (error) {
            console.error('rejected', error);
            setErrorMessage('There was an error submitting your support request. Please try again.');
        }
        trackSupportRequest(requestType);
    };

    if (isSubmitted) {
        return (
            <div className={styles.supportModal}>
                <div className={styles.header}>
                    <div className={styles.title}>Success!</div>
                </div>
                <div className={styles.success}>
                    <div className={styles.icon}>
                        <DoneOutlined />
                    </div>
                    <div className={styles.message}>You have submitted a support request to your organisation&apos;s administrator</div>
                </div>
                <div className={styles.footer}>
                    <PrimaryButton onClick={() => setIsSubmitted(false)}>
                        Submit another request <AddOutlined />
                    </PrimaryButton>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.supportModal}>
            <div className={styles.header}>
                <div className={styles.icon}>
                    <AccountCircleOutlined />
                </div>
                <div className={styles.title}>Request support from my organisations&apos;s administrator</div>
            </div>
            <div className={styles.banner}>
                <div className={styles.labelColor}>Request coming from</div>
                <div className={styles.infoItems}>
                    <div className={styles.infoItem}>
                        <PersonOutlineOutlined />
                        <div className={styles.infoItemsFields}>
                            <div className={styles.name}>Name</div>
                            <div>{name}</div>
                        </div>
                    </div>

                    <div className={styles.infoItem}>
                        <CorporateFareOutlined />
                        <div className={styles.infoItemsFields}>
                            <div className={styles.name}>Organisation</div>
                            <div>{currentCustomerName}</div>
                        </div>
                    </div>

                    <div className={styles.infoItem}>
                        <FolderOutlined />
                        <div className={styles.infoItemsFields}>
                            <div className={styles.name}>Project</div>
                            <div>{currentProjectDefinition?.projectName || '-'}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.form}>
                <div className={styles.formField}>
                    <div className={styles.labelColor}>I want to</div>
                    <div>
                        <Select
                            width={'100%'}
                            options={requestTypeOptions}
                            selectedValue={requestType}
                            placeholder='Select request type'
                            onChange={setRequestType}
                        />
                    </div>
                </div>
                <div className={styles.formField}>
                    <div className={styles.labelColor}>Description of support request</div>
                    <Textarea
                        className={styles.textarea}
                        onChange={e => setDescription(e.target.value)}
                        value={description}
                        maxLength={1500}
                        placeholder='Please provide any additional information that will help us to help you.'
                    />
                </div>
            </div>
            <div className={styles.footer}>
                {errorMessage && <ErrorMessage message={errorMessage} />}
                <PrimaryButton disabled={!canSubmit} onClick={submitForm}>
                    Submit request
                </PrimaryButton>
            </div>
        </div>
    );
};

export default SupportMenuContactForm;
