import React, { useEffect, useState } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Search } from '@mui/icons-material';

import { useAppDispatch, useAppSelector } from 'src/store';
import { NewSurveyDesignFormState, setFormState } from 'src/app/surveys/views/new-survey-design/state/newSurveyDesignSlice';
import { getCurrentSurveyDesignFormState } from 'src/app/surveys/views/new-survey-design/state/newSurveyDesignSelector';

import { PrimaryButton } from 'src/shared/components/button/Button';
import { CMSHabitatAssayType } from 'src/shared/types';
import cmsService from 'src/services/cms-service';
import Autocomplete from 'src/shared/components/autocomplete/Autocomplete';

import commonStyles from 'src/app/surveys/views/new-survey-design/NewSurveyDesign.module.scss';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import styles from './HabitatAssayTypes.module.scss';

const HabitatAssayTypes = () => {
    const dispatch = useAppDispatch();
    const formState = useAppSelector(getCurrentSurveyDesignFormState);
    const [habitatAssayOptions, setHabitatAssayOptions] = useState<CMSHabitatAssayType[]>([]);
    const { habitatAssayTypes } = formState;
    const [selectedHabitatAssayType, setSelectedHabitatAssayTypeValue] = useState<CMSHabitatAssayType | null>(null);
    const [habitatAssaySearchText, setHabitatAssaySearchText] = useState('');

    useEffect(() => {
        const fetchHabitatAssayTypes = async () => {
            const allHabitatAssays = await cmsService.getAllHabitatAssayTypes();

            setHabitatAssayOptions(allHabitatAssays as CMSHabitatAssayType[]);
        };

        fetchHabitatAssayTypes();
    }, []);

    if (!habitatAssayOptions.length) {
        return null;
    }

    const onFormChange = (fragment: Partial<NewSurveyDesignFormState>) => {
        dispatch(
            setFormState({
                ...fragment,
            })
        );
    };

    const addHabitatAssayType = () => {
        if (
            !selectedHabitatAssayType ||
            habitatAssayTypes.some(entry => entry.habitatAssayKey === selectedHabitatAssayType?.habitatAssayKey)
        ) {
            return;
        }

        onFormChange({
            habitatAssayTypes: [
                ...habitatAssayTypes,
                {
                    habitatAssayKey: selectedHabitatAssayType.habitatAssayKey,
                    habitatName: selectedHabitatAssayType.habitatName,
                    assayName: selectedHabitatAssayType.assayName,
                },
            ],
        });
        setSelectedHabitatAssayTypeValue(null);
        setHabitatAssaySearchText('');
    };

    const removehabitatAssayType = (habitatAssayKey: string) => {
        onFormChange({
            habitatAssayTypes: [...habitatAssayTypes].filter(item => item.habitatAssayKey !== habitatAssayKey),
        });
    };

    return (
        <div className={commonStyles.formSection}>
            <div className={commonStyles.formSectionTitle}>Test</div>
            <div className={commonStyles.formInputSection}>
                <div className={commonStyles.formInputSectionTitle}>
                    Test <QuestionButtonHelp type='api' slug='project-definition-habitat-test-type' />
                </div>
                <div className={styles.addHabitatAssayType}>
                    <Autocomplete
                        options={['', ...habitatAssayOptions.map(entry => `${entry.habitatName} - ${entry.assayName}`)]}
                        placeholder='Search'
                        prefixIcon={<Search />}
                        onChange={value =>
                            setSelectedHabitatAssayTypeValue(
                                habitatAssayOptions.find(entry => `${entry.habitatName} - ${entry.assayName}` === value) || null
                            )
                        }
                        onInputChange={value => setHabitatAssaySearchText(value)}
                        inputValue={habitatAssaySearchText}
                        value={
                            selectedHabitatAssayType
                                ? `${selectedHabitatAssayType?.habitatName} - ${selectedHabitatAssayType?.assayName}`
                                : ''
                        }
                    />

                    {habitatAssayTypes.length < 8 && (
                        <PrimaryButton
                            className={commonStyles.addButton}
                            onClick={addHabitatAssayType}
                            disabled={!selectedHabitatAssayType}
                        >
                            <span>+</span> ADD
                        </PrimaryButton>
                    )}
                </div>
                <div className={styles.habitatAssayTypes}>
                    {habitatAssayTypes.map((entry, index) => {
                        return (
                            <div key={index} className={styles.habitatAssayTypeTag}>
                                <div key={index} className={styles.habitatAssayTypeTagValue}>
                                    <div className={styles.habitatName}>{entry.habitatName}</div>
                                    <div className={styles.assayName}>{entry.assayName}</div>
                                </div>
                                <ClearOutlinedIcon onClick={() => removehabitatAssayType(entry.habitatAssayKey)} />
                            </div>
                        );
                    })}
                </div>
                {habitatAssayTypes.length > 0 && <div className={commonStyles.maximumText}>Maximum 8 test</div>}
            </div>
        </div>
    );
};

export default HabitatAssayTypes;
