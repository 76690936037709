import React from 'react';
import Chip from 'src/shared/components/chip/Chip';
import { Status } from 'src/shared/types';
import { ReactComponent as ErrorTriangleIcon } from 'src/assets/svg/general/error-triangle.svg';
import { ReactComponent as WarningCircleIcon } from 'src/assets/svg/general/warning-circle.svg';

export type ChipStatusIndicatorProps = {
    status: Status.ERROR | Status.WARNING;
    label: string;
};

const statusConfig = {
    [Status.ERROR]: {
        title: 'Error',
        backgroundColor: '#F9DFE0',
        labelColor: '#AD180E',
    },
    [Status.WARNING]: {
        title: 'Warning',
        backgroundColor: '#FFEED3',
        labelColor: '#CF8515',
    },
};

const ChipStatusIndicator = (props: ChipStatusIndicatorProps) => {
    const { status, label } = props;

    return (
        <Chip
            label={
                <div className='flex gap-1 items-center'>
                    {label}
                    {status === Status.ERROR && <ErrorTriangleIcon />}
                    {status === Status.WARNING && <WarningCircleIcon />}
                </div>
            }
            backgroundColor={statusConfig[status].backgroundColor}
            labelColor={statusConfig[status].labelColor}
            className='rounded-full'
        />
    );
};

export default ChipStatusIndicator;
