import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';
import { SampleFilter } from 'src/shared/types';

import { sampleMetricsTableQuery, SampleMetricsTableQueryResult } from './queries/sampleMetricsTableQuery';
import { habitatInsightsMetricsTableQuery, HabitatInsightsMetricsTableQueryResult } from './queries/habitatInsightsMetricsTableQuery';

type SampleMetricsTableQueryParams = {
    projectId: string;
    samples: SampleFilter;
};

type HabitatInsightsMetricsTableQueryParams = {
    projectId: string;
    habitatInsightsCharts: string[];
    siteName: string[];
};

const tablesGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        sampleMetricsTableData: builder.query<SampleMetricsTableQueryResult, SampleMetricsTableQueryParams>({
            query: params => ({
                body: sampleMetricsTableQuery,
                variables: params,
            }),
        }),
        habitatInsightsMetricsTableData: builder.query<HabitatInsightsMetricsTableQueryResult, HabitatInsightsMetricsTableQueryParams>({
            query: params => ({
                body: habitatInsightsMetricsTableQuery,
                variables: { ...params, hlcaCharts: params.habitatInsightsCharts },
            }),
        }),
    }),
});

export const { useSampleMetricsTableDataQuery, useHabitatInsightsMetricsTableDataQuery } = tablesGraphSlice;

export default tablesGraphSlice.reducer;
