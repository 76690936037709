import React from 'react';
import useProjectBreadcrumb from 'src/shared/hooks/useProjectBreadcrumb';
import StandardAppLayout from 'src/shared/components/layout/standard-app-layout/StandardAppLayout';
import StartPageBanner from './components/StartPageBanner';
import Overview from './components/Overview';
import SubmissionProcess from './components/SubmissionProcess';

const SampleManifestStartPage = () => {
    const mainContent = (
        <div className='flex flex-col gap-8'>
            <StartPageBanner />
            <Overview />
            <SubmissionProcess />
        </div>
    );

    const title = 'Sample Manifest';

    const breadcrumbs = useProjectBreadcrumb(title);

    return <StandardAppLayout mainContent={mainContent} title={title} breadcrumbs={breadcrumbs} />;
};

export default SampleManifestStartPage;
