import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import { getTableRoutesAvailability } from 'src/routes/state/routesSelector';
import { useAppSelector } from 'src/store';
import { IndividualRouteSetting, TableViews } from 'src/routes/routesConfig';
import SampleMetricsTable from './sample-metrics-table/SampleMetricsTable';
import HabitatInsightsMetricsTable from './habitat-insights-metrics-table/HabitatInsightsMetricsTable';

export const tableRouteSettings: { [key in TableViews]: IndividualRouteSetting } = {
    sampleMetrics: {
        name: 'Sample Metrics Table',
        path: '/sample-metrics',
        handler: <SampleMetricsTable />,
    },
    habitatInsightsMetrics: {
        name: 'Habitat Insights Metrics Table',
        path: '/habitat-insights-metrics',
        handler: <HabitatInsightsMetricsTable />,
    },
};
const Tables = () => {
    const routesConfig = useAppSelector(getTableRoutesAvailability);

    return (
        <Routes>
            {Object.keys(routesConfig).map(routeKey => {
                const isAvailable = routesConfig[routeKey as TableViews];
                const routeSetting = tableRouteSettings[routeKey as TableViews];

                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={'/projects'}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default Tables;
