import React from 'react';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

export type CheckboxProps = {
    onChange: (status: boolean) => void;
    disabled?: boolean;
    checked: boolean;
    className?: string;
    label?: string;
    labelClassName?: string;
    checkboxClassName?: string;
};

const Checkbox = (props: CheckboxProps) => {
    const onChange = () => {
        if (!props.disabled) {
            props.onChange(!props.checked);
        }
    };

    const wrapperClasses = classNames(styles.wrapper, props.className);

    const checkboxClasses = classNames(
        styles.checkboxDefault,
        props.checked ? styles.checkboxChecked : styles.checkboxUnchecked,
        props.disabled && styles.checkboxDisabled,
        props.checkboxClassName
    );

    const labelClasses = classNames(styles.label, props.labelClassName);

    return (
        <div className={wrapperClasses}>
            <label className={styles.grid} data-testid='checkbox-label-grid'>
                <input
                    type='checkbox'
                    className={checkboxClasses}
                    checked={props.checked}
                    disabled={props.disabled}
                    onChange={onChange}
                    data-testid='checkbox'
                />
                {props.label && (
                    <span className={labelClasses} data-testid='label'>
                        {props.label}
                    </span>
                )}
            </label>
        </div>
    );
};

export default Checkbox;
