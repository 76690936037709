import React from 'react';
import TabbedCarousel from 'src/shared/components/tabbed-carousel/TabbedCarousel';
import { ReactComponent as InviteUserIcon } from 'src/assets/svg/general/invite-user.svg';
import { ReactComponent as ListIcon } from 'src/assets/svg/general/list.svg';
import { ReactComponent as RubricIcon } from 'src/assets/svg/general/rubric.svg';
import { ReactComponent as SubmitIcon } from 'src/assets/svg/general/submit.svg';
import { ReactComponent as DnaIcon } from 'src/assets/svg/general/dna.svg';
import InviteTeamImage from 'src/assets/images/samples/invite-team.png';
import CMSArticle from 'src/shared/components/cms-article/CMSArticle';

const SubmissionProcess = () => {
    const carousel = (
        <TabbedCarousel
            showArrowControls={true}
            tabs={[
                {
                    title: 'Invite sampling team',
                    subTitle: 'Step 1',
                    icon: <InviteUserIcon className='fill-primary' />,
                    content: (
                        <div>
                            <div className='text-grey-60 text-sm'>
                                <CMSArticle slug='sample-manifest-invite-sampling-team' />
                            </div>
                        </div>
                    ),
                    contentImage: <img height='250px' width='250px' src={InviteTeamImage} alt='Invite team' />,
                },
                {
                    title: 'Add field data',
                    subTitle: 'Step 2',
                    icon: <ListIcon className='fill-primary' />,
                    content: (
                        <div>
                            <div className='text-grey-60 text-sm'>
                                <CMSArticle slug='sample-manifest-add-field-data' />
                            </div>
                        </div>
                    ),
                    contentImage: <img height='250px' width='250px' src={InviteTeamImage} alt='Add field data' />,
                },
                {
                    title: 'Review samples',
                    subTitle: 'Step 3',
                    icon: <RubricIcon className='fill-primary' />,
                    content: (
                        <div>
                            <div className='text-grey-60 text-sm'>
                                <CMSArticle slug='sample-manifest-review-samples' />
                            </div>
                        </div>
                    ),
                    contentImage: <img height='250px' width='250px' src={InviteTeamImage} alt='Review samples' />,
                },
                {
                    title: 'Add tests',
                    subTitle: 'Step 4',
                    icon: <DnaIcon className='fill-primary' />,
                    content: (
                        <div>
                            <div className='text-grey-60 text-sm'>
                                <CMSArticle slug='sample-manifest-add-tests' />
                            </div>
                        </div>
                    ),
                    contentImage: <img height='250px' width='250px' src={InviteTeamImage} alt='Add tests' />,
                },
                {
                    title: 'Submit',
                    subTitle: 'Step 5',
                    icon: <SubmitIcon className='fill-primary' />,
                    content: (
                        <div>
                            <div className='text-grey-60 text-sm'>
                                <CMSArticle slug='sample-manifest-submit' />
                            </div>
                        </div>
                    ),
                    contentImage: <img height='250px' width='250px' src={InviteTeamImage} alt='Submit' />,
                },
            ]}
        />
    );

    return (
        <div className='flex flex-col shadow-lg p-4 rounded-lg bg-white gap-6'>
            <div className='text-primary text-lg'>Submission Process</div>
            <div className='flex border-b-grey-40 pb-4'>{carousel}</div>
        </div>
    );
};

export default SubmissionProcess;
