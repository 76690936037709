import { useEffect, type MutableRefObject } from 'react';

import analyticsTracking from 'src/services/analytics-service';

import { AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';

const useGlobalAnalytics = <T extends HTMLElement>(ref: MutableRefObject<T | null>) => {
    useEffect(() => {
        ref?.current?.addEventListener('analytics', handleAnalyticsCustomEvent as EventListener);
        return () => {
            ref?.current?.removeEventListener('analytics', handleAnalyticsCustomEvent as EventListener);
        };
    }, [ref?.current]);

    const sendTrackEvent = (payload: AnalyticsOptions) => {
        analyticsTracking.trackEvent({
            ...payload,
        });
    };

    const handleAnalyticsCustomEvent = (event: CustomEvent<AnalyticsOptions>) => {
        sendTrackEvent({
            ...event.detail,
        });
    };
};

export default useGlobalAnalytics;
