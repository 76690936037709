import moment from 'moment';
import { ProjectSamplingCadenceType } from 'src/shared/types';
import { NewProjectDefinitionFormState } from '../../state/newProjectDefinitionSlice';

type SamplingEventValidationData = {
    hasError: boolean;
    showErrorBorder: boolean;
    errorMessages: string[];
};

export const validateSamplingEvent = (formState: NewProjectDefinitionFormState, currentEventIndex: number): SamplingEventValidationData => {
    const { samplingCadence, samplingEvents, projectLifespanYears, samplingEventsPerYear, projectEndDate } = formState;

    const errorMessages = [];
    let hasError = false;
    let showErrorBorder = false;

    const samplingEventData = samplingEvents[currentEventIndex] || {
        name: '',
        fromDate: '',
        toDate: '',
    };

    if (![samplingEventData.name, samplingEventData.fromDate, samplingEventData.toDate].every(Boolean)) {
        hasError = true;
    }

    if (samplingEventData.fromDate && samplingEventData.toDate) {
        const currentEventFromDate = moment(samplingEventData.fromDate);
        const currentEventToDate = moment(samplingEventData.toDate);

        // current event fromDate must be same or before the end date
        const isFromDateAfterEndDate = currentEventFromDate.isAfter(currentEventToDate);
        if (isFromDateAfterEndDate) {
            hasError = true;
            showErrorBorder = true;
            errorMessages.push(`‘From’ date must occur before ‘To’ date`);
        }

        // next sampling event start date must be after 5 days of the previous event end date
        if (samplingEventsPerYear && samplingEventsPerYear > 1) {
            if (currentEventIndex < samplingEventsPerYear - 1) {
                const nextEventData = samplingEvents[currentEventIndex + 1];
                if (nextEventData && nextEventData.fromDate && nextEventData.toDate) {
                    const nextEventFromDate = moment(nextEventData.fromDate);
                    const diff = nextEventFromDate.diff(currentEventToDate, 'days');
                    if (diff < 5) {
                        hasError = true;
                        showErrorBorder = true;
                    }
                }
            }

            if (currentEventIndex > 0) {
                const prevEventData = samplingEvents[currentEventIndex - 1];
                if (prevEventData && prevEventData.toDate) {
                    const prevEventToDate = moment(prevEventData.toDate);
                    const diff = currentEventFromDate.diff(prevEventToDate, 'days');
                    if (diff < 5) {
                        hasError = true;
                        showErrorBorder = true;
                        errorMessages.push(`There must be at least 5 days between the two sampling events above`);
                    }
                }
            }
        }

        if (currentEventIndex === samplingEventsPerYear - 1) {
            // Last event end date should be within 1 year of the first event start date
            const yearRangeStartDate = moment(samplingEvents[0].fromDate);
            const yearRangeEndDate = moment(samplingEvents[0].fromDate).add(1, 'year');
            const isWithinOneYear = currentEventToDate.isBetween(yearRangeStartDate, yearRangeEndDate, undefined, '[)');
            if (!isFromDateAfterEndDate && !isWithinOneYear) {
                hasError = true;
                showErrorBorder = true;
                errorMessages.push(
                    `Last date of the ${
                        samplingEventsPerYear > 1 ? 'last' : ''
                    } event cannot occur more than 1 year after the start date of the ${samplingEventsPerYear > 1 ? 'first' : ''} event`
                );
            }

            // Number of sampling years must be provided for multi-year projects
            const isMultiYearProject = samplingCadence === ProjectSamplingCadenceType.MULTI_YEAR;
            const hasInvalidSamplingYears = !projectLifespanYears || projectLifespanYears < 2 || projectLifespanYears > 30;
            const hasSamplingYearsError = isMultiYearProject && hasInvalidSamplingYears;
            if (hasSamplingYearsError) {
                hasError = true;
                errorMessages.push(`Enter valid number of sampling years above`);
            }

            // Project end date should be within 6 months of the last year's last event end date
            if (projectEndDate && !hasSamplingYearsError) {
                const projectEndDateVal = moment(projectEndDate);
                const totalSamplingYears = !isMultiYearProject ? 1 : projectLifespanYears || 1;
                const lastEventEndDate = currentEventToDate.clone().add(totalSamplingYears - 1, 'years');
                const sixMonthsAfterLastEvent = lastEventEndDate.clone().add(6, 'months');
                if (projectEndDateVal.isBefore(lastEventEndDate) || projectEndDateVal.isAfter(sixMonthsAfterLastEvent)) {
                    hasError = true;
                    errorMessages.push('project end date error');
                }
            }
        }
    }

    return {
        hasError,
        errorMessages,
        showErrorBorder,
    };
};
