import { gql } from 'graphql-request';
import { ChartIds } from 'src/shared/types';
export const bacterialFunctionalDiversityMetricsQuery = gql`
    query bacterialFuncDivMetricsData($projectId: String!, $fromDate: String, $toDate: String, $habitatAssay: String!) {
        bacterialFunctionalDiversity: chart(
            projectId: $projectId, 
            chartId: "${ChartIds.BACTERIAL_FUNCTIONAL_DIVERSITY}", 
            habitatAssay: $habitatAssay,
            fromDate: $fromDate, 
            toDate: $toDate
        ) {
            data
        }
    }
`;

export type BacterialFunctionalDiversityMetricsChartData = {
    data: {
        data: {
            datetime: string;
            group: {
                type: string;
                value: number;
            }[];
        }[];
        metric: string;
    }[];
};

export type BacterialFunctionalDiversityMetricsQueryResult = {
    bacterialFunctionalDiversity: BacterialFunctionalDiversityMetricsChartData;
};
