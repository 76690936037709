import storageService, { StorageServiceInterface, StorageType } from 'src/services/storage-service';

export enum StorageKeys {
    CURRENT_PROJECT_ID = 'projects.currentProjectId',
}

const localStorageOptions = {
    storage: StorageType.SESSION_STORAGE,
};

export class ProjectsStorageService {
    private storageService: StorageServiceInterface;

    public constructor(storageService: StorageServiceInterface) {
        this.storageService = storageService;
    }

    public getCurrentProjectId(): string | null {
        return this.storageService.get(StorageKeys.CURRENT_PROJECT_ID, localStorageOptions);
    }

    public setCurrentProjectId(projectId: string | null): void {
        this.storageService.remove(StorageKeys.CURRENT_PROJECT_ID, localStorageOptions);

        this.storageService.save(StorageKeys.CURRENT_PROJECT_ID, projectId, localStorageOptions);
    }

    public clearAll(): void {
        this.storageService.remove(StorageKeys.CURRENT_PROJECT_ID, localStorageOptions);
    }
}

export default new ProjectsStorageService(storageService);
