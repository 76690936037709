import React from 'react';
import ChevronRight from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useAppDispatch, useAppSelector } from 'src/store';

import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import styles from './BottomNavigation.module.scss';
import { setCurrentStep } from '../../state/newProjectDefinitionSlice';
import { getCurrentProjectDefinitionFormStep } from '../../state/newProjectDefinitionSelector';
import useProjectDefinition from '../../hooks/useProjectDefinition';

const BottomNavigation = () => {
    const dispatch = useAppDispatch();
    const formStep = useAppSelector(getCurrentProjectDefinitionFormStep);

    const { hasCompletedCurrentStep } = useProjectDefinition();
    const onStepChange = (step: number) => {
        dispatch(setCurrentStep(step));
    };

    return (
        <div className={styles.bottomNavigation}>
            <SecondaryButton onClick={() => onStepChange(formStep - 1)} disabled={formStep === 0}>
                <ChevronLeft /> Back
            </SecondaryButton>

            <PrimaryButton onClick={() => onStepChange(formStep + 1)} disabled={!hasCompletedCurrentStep()}>
                Next <ChevronRight />
            </PrimaryButton>
        </div>
    );
};

export default BottomNavigation;
